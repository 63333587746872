const isDate = fullDate => {
  const isNotString = typeof fullDate !== 'string';
  if (isNotString) return false;

  const isNotIsoDate = !fullDate?.includes('T');
  if (isNotIsoDate) return false;

  const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
  const date = fullDate.split('T')[0];
  return isoDateRegex.test(date);
};

export default isDate;
