import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { defaultFontSize } from '../../config';

import './FontSizeControl.less';

const FontSizeControl = () => {
  const { formatMessage } = useIntl();

  const [fontSize, setFontSize] = useState(defaultFontSize);

  const changeFontSize = () => {
    document.documentElement.style.fontSize = fontSize + 'px';
  };

  React.useEffect(changeFontSize, [fontSize]);

  return (
    <ul className="font-size-control">
      <li>
        <button
          onClick={() => setFontSize(defaultFontSize)}
          aria-label="ant-btn"
          aria-pressed="false"
          className="ant-btn btn btn-transp btn-text"
        >
          A
        </button>
      </li>
      <li>
        <button
          onClick={() => setFontSize(defaultFontSize + 2)}
          aria-label={formatMessage({
            id: 'accessability_increase_font_size',
            defaultMessage: 'Increase font size'
          })}
          className="ant-btn btn btn-transp btn-text"
        >
          A
        </button>
      </li>
      <li>
        <button
          onClick={() => setFontSize(defaultFontSize + 4)}
          aria-label={formatMessage({
            id: 'accessability_increase_font_size',
            defaultMessage: 'Increase font size'
          })}
          className="ant-btn btn btn-transp btn-text"
        >
          A
        </button>
      </li>
    </ul>
  );
};

export default FontSizeControl;
