import { Component, Fragment, createRef, lazy } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { showError } from '../../store/actions/globalActions';
import {
  removeSelectedParcel,
  setSelectedParcel
} from '../../store/actions/mapActions';
import { mapPortalSelectors } from '../../store/selectors';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Icon, Tooltip } from 'antd';
import SuspenseWrapper from '../SuspenseWrapper';
import LoaderLayer from '../LoaderLayer';
import PrintTopicalSearchResultItem from '../PrintTopicalSearchResultItem';

import { MapPortalShape } from '../../shapes/mapPortal';
import { TopicalSearchToolResultColumnShape } from '../../shapes/topicalSearchTool';

import './TopicalSearchResultItem.less';

const PrintSettings = lazy(() => import('../PrintSettings'));

class TopicalSearchResultItem extends Component {
  state = {
    open:
      this.props.mapPortal.get('topical_search_tool_results_presentation') ===
      'unwrap',
    printModalVisible: false,
    printCardOrientation: 'portrait',
    isTruncated: false,
    showTooltip: false,
    showPrintItem: false
  };

  printerRef = createRef();
  titleRef = createRef();

  componentDidMount() {
    this.isTitleTruncated();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.resultId !== this.props.resultId) {
    }

    if (!prevState.printModalVisible && this.state.printModalVisible) {
      this.setState({ showPrintItem: true });
    }
  }

  showInnerItem = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  isTitleTruncated = () => {
    const { scrollWidth, clientWidth } = this.titleRef?.current;

    if (scrollWidth > clientWidth) {
      this.setState({ isTruncated: true });
    }
  };

  showGeometryResult = (event, geometry) => {
    event.stopPropagation();

    const selectedParcelData = { ...geometry, popupMaxZoom: true };
    this.props.setSelectedParcel(selectedParcelData);
  };

  removeSelectedParcel = event => {
    event.stopPropagation();
    this.props.removeSelectedParcel(this.props.resultId);
  };

  togglePrintModalVisibility = event => {
    event.stopPropagation();
    this.setState(prevState => ({
      printModalVisible: !prevState.printModalVisible,
      printCardOrientation: 'portrait'
    }));

    if (this.state.printModalVisible) {
      this.setState({ showPrintItem: false });
    }
  };

  displayTooltip = showTooltip => {
    if (this.state.isTruncated) {
      this.setState({ showTooltip });
    }
  };

  renderTitle = () => (
    <div
      className="sidebar-result-item-title"
      ref={this.titleRef}
      onMouseEnter={() => this.displayTooltip(true)}
      onMouseLeave={() => this.displayTooltip(false)}
    >
      {this.props.title}
    </div>
  );

  render() {
    const sidebarResultItemClass = this.props.innerItem
      ? this.state.open
        ? 'sidebar-result-item sidebar-result-item-selected'
        : 'sidebar-result-item'
      : 'sidebar-result-item-not-to-open';

    const { resultId, selectedParcel, intl } = this.props;
    const { geometry } = this.props;
    const isSelected =
      selectedParcel?.toJS().findIndex(({ id }) => id === resultId) > -1;

    return (
      <Fragment>
        <div
          className={sidebarResultItemClass}
          onClick={this.props.innerItem ? this.showInnerItem : null}
          style={this.props.style}
        >
          {this.state.isTruncated ? (
            <Tooltip visible={this.state.showTooltip} title={this.props.title}>
              {this.renderTitle()}
            </Tooltip>
          ) : (
            this.renderTitle()
          )}
          <div className={'sidebar-result-item-searchIcon'}>
            <Tooltip
              title={intl.formatMessage({
                id: 'district_portal_information_modal_printer_tooltip',
                defaultMessage: 'Print view of the "Information about object".'
              })}
            >
              <Icon type="printer" onClick={this.togglePrintModalVisibility} />
            </Tooltip>
          </div>
          <div className={'sidebar-result-item-searchIcon'}>
            {!!geometry?.geometry ? (
              <Tooltip
                title={
                  isSelected
                    ? intl.formatMessage({
                        id: 'district_portal_search_form_topical_search_remove',
                        defaultMessage: 'Remove selection'
                      })
                    : intl.formatMessage({
                        id: 'district_portal_search_form_topical_search_zoom',
                        defaultMessage: 'Zoom to geom'
                      })
                }
              >
                <Icon
                  type={isSelected ? 'close' : 'search'}
                  onClick={event =>
                    isSelected
                      ? this.removeSelectedParcel(event)
                      : this.showGeometryResult(event, geometry)
                  }
                />
              </Tooltip>
            ) : (
              ''
            )}
          </div>
        </div>

        <ReactCSSTransitionGroup
          transitionName="sidebar-result-item-animate"
          transitionEnterTimeout={400}
          transitionLeaveTimeout={400}
        >
          {(this.props.isOpened || this.state.open) && (
            <div className="sidebar-result-item_list">
              {this.props.children}
            </div>
          )}
        </ReactCSSTransitionGroup>
        {!!this.props.geometry && this.state.printModalVisible && (
          <PrintTopicalSearchResultItem
            title={this.props.title}
            getRef={el => (this.printerRef.current = el)}
            geometry={this.props.geometry}
            featureId={resultId}
            orientation={this.state.printCardOrientation}
            result={this.props.result}
            resultColumns={this.props.resultColumns}
          >
            {this.props.children}
          </PrintTopicalSearchResultItem>
        )}
        {this.state.printModalVisible && this.state.showPrintItem && (
          <SuspenseWrapper fallback={<LoaderLayer />}>
            <PrintSettings
              printViewRef={this.printerRef.current}
              onClose={this.togglePrintModalVisibility}
              modalTitle={intl.formatMessage({
                id: 'information_modal_print_title',
                defaultMessage: 'Print informations about object'
              })}
              width={900}
              onOrientationChange={orientation =>
                this.setState({ printCardOrientation: orientation })
              }
            />
          </SuspenseWrapper>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { prefix, portal } = ownProps.match.params;
  return {
    mapPortal: mapPortalSelectors.getMapPortalByShortName(
      state,
      prefix,
      portal
    ),
    selectedParcel: state.map.get('selectedParcel')
  };
};

const mapDispatchToProps = {
  setSelectedParcel,
  removeSelectedParcel,
  showError
};

TopicalSearchResultItem.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node.isRequired,
  isOpened: PropTypes.bool,
  nameValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  style: PropTypes.object,
  mapPortal: MapPortalShape,
  removeSelectedParcel: PropTypes.func.isRequired,
  result: PropTypes.object.isRequired,
  resultColumns: PropTypes.oneOfType([
    PropTypes.arrayOf(TopicalSearchToolResultColumnShape),
    ImmutablePropTypes.listOf(TopicalSearchToolResultColumnShape)
  ]).isRequired
};

export default injectIntl(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TopicalSearchResultItem)
  )
);
