import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Brand.less';

const PureBrandComponent = ({
  darkTitle,
  inverse,
  className,
  isLinkEnabled,
  onClick,
  settingsLogo,
  settingsTitle,
  settingsSubtitle
}) => (
  <div
    className={classNames('brand', className, {
      'brand-inverse': inverse,
      'brand-dark': darkTitle
    })}
    onClick={!isLinkEnabled ? onClick : undefined}
    aria-label="System title"
    role="heading"
    aria-level="0"
  >
    {settingsLogo && <img src={settingsLogo} alt="SIP logo" />}
    <span className="brand-title">
      <h1 className="brand-title_h1" id="system_title">
        {settingsTitle || ''}
      </h1>
      <span id="system_second_title">{settingsSubtitle || ''}</span>
    </span>
  </div>
);

PureBrandComponent.propTypes = {
  className: PropTypes.string,
  inverse: PropTypes.bool,
  isLinkEnabled: PropTypes.bool,
  onClick: PropTypes.func,
  settingsLogo: PropTypes.string,
  settingsTitle: PropTypes.string,
  settingsSubtitle: PropTypes.string
};

export default PureBrandComponent;
