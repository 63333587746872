import { Component } from 'react';
import isEqual from 'react-fast-compare';
import classNames from 'classnames';

import NavSidebarItem from '../NavSidebarItem/';
import Button from '../Button';
import NavSidebarHamburger from '../NavSidebarHamburger';
import { Icon } from 'antd';

import IconData from '../../icons/Data';
import IconLayers from '../../icons/Layers';
import IconSearch from '../../icons/Search';

import sidebarItemsEnums from '../Sidebar/sidebarEnums';

import isItIframe from '../../utils/isItIframe';

import './NavSidebar.less';

export const collapseNavSidebar = () =>
  document.body.classList.toggle('mobile-nav-header-collapsed');

export default class NavSidebar extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  render() {
    return (
      <div
        className={classNames('nav-sidebar', {
          'nav-sidebar-iframe': isItIframe()
        })}
      >
        <nav className="nav-sidebar_items">
          <NavSidebarItem
            icon={<IconData />}
            toOpen={sidebarItemsEnums.compositions}
          />
          <NavSidebarItem
            icon={<IconLayers />}
            toOpen={sidebarItemsEnums.layers}
          />
          <NavSidebarItem
            icon={<IconSearch />}
            toOpen={sidebarItemsEnums.search_tools}
          />
          <NavSidebarHamburger />
        </nav>
        <Button className="nav-sidebar-collapser" onClick={collapseNavSidebar}>
          <Icon type="caret-up" />
        </Button>
      </div>
    );
  }
}
