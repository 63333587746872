import createAxiosInstance from '../../utils/createAxiosInstance';

const getWmsInfo = ({ link, serviceType, version }) => {
  let counter = 0;
  const axiosInstance = createAxiosInstance();
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      counter++;
      if (counter < 3) {
        return axiosInstance.request({
          method: error.response.config.method,
          url: error.response.config.url
        });
      }
      return error;
    }
  );

  return new Promise((resolve, reject) => {
    return axiosInstance
      .get(
        `/wms_information/?link=${link}&service_type=${serviceType}&version=${version}`
      )
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
export default getWmsInfo;
