import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export const TopicalSearchToolShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  prompt: PropTypes.string,
  content_type: PropTypes.number.isRequired
});

export const TopicalSearchToolsShape = ImmutablePropTypes.listOf(
  TopicalSearchToolShape
);

export const TopicalSearchToolColumnShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayable_name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  topical_search_tool: PropTypes.number.isRequired
});

export const TopicalSearchToolColumnsShape = ImmutablePropTypes.listOf(
  TopicalSearchToolColumnShape
);

export const TopicalSearchToolResultColumnShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayable_name: PropTypes.string.isRequired,
  topical_search_tool: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
});

export const TopicalSearchToolResultColumnsShape = ImmutablePropTypes.listOf(
  TopicalSearchToolResultColumnShape
);

export const TopicalSearchLayerLookupFieldsShape = ImmutablePropTypes.listOf(
  PropTypes.string
);

export const TopicalSearchLayerResultFieldsShape = ImmutablePropTypes.listOf(
  PropTypes.string
);

export const TopicalSearchLayerFieldSearchTypesShape = ImmutablePropTypes.map;
