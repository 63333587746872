import { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

const AdminReportData = memo(function AdminReportData(props) {
  return (
    <Fragment>
      <h2>{props.title}</h2>
      {Object.keys(props.allData).map((data, i) => {
        return (
          <Row key={i} className={`single-report`}>
            <Col span={12}>{data}</Col>
            <Col span={12} style={{ wordBreak: 'break-word' }}>
              {props.allData[data]}
            </Col>
          </Row>
        );
      })}
    </Fragment>
  );
});

AdminReportData.propTypes = {
  allData: PropTypes.object,
  title: PropTypes.string
};

export default AdminReportData;
