import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import MapContext from '../../contexts/LeafletMapContext';

import { Icon } from 'antd';

const MapLoader = () => {
  const { formatMessage } = useIntl();
  const map = useContext(MapContext).getLeafletElement();

  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    const onLayerLoadingStart = () => setIsLoading(true);
    const onLayerLoadingEnd = ({ isLoadedAll }) =>
      isLoadedAll && setIsLoading(false);

    map.on('layerLoadingStart', onLayerLoadingStart);
    map.on('layerLoadingEnd', onLayerLoadingEnd);

    return () => {
      map.off('layerLoadingStart', onLayerLoadingStart);
      map.off('layerLoadingEnd', onLayerLoadingEnd);
    };
  }, []);

  const loadingText = formatMessage({
    id: 'map_loader_loading',
    defaultMessage: 'Loading'
  });

  const readyText = formatMessage({
    id: 'map_loader_ready',
    defaultMessage: 'Ready'
  });

  return (
    <span
      style={{
        display: 'block',
        whiteSpace: 'nowrap',
        textAlign: 'left'
      }}
    >
      {isLoading && <Icon type="loading" />}{' '}
      {isLoading ? loadingText : readyText}
    </span>
  );
};

export default MapLoader;
