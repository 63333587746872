import PropTypes from 'prop-types';

import { Icon } from 'antd';

import './LinkWithIcon.less';

const LinkWithIcon = props => {
  const blank = props.blank
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};

  return (
    <a
      className="link-with-icon"
      href={props.href}
      {...blank}
      onClick={props.onClick}
    >
      <Icon type={props.icon} /> {props.text || props.href}
    </a>
  );
};

LinkWithIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
  blank: PropTypes.bool,
  onClick: PropTypes.func
};

export default LinkWithIcon;
