import { useIntl } from 'react-intl';

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = {
    toolbarName: formatMessage({
      id: 'district_portal_toolbar_map_settings',
      defaultMessage: 'Map tools'
    }),
    mode: formatMessage({
      id: 'district_portal_map_toolbar_advanced_mode',
      defaultMessage: 'advanced mode'
    }),
    close: formatMessage({
      id: 'general_close',
      defaultMessage: 'Close'
    }),
    settings: formatMessage({
      id: 'district_portal_toolbar_settings',
      defaultMessage: 'Toolbar settings'
    })
  };

  return { translations };
};

export default useTranslations;
