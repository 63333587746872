export const ADD_POPUP_CONFIG_BEGIN = 'ADD_POPUP_CONFIG_BEGIN';
export const ADD_POPUP_CONFIG_SUCCESS = 'ADD_POPUP_CONFIG_SUCCESS';
export const ADD_POPUP_CONFIG_FAILED = 'ADD_POPUP_CONFIG_FAILED';

export const DELETE_POPUP_CONFIG_BEGIN = 'DELETE_POPUP_CONFIG_BEGIN';
export const DELETE_POPUP_CONFIG_SUCCESS = 'DELETE_POPUP_CONFIG_SUCCESS';
export const DELETE_POPUP_CONFIG_FAILED = 'DELETE_POPUP_CONFIG_FAILED';

export const FETCH_POPUP_CONFIGS_BEGIN = 'FETCH_POPUP_CONFIGS_BEGIN';
export const FETCH_POPUP_CONFIGS_SUCCESS = 'FETCH_POPUP_CONFIGS_SUCCESS';
export const FETCH_POPUP_CONFIGS_FAILED = 'FETCH_POPUP_CONFIGS_FAILED';

export const FETCH_POPUP_CONFIG_BEGIN = 'FETCH_POPUP_CONFIG_BEGIN';
export const FETCH_POPUP_CONFIG_SUCCESS = 'FETCH_POPUP_CONFIG_SUCCESS';
export const FETCH_POPUP_CONFIG_FAILED = 'FETCH_POPUP_CONFIG_FAILED';

export const FETCH_POPUP_LAYERS_CONFIG_BEGIN =
  'FETCH_POPUP_LAYERS_CONFIG_BEGIN';
export const FETCH_POPUP_LAYERS_CONFIG_SUCCESS =
  'FETCH_POPUP_LAYERS_CONFIG_SUCCESS';
export const FETCH_POPUP_LAYERS_CONFIG_FAILED =
  'FETCH_POPUP_LAYERS_CONFIG_FAILED';

export const ADD_POPUP_LAYER_CONFIG_BEGIN = 'ADD_POPUP_LAYER_CONFIG_BEGIN';
export const ADD_POPUP_LAYER_CONFIG_SUCCESS = 'ADD_POPUP_LAYER_CONFIG_SUCCESS';
export const ADD_POPUP_LAYER_CONFIG_FAILED = 'ADD_POPUP_LAYER_CONFIG_FAILED';

export const EDIT_POPUP_LAYER_CONFIG_BEGIN = 'EDIT_POPUP_LAYER_CONFIG_BEGIN';
export const EDIT_POPUP_LAYER_CONFIG_SUCCESS =
  'EDIT_POPUP_LAYER_CONFIG_SUCCESS';
export const EDIT_POPUP_LAYER_CONFIG_FAILED = 'EDIT_POPUP_LAYER_CONFIG_FAILED';

export const DELETE_POPUP_LAYER_CONFIG_BEGIN =
  'DELETE_POPUP_LAYER_CONFIG_BEGIN';
export const DELETE_POPUP_LAYER_CONFIG_SUCCESS =
  'DELETE_POPUP_LAYER_CONFIG_SUCCESS';
export const DELETE_POPUP_LAYER_CONFIG_FAILED =
  'DELETE_POPUP_LAYER_CONFIG_FAILED';

export const SET_POPUP_LAYER_CONFIG_ORDER_BEGIN =
  'SET_POPUP_LAYER_CONFIG_ORDER_BEGIN';
export const SET_POPUP_LAYER_CONFIG_ORDER_SUCCESS =
  'SET_POPUP_LAYER_CONFIG_ORDER_SUCCESS';
export const SET_POPUP_LAYER_CONFIG_ORDER_FAILED =
  'SET_POPUP_LAYER_CONFIG_ORDER_FAILED';

export const GET_LAYER_CONFIG_FOR_COORD_BEGIN =
  'GET_LAYER_CONFIG_FOR_COORD_BEGIN';
export const GET_LAYER_CONFIG_FOR_COORD_SUCCESS =
  'GET_LAYER_CONFIG_FOR_COORD_SUCCESS';
export const GET_LAYER_CONFIG_FOR_COORD_FAILED =
  'GET_LAYER_CONFIG_FOR_COORD_FAILED';

export const GET_LAYER_SEARCH_LIST_BEGIN = 'GET_LAYER_SEARCH_LIST_BEGIN';
export const GET_LAYER_SEARCH_LIST_SUCCESS = 'GET_LAYER_SEARCH_LIST_SUCCESS';
export const GET_LAYER_SEARCH_LIST_FAILED = 'GET_LAYER_SEARCH_LIST_FAILED';

export const SET_COORDINATES_TO_IDENTIFICATION =
  'SET_COORDINATES_TO_IDENTIFICATION';
export const CLEAR_COORDINATES_TO_IDENTIFICATION =
  'CLEAR_COORDINATES_TO_IDENTIFICATION';

export const FETCH_ATTACHMENTS_BEGIN = 'FETCH_ATTACHMENTS_BEGIN';
export const FETCH_ATTACHMENTS_SUCCESS = 'FETCH_ATTACHMENTS_SUCCESS';
export const FETCH_ATTACHMENTS_FAILED = 'FETCH_ATTACHMENTS_FAILED';

export const FETCH_LAYER_ATTRIBUTES_BEGIN = 'FETCH_LAYER_ATTRIBUTES_BEGIN';
export const FETCH_LAYER_ATTRIBUTES_SUCCESS = 'FETCH_LAYER_ATTRIBUTES_SUCCESS';
export const FETCH_LAYER_ATTRIBUTES_FAILED = 'FETCH_LAYER_ATTRIBUTES_FAILED';

export const PATCH_LAYER_ATTRIBUTE_BEGIN = 'PATCH_LAYER_ATTRIBUTE_BEGIN';
export const PATCH_LAYER_ATTRIBUTE_SUCCESS = 'PATCH_LAYER_ATTRIBUTE_SUCCESS';
export const PATCH_LAYER_ATTRIBUTE_FAILED = 'PATCH_LAYER_ATTRIBUTE_FAILED';

export const OPEN_INFORMATION_MODAL = 'OPEN_INFORMATION_MODAL';
export const CLOSE_INFORMATION_MODAL = 'CLOSE_INFORMATION_MODAL';

export const MINIMIZE_INFORMATION_MODAL = 'MINIMIZE_INFORMATION_MODAL';
export const MAXIMIZE_INFORMATION_MODAL = 'MAXIMIZE_INFORMATION_MODAL';

export const FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_BEGIN =
  'FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_BEGIN';
export const FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_SUCCESS =
  'FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_SUCCESS';
export const FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_FAILED =
  'FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_FAILED';

export const FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_BEGIN =
  'FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_BEGIN';
export const FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_SUCCESS =
  'FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_SUCCESS';
export const FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_FAILED =
  'FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_FAILED';

export const UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_BEGIN =
  'UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_BEGIN';
export const UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_SUCCESS =
  'UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_SUCCESS';
export const UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_FAILED =
  'UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_FAILED';

export const UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_BEGIN =
  'UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_BEGIN';
export const UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_SUCCESS =
  'UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_SUCCESS';
export const UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_FAILED =
  'UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_FAILED';

export const DESKTOP_ADD_POPUP_CONFIG_BEGIN = 'DESKTOP_ADD_POPUP_CONFIG_BEGIN';
export const DESKTOP_ADD_POPUP_CONFIG_SUCCESS =
  'DESKTOP_ADD_POPUP_CONFIG_SUCCESS';
export const DESKTOP_ADD_POPUP_CONFIG_FAILED =
  'DESKTOP_ADD_POPUP_CONFIG_FAILED';

export const DESKTOP_EDIT_POPUP_CONFIG_BEGIN =
  'DESKTOP_EDIT_POPUP_CONFIG_BEGIN';
export const DESKTOP_EDIT_POPUP_CONFIG_SUCCESS =
  'DESKTOP_EDIT_POPUP_CONFIG_SUCCESS';
export const DESKTOP_EDIT_POPUP_CONFIG_FAILED =
  'DESKTOP_EDIT_POPUP_CONFIG_FAILED';

export const DESKTOP_DELETE_POPUP_CONFIG_BEGIN =
  'DESKTOP_DELETE_POPUP_CONFIG_BEGIN';
export const DESKTOP_DELETE_POPUP_CONFIG_SUCCESS =
  'DESKTOP_DELETE_POPUP_CONFIG_SUCCESS';
export const DESKTOP_DELETE_POPUP_CONFIG_FAILED =
  'DESKTOP_DELETE_POPUP_CONFIG_FAILED';

export const DESKTOP_FETCH_POPUP_CONFIGS_BEGIN =
  'DESKTOP_FETCH_POPUP_CONFIGS_BEGIN';
export const DESKTOP_FETCH_POPUP_CONFIGS_SUCCESS =
  'DESKTOP_FETCH_POPUP_CONFIGS_SUCCESS';
export const DESKTOP_FETCH_POPUP_CONFIGS_FAILED =
  'DESKTOP_FETCH_POPUP_CONFIGS_FAILED';

export const DESKTOP_ADD_POPUP_LAYER_CONFIG_BEGIN =
  'DESKTOP_ADD_POPUP_LAYER_CONFIG_BEGIN';
export const DESKTOP_ADD_POPUP_LAYER_CONFIG_SUCCESS =
  'DESKTOP_ADD_POPUP_LAYER_CONFIG_SUCCESS';
export const DESKTOP_ADD_POPUP_LAYER_CONFIG_FAILED =
  'DESKTOP_ADD_POPUP_LAYER_CONFIG_FAILED';

export const FETCH_POPUP_LAYER_DEFAULT_ATTRIBUTES_BEGIN =
  'FETCH_POPUP_LAYER_DEFAULT_ATTRIBUTES_BEGIN';
export const FETCH_POPUP_LAYER_DEFAULT_ATTRIBUTES_SUCCESS =
  'FETCH_POPUP_LAYER_DEFAULT_ATTRIBUTES_SUCCESS';
export const FETCH_POPUP_LAYER_DEFAULT_ATTRIBUTES_FAILED =
  'FETCH_POPUP_LAYER_DEFAULT_ATTRIBUTES_FAILED';

export const ADD_POPUP_LAYER_ATTRIBUTE_BEGIN =
  'ADD_POPUP_LAYER_ATTRIBUTE_BEGIN';
export const ADD_POPUP_LAYER_ATTRIBUTE_SUCCESS =
  'ADD_POPUP_LAYER_ATTRIBUTE_SUCCESS';
export const ADD_POPUP_LAYER_ATTRIBUTE_FAILED =
  'ADD_POPUP_LAYER_ATTRIBUTE_FAILED';

export const EDIT_POPUP_LAYER_ATTRIBUTE_BEGIN =
  'EDIT_POPUP_LAYER_ATTRIBUTE_BEGIN';
export const EDIT_POPUP_LAYER_ATTRIBUTE_SUCCESS =
  'EDIT_POPUP_LAYER_ATTRIBUTE_SUCCESS';
export const EDIT_POPUP_LAYER_ATTRIBUTE_FAILED =
  'EDIT_POPUP_LAYER_ATTRIBUTE_FAILED';

export const DELETE_POPUP_LAYER_ATTRIBUTE_BEGIN =
  'DELETE_POPUP_LAYER_ATTRIBUTE_BEGIN';
export const DELETE_POPUP_LAYER_ATTRIBUTE_SUCCESS =
  'DELETE_POPUP_LAYER_ATTRIBUTE_SUCCESS';
export const DELETE_POPUP_LAYER_ATTRIBUTE_FAILED =
  'DELETE_POPUP_LAYER_ATTRIBUTE_FAILED';

export const DESKTOP_ADD_LAYER_TO_POPUP_STRUCTURE =
  'DESKTOP_ADD_LAYER_TO_POPUP_STRUCTURE';

export const DESKTOP_CLEAR_POPUP_STRUCTURE = 'DESKTOP_CLEAR_POPUP_STRUCTURE';

export const SET_POPUP_CARD_TREE = 'SET_POPUP_CARD_TREE';
export const SET_POPUP_CARD_LOADING_LAYER_STATUS =
  'SET_POPUP_CARD_LOADING_LAYER_STATUS';

export const ADD_POPUP_LAYER_JOIN_BEGIN = 'ADD_POPUP_LAYER_JOIN_BEGIN';
export const ADD_POPUP_LAYER_JOIN_SUCCESS = 'ADD_POPUP_LAYER_JOIN_SUCCESS';
export const ADD_POPUP_LAYER_JOIN_FAILED = 'ADD_POPUP_LAYER_JOIN_FAILED';

export const FETCH_POPUP_LAYER_JOINS_BEGIN = 'FETCH_POPUP_LAYER_JOINS_BEGIN';
export const FETCH_POPUP_LAYER_JOINS_SUCCESS =
  'FETCH_POPUP_LAYER_JOINS_SUCCESS';
export const FETCH_POPUP_LAYER_JOINS_FAILED = 'FETCH_POPUP_LAYER_JOINS_FAILED';

export const DELETE_POPUP_LAYER_JOIN_BEGIN = 'DELETE_POPUP_LAYER_JOIN_BEGIN';
export const DELETE_POPUP_LAYER_JOIN_SUCCESS =
  'DELETE_POPUP_LAYER_JOIN_SUCCESS';
export const DELETE_POPUP_LAYER_JOIN_FAILED = 'DELETE_POPUP_LAYER_JOIN_FAILED';

export const UPDATE_POPUP_LAYER_JOIN_BEGIN = 'UPDATE_POPUP_LAYER_JOIN_BEGIN';
export const UPDATE_POPUP_LAYER_JOIN_SUCCESS =
  'UPDATE_POPUP_LAYER_JOIN_SUCCESS';
export const UPDATE_POPUP_LAYER_JOIN_FAILED = 'UPDATE_POPUP_LAYER_JOIN_FAILED';

export const FETCH_POPUP_LAYER_JOIN_ATTRIBUTES_BEGIN =
  'FETCH_POPUP_LAYER_JOIN_ATTRIBUTES_BEGIN';
export const FETCH_POPUP_LAYER_JOIN_ATTRIBUTES_SUCCESS =
  'FETCH_POPUP_LAYER_JOIN_ATTRIBUTES_SUCCESS';
export const FETCH_POPUP_LAYER_JOIN_ATTRIBUTES_FAILED =
  'FETCH_POPUP_LAYER_JOIN_ATTRIBUTES_FAILED';

export const ADD_POPUP_LAYER_JOIN_ATTRIBUTES_BEGIN =
  'ADD_POPUP_LAYER_JOIN_ATTRIBUTES_BEGIN';
export const ADD_POPUP_LAYER_JOIN_ATTRIBUTES_SUCCESS =
  'ADD_POPUP_LAYER_JOIN_ATTRIBUTES_SUCCESS';
export const ADD_POPUP_LAYER_JOIN_ATTRIBUTES_FAILED =
  'ADD_POPUP_LAYER_JOIN_ATTRIBUTES_FAILED';

export const DELETE_POPUP_LAYER_JOIN_ATTRIBUTE_BEGIN =
  'DELETE_POPUP_LAYER_JOIN_ATTRIBUTE_BEGIN';
export const DELETE_POPUP_LAYER_JOIN_ATTRIBUTE_SUCCESS =
  'DELETE_POPUP_LAYER_JOIN_ATTRIBUTE_SUCCESS';
export const DELETE_POPUP_LAYER_JOIN_ATTRIBUTE_FAILED =
  'DELETE_POPUP_LAYER_JOIN_ATTRIBUTE_FAILED';

export const UPDATE_POPUP_LAYER_JOIN_ATTRIBUTE_BEGIN =
  'UPDATE_POPUP_LAYER_JOIN_ATTRIBUTE_BEGIN';
export const UPDATE_POPUP_LAYER_JOIN_ATTRIBUTE_SUCCESS =
  'UPDATE_POPUP_LAYER_JOIN_ATTRIBUTE_SUCCESS';
export const UPDATE_POPUP_LAYER_JOIN_ATTRIBUTE_FAILED =
  'UPDATE_POPUP_LAYER_JOIN_ATTRIBUTE_FAILED';

export const UPDATE_PLOT_LIST_INFORMATION = 'UPDATE_PLOT_LIST_INFORMATION';
export const UPDATE_ROAD_LIST_INFORMATION = 'UPDATE_ROAD_LIST_INFORMATION';

export const SET_MULTIPLE_COORDINATES_TO_IDENTIFICATION =
  'SET_MULTIPLE_COORDINATES_TO_IDENTIFICATION';
export const CLEAR_MULTIPLE_COORDINATES_TO_IDENTIFICATION =
  'CLEAR_MULTIPLE_COORDINATES_TO_IDENTIFICATION';

export const DOWNLOAD_POPUP_DATA_BEGIN = 'DOWNLOAD_POPUP_DATA_BEGIN';
export const DOWNLOAD_POPUP_DATA_SUCCESS = 'DOWNLOAD_POPUP_DATA_SUCCESS';
export const DOWNLOAD_POPUP_DATA_FAILED = 'DOWNLOAD_POPUP_DATA_FAILED';

export const FETCH_DEFAULT_LOGIC_PRIMARY_KEYS_STARTED =
  'FETCH_DEFAULT_LOGIC_PRIMARY_KEYS_STARTED';
export const FETCH_DEFAULT_LOGIC_PRIMARY_KEYS_FINISHED =
  'FETCH_DEFAULT_LOGIC_PRIMARY_KEYS_FINISHED';

export const GET_QRCODE_FOR_OBJECT_STARTED = 'GET_QRCODE_FOR_OBJECT_STARTED';
export const GET_QRCODE_FOR_OBJECT_FINISHED = 'GET_QRCODE_FOR_OBJECT_FINISHED';
