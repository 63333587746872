import { useIntl } from 'react-intl';

export const useToolbarItemTranslation = () => {
  const { formatMessage } = useIntl();

  const closeInformationTool = formatMessage({
    id: 'close_identification_tool',
    defaultMessage: 'Close information tool'
  });

  const activateTool = formatMessage({
    id: 'activate_tool',
    defaultMessage: 'Activate tool'
  });

  const deactivateTool = formatMessage({
    id: 'deactivate_tool',
    defaultMessage: 'Deactivate tool'
  });

  return { closeInformationTool, activateTool, deactivateTool };
};
