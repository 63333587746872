import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Icon, Tooltip } from 'antd';

import copy from '../../utils/copyToClipboard';

import Button from '../Button';

import './CopyButton.less';

export class CopyButton extends Component {
  constructor(props) {
    super(props);

    this.copy = this.copy.bind(this);
  }

  copy() {
    const isCopied = copy(this.props.textToCopy);

    if (isCopied) {
      this.props.onSuccess();
    } else {
      this.props.onFailed();
    }
  }

  render() {
    const { formatMessage } = this.props.intl;

    const tooltipId = this.props.share
      ? 'district_portal_save_map_share'
      : 'general_copy';
    const defaultMessage = this.props.share ? 'Share' : 'Copy';
    const tooltipTitle = formatMessage({ id: tooltipId, defaultMessage });
    const copyButtonLabel = formatMessage({
      id: 'general_copy',
      defaultMessage: 'Copy'
    });

    return (
      <Tooltip title={tooltipTitle} overlayClassName="custom-tooltip">
        <Button
          disabled={this.props.disabled}
          className={this.props.className || ''}
          onClick={this.copy}
          variant={this.props.variant || 'text'}
          aria-label={copyButtonLabel}
        >
          {<Icon type={this.props.share ? 'share-alt' : 'copy'} />}
        </Button>
      </Tooltip>
    );
  }
}

CopyButton.propTypes = {
  textToCopy: PropTypes.string.isRequired,
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  onFailed: PropTypes.func,
  variant: PropTypes.string,
  share: PropTypes.bool,
  disabled: PropTypes.bool
};

export default injectIntl(CopyButton);
