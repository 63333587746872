module.exports = {
  adminRoutePaths: {
    dashboard: '',
    shareEdits: '/udostepnij-edycje',
    confirmEdits: '/potwierdz-edycje',
    confirmEditsDetails: '/potwierdz-edycje/szczegoly/:id',
    browseMapPortals: '/portale-mapowe',
    addMapPortal: '/portale-mapowe/dodaj',
    editMapPortal: '/portale-mapowe/edytuj',
    editSelectedMapPortal: '/portale-mapowe/edytuj/:id/:tab?',
    browseProjects: '/projekty',
    addProject: '/projekty/dodaj',
    projectLogs: '/projekty/historia-aktualizacji',
    projectSettings: '/projekty/ustawienia/:id/:tab',
    users: '/uzytkownicy',
    usersBasic: '/uzytkownicy?role=basic',
    usersBasicPlus: '/uzytkownicy?role=basic_plus',
    usersAdvanced: '/uzytkownicy?role=advanced',
    usersAdministrator: '/uzytkownicy?role=administrator',
    browseThemes: '/motywy-lokalne',
    addTheme: '/motywy-lokalne/dodaj',
    editTheme: '/motywy-lokalne/edytuj',
    editSelectedTheme: '/motywy-lokalne/edytuj/:id',
    browseGlobalThemes: '/motywy-globalne',
    addGlobalTheme: '/motywy-globalne/dodaj',
    editGlobalTheme: '/motywy-globalne/edytuj',
    editSelectedGlobalTheme: '/motywy-globalne/edytuj/:id',
    compositions: '/kompozycje',
    userProfile: '/profil',
    reports: '/zgloszenia',
    singleReport: '/zgloszenia/:id',
    reportSubscriptions: '/zgloszenia/subskrypcje',
    reportCategories: '/zgloszenia/kategorie',
    reportColors: '/zgloszenia/ustawienia',
    locations: '/zgloszenia/lokalizacje',
    groups: '/grupy',
    groupsAdd: '/grupy/dodaj',
    groupsEdit: '/grupy/edytuj/:id',
    localSettings: '/ustawienia/lokalne/:tab',
    browseWms: '/wms',
    addWms: '/wms/dodaj',
    statuses: '/zgloszenia/statusy',
    typeReports: '/zgloszenia/rodzaje-zgloszen'
  },
  superadminRoutePaths: {
    addLanguage: '/jezyki/dodaj',
    permissions: '/uprawnienia',
    addUser: '/uzytkownicy/dodaj',
    browseLanguages: '/jezyki',
    editLayers: '/edycja-warstw-atrybutow',
    globalSettings: '/ustawienia/globalne/:tab',
    listPortals: '/ustawienia/lista-portali',
    usersSettings: '/uzytkownicy/ustawienia'
  }
};
