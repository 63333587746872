import { useCallback } from 'react';
import { feature } from '@turf/helpers';

import { useEditorToolsContext } from '../../contexts/EditorToolsContext';

import useMap from '../useMap';

import { getSingleGeometryType, fitLayerView } from '../../utils/mapUtils';

const useEnableEdition = () => {
  const map = useMap();

  const {
    setEditionId,
    setSketchType,
    setLoadedSketch,
    setIsEditorActive
  } = useEditorToolsContext();

  const enableSketchEdition = useCallback(sketchData => {
    const { geometry, symbolization, labelingState } = sketchData;

    const geometryType = getSingleGeometryType(geometry.type);
    const config = { symbolization, labelingState, geometry };

    const id = map.editLayer(feature(geometry), geometry.type, config);
    map.createLayer({ geometryType });

    setEditionId(id);
    setSketchType(geometryType);
    setIsEditorActive(true);
    setLoadedSketch(sketchData);

    const edition = map.getEdition(id);
    fitLayerView(map, edition.getLayer());
  }, []);

  return enableSketchEdition;
};

export default useEnableEdition;
