import { useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { districtSelectors } from '../../store/selectors';
import { fetchDistrictRolesWithSuperAdmin } from '../../store/actions/districtActions';

const {
  getIsFetchingDistrictRolesWithSuperUser,
  getDistrictRolesWithSuperUser
} = districtSelectors;

const useDistrictRoles = ({ district }) => {
  const dispatch = useDispatch();

  const isFetching = useSelector(getIsFetchingDistrictRolesWithSuperUser);
  const roles = useSelector(state =>
    getDistrictRolesWithSuperUser(state, district)
  );

  const districtRoles = useMemo(
    () =>
      !!roles?.length ? [{ id: 0, name: 'Publiczna', index: 0 }, ...roles] : [],
    [roles]
  );

  const fetchRoles = useCallback(() => {
    if (!roles && district) {
      dispatch(fetchDistrictRolesWithSuperAdmin(district));
    }
  }, [roles, district]);

  useEffect(fetchRoles, [fetchRoles]);

  return { isFetching, districtRoles };
};

export default useDistrictRoles;
