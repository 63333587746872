import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, Input } from 'antd';
import { useIntl } from 'react-intl';

import Button from '../Button';

import './NumberPagination.less';

const selectedPageStyles = { fontWeight: 'bold', textDecoration: 'underline' };
const nonSelectedPageStyles = { fontWeight: '', textDecoration: 'none' };
const baseInputSearchWidth = 55;
/**
 * Komponent renderujący paginację w formacie liczbowym:
 * 1) Przykładowy wygląd dla 5 stron z aktywną 2 stroną: 1 2 3 4 5
 * 2) Przykładowy wygląd dla 15 stron z aktywną 7 stroną: 1 ... 6 7 5 ... 15
 *
 * page - aktualna strona
 * lastPage - ostatnia możliwa strona
 * wrapFromPage - do ktorej strony ma się wyświetlać wariant 1), domyślnie 6
 * onChangePage - funckja aktualizujaca aktualną stronę
 *
 * @param {*} props
 */
const NumberPagination = ({
  page,
  lastPage,
  wrapFromPage,
  onChangePage,
  allowDirectInput
}) => {
  const [inputValue, setInputValue] = useState('');
  const [currentInputWidth, setCurrentInputWidth] = useState(
    baseInputSearchWidth
  );

  const { formatMessage } = useIntl();

  const goToPage = formatMessage({
    defaultMessage: 'Go to page',
    id: 'go_to_page'
  });

  const calculateInputWidth = (
    inputValue,
    baseWidth = baseInputSearchWidth,
    widthPerDigit = 5
  ) => baseWidth + String(inputValue).length * widthPerDigit;

  useEffect(() => {
    setCurrentInputWidth(calculateInputWidth(inputValue));
  }, [inputValue]);

  useEffect(() => {
    setInputValue(page);
  }, [page]);

  const handleInputChange = ({ target: { value } }) => {
    if (value === '' || (value >= 1 && value <= lastPage)) setInputValue(value);
  };

  const goToInputPage = () => {
    if (inputValue) {
      onChangePage(Number(inputValue));
    }
  };

  const renderPages = () => {
    let buttons = [];

    if (lastPage <= wrapFromPage) {
      for (let i = 1; i < lastPage + 1; i++) {
        buttons.push(
          <Button
            key={i}
            className="number-pagination"
            style={i === page ? selectedPageStyles : nonSelectedPageStyles}
            onClick={() => onChangePage(i)}
          >
            {i}
          </Button>
        );
      }
    } else {
      if (page > 3)
        buttons.push(
          <Button key="nothing" className="number-pagination">
            ...
          </Button>
        );
      if (page === lastPage) {
        buttons.push(
          <Button
            className="number-pagination"
            onClick={() => onChangePage(page - 2)}
            key={page - 2}
          >
            {page - 2}
          </Button>
        );
      }

      if (page > 2)
        buttons.push(
          <Button
            className="number-pagination"
            onClick={() => onChangePage(page - 1)}
            key={page - 1}
          >
            {page - 1}
          </Button>
        );

      if (page !== 1 && page !== lastPage)
        buttons.push(
          <Button
            key={page}
            className="number-pagination"
            style={selectedPageStyles}
          >
            {page}
          </Button>
        );

      if (page < lastPage - 1)
        buttons.push(
          <Button
            key={page + 1}
            className="number-pagination"
            onClick={() => onChangePage(page + 1)}
          >
            {page + 1}
          </Button>
        );

      if (page === 1)
        buttons.push(
          <Button
            className="number-pagination"
            onClick={() => onChangePage(page + 2)}
            key={page + 2}
            style={nonSelectedPageStyles}
          >
            {page + 2}
          </Button>
        );

      if (page < lastPage - 2)
        buttons.push(<Button className="number-pagination">...</Button>);

      buttons = [
        <Button
          key={1}
          className="number-pagination"
          style={page === 1 ? selectedPageStyles : nonSelectedPageStyles}
          onClick={() => onChangePage(1)}
        >
          {1}
        </Button>,
        ...buttons
      ];

      buttons.push(
        <Button
          className="number-pagination"
          onClick={() => onChangePage(lastPage)}
          style={page === lastPage ? selectedPageStyles : nonSelectedPageStyles}
          key={lastPage}
        >
          {lastPage}
        </Button>
      );
    }
    if (allowDirectInput) {
      buttons.push(
        <div className="number-pagination-input-wrapper">
          <span>{goToPage}</span>
          <div
            className="boundary-finder-wrapper"
            style={{ width: `${currentInputWidth}px` }}
          >
            <Input
              className="no-arrows"
              type="number"
              value={inputValue}
              onChange={handleInputChange}
              onPressEnter={goToInputPage}
            />
            <Button onClick={goToInputPage} className="boundary-icon-button">
              <Icon type="search" className="search-icon-type" />
            </Button>
          </div>
        </div>
      );
    }

    return buttons;
  };

  return renderPages();
};

NumberPagination.propTypes = {
  page: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  wrapFromPage: PropTypes.number,
  allowDirectInput: PropTypes.bool
};

NumberPagination.defaultProps = {
  wrapFromPage: 6,
  allowDirectInput: false
};

export default NumberPagination;
