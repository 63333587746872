export const getDashSize = lineType => {
  switch (lineType) {
    case 'solid':
      return 0;
    case 'dashed':
      return 10;
    case 'dotted':
      return 5;
  }
};
