import { lazy, memo, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Loader, SuspenseWrapper } from '../../components';
import ComponentsReadyListener from '../../components/ComponentsReadyListener';

import { districtSelectors } from '../../store/selectors';

import {
  fetchDistrictDetails,
  fetchDistrictSettings
} from '../../store/actions/districtActions';
import LeafletMapContext from '../../contexts/LeafletMapContext';

const MapPortalView = lazy(() => import('../../views/MapPortal'));
const AdminView = lazy(() => import('../../views/Admin'));
const DistrictHomeView = lazy(() => import('../../views/DistrictHome'));

const LazyAdminView = () => (
  <SuspenseWrapper showErrorBoard>
    <AdminView />
  </SuspenseWrapper>
);

const { getDistrictDetails } = districtSelectors;

const DistrictRouterView = () => {
  const dispatch = useDispatch();
  const { prefix } = useParams();
  const { path } = useRouteMatch();

  const { stopWatchPosition } = useContext(LeafletMapContext);

  const districtDetails = useSelector(state =>
    getDistrictDetails(state, prefix)
  );

  useEffect(() => {
    fetchDetails();
  }, [prefix, districtDetails]);

  useEffect(
    () => () => {
      stopWatchPosition();
    },
    []
  );

  const fetchDetails = () => {
    if (districtDetails) return;

    dispatch(fetchDistrictDetails(prefix));
    dispatch(fetchDistrictSettings(prefix));
  };

  if (!districtDetails) return null;

  const isFetching = districtDetails.get('isFetching');

  if (isFetching) {
    return (
      <Loader
        tip={
          <FormattedMessage
            id="district_portal_loading_district_details"
            defaultMessage="Loading district details"
          />
        }
      />
    );
  }
  return (
    <Switch>
      <Route
        exact
        path={path}
        render={props => (
          <SuspenseWrapper showErrorBoard withFooter>
            <DistrictHomeView {...props} />
          </SuspenseWrapper>
        )}
      />
      <Route path={`${path}/admin`} component={LazyAdminView} />
      <Route
        path={`${path}/:portal`}
        render={props => (
          <SuspenseWrapper showErrorBoard>
            <ComponentsReadyListener />
            <MapPortalView {...props} />
          </SuspenseWrapper>
        )}
      />
    </Switch>
  );
};

export default memo(DistrictRouterView);
