import { useHistory } from 'react-router';
import useGlobalSettings from '../useGlobalSettings';

import { getHomePageUrl } from '../../utils/lib';

const useRedirectToHomePage = () => {
  const { push } = useHistory();
  const globalSettings = useGlobalSettings({ toJS: false });

  return () => {
    const redirectUrl = getHomePageUrl(globalSettings);
    push(redirectUrl);
  };
};

export default useRedirectToHomePage;
