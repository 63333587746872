import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'antd';

import './RoleChanger.less';

export default class RoleChanger extends Component {
  state = {
    editMode: false
  };

  activeEditMode = () => {
    this.setState({ editMode: true });
    this.props.onEditClick();
  };

  deactiveEditMode = () => {
    this.setState({ editMode: false });
    this.props.onCancelClick();
  };

  onSaveClick = () => {
    this.setState({ editMode: false });
    this.props.onSaveClick();
  };

  render() {
    return (
      <div className="role-changer">
        {!this.state.editMode && (
          <Icon type="edit" onClick={this.activeEditMode} />
        )}
        {this.state.editMode && (
          <Fragment>
            <Icon type="save" onClick={this.onSaveClick} />
            <Icon type="close" onClick={this.deactiveEditMode} />
          </Fragment>
        )}
      </div>
    );
  }
}

RoleChanger.propTypes = {
  onSaveClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};
