import { useState } from 'react';
import { Button, Icon, Select, Upload } from 'antd';
import { isString } from 'lodash';

import useSymbolizationMessages from '../useSymbolizationMessages';
import usePointSymbolization from '../usePointSymbolization';
import useLineSymbolization from '../useLineSymbolization';
import usePolygonSymbolization from '../usePolygonSymbolization';
import useLabelSymbolization from '../useLabelSymbolization';

import { FormItem } from '../../components';
import PointSymbolizationForm from '../../forms/SymbolizationForms/PointSymbolizationForm';
import LineSymbolizationForm from '../../forms/SymbolizationForms/LineSymbolizationForm';
import FillSymbolizationForm from '../../forms/SymbolizationForms/FillSymbolizationForm';
import LabelSymbolizationForm from '../../forms/SymbolizationForms/LabelSymbolizationForm';

import { GEOMETRY_TYPES } from '../../constants/map';

import './useSymbolization.less';

const { Option } = Select;

const POINT_TYPES = [GEOMETRY_TYPES.POINT, GEOMETRY_TYPES.MULTI_POINT];
const LINE_TYPES = [
  GEOMETRY_TYPES.LINE_STRING,
  GEOMETRY_TYPES.MULTI_LINE_STRING
];
const POLYGON_TYPES = [GEOMETRY_TYPES.POLYGON, GEOMETRY_TYPES.MULTI_POLYGON];

const useSymbolization = ({
  geometryType,
  form,
  selectedItemStyles,
  label = false,
  isLabelVisibleComponent
}) => {
  const { getFieldDecorator } = form;
  const isPoint = POINT_TYPES.includes(geometryType);
  const isLine = LINE_TYPES.includes(geometryType);
  const isPolygon = POLYGON_TYPES.includes(geometryType);

  const isUnknownGeometry =
    !POINT_TYPES.includes(geometryType) &&
    !LINE_TYPES.includes(geometryType) &&
    !POLYGON_TYPES.includes(geometryType) &&
    isString(geometryType);

  const {
    basicMsg,
    symbolizationMsg,
    uploadSvgMsg,
    svgSymbolMsg
  } = useSymbolizationMessages();

  const symbolizationTypes = [basicMsg, 'SVG'];
  const [symbolizationType, setSymbolizationType] = useState(
    symbolizationTypes?.[0]
  );

  const {
    symbol,
    symbolColor,
    symbolSize,
    uploadSvgProps,
    formValues,
    getIcon,
    setSymbol,
    setSymbolColor,
    setSymbolSize
  } = usePointSymbolization({ symbolizationType });

  const {
    lineColor,
    lineType,
    lineSize,
    lineStyles,
    setLineColor,
    setLineSize,
    setLineType
  } = useLineSymbolization();

  const {
    outlineColor,
    outlineType,
    outlineSize,
    withoutFill,
    fillType,
    fillColor,
    fillOpacity,
    polygonStyles,
    setOutlineColor,
    setOutlineType,
    setOutlineSize,
    setWithoutFill,
    setFillType,
    setFillColor,
    setFillOpacity,
    updateValues: updatePolygoneValues
  } = usePolygonSymbolization();

  const {
    labelSymbolization,
    labelColor,
    labelFont,
    labelSize,
    labelHidden,
    setLabelColor,
    setLabelFont,
    setLabelSize,
    setLabelHidden,
    updateLabelingStyles: updateLabelValues
  } = useLabelSymbolization();

  const getPointSymbolization = symbolization => {
    symbolization.pointSymbolizationFormValues = formValues;
    symbolization.customMarkerIcon = getIcon();
  };

  const getLineSymbolization = symbolization =>
    (symbolization.customLineStyles = lineStyles);

  const getPolygoneSymbolization = symbolization =>
    (symbolization.customPolygonStyles = polygonStyles);

  const getSymbolization = () => {
    const symbolization = {};

    if (isPoint) getPointSymbolization(symbolization);

    if (isLine) getLineSymbolization(symbolization);

    if (isPolygon) getPolygoneSymbolization(symbolization);

    if (isUnknownGeometry) {
      getPointSymbolization(symbolization);
      getLineSymbolization(symbolization);
      getPolygoneSymbolization(symbolization);
    }

    if (label) return { ...symbolization, labelSymbolization };

    return symbolization;
  };

  const symbolization = getSymbolization();

  const renderPointSymbolizationSettings = () => (
    <div className="symbolization">
      <FormItem label="Symbolizacja punktu" style={{ fontWeight: 'bold' }}>
        {getFieldDecorator('symbolization', {
          initialValue: symbolizationTypes[0]
        })(
          <Select
            onChange={setSymbolizationType}
            placeholder={symbolizationMsg}
          >
            {symbolizationTypes.map(name => (
              <Option value={name} key={name}>
                {name}
              </Option>
            ))}
          </Select>
        )}
      </FormItem>
      {symbolizationType === symbolizationTypes[0] ? (
        <PointSymbolizationForm
          symbol={symbol}
          symbolColor={symbolColor}
          symbolSize={symbolSize}
          setSymbol={setSymbol}
          setSymbolColor={setSymbolColor}
          setSymbolSize={setSymbolSize}
          selectedItemStyles={selectedItemStyles}
          getFieldDecorator={getFieldDecorator}
        />
      ) : (
        <FormItem label={svgSymbolMsg}>
          <Upload {...uploadSvgProps}>
            <Button variant="default">
              <Icon type="upload" /> {uploadSvgMsg}
            </Button>
          </Upload>
        </FormItem>
      )}
    </div>
  );

  const renderLineSymbolizationSettings = () => (
    <div className="symbolization">
      <FormItem label="Symbolizacja linii (obrys)" />
      <LineSymbolizationForm
        lineColor={lineColor}
        lineType={lineType}
        lineSize={lineSize}
        setLineColor={setLineColor}
        setLineType={setLineType}
        setLineSize={setLineSize}
        getFieldDecorator={getFieldDecorator}
        formType="Line"
      />
    </div>
  );

  const renderPolygonSymbolizationSettings = () => (
    <div className="symbolization">
      <FormItem label="Symbolizacja poligonu (obrys)" />
      <LineSymbolizationForm
        lineColor={outlineColor}
        lineType={outlineType}
        lineSize={outlineSize}
        setLineColor={setOutlineColor}
        setLineType={setOutlineType}
        setLineSize={setOutlineSize}
        getFieldDecorator={getFieldDecorator}
        formType="Polygone"
      />
      <FormItem label="Symbolizacja poligonu (wypełnienie)" />
      <FillSymbolizationForm
        withoutFill={withoutFill}
        fillType={fillType}
        fillColor={fillColor}
        fillOpacity={fillOpacity}
        setWithoutFill={setWithoutFill}
        setFillType={setFillType}
        setFillColor={setFillColor}
        setFillOpacity={setFillOpacity}
        getFieldDecorator={getFieldDecorator}
      />
      {label && renderLabelSymbolization()}
    </div>
  );

  const renderLabelSymbolization = () => (
    <div className="symbolization">
      <FormItem label="Symbolizacja eytkiet" />
      <LabelSymbolizationForm
        labelColor={labelColor}
        labelFont={labelFont}
        labelSize={labelSize}
        labelHidden={labelHidden}
        setLabelColor={setLabelColor}
        setLabelFont={setLabelFont}
        setLabelSize={setLabelSize}
        setLabelHidden={setLabelHidden}
        getFieldDecorator={getFieldDecorator}
        isLabelVisibleComponent={isLabelVisibleComponent}
      />
    </div>
  );

  const renderSymbolizationSettings = () => (
    <>
      {renderPointSymbolizationSettings()}
      {renderLineSymbolizationSettings()}
      {renderPolygonSymbolizationSettings()}
    </>
  );

  const getSymbolizationForm = () => {
    if (isPoint) return renderPointSymbolizationSettings();
    if (isLine) return renderLineSymbolizationSettings();
    if (isPolygon) return renderPolygonSymbolizationSettings();
    if (isUnknownGeometry) return renderSymbolizationSettings();

    return null;
  };

  return {
    getSymbolizationForm,
    updatePolygoneValues,
    updateLabelValues,
    symbolization
  };
};

export default useSymbolization;
