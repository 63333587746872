import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserForms } from '../../store/actions/editDataActions';
import { editDataSelectors, userSelectors } from '../../store/selectors';

const { getDistrictUserForms } = editDataSelectors;

export default function useForms(district, mapPortalCompositionId) {
  const dispatch = useDispatch();

  const [filteredUserForms, setFilteredUserForms] = useState();
  const userData = useSelector(state => userSelectors.userData(state));
  const userForms = useSelector(state => {
    const data = getDistrictUserForms(state, district);
    return data && data.filter(form => form.can_add);
  });

  const [fetching, setFetching] = useState(false);

  const getForms = async () => {
    if ((!mapPortalCompositionId && userForms) || !userData) return;

    setFetching(true);

    const forms = await dispatch(
      getUserForms(district, mapPortalCompositionId)
    );
    if (mapPortalCompositionId) setFilteredUserForms(forms);

    setFetching(false);
  };

  React.useEffect(() => {
    getForms();
  }, [district, mapPortalCompositionId]);

  return [fetching, mapPortalCompositionId ? filteredUserForms : userForms];
}
