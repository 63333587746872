import { useContext, useState } from 'react';
import { connect } from 'react-redux';

import { Dropdown, Menu } from 'antd';

import { globalSelectors } from '../../store/selectors';

import LanguageContext from '../../contexts/LanguageContext.js';

import { LanguagesShape } from '../../shapes';

import './LanguageSwitcher.less';

const MenuItem = Menu.Item;

export const LanguageSwitcher = ({ languages }) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const [dropdown, setDropdown] = useState(false);

  const menu = (
    <Menu>
      {languages.map(({ name, code }) => (
        <MenuItem onClick={() => setLanguage(code)} key={code}>
          {name}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <button
      className="toolbar_language"
      aria-label="Select language"
      onClick={() => setDropdown(prevValue => !prevValue)}
    >
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomCenter"
        visible={dropdown}
      >
        <span>{language.toUpperCase()}</span>
      </Dropdown>
    </button>
  );
};

/* istanbul ignore next */
const mapStateToProps = state => ({
  languages: globalSelectors.getLanguages(state)
});

LanguageSwitcher.propTypes = {
  languages: LanguagesShape
};

export default connect(mapStateToProps, null)(LanguageSwitcher);
