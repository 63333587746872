import { Map, List, fromJS } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';

import {
  ADD_POPUP_CONFIG_SUCCESS,
  DELETE_POPUP_CONFIG_SUCCESS,
  FETCH_POPUP_CONFIGS_SUCCESS,
  FETCH_POPUP_CONFIG_SUCCESS,
  FETCH_POPUP_LAYERS_CONFIG_SUCCESS,
  SET_COORDINATES_TO_IDENTIFICATION,
  CLEAR_COORDINATES_TO_IDENTIFICATION,
  EDIT_POPUP_LAYER_CONFIG_SUCCESS,
  ADD_POPUP_LAYER_CONFIG_SUCCESS,
  DELETE_POPUP_LAYER_CONFIG_SUCCESS,
  GET_LAYER_SEARCH_LIST_BEGIN,
  GET_LAYER_SEARCH_LIST_SUCCESS,
  FETCH_ATTACHMENTS_BEGIN,
  FETCH_ATTACHMENTS_SUCCESS,
  FETCH_ATTACHMENTS_FAILED,
  FETCH_LAYER_ATTRIBUTES_SUCCESS,
  OPEN_INFORMATION_MODAL,
  CLOSE_INFORMATION_MODAL,
  MINIMIZE_INFORMATION_MODAL,
  MAXIMIZE_INFORMATION_MODAL,
  FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_BEGIN,
  FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_FAILED,
  FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_SUCCESS,
  UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_BEGIN,
  UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_FAILED,
  UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_SUCCESS,
  FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_BEGIN,
  FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_SUCCESS,
  FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_FAILED,
  UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_BEGIN,
  UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_FAILED,
  UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_SUCCESS,
  DESKTOP_ADD_POPUP_CONFIG_SUCCESS,
  DESKTOP_EDIT_POPUP_CONFIG_SUCCESS,
  DESKTOP_DELETE_POPUP_CONFIG_SUCCESS,
  DESKTOP_FETCH_POPUP_CONFIGS_SUCCESS,
  FETCH_POPUP_LAYER_DEFAULT_ATTRIBUTES_SUCCESS,
  ADD_POPUP_LAYER_ATTRIBUTE_SUCCESS,
  EDIT_POPUP_LAYER_ATTRIBUTE_SUCCESS,
  DELETE_POPUP_LAYER_ATTRIBUTE_SUCCESS,
  DESKTOP_ADD_LAYER_TO_POPUP_STRUCTURE,
  DESKTOP_CLEAR_POPUP_STRUCTURE,
  SET_POPUP_CARD_LOADING_LAYER_STATUS,
  SET_POPUP_CARD_TREE,
  ADD_POPUP_LAYER_JOIN_SUCCESS,
  FETCH_POPUP_LAYER_JOINS_SUCCESS,
  DELETE_POPUP_LAYER_JOIN_SUCCESS,
  UPDATE_POPUP_LAYER_JOIN_SUCCESS,
  FETCH_POPUP_LAYER_JOIN_ATTRIBUTES_SUCCESS,
  ADD_POPUP_LAYER_JOIN_ATTRIBUTES_SUCCESS,
  DELETE_POPUP_LAYER_JOIN_ATTRIBUTE_SUCCESS,
  UPDATE_POPUP_LAYER_JOIN_ATTRIBUTE_SUCCESS,
  UPDATE_PLOT_LIST_INFORMATION,
  SET_MULTIPLE_COORDINATES_TO_IDENTIFICATION,
  CLEAR_MULTIPLE_COORDINATES_TO_IDENTIFICATION,
  DOWNLOAD_POPUP_DATA_BEGIN,
  DOWNLOAD_POPUP_DATA_SUCCESS,
  DOWNLOAD_POPUP_DATA_FAILED,
  FETCH_DEFAULT_LOGIC_PRIMARY_KEYS_STARTED,
  FETCH_DEFAULT_LOGIC_PRIMARY_KEYS_FINISHED,
  UPDATE_ROAD_LIST_INFORMATION
} from '../constants/popupActionTypes';

const initialState = Map({
  configs: Map({}),
  layersConfigs: List([]),
  coordinatesToIdentification: Map({}),
  layerList: List([]),
  attachments: Map({}),
  layerAttributes: Map({}),
  isOpenInformationModal: false,
  isInformationModalMinimized: false,
  minimizedModalPanelName: '',
  defaultAttachmentsAttributes: Map({}),
  isFetchingDefaultAttachmentsAttributes: false,
  isFetchedDefaultAttachmentsAttributes: false,
  isUpdateingDefaultAttachmentsAttributes: false,
  isUpdatedDefaultAttachmentsAttributes: false,
  defaultAttachmentsAttributesComposition: Map({}),
  existsDefaultAttributesComposition: false,
  layersDefaultAttributes: Map(),
  popupStructure: List(),
  popupCardTree: List(),
  printCardLoadingLayerStatuses: List(),
  joins: Map({}),
  joinsAttributes: Map({}),
  plotListInformation: List([]),
  multipleCoordinatesToIdentification: List([]),
  isDownloadingPopupData: false,
  isFetchingDefaultLogicPrimaryKeys: false,
  roadListInformation: List([])
});

const addPopupConfigSuccess = (state, action) =>
  state.setIn(['configs', String(action.config.id)], action.config);

const deletePopupConfigSuccess = (state, action) =>
  state.deleteIn(['configs', String(action.configId)]);

const fetchPopupConfigsSuccess = (state, action) => {
  const configs = {};

  action.configs.forEach(config => {
    configs[String(config.id)] = config;
  });

  return state.set('configs', Map(configs));
};

const fetchPopupConfigSuccess = (state, action) =>
  state.setIn(['configs', String(action.config.id)], action.config);

const fetchPopupLayersConfigsSuccess = (state, action) =>
  state.merge({
    layersConfigs: List(action.layersConfigs)
  });

const fetchLayerListBegin = state => state.set('layerList', List([]));

const fetchLayerListSuccess = (state, action) =>
  state.set('layerList', List(action.layerList));

const addPopupLayerConfigSuccess = (state, action) => {
  const compositionConfig = state.getIn([
    'configs',
    String(action.layerConfig.config)
  ]);

  const newCompositionConfig = {
    ...compositionConfig,
    composition_configs: [
      ...compositionConfig.composition_configs,
      {
        id: action.layerConfig.id,
        displayable_name: action.layerConfig.displayable_name,
        content_type_id: action.layerConfig.content_type,
        index: 0
      }
    ]
  };

  return state.setIn(
    ['configs', String(action.layerConfig.config)],
    newCompositionConfig
  );
};

const editPopupLayerConfigSuccess = (state, action) =>
  state.update('layersConfigs', List(), list => {
    const newList = [...list];
    const editedLayerConfigIndex = newList.findIndex(
      config => config.id === action.layerConfig.id
    );
    newList[editedLayerConfigIndex] = action.layerConfig;
    return List(newList);
  });

const deletePopupLayerConfigSuccess = (state, action) => {
  const compositionConfig = {
    ...state.getIn(['configs', String(action.configId)])
  };

  const newConfigs = compositionConfig.composition_configs?.filter(
    ({ id }) => id !== action.layerConfigId
  );
  compositionConfig.composition_configs = newConfigs;

  return state.setIn(['configs', String(action.configId)], compositionConfig);
};

const setCoordinatesToIdentification = (state, action) =>
  state.merge({
    coordinatesToIdentification: Map({
      lat: action.lat,
      lng: action.lng
    })
  });

const clearCoordinatesToIdentification = state =>
  state.merge({
    coordinatesToIdentification: Map({})
  });

const setMultipleCoordinatesToIdentification = (state, action) =>
  state.merge({
    multipleCoordinatesToIdentification: List(
      action.multipleCoordinatesToIdentification
    )
  });

const clearMultipleCoordinatesToIdentification = (state, action) =>
  state.merge({
    multipleCoordinatesToIdentification: List([])
  });

const fetchAttachmentsBegin = state =>
  state.merge({
    isFetchingAttachments: true
  });

const fetchAttachmentsSuccess = (state, action) => {
  return state.merge(
    Map({
      isFetchingAttachments: false,
      attachments: state.get('attachments').merge({
        [action.objectId]: fromJS(action.attachments)
      })
    })
  );
};

const fetchLayerAttrbutesSuccess = (state, action) =>
  state.mergeDeep(
    Map({
      layerAttributes: Map({
        ...state.get('layerAttributes'),
        [action.modelId]: action.layerAttributes
      })
    })
  );

const fetchAttachmentsFailed = state =>
  state.merge({
    isFetchingAttachments: false
  });

const openInformationModal = state =>
  state.merge(
    Map({
      isOpenInformationModal: true,
      isInformationModalMinimized: false,
      minimizedModalPanelName: ''
    })
  );

const closeInformationModal = state =>
  state.merge(
    Map({
      isOpenInformationModal: false,
      isInformationModalMinimized: false,
      minimizedModalPanelName: ''
    })
  );

const minimizeInformationModal = (state, action) =>
  state.merge(
    Map({
      isInformationModalMinimized: true,
      minimizedModalPanelName: action.panelName
    })
  );

const maximizeInformationModal = state =>
  state.merge(
    Map({
      isInformationModalMinimized: false,
      minimizedModalPanelName: ''
    })
  );

const fetchDefaultAttachmentsAttributesBegin = state =>
  state.merge({
    isFetchingDefaultAttachmentsAttributes: true,
    isFetchedDefaultAttachmentsAttributes: false,
    defaultAttachmentsAttributes: Map({})
  });

const fetchDefaultAttachmentsAttributesSuccess = (state, action) =>
  state.merge({
    isFetchingDefaultAttachmentsAttributes: false,
    isFetchedDefaultAttachmentsAttributes: true,
    defaultAttachmentsAttributes: Map(action.default)
  });

const fetchDefaultAttachmentsAttributesFailed = state =>
  state.merge({
    isFetchingDefaultAttachmentsAttributes: false,
    isFetchedDefaultAttachmentsAttributes: false,
    defaultAttachmentsAttributes: Map({})
  });

const fetchDefaultAttachmentsAttributesCompositionBegin = state =>
  state.merge({
    isFetchingDefaultAttachmentsAttributesComposition: true,
    isFetchedDefaultAttachmentsAttributesComposition: false,
    defaultAttachmentsAttributesComposition: Map({})
  });

const fetchDefaultAttachmentsAttributesCompositionSuccess = (state, action) =>
  state.merge({
    isFetchingDefaultAttachmentsAttributesComposition: false,
    isFetchedDefaultAttachmentsAttributesComposition: true,
    defaultAttachmentsAttributesComposition: Map(action.default),
    existsDefaultAttributesComposition: action.exists
  });

const fetchDefaultAttachmentsAttributesCompositionFailed = state =>
  state.merge({
    isFetchingDefaultAttachmentsAttributesComposition: false,
    isFetchedDefaultAttachmentsAttributesComposition: false,
    defaultAttachmentsAttributesComposition: Map({})
  });

const updateDefaultAttachmentsAttributesBegin = state =>
  state.merge({
    isUpdatedDefaultAttachmentsAttributes: true,
    isUpdateingDefaultAttachmentsAttributes: false
  });

const updateDefaultAttachmentsAttributesSuccess = (state, { attributes }) =>
  state.merge({
    isUpdateingDefaultAttachmentsAttributes: false,
    isUpdatedDefaultAttachmentsAttributes: true,
    defaultAttachmentsAttributes: Map(attributes)
  });

const updateDefaultAttachmentsAttributesFailed = state =>
  state.merge({
    isUpdateingDefaultAttachmentsAttributes: false,
    isUpdatedDefaultAttachmentsAttributes: false
  });

const updateDefaultAttachmentsAttributesCompositionBegin = state =>
  state.merge({
    isUpdatedDefaultAttachmentsAttributesComposition: true,
    isUpdateingDefaultAttachmentsAttributesComposition: false
  });

const updateDefaultAttachmentsAttributesCompositionSuccess = (
  state,
  { attributes }
) =>
  state.merge({
    isUpdateingDefaultAttachmentsAttributesComposition: false,
    isUpdatedDefaultAttachmentsAttributesComposition: true,
    existsDefaultAttributesComposition: true,
    defaultAttachmentsAttributesComposition: attributes
  });

const updateDefaultAttachmentsAttributesCompositionFailed = state =>
  state.merge({
    isUpdateingDefaultAttachmentsAttributesComposition: false,
    isUpdatedDefaultAttachmentsAttributesComposition: false
  });

const desktopAddPopupConfigSuccess = (
  state,
  { district, mapPortalCompositionId, config }
) => {
  const path = ['configs', district, mapPortalCompositionId];
  const configs = state.getIn(path, List());
  const nextConfigs = configs.push({ ...config });
  return state.setIn(path, nextConfigs);
};

const desktopEditPopupConfigSuccess = (
  state,
  { district, mapPortalCompositionId, config }
) => {
  const path = ['configs', district, mapPortalCompositionId];
  const configs = state.getIn(path);
  const configIndex = configs.findIndex(({ id }) => id === config.id);
  return state.setIn([...path, configIndex], config);
};

const desktopDeletePopupConfigSuccess = (
  state,
  { district, mapPortalCompositionId, configId }
) => {
  const path = ['configs', district, mapPortalCompositionId];
  const configs = state.getIn(path);
  const configIndex = configs.findIndex(({ id }) => id === configId);
  const nextConfigs = configs.delete(configIndex);
  return state.setIn(path, nextConfigs);
};

const desktopFetchPopupConfigsSuccess = (
  state,
  { district, mapPortalCompositionId, configs }
) => state.setIn(['configs', district, mapPortalCompositionId], List(configs));

const fetchPopupLayerDefaultAttributesSuccess = (
  state,
  { layerId, attributes }
) => state.setIn(['layersDefaultAttributes', layerId], List(attributes));

const addPopupLayerAttributeSuccess = (
  state,
  { district, mapPortalCompositionId, layerConfigId, attribute }
) => {
  const path = ['configs', district, mapPortalCompositionId];
  const layerConfigs = state.getIn(path);
  const layerConfigIndex = layerConfigs?.findIndex(
    item => item.id === layerConfigId
  );
  const layerConfig = layerConfigs.get(layerConfigIndex);

  const newLayerConfig = cloneDeep(layerConfig);
  newLayerConfig.attributes.unshift(attribute);

  return state.setIn([...path, layerConfigIndex], newLayerConfig);
};

const editPopupLayerAttributeSuccess = (
  state,
  { district, mapPortalCompositionId, layerConfigId, attribute }
) => {
  const path = ['configs', district, mapPortalCompositionId];
  const layerConfigs = state.getIn(path);
  const layerConfigIndex = layerConfigs?.findIndex(
    item => item.id === layerConfigId
  );
  const layerConfig = layerConfigs.get(layerConfigIndex);

  const newLayerConfig = cloneDeep(layerConfig);
  const attributeToEditIndex = newLayerConfig.attributes.findIndex(
    ({ id }) => id === attribute.id
  );
  newLayerConfig.attributes[attributeToEditIndex] = attribute;

  return state.setIn([...path, layerConfigIndex], newLayerConfig);
};

const deletePopupLayerAttributeSuccess = (
  state,
  { district, mapPortalCompositionId, layerConfigId, attributeId }
) => {
  const path = ['configs', district, mapPortalCompositionId];
  const layerConfigs = state.getIn(path);
  const layerConfigIndex = layerConfigs?.findIndex(
    item => item.id === layerConfigId
  );
  const layerConfig = layerConfigs.get(layerConfigIndex);

  const newLayerConfig = cloneDeep(layerConfig);
  newLayerConfig.attributes = newLayerConfig.attributes.filter(
    ({ id }) => id !== attributeId
  );

  return state.setIn([...path, layerConfigIndex], newLayerConfig);
};

const desktopAddLayerToPopupStructure = (state, { layer }) =>
  state.update('popupStructure', list => {
    const isExist = list.find(({ id }) => id === layer.id);
    return isExist ? list : list.push(layer);
  });

const desktopClearPopupStructure = state => state.set('popupStructure', List());

const setPopupCardTree = (state, { tree }) =>
  state.set('popupCardTree', List(tree));

const setPopupCardLayerLoadingStatus = (state, action) => {
  let statuses = state.get('printCardLoadingLayerStatuses');
  const statusIndex = statuses.findIndex(({ id }) => id === action.id);
  const loadingStatus = { id: action.id, isLoading: action.status };

  if (statusIndex === -1) {
    statuses = statuses.push(loadingStatus);
  } else {
    statuses = statuses.set(statusIndex, loadingStatus);
  }

  return state.set('printCardLoadingLayerStatuses', statuses);
};

const addPopupLayerJoinSuccess = (
  state,
  { district, mapPortalCompositionId, layerConfigId, join }
) => {
  const layerJoins = state.getIn([
    'joins',
    district,
    mapPortalCompositionId,
    layerConfigId
  ]);

  const newLayerJoins = [...layerJoins, join];

  return state.setIn(
    ['joins', district, mapPortalCompositionId, layerConfigId],
    newLayerJoins
  );
};

const fetchPopupLayerJoinsSuccess = (
  state,
  { district, mapPortalCompositionId, layerConfigId, joins }
) =>
  state.setIn(
    ['joins', district, mapPortalCompositionId, layerConfigId],
    joins
  );

const deletePopupLayerJoinsSuccess = (
  state,
  { district, mapPortalCompositionId, layerConfigId, joinId }
) => {
  const layerJoins = state.getIn([
    'joins',
    district,
    mapPortalCompositionId,
    layerConfigId
  ]);

  const nextJoins = layerJoins.filter(({ id }) => id !== joinId);

  return state.setIn(
    ['joins', district, mapPortalCompositionId, layerConfigId],
    nextJoins
  );
};

const updatePopupLayerJoinsSuccess = (
  state,
  { district, mapPortalCompositionId, layerConfigId, joinId, join }
) => {
  const layerJoins = state.getIn([
    'joins',
    district,
    mapPortalCompositionId,
    layerConfigId
  ]);

  const nextJoins = [...layerJoins.filter(({ id }) => id !== joinId), join];

  return state.setIn(
    ['joins', district, mapPortalCompositionId, layerConfigId],
    nextJoins
  );
};

const fetchPopupLayerJoinAttributesSuccess = (
  state,
  { district, mapPortalCompositionId, layerConfigId, joinId, joinAttributes }
) =>
  state.setIn(
    [
      'joinsAttributes',
      district,
      mapPortalCompositionId,
      layerConfigId,
      joinId
    ],
    joinAttributes
  );

const addPopupLayerJoinAttributesSuccess = (
  state,
  { district, mapPortalCompositionId, layerConfigId, joinId, attribute }
) => {
  const layerJoinAttributes = state.getIn([
    'joinsAttributes',
    district,
    mapPortalCompositionId,
    layerConfigId,
    joinId
  ]);

  const newLayerJoinAttributes = [...layerJoinAttributes, attribute];

  return state.setIn(
    [
      'joinsAttributes',
      district,
      mapPortalCompositionId,
      layerConfigId,
      joinId
    ],
    newLayerJoinAttributes
  );
};

const deletePopupLayerJoinAttributesSuccess = (
  state,
  { district, mapPortalCompositionId, layerConfigId, joinId, attributeId }
) => {
  const layerJoinAttributes = state.getIn([
    'joinsAttributes',
    district,
    mapPortalCompositionId,
    layerConfigId,
    joinId
  ]);

  const newLayerJoinAttributes = layerJoinAttributes.filter(
    ({ id }) => id !== attributeId
  );

  return state.setIn(
    [
      'joinsAttributes',
      district,
      mapPortalCompositionId,
      layerConfigId,
      joinId
    ],
    newLayerJoinAttributes
  );
};

const updatePopupLayerJoinAttributeSuccess = (
  state,
  {
    district,
    mapPortalCompositionId,
    layerConfigId,
    joinId,
    attributeId,
    attribute
  }
) => {
  const layerJoinAttributes = state.getIn([
    'joinsAttributes',
    district,
    mapPortalCompositionId,
    layerConfigId,
    joinId
  ]);

  const nextLayerJoinAttributes = [
    ...layerJoinAttributes.filter(({ id }) => id !== attributeId),
    attribute
  ];

  return state.setIn(
    [
      'joinsAttributes',
      district,
      mapPortalCompositionId,
      layerConfigId,
      joinId
    ],
    nextLayerJoinAttributes
  );
};

const updatePlotListInformation = (state, action) =>
  state.set('plotListInformation', List(action.plotListInformation));

const setPopupDataDownloading = state =>
  state.set('isDownloadingPopupData', true);

const unsetPopupDataDownloading = state =>
  state.set('isDownloadingPopupData', false);

const setFetchDefaultLogicPrimaryKeys = state =>
  state.set('isFetchingDefaultLogicPrimaryKeys', true);

const unsetFetchDefaultLogicPrimaryKeys = state =>
  state.set('isFetchingDefaultLogicPrimaryKeys', false);

const updateRoadListInformation = (state, action) =>
  state.set('roadListInformation', List(action.roadListInformation));

export default function reducer(state = initialState, action) {
  return (
    {
      [ADD_POPUP_CONFIG_SUCCESS]: addPopupConfigSuccess,
      [DELETE_POPUP_CONFIG_SUCCESS]: deletePopupConfigSuccess,
      [FETCH_POPUP_CONFIGS_SUCCESS]: fetchPopupConfigsSuccess,
      [FETCH_POPUP_CONFIG_SUCCESS]: fetchPopupConfigSuccess,
      [FETCH_POPUP_LAYERS_CONFIG_SUCCESS]: fetchPopupLayersConfigsSuccess,
      [ADD_POPUP_LAYER_CONFIG_SUCCESS]: addPopupLayerConfigSuccess,
      [EDIT_POPUP_LAYER_CONFIG_SUCCESS]: editPopupLayerConfigSuccess,
      [DELETE_POPUP_LAYER_CONFIG_SUCCESS]: deletePopupLayerConfigSuccess,
      [SET_COORDINATES_TO_IDENTIFICATION]: setCoordinatesToIdentification,
      [CLEAR_COORDINATES_TO_IDENTIFICATION]: clearCoordinatesToIdentification,
      [GET_LAYER_SEARCH_LIST_BEGIN]: fetchLayerListBegin,
      [GET_LAYER_SEARCH_LIST_SUCCESS]: fetchLayerListSuccess,
      [FETCH_ATTACHMENTS_BEGIN]: fetchAttachmentsBegin,
      [FETCH_ATTACHMENTS_SUCCESS]: fetchAttachmentsSuccess,
      [FETCH_ATTACHMENTS_FAILED]: fetchAttachmentsFailed,
      [FETCH_LAYER_ATTRIBUTES_SUCCESS]: fetchLayerAttrbutesSuccess,
      [OPEN_INFORMATION_MODAL]: openInformationModal,
      [CLOSE_INFORMATION_MODAL]: closeInformationModal,
      [MINIMIZE_INFORMATION_MODAL]: minimizeInformationModal,
      [MAXIMIZE_INFORMATION_MODAL]: maximizeInformationModal,
      [FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_BEGIN]: fetchDefaultAttachmentsAttributesBegin,
      [FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_SUCCESS]: fetchDefaultAttachmentsAttributesSuccess,
      [FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_FAILED]: fetchDefaultAttachmentsAttributesFailed,
      [FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_BEGIN]: fetchDefaultAttachmentsAttributesCompositionBegin,
      [FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_SUCCESS]: fetchDefaultAttachmentsAttributesCompositionSuccess,
      [FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_FAILED]: fetchDefaultAttachmentsAttributesCompositionFailed,
      [UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_BEGIN]: updateDefaultAttachmentsAttributesBegin,
      [UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_SUCCESS]: updateDefaultAttachmentsAttributesSuccess,
      [UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_FAILED]: updateDefaultAttachmentsAttributesFailed,
      [UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_BEGIN]: updateDefaultAttachmentsAttributesCompositionBegin,
      [UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_SUCCESS]: updateDefaultAttachmentsAttributesCompositionSuccess,
      [UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_FAILED]: updateDefaultAttachmentsAttributesCompositionFailed,
      [DESKTOP_ADD_POPUP_CONFIG_SUCCESS]: desktopAddPopupConfigSuccess,
      [DESKTOP_EDIT_POPUP_CONFIG_SUCCESS]: desktopEditPopupConfigSuccess,
      [DESKTOP_DELETE_POPUP_CONFIG_SUCCESS]: desktopDeletePopupConfigSuccess,
      [DESKTOP_FETCH_POPUP_CONFIGS_SUCCESS]: desktopFetchPopupConfigsSuccess,
      [FETCH_POPUP_LAYER_DEFAULT_ATTRIBUTES_SUCCESS]: fetchPopupLayerDefaultAttributesSuccess,
      [ADD_POPUP_LAYER_ATTRIBUTE_SUCCESS]: addPopupLayerAttributeSuccess,
      [EDIT_POPUP_LAYER_ATTRIBUTE_SUCCESS]: editPopupLayerAttributeSuccess,
      [DELETE_POPUP_LAYER_ATTRIBUTE_SUCCESS]: deletePopupLayerAttributeSuccess,
      [DESKTOP_ADD_LAYER_TO_POPUP_STRUCTURE]: desktopAddLayerToPopupStructure,
      [DESKTOP_CLEAR_POPUP_STRUCTURE]: desktopClearPopupStructure,
      [SET_POPUP_CARD_TREE]: setPopupCardTree,
      [SET_POPUP_CARD_LOADING_LAYER_STATUS]: setPopupCardLayerLoadingStatus,
      [ADD_POPUP_LAYER_JOIN_SUCCESS]: addPopupLayerJoinSuccess,
      [FETCH_POPUP_LAYER_JOINS_SUCCESS]: fetchPopupLayerJoinsSuccess,
      [DELETE_POPUP_LAYER_JOIN_SUCCESS]: deletePopupLayerJoinsSuccess,
      [UPDATE_POPUP_LAYER_JOIN_SUCCESS]: updatePopupLayerJoinsSuccess,
      [FETCH_POPUP_LAYER_JOIN_ATTRIBUTES_SUCCESS]: fetchPopupLayerJoinAttributesSuccess,
      [ADD_POPUP_LAYER_JOIN_ATTRIBUTES_SUCCESS]: addPopupLayerJoinAttributesSuccess,
      [DELETE_POPUP_LAYER_JOIN_ATTRIBUTE_SUCCESS]: deletePopupLayerJoinAttributesSuccess,
      [UPDATE_POPUP_LAYER_JOIN_ATTRIBUTE_SUCCESS]: updatePopupLayerJoinAttributeSuccess,
      [UPDATE_PLOT_LIST_INFORMATION]: updatePlotListInformation,
      [SET_MULTIPLE_COORDINATES_TO_IDENTIFICATION]: setMultipleCoordinatesToIdentification,
      [CLEAR_MULTIPLE_COORDINATES_TO_IDENTIFICATION]: clearMultipleCoordinatesToIdentification,
      [DOWNLOAD_POPUP_DATA_BEGIN]: setPopupDataDownloading,
      [DOWNLOAD_POPUP_DATA_SUCCESS]: unsetPopupDataDownloading,
      [DOWNLOAD_POPUP_DATA_FAILED]: unsetPopupDataDownloading,
      [FETCH_DEFAULT_LOGIC_PRIMARY_KEYS_STARTED]: setFetchDefaultLogicPrimaryKeys,
      [FETCH_DEFAULT_LOGIC_PRIMARY_KEYS_FINISHED]: unsetFetchDefaultLogicPrimaryKeys,
      [UPDATE_ROAD_LIST_INFORMATION]: updateRoadListInformation
    }[action.type] || (s => s)
  )(state, action);
}
