import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Icon, Tooltip } from 'antd';

import Button from '../Button';

const DownloadButton = ({
  className,
  disabled,
  isLoading,
  variant,
  onClick
}) => {
  const { formatMessage } = useIntl();

  const tooltipTitle = formatMessage({
    id: 'district_portal_information_modal_download_information',
    defaultMessage: 'Download'
  });

  const buttonLabel = formatMessage({
    id: 'general_download',
    defaultMessage: 'Download'
  });

  return (
    <Tooltip title={tooltipTitle} overlayClassName="custom-tooltip">
      <Button
        className={className || ''}
        disabled={disabled}
        onClick={onClick}
        variant={variant || 'text'}
        aria-label={buttonLabel}
      >
        <Icon type={isLoading ? 'loading' : 'download'} />
      </Button>
    </Tooltip>
  );
};

DownloadButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  variant: PropTypes.string,
  onClick: PropTypes.func
};

export default DownloadButton;
