export const FETCH_COMPOSITION_REGISTERS_BEGIN =
  'FETCH_COMPOSITION_REGISTERS_BEGIN';
export const FETCH_COMPOSITION_REGISTERS_SUCCESS =
  'FETCH_COMPOSITION_REGISTERS_SUCCESS';
export const FETCH_COMPOSITION_REGISTERS_FAILED =
  'FETCH_COMPOSITION_REGISTERS_FAILED';
export const OPEN_REGISTER_TOOL = 'OPEN_REGISTER_TOOL';
export const CLOSE_REGISTER_TOOL = 'CLOSE_REGISTER_TOOL';
export const SET_REGISTER_TOOL_IN_SEPARATE_WINDOW =
  'SET_REGISTER_TOOL_IN_SEPARATE_WINDOW';
export const ADD_COMPOSITION_REGISTER_BEGIN = 'ADD_COMPOSITION_REGISTER_BEGIN';
export const ADD_COMPOSITION_REGISTER_SUCCESS =
  'ADD_COMPOSITION_REGISTER_SUCCESS';
export const ADD_COMPOSITION_REGISTER_FAILED =
  'ADD_COMPOSITION_REGISTER_FAILED';
export const EDIT_COMPOSITION_REGISTER_BEGIN =
  'EDIT_COMPOSITION_REGISTER_BEGIN';
export const EDIT_COMPOSITION_REGISTER_SUCCESS =
  'EDIT_COMPOSITION_REGISTER_SUCCESS';
export const EDIT_COMPOSITION_REGISTER_FAILED =
  'EDIT_COMPOSITION_REGISTER_FAILED';
export const DELETE_COMPOSITION_REGISTER_BEGIN =
  'DELETE_CCOMPOSITION_REGISTER_BEGIN';
export const DELETE_COMPOSITION_REGISTER_SUCCESS =
  'DELETE_CCOMPOSITION_REGISTER_SUCCESS';
export const DELETE_COMPOSITION_REGISTER_FAILED =
  'DELETE_CCOMPOSITION_REGISTER_FAILED';

export const FETCH_REGISTER_ATTRIBUTTES_BEGIN =
  'FETCH_REGISTER_ATTRIBUTTES_BEGIN';
export const FETCH_REGISTER_ATTRIBUTTES_SUCCESS =
  'FETCH_REGISTER_ATTRIBUTTES_SUCCESS';
export const FETCH_REGISTER_ATTRIBUTTES_FAILED =
  'FETCH_REGISTER_ATTRIBUTTES_FAILED';

export const EDIT_REGISTER_ATTRIBUTTE_BEGIN = 'EDIT_REGISTER_ATTRIBUTTE_BEGIN';
export const EDIT_REGISTER_ATTRIBUTTE_SUCCESS =
  'EDIT_REGISTER_ATTRIBUTTE_SUCCESS';
export const EDIT_REGISTER_ATTRIBUTTE_FAILED =
  'EDIT_REGISTER_ATTRIBUTTE_FAILED';
export const SELECTED_REGISTER_LAYER = 'SELECTED_REGISTER_LAYER';
export const SELECTED_REGISTER_FEATURES = 'SELECTED_REGISTER_FEATURES';

export const OPEN_REGISTER_DETAILS = 'OPEN_REGISTER_DETAILS';
export const CLOSE_REGISTER_DETAILS = 'CLOSE_REGISTER_DETAILS';

export const GET_FINN_OBJECT_BEGIN = 'GET_FINN_OBJECT_BEGIN';
export const GET_FINN_OBJECT_SUCCESS = 'GET_FINN_OBJECT_SUCCESS';
export const GET_FINN_OBJECT_FAILED = 'GET_FINN_OBJECT_FAILED';

export const GET_FINN_SETTINGS_BEGIN = 'GET_FINN_SETTINGS_BEGIN';
export const GET_FINN_SETTINGS_SUCCESS = 'GET_FINN_SETTINGS_SUCCESS';
export const GET_FINN_SETTINGS_FAILED = 'GET_FINN_SETTINGS_FAILED';

export const GET_FINN_LIST_BEGIN = 'GET_FINN_LIST_BEGIN';
export const GET_FINN_LIST_SUCCESS = 'GET_FINN_LIST_SUCCESS';
export const GET_FINN_LIST_FAILED = 'GET_FINN_LIST_FAILED';

export const REGISTER_TOOL_COMPONENT = 'REGISTER_TOOL_COMPONENT';

export const CHANGE_OPTION_ID = 'CHANGE_OPTION_ID';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_SHOW_FILTERS = 'SET_SHOW_FILTERS';
export const SET_REGISTER_QUERY = 'SET_REGISTER_QUERY';
export const RESET_REGISTER_DETAILS = 'RESET_REGISTER_DETAILS';
export const SET_FILTERS_VALUES = 'SET_FILTERS_VALUES';
export const SET_PAGINATION_VALUES = 'SET_PAGINATION_VALUES';
export const CHANGE_REGISTER_COMPOSITION = 'CHANGE_REGISTER_COMPOSITION';

export const FETCH_RELATED_FINN_OBJECT_BEGIN =
  'FETCH_RELATED_FINN_OBJECT_BEGIN';
export const FETCH_RELATED_FINN_OBJECT_SUCCESS =
  'FETCH_RELATED_FINN_OBJECT_SUCCESS';
export const FETCH_RELATED_FINN_OBJECT_FAILED =
  'FETCH_RELATED_FINN_OBJECT_FAILED';

export const OPEN_CHANGE_HISTORY_TABLE = 'OPEN_CHANGE_HISTORY_TABLE';
export const CLOSE_CHANGE_HISTORY_TABLE = 'CLOSE_CHANGE_HISTORY_TABLE';
export const FETCH_CHANGE_HISTORY_BEGIN = 'FETCH_CHANGE_HISTORY_BEGIN';
export const FETCH_CHANGE_HISTORY_SUCCESS = 'FETCH_CHANGE_HISTORY_SUCCESS';
export const FETCH_CHANGE_HISTORY_FAILED = 'FETCH_CHANGE_HISTORY_FAILED';

export const RESTORE_REGISTER_INITIAL_STATE = 'RESTORE_REGISTER_INITIAL_STATE';
export const CHANGE_REGISTER_LAYER = 'CHANGE_REGISTER_LAYER';
export const IS_REGISTER_EDITION_ENABLED = 'IS_REGISTER_EDITION_ENABLED';
export const FORM_EDITION_ID = 'FORM_EDITION_ID';
