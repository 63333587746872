import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  fetchMapPortalTools,
  updateMapPortalTool
} from '../../../store/actions/mapPortalActions';

import { SWITCH_KEYS } from '../constants';

import { translations } from '../../../forms/MapPortalToolsForm';

const useHandleModalLogic = ({ tool, closeEditor, mapPortalId }) => {
  const dispatch = useDispatch();
  const { advanced_mode, is_responsive, informative_mode } = tool;
  const [configuration, setConfiguration] = useState({
    advanced_mode,
    is_responsive,
    informative_mode
  });

  const title = (
    <>
      <span>Konfiguracja narzędzia: </span>
      {translations?.[tool?.name]}
    </>
  );

  const switchList = [
    {
      label: 'Narzędzie dostępne w trybie informacyjnym:',
      key: SWITCH_KEYS.INFORMATIVE_MODE
    },
    {
      label: 'Narzędzie dostępne w trybie zaawansowanym:',
      key: SWITCH_KEYS.ADVANCED_MODE
    },
    {
      label: 'Czy narzędzie ma być responsywne?',
      key: SWITCH_KEYS.IS_RESPONSIVE
    }
  ];

  const handleChange = (value, key) => {
    setConfiguration({ ...configuration, [key]: value });
  };

  const saveChanges = async () => {
    const { toolbar_display_id: toolbarId } = tool;

    await dispatch(updateMapPortalTool({ toolbarId, data: configuration }));
    await dispatch(fetchMapPortalTools(mapPortalId));
    closeEditor();
  };

  return { title, switchList, handleChange, saveChanges, configuration };
};

export default useHandleModalLogic;
