import axios from 'axios';

const validatePassword = ({
  language,
  validationBody = {},
  onValid,
  onInvalid,
  onError
}) =>
  new Promise((resolve, reject) => {
    axios
      .post('validate_password/', validationBody, {
        headers: { 'Accept-Language': language }
      })
      .then(response => {
        if (response.status === 204) {
          onValid && onValid();
          return resolve(response.data.password);
        }

        onInvalid && onInvalid(response.data.password);
        resolve();
      })
      .catch(err => {
        onError && onError(err);
        reject(err);
      });
  });

export default validatePassword;
