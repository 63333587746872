import axios from 'axios';

import {
  SET_ZOOM,
  ADD_HISTORY_VIEW,
  CHANGE_NUMBER_HISTORY,
  SET_HISTORY_CLICKED,
  SET_MEASURE_TOOL,
  SET_SELECTED_PARCEL,
  CLEAR_SELECTED_PARCEL,
  SET_BASE_MAP_LAYER,
  SET_MINI_MAP_BASE_MAP,
  SET_CHECKED_LAYERS,
  ADD_TO_INVISIBLE_LAYERS,
  REMOVE_FROM_INVISIBLE_LAYERS,
  ADD_TO_LAYERS_ZOOM,
  SET_LAYER_OPACITY,
  SET_LAYERS_ORDER,
  ADD_PRINT_PREVIEW_LOADING_LAYER,
  DELETE_PRINT_PREVIEW_LOADING_LAYER,
  ZOOM_TO_LAYER_START,
  ZOOM_TO_LAYER_END,
  SET_FULLSCREEN_STATUS,
  SET_CURRENT_CURSOR_CRS,
  CONVERT_COORDINATES_TO_HDMS,
  SET_RENDERED_LAYERS_NUMBER,
  SET_STREET_VIEW_COORDINATES,
  SET_SCALES_DENOMINATORS,
  SET_MAPLOAD_TRUE,
  SET_MAPLOAD_FALSE,
  REMOVE_SELECTED_PARCEL,
  SET_MAIN_MINI_MAP_DRAGGING_STATUS,
  FETCH_EXTERNAL_WFS_ACTIONS,
  SET_LAYER_STYLE,
  SET_SELECTED_POINT,
  CLEAR_SELECTED_POINT,
  SET_SELECTED_NEIGHBORING_PARCEL,
  CLEAR_SELECTED_NEIGHBORING_PARCEL,
  IS_GEOM_LOADING_ON_MAP,
  CLEAR_SELECTED_DEFAULT_PARCELS,
  SET_SELECTED_DEFAULT_PARCELS,
  SET_ACTIVE_BACKGROUND_TOOL,
  SET_BACKGROUND_PARCEL_COLOR
} from '../constants/mapActionTypes';

export const setZoomLevel = zoomLevel => ({
  type: SET_ZOOM,
  zoomLevel
});

export const setScalesDenominators = scalesDenominators => ({
  type: SET_SCALES_DENOMINATORS,
  denominators: scalesDenominators
});

/**
 * Add object with latlng and zoom to store
 *
 * @param data - object with latlng, zoom
 *
 * @returns
 */
export const historyMapAdd = data => ({
  type: ADD_HISTORY_VIEW,
  data
});

/**
 * Change value of number history
 *
 * @param number - number of current history
 *
 * @returns
 */
export const numberHistoryChange = number => ({
  type: CHANGE_NUMBER_HISTORY,
  number
});

/**
 * Change value of number history
 *
 * @param number - number of current history
 *
 * @returns
 */
export const setHistoryClicked = clicked => ({
  type: SET_HISTORY_CLICKED,
  clicked
});

export const setActiveBackgroundTool = activeBackgroundTool => ({
  type: SET_ACTIVE_BACKGROUND_TOOL,
  activeBackgroundTool
});

export const setTypeClick = measure => ({
  type: SET_MEASURE_TOOL,
  measure
});

export const clearTypeClick = () => dispatch => {
  dispatch(setTypeClick(''));
};

export const setIsGeomLoadingOnMap = isGeomLoadingOnMap => ({
  type: IS_GEOM_LOADING_ON_MAP,
  isGeomLoadingOnMap
});

export const setSelectedParcel = (
  selectedParcel,
  onlyOne = false,
  onlyArray = false
) => ({
  type: SET_SELECTED_PARCEL,
  selectedParcel: JSON.parse(JSON.stringify(selectedParcel)), // Deep copy
  onlyOne,
  onlyArray
});

export const setSelectedNeighboringParcel = ({
  selectedNeighboringParcel,
  onlyOne = false,
  onlyArray = true
}) => ({
  type: SET_SELECTED_NEIGHBORING_PARCEL,
  selectedNeighboringParcel: JSON.parse(
    JSON.stringify(selectedNeighboringParcel)
  ),
  onlyOne,
  onlyArray
});

export const clearSelectedNeighboringParcel = () => ({
  type: CLEAR_SELECTED_NEIGHBORING_PARCEL
});

export const setSelectedDefaultParcels = (
  selectedDefaultParcels,
  onlyOne = false,
  onlyArray = true
) => ({
  type: SET_SELECTED_DEFAULT_PARCELS,
  selectedDefaultParcels: JSON.parse(JSON.stringify(selectedDefaultParcels)),
  onlyOne,
  onlyArray
});

export const clearSelectedDefaultParcels = () => ({
  type: CLEAR_SELECTED_DEFAULT_PARCELS
});

export const setBackgroundParcelColor = backgroundParcelColor => ({
  type: SET_BACKGROUND_PARCEL_COLOR,
  backgroundParcelColor
});

export const setSelectedPoint = selectedPoint => ({
  type: SET_SELECTED_POINT,
  selectedPoint
});

export const clearSelectedPoint = () => ({
  type: CLEAR_SELECTED_POINT
});

export const clearSelectedParcel = () => ({
  type: CLEAR_SELECTED_PARCEL
});

export const removeSelectedParcel = id => ({
  type: REMOVE_SELECTED_PARCEL,
  id
});

export const setBaseMapLayer = baseMapLayer => ({
  type: SET_BASE_MAP_LAYER,
  baseMapLayer
});

export const setMiniMapBaseMap = miniMapBaseMap => ({
  type: SET_MINI_MAP_BASE_MAP,
  miniMapBaseMap
});

export const setCheckedLayers = checkedLayers => {
  const payload = checkedLayers[0] === 'null' ? [] : checkedLayers;

  return {
    type: SET_CHECKED_LAYERS,
    checkedLayers: payload
  };
};

export const addToInvisibleLayers = id => ({
  type: ADD_TO_INVISIBLE_LAYERS,
  id
});

export const removeFromInvisibleLayers = id => ({
  type: REMOVE_FROM_INVISIBLE_LAYERS,
  id
});

export const addToLayersZoom = (id, zoomRange) => ({
  type: ADD_TO_LAYERS_ZOOM,
  id,
  zoomRange
});

export const setLayerOpacity = (layerId, opacityValue) => ({
  type: SET_LAYER_OPACITY,
  layerId,
  opacityValue
});

export const setLayerStyle = (layerId, styleName) => ({
  type: SET_LAYER_STYLE,
  layerId,
  styleName
});

export const setLayersOrder = layersOrder => ({
  type: SET_LAYERS_ORDER,
  layersOrder
});

export const addPrintPreviewLoadingLayer = layerId => ({
  type: ADD_PRINT_PREVIEW_LOADING_LAYER,
  layerId
});

export const deletePrintPreviewLoadingLayer = layerId => ({
  type: DELETE_PRINT_PREVIEW_LOADING_LAYER,
  layerId
});

export const setFullscreenStatus = isActive => ({
  type: SET_FULLSCREEN_STATUS,
  isActive
});

export const zoomToLayerStart = bounds => ({
  type: ZOOM_TO_LAYER_START,
  bounds
});

export const zoomToLayerEnd = () => ({
  type: ZOOM_TO_LAYER_END
});

export const setCurrentCursorCrs = currentCursorCrs => ({
  type: SET_CURRENT_CURSOR_CRS,
  currentCursorCrs
});

export const setConvertCoordinatesToHDMS = convertCoordinatesToHDMS => ({
  type: CONVERT_COORDINATES_TO_HDMS,
  convertCoordinatesToHDMS
});

export const setRenderedLayersNumber = renderedLayersNumber => ({
  type: SET_RENDERED_LAYERS_NUMBER,
  renderedLayersNumber
});

export const setStreetViewCoordinates = coordinates => ({
  type: SET_STREET_VIEW_COORDINATES,
  coordinates
});

export const setIsMapLoadTrue = () => ({
  type: SET_MAPLOAD_TRUE
});

export const setIsMapLoadFalse = () => ({
  type: SET_MAPLOAD_FALSE
});

export const setMainMiniMapDraggingStatus = isDragging => ({
  type: SET_MAIN_MINI_MAP_DRAGGING_STATUS,
  isDragging
});

export const fetchWfsExternalActions = (layer, isFetched) => ({
  type: FETCH_EXTERNAL_WFS_ACTIONS,
  layer,
  isFetched
});

export const fetchWfsExternal = (url, name, crsform, version) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchWfsExternalActions(name, true));
    axios
      .get(
        `/wfs_to_geojson/?link=${url}&layers=${name}&service_type=WFS&srs=EPSG:${crsform}&version=${version}`,
        {
          timeout: 30000
        }
      )
      .then(resolve)
      .catch(reject)
      .finally(() => {
        dispatch(fetchWfsExternalActions(name, false));
      });
  });
