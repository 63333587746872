import { Map, List, Set } from 'immutable';

import {
  SET_ZOOM,
  ADD_HISTORY_VIEW,
  CHANGE_NUMBER_HISTORY,
  SET_HISTORY_CLICKED,
  SET_MEASURE_TOOL,
  SET_FULLSCREEN_STATUS,
  SET_SELECTED_PARCEL,
  CLEAR_SELECTED_PARCEL,
  SET_BASE_MAP_LAYER,
  SET_MINI_MAP_BASE_MAP,
  SET_CHECKED_LAYERS,
  ADD_TO_INVISIBLE_LAYERS,
  REMOVE_FROM_INVISIBLE_LAYERS,
  ADD_TO_LAYERS_ZOOM,
  SET_LAYER_OPACITY,
  SET_LAYERS_ORDER,
  ADD_PRINT_PREVIEW_LOADING_LAYER,
  DELETE_PRINT_PREVIEW_LOADING_LAYER,
  ZOOM_TO_LAYER_START,
  ZOOM_TO_LAYER_END,
  SET_CURRENT_CURSOR_CRS,
  CONVERT_COORDINATES_TO_HDMS,
  SET_RENDERED_LAYERS_NUMBER,
  SET_STREET_VIEW_COORDINATES,
  SET_SCALES_DENOMINATORS,
  SET_MAPLOAD_TRUE,
  SET_MAPLOAD_FALSE,
  REMOVE_SELECTED_PARCEL,
  SET_MAIN_MINI_MAP_DRAGGING_STATUS,
  FETCH_EXTERNAL_WFS_ACTIONS,
  SET_LAYER_STYLE,
  SET_SELECTED_POINT,
  CLEAR_SELECTED_POINT,
  SET_SELECTED_NEIGHBORING_PARCEL,
  CLEAR_SELECTED_NEIGHBORING_PARCEL,
  IS_GEOM_LOADING_ON_MAP,
  SET_SELECTED_DEFAULT_PARCELS,
  CLEAR_SELECTED_DEFAULT_PARCELS,
  SET_ACTIVE_BACKGROUND_TOOL,
  SET_BACKGROUND_PARCEL_COLOR
} from '../constants/mapActionTypes';

import {
  OPEN_INFORMATION_MODAL,
  CLOSE_INFORMATION_MODAL,
  MINIMIZE_INFORMATION_MODAL,
  MAXIMIZE_INFORMATION_MODAL
} from '../constants/popupActionTypes';

import { enumClickTypes } from '../../mapConfig';
import { OPEN_WMS_INFORMATION_MODAL } from '../constants/wmsPopupActionTypes';
import { IDENTIFICATION_KEYS } from '../../constants/toolbar';

export const initialState = Map({
  zoomLevel: 7,
  scalesDenominators: List([]),
  minMapZoomLevel: 0,
  maxMapZoomLevel: 18,
  historyMap: List([]),
  historyNumber: 0,
  historyClicked: false,
  typeClick: '',
  fullscreen: false,
  selectedParcel: List([]),
  selectedPoint: {},
  selectedNeighboringParcel: List([]),
  baseMapLayer: null,
  checkedLayers: List([]),
  invisibleLayers: Set([]),
  layersZoom: Map({}),
  layersOpacity: Map({}),
  layersStyle: Map({}),
  layersOrder: List([]),
  printPreviewLoadingLayers: List([]),
  zoomToLayer: false,
  zoomToLayerBounds: [],
  currentCursorCrs: null,
  convertCoordinatesToHDMS: false,
  renderedLayersNumber: 0,
  streetViewCoordinates: null,
  isMapLoaded: false,
  isMainMiniMapDragging: false,
  fetchngWfsLayers: Map({}),
  isGeomLoadingOnMap: false,
  activeBackgroundTool: '',
  backgroundParcelColor: null
});

const setZoomLevel = (state, action) => {
  if (state.get('zoomLevel') === action.zoomLevel) {
    return state;
  }

  return state.merge(
    Map({
      zoomLevel: action.zoomLevel
    })
  );
};

const setScalesDenominators = (state, { denominators }) =>
  state
    .set('scalesDenominators', List(denominators).reverse())
    .set('maxMapZoomLevel', denominators.length - 1);

const addHistoryMap = (state, action) => {
  return state.merge(
    Map({
      historyMap: state.get('historyMap').push(action.data),
      historyNumber: state.get('historyMap').size
    })
  );
};

const changeNumberHistory = (state, action) => {
  return state.merge(
    Map({
      historyNumber: action.number,
      historyClicked: true
    })
  );
};

const setClickedHistory = (state, action) => {
  return state.merge(
    Map({
      historyClicked: action.clicked
    })
  );
};

const openInformationModal = state =>
  state.set('typeClick', enumClickTypes.identification);

// helping function responsible for changing actual click types in depends of
// tools active in the background(identification for objects) and other tools activities
const updateTypeClickReducer = (state, { activeToolIndex }) => {
  const typeClick = state.get('typeClick');
  const activeBackgroundTool = state.get('activeBackgroundTool');
  const { routePlanning, identification, none } = enumClickTypes;
  const { identificationToolKey } = IDENTIFICATION_KEYS;

  const isRoutePlanning = typeClick === routePlanning;
  const isIdentification = typeClick === identification;
  const isNone = typeClick === none;

  const withoutActiveTool = isIdentification && !activeToolIndex;

  const withActiveTool =
    isIdentification && activeToolIndex === identificationToolKey;

  const activeIdentifications = isNone || isIdentification;

  let newTypeClick = none;

  if (isRoutePlanning) {
    newTypeClick = routePlanning;
  } else if (
    !activeBackgroundTool &&
    activeIdentifications &&
    withoutActiveTool
  ) {
    newTypeClick = none;
  } else if (activeIdentifications && withActiveTool) {
    newTypeClick = identification;
  } else if (activeIdentifications) {
    newTypeClick = none;
  } else if (activeBackgroundTool) {
    newTypeClick = typeClick;
  }

  return state.set('typeClick', newTypeClick);
};

const closeInformationModal = (state, action) => {
  const stateAfterTypeClickUpdate = updateTypeClickReducer(state, action);

  return stateAfterTypeClickUpdate.merge(
    Map({
      selectedParcel: List([])
    })
  );
};

const setIsMapLoadTrue = state => state.set('isMapLoaded', true);

const setIsMapLoadFalse = state => state.set('isMapLoaded', false);

const minimizeInformationModal = state => state.set('typeClick', '');

const maximizeInformationModal = state =>
  state.set('typeClick', enumClickTypes.identification);

const setTypeClick = (state, action) =>
  state.merge(
    Map({
      typeClick: action.measure
    })
  );

const setFullscreenStatus = (state, { isActive }) =>
  state.set('fullscreen', isActive);

const setSelectedParcel = (state, action) =>
  state.update('selectedParcel', List(), list => {
    if (action.onlyArray) return List(action.selectedParcel);

    return List(
      action.onlyOne
        ? [action.selectedParcel]
        : [...list, action.selectedParcel]
    );
  });

const removeSelectedParcel = (state, { id }) =>
  state.update('selectedParcel', List(), list =>
    List([...list.filter(({ id: parcelId }) => parcelId !== id)])
  );

const clearSelectedParcel = state =>
  state.merge(
    Map({
      selectedParcel: List([])
    })
  );

const setSelectedNeighboringParcel = (state, action) =>
  state.update('selectedNeighboringParcel', List(), list => {
    if (action.onlyArray) return List(action.selectedNeighboringParcel);

    return List(
      action.onlyOne
        ? [action.selectedNeighboringParcel]
        : [...list, action.selectedNeighboringParcel]
    );
  });

const clearSelectedNeighboringParcel = state =>
  state.merge(
    Map({
      selectedNeighboringParcel: List([])
    })
  );

const setSelectedDefaultParcels = (state, action) =>
  state.update('selectedDefaultParcels', List(), list => {
    if (action.onlyArray) return List(action.selectedDefaultParcels);

    return List(
      action.onlyOne
        ? [action.selectedDefaultParcels]
        : [...list, action.selectedDefaultParcels]
    );
  });

const clearSelectedDefaultParcels = state =>
  state.merge(
    Map({
      selectedDefaultParcels: List([])
    })
  );

const setBackgroundParcelColor = (state, action) =>
  state.set('backgroundParcelColor', action.backgroundParcelColor);

const clearSelectedPoint = state => state.set('selectedPoint', null);

const setSelectedPoint = (state, action) =>
  state.set('selectedPoint', action.selectedPoint);

const setBaseMapLayer = (state, { baseMapLayer }) =>
  state.set('baseMapLayer', baseMapLayer);

const setMiniMapBaseMap = (state, { miniMapBaseMap }) =>
  state.set('miniMapBaseMap', miniMapBaseMap);

const setCheckedLayers = (state, { checkedLayers }) =>
  state.set('checkedLayers', List(checkedLayers));

const addToInvisibleLayers = (state, { id }) =>
  state.merge(
    Map({
      invisibleLayers: state.get('invisibleLayers').add(id)
    })
  );

const removeFromInvisibleLayers = (state, { id }) =>
  state.merge(
    Map({
      invisibleLayers: state.get('invisibleLayers').remove(id)
    })
  );

const addToLayersZoom = (state, { id, zoomRange }) =>
  state.setIn(['layersZoom', id], zoomRange);

const setLayerOpacity = (state, action) => {
  return state.mergeDeep(
    Map({
      layersOpacity: Map({
        [action.layerId]: action.opacityValue
      })
    })
  );
};

const setLayerStyle = (state, action) => {
  return state.mergeDeep(
    Map({
      layersStyle: Map({
        [action.layerId]: action.styleName
      })
    })
  );
};

const setLayersOrder = (state, action) => {
  return state.merge(
    Map({
      layersOrder: List(action.layersOrder)
    })
  );
};

const addPrintPreviewLoadingLayer = (state, action) =>
  state.update('printPreviewLoadingLayers', List(), list =>
    List([...list, action.layerId])
  );

const deletePrintPreviewLoadingLayer = (state, action) =>
  state.update('printPreviewLoadingLayers', List(), list =>
    List(list.filter(id => id !== action.layerId))
  );

const zoomToLayerStart = (state, action) =>
  state.merge({
    zoomToLayer: true,
    zoomToLayerBounds: action.bounds
  });

const zoomToLayerEnd = state =>
  state.merge({
    zoomToLayer: false,
    zoomToLayerBounds: []
  });

const setCurrentCursorCrs = (state, { currentCursorCrs }) =>
  state.set('currentCursorCrs', currentCursorCrs);

const setConvertCoordinatesToHDMS = (state, { convertCoordinatesToHDMS }) =>
  state.set('convertCoordinatesToHDMS', convertCoordinatesToHDMS);

const setRenderedLayersNumber = (state, { renderedLayersNumber }) =>
  state.set('renderedLayersNumber', renderedLayersNumber);

const setStreetViewCoordinates = (state, { coordinates }) =>
  state.set('streetViewCoordinates', coordinates);

const setMainMiniMapDraggingStatus = (state, { isDragging }) =>
  state.set('isMainMiniMapDragging', isDragging);

const setFetchedWfsLayers = (state, { layer, isFetched }) =>
  state.setIn(['fetchngWfsLayers', layer], isFetched);

const openWmsInformationModal = state =>
  state.set('typeClick', enumClickTypes.wmsIdentification);

const isGeomLoadingOnMap = (state, { isGeomLoadingOnMap }) =>
  state.set('isGeomLoadingOnMap', isGeomLoadingOnMap);

const setActiveBackgroundTool = (state, action) =>
  state.merge(
    Map({
      activeBackgroundTool: action.activeBackgroundTool
    })
  );

export default function reducer(state = initialState, action) {
  return (
    {
      [SET_ZOOM]: setZoomLevel,
      [ADD_HISTORY_VIEW]: addHistoryMap,
      [CHANGE_NUMBER_HISTORY]: changeNumberHistory,
      [SET_HISTORY_CLICKED]: setClickedHistory,
      [SET_MEASURE_TOOL]: setTypeClick,
      [OPEN_INFORMATION_MODAL]: openInformationModal,
      [CLOSE_INFORMATION_MODAL]: closeInformationModal,
      [MINIMIZE_INFORMATION_MODAL]: minimizeInformationModal,
      [MAXIMIZE_INFORMATION_MODAL]: maximizeInformationModal,
      [SET_FULLSCREEN_STATUS]: setFullscreenStatus,
      [SET_SELECTED_PARCEL]: setSelectedParcel,
      [SET_SELECTED_POINT]: setSelectedPoint,
      [SET_SELECTED_NEIGHBORING_PARCEL]: setSelectedNeighboringParcel,
      [CLEAR_SELECTED_NEIGHBORING_PARCEL]: clearSelectedNeighboringParcel,
      [SET_SELECTED_DEFAULT_PARCELS]: setSelectedDefaultParcels,
      [CLEAR_SELECTED_DEFAULT_PARCELS]: clearSelectedDefaultParcels,
      [CLEAR_SELECTED_PARCEL]: clearSelectedParcel,
      [REMOVE_SELECTED_PARCEL]: removeSelectedParcel,
      [SET_BASE_MAP_LAYER]: setBaseMapLayer,
      [SET_MINI_MAP_BASE_MAP]: setMiniMapBaseMap,
      [SET_CHECKED_LAYERS]: setCheckedLayers,
      [ADD_TO_INVISIBLE_LAYERS]: addToInvisibleLayers,
      [REMOVE_FROM_INVISIBLE_LAYERS]: removeFromInvisibleLayers,
      [ADD_TO_LAYERS_ZOOM]: addToLayersZoom,
      [SET_LAYER_OPACITY]: setLayerOpacity,
      [SET_LAYER_STYLE]: setLayerStyle,
      [SET_LAYERS_ORDER]: setLayersOrder,
      [ADD_PRINT_PREVIEW_LOADING_LAYER]: addPrintPreviewLoadingLayer,
      [DELETE_PRINT_PREVIEW_LOADING_LAYER]: deletePrintPreviewLoadingLayer,
      [ZOOM_TO_LAYER_START]: zoomToLayerStart,
      [ZOOM_TO_LAYER_END]: zoomToLayerEnd,
      [SET_CURRENT_CURSOR_CRS]: setCurrentCursorCrs,
      [CONVERT_COORDINATES_TO_HDMS]: setConvertCoordinatesToHDMS,
      [SET_RENDERED_LAYERS_NUMBER]: setRenderedLayersNumber,
      [SET_STREET_VIEW_COORDINATES]: setStreetViewCoordinates,
      [SET_SCALES_DENOMINATORS]: setScalesDenominators,
      [SET_MAPLOAD_TRUE]: setIsMapLoadTrue,
      [SET_MAPLOAD_FALSE]: setIsMapLoadFalse,
      [SET_MAIN_MINI_MAP_DRAGGING_STATUS]: setMainMiniMapDraggingStatus,
      [FETCH_EXTERNAL_WFS_ACTIONS]: setFetchedWfsLayers,
      [OPEN_WMS_INFORMATION_MODAL]: openWmsInformationModal,
      [CLEAR_SELECTED_POINT]: clearSelectedPoint,
      [IS_GEOM_LOADING_ON_MAP]: isGeomLoadingOnMap,
      [SET_ACTIVE_BACKGROUND_TOOL]: setActiveBackgroundTool,
      [SET_BACKGROUND_PARCEL_COLOR]: setBackgroundParcelColor
    }[action.type] || (s => s)
  )(state, action);
}
