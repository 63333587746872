import { useIntl } from 'react-intl';

const useTranslateConfigSelector = () => {
  const { formatMessage } = useIntl();

  const translations = {
    globalSubmissionSettings: formatMessage({
      id: 'global_submission_settings',
      defaultMessage: 'Global settings of the Submission form:'
    }),
    maxNumberOfAttachments: formatMessage({
      id: 'max_number_of_attachments',
      defaultMessage: 'Maximum number of attachments:'
    }),
    maxAttachmentsSize: formatMessage({
      id: 'max_attachment_size',
      defaultMessage: 'Maximum attachment size:'
    }),
    attachmentsFormat: formatMessage({
      id: 'attachments_format',
      defaultMessage: 'Attachment formats:'
    }),
    submissionGeoType: formatMessage({
      id: 'submission_geometry_type',
      defaultMessage: 'Submission geometry type:'
    }),
    multipleGeoOption: formatMessage({
      id: 'multiple_geometry_option',
      defaultMessage:
        'Would the option to add multiple geometries to one report be available?'
    }),
    isRequired: formatMessage({
      id: 'general_required_field',
      defaultMessage: 'Field required!'
    }),
    cannotExceed: formatMessage({
      id: 'value_cannot_exceed',
      defaultMessage: 'Value cannot exceed'
    }),
    valueCannotBeEqual: formatMessage({
      id: 'value_cannot_be_equal',
      defaultMessage: 'Value cannot be equal'
    }),
    save: formatMessage({
      id: 'general_save',
      defaultMessage: 'Save'
    }),
    updatedSuccessfuly: formatMessage({
      id: 'configuration_update_success',
      defaultMessage: 'Configuration updated successful'
    }),
    chooseMinimumOneOption: formatMessage({
      id: 'choose_minimum_one_option',
      defaultMessage: 'Choose minimum one option'
    })
  };

  return translations;
};

export default useTranslateConfigSelector;
