import { Icon, Popover, Row } from 'antd';

import Button from '../../Button';

import { convertBytes } from '../../../utils/lib';

import './UploadedImagesInfo.less';

const IMAGE_MAX_SIZE = 1; // MB

const UploadedImagesInfo = ({
  file,
  dimensions,
  minimalImageDimensions = {}
}) => {
  const { width, height } = dimensions;
  const {
    width: minimalWidth,
    height: minimialHeight
  } = minimalImageDimensions;

  const isFileSizeValid = convertBytes(file.size, 'MB') > IMAGE_MAX_SIZE;
  const areFileDimensions = minimalWidth && minimialHeight;

  const getPopoverContent = () => (
    <>
      {isFileSizeValid && (
        <Row>
          <div className="ant-form-explain">
            {`Zalecany maksymalny rozmiar grafiki to ${IMAGE_MAX_SIZE} MB.`}{' '}
            {`Aktualny rozmiar: ${convertBytes(file.size, 'MB').toFixed(
              2
            )} MB.`}
          </div>
        </Row>
      )}
      {areFileDimensions && (
        <>
          <Row>
            <div className="ant-form-explain">
              {`Minimalne wymiary grafiki ${minimalWidth}(px) x ${minimialHeight}(px) lub zachowana`}{' '}
              {`proporcja ${minimalWidth / minimialHeight} : 1.`}
            </div>
          </Row>
          <Row>
            <div className="ant-form-explain">
              {`Atualne wymiary: ${width}(px) x ${height}(px).`}{' '}
              {`Aktulna proporcja: ${(width / height).toFixed(2)} : 1.`}
            </div>
          </Row>
        </>
      )}
    </>
  );

  return isFileSizeValid || areFileDimensions ? (
    <Popover
      overlayClassName="custom-popover"
      content={getPopoverContent()}
      placement="topRight"
    >
      <Button variant="warning">
        <Icon type="exclamation-circle" />
      </Button>
    </Popover>
  ) : null;
};

export default UploadedImagesInfo;
