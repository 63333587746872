import L from 'leaflet';
import { polygon, lineString, point } from '@turf/helpers';
import { coordEach } from '@turf/meta';
import { isEqual } from 'lodash';
import centerOfMass from '@turf/center-of-mass';

import {
  isMultiPartGeometry,
  getLineMiddlePoint,
  reverseCoordinates
} from '../../../../utils/mapUtils';

import {
  emptyGeometries,
  INITIALIZED_LAYER_COORDINATES,
  INITIALIZED_LAYER_BASIC_PROPERTIES
} from '../const';
import { CLIPBOARD_OBJECT_GEOMETRY_TYPE } from '../../../../constants/clipboard';

// TODO: Większość tych metod można upublicznić.
const utils = {
  _isPoint(layer) {
    return layer instanceof L.Circle;
  },
  _isPolygonTypeGeometry(type) {
    return type === 'Polygon' || type === 'MultiPolygon';
  },
  _isLineStringTypeGeometry(type) {
    return type === 'LineString' || type === 'MultiLineString';
  },
  _isPointTypeGeometry(type) {
    return type === 'Point' || type === 'MultiPoint';
  },
  _isMultiPartLayer(editionId) {
    const geometryType = this._getEditionGeometryType(editionId);
    return isMultiPartGeometry(geometryType);
  },
  _getLefleatGeometryConstructor(editionId) {
    const geometryType = this._getEditionGeometryType(editionId);

    switch (geometryType) {
      case 'Polygon':
      case 'MultiPolygon':
      case 'MultiRectangle':
      case 'MultiCircle':
        return L.polygon;
      case 'LineString':
      case 'MultiLineString':
        return L.polyline;
      case 'Point':
      case 'MultiPoint':
        return L.circle;
    }
  },
  _getLayerCoordinates(layer) {
    if (this._isPoint(layer)) return layer.getLatLng();
    return layer.getLatLngs();
  },
  _setLayerCoordinates(layer, coords) {
    if (this._isPoint(layer)) return layer.setLatLng(coords);
    return layer.setLatLngs(coords);
  },
  _closeObjectsTools(editionId, isLabelingMode) {
    this._closeDeleteObjectTooltips(editionId);
    this._closeRotateObjectMode(editionId);
    this._closeMoveObjectMode(editionId);
    this._closeSliceObjectMode(editionId);
    this._closeMergeObjectsMode(editionId);
    this._closeObjectBufferMode(editionId);
    this._closeCopyObjectsTooltips(editionId);
    this._closeMeasureObjectsTooltips(editionId);
    this._closeSymbolizationMode(editionId);
    this._closeLabelingMode(editionId);
    this._closeLinearGuidesMode(editionId);

    this._redrawEditionVertexes(editionId);

    // When labelingMode is turned on labeling-layer can't be removed
    if (!isLabelingMode) {
      this._removeLabelingLayer();
    }

    this._closeAddShapeMode(editionId);
  },
  _getGeometryGeoJSON(geometryType, coords) {
    switch (geometryType) {
      case 'Polygon':
      case 'MultiPolygon':
        return polygon(coords);
      case 'LineString':
      case 'MultiLineString':
        return lineString(coords);
      case 'Point':
      case 'MultiPoint':
        return point(coords);
    }
  },
  _removeEmptyCoords(coordinates) {
    return coordinates.filter(coords => coords.length);
  },
  _getLeafletLayerToEdit(type, coordinates) {
    if (
      type === 'Polygon' ||
      type === 'MultiPolygon' ||
      this._isRectangleLayer(type) ||
      this._isCircleLayer(type)
    ) {
      return new L.Polygon(coordinates);
    }

    if (
      type === 'LineString' ||
      type === 'MultiLineString' ||
      // MultiPoint traktujemy jako LineString, bo to w naszym przypadku chyba
      // najwygodniejszy i najszybszy sposób na obsługę tego typu danych.
      // Przed dodaniem na mapę ukrywamy linie i tym sposobem zostaną same punkty.
      type === 'MultiPoint'
    ) {
      return new L.Polyline(coordinates);
    }

    if (type === 'Point') {
      return new L.Circle(coordinates, { radius: 0 });
    }

    throw Error('Unknown layer type!');
  },
  checkIsEditionLayerEmpty(editionId) {
    const geometryType = this._getEditionGeometryType(editionId);
    const layerGeoJson = this._getEditionLayer(editionId).toGeoJSON();
    return isEqual(
      layerGeoJson.geometry.coordinates,
      emptyGeometries[geometryType]
    );
  },
  // Poniższa funkcja nie jest pełną implementacją - sprawdza tylko poprawność
  // współrzędnych GeoJSON'a na potrzeby _getBufferLayer.
  _validateGeoJson(geoJson) {
    let isGeoJsonValid = true;

    coordEach(geoJson, coord => {
      if (!isGeoJsonValid) return;

      if (!coord || (Array.isArray(coord) && !coord.length)) {
        isGeoJsonValid = false;
      }
    });

    return isGeoJsonValid;
  },
  _isLastGeometryShape(editionId) {
    let isLastShape = false;

    const geometryType = this._getEditionGeometryType(editionId);
    const layer = this._getEditionLayer(editionId);
    const latLngs = layer.getLatLngs();

    if (geometryType === 'MultiPoint' || geometryType === 'MultiLineString') {
      isLastShape = latLngs.length === 1;
    }

    if (geometryType === 'MultiPolygon') {
      isLastShape = latLngs.length === 1 && latLngs[0].length === 1;
    }

    return isLastShape;
  },
  _isMultiPolygonObject(editionId) {
    const layer = this._getEditionLayer(editionId);
    const {
      geometry: { type }
    } = layer.toGeoJSON();

    return type === 'MultiPolygon';
  },
  _getCurrentGeometry(editionId) {
    const layer = this._getEditionLayer(editionId);
    return layer.toGeoJSON();
  },
  _getObjectCenterPointCoordinates(object) {
    const {
      geometry: { type, coordinates }
    } = object;

    const centerPointsCoordinates = [];

    if (type === CLIPBOARD_OBJECT_GEOMETRY_TYPE.Point) {
      // W Leaflet zamienione są wartości lng z lat i na odwrót.
      coordinates.forEach(([lat, lng]) =>
        centerPointsCoordinates.push({
          lat: lng,
          lng: lat
        })
      );
    }

    if (type === CLIPBOARD_OBJECT_GEOMETRY_TYPE.LineString) {
      coordinates.forEach(coordinatesItem => {
        const { lat, lng } = getLineMiddlePoint(
          coordinatesItem[0],
          coordinatesItem[1]
        );

        centerPointsCoordinates.push({ lat: lng, lng: lat });
      });
    }

    if (type === CLIPBOARD_OBJECT_GEOMETRY_TYPE.Polygon) {
      coordinates.forEach(coordinatesItem => {
        const polygon = L.polygon(coordinatesItem);
        const polygonCenter = centerOfMass(polygon.toGeoJSON());
        const [lng, lat] = polygonCenter.geometry.coordinates;
        centerPointsCoordinates.push({ lat: lng, lng: lat });
      });
    }

    return centerPointsCoordinates;
  },
  _compareObjectCoordinates({ prevCoordinates, newCoordinates }) {
    return JSON.stringify(prevCoordinates) === JSON.stringify(newCoordinates);
  },
  _createInitializedLayerObject(geometryType) {
    return {
      ...INITIALIZED_LAYER_BASIC_PROPERTIES,
      geometry: {
        type: geometryType,
        coordinates: INITIALIZED_LAYER_COORDINATES[geometryType]
      }
    };
  },
  _createNewLayer({
    editionId,
    initializedLayerObject,
    initializedLayerProps,
    addToFeatureGroup = false
  }) {
    const initializedLayer = L.geoJSON(initializedLayerObject);

    if (initializedLayerProps) {
      const initializedLayerPropsKeys = Object.keys(initializedLayerProps);

      initializedLayerPropsKeys.forEach(
        key => (initializedLayer[key] = initializedLayerProps[key])
      );
    }

    if (addToFeatureGroup) {
      const featureGroup = this._getEditionFeatureGroup(editionId);
      featureGroup.addLayer(initializedLayer);
    } else {
      this.addLayer(initializedLayer);
    }

    return initializedLayer;
  },
  // Jeśli warstwa zostanie zainicjalizowana, to początkowo zostaje dodany
  // punkt z współrzędnymi lat = 0, lng = 0, dlatego tez podczas wklejania
  // sprawdzam warstę i jeśli dodany punkt, linia etc. posiada choć jeden punkt
  // ze współrzędnymi [0,0] to zostaje on usunięty.
  _initializeAndCreateNewLayer({ editionId, layerProps, addToFeatureGroup }) {
    const geometryType = this._getEditionGeometryType(editionId);
    const initializedLayerObject = this._createInitializedLayerObject(
      geometryType
    );

    const createdLayer = this._createNewLayer({
      editionId,
      initializedLayerObject,
      initializedLayerProps: layerProps,
      addToFeatureGroup
    });

    return createdLayer;
  },
  _removeDoubledLastPoints(flattenedCoordinates) {
    const coordinatesWithoutDoubledPoint = flattenedCoordinates.map(
      singleObject => {
        const doubledPointIndex = singleObject.length - 2;
        singleObject.splice(doubledPointIndex, 1);
        return singleObject;
      }
    );

    return coordinatesWithoutDoubledPoint;
  },
  _parseMultiPolygonCoordinates(coordinates) {
    // Flat wykonany po to, by nadać tablicy taką samą strukturę, jaką posiada
    // tablica coordinates dla szkicu liniowego
    const flattenedCoordinates = coordinates.flat(1);

    // W wyniku zakończenia szkicu (event double-click) dubluje się ostatni punkt ostatniej linii
    // w szkicu
    return this._removeDoubledLastPoints(flattenedCoordinates);
  },
  _getObjectsLinesCoordinates(editionId) {
    const objectsLinesCoordinates = [];

    const {
      geometry: { coordinates }
    } = this._getCurrentGeometry(editionId);

    const objectsCoordinates = this._isMultiPolygonObject(editionId)
      ? this._parseMultiPolygonCoordinates(coordinates)
      : coordinates;

    objectsCoordinates.forEach(singleObjectCoordinates => {
      const singleObjectLinesCoordinates = [];

      singleObjectCoordinates.forEach((pointCoordinates, index) => {
        const lastIndex = singleObjectCoordinates.length - 1;

        if (index !== lastIndex) {
          const singleLineCoordinates = [
            pointCoordinates,
            singleObjectCoordinates[index + 1]
          ];

          singleObjectLinesCoordinates.push(singleLineCoordinates);
        }
      });

      objectsLinesCoordinates.push(singleObjectLinesCoordinates);
    });

    return objectsLinesCoordinates.flat(1);
  },
  _getObjectsLinesCenterPoints(objectsLinesCoordinates) {
    const objectsLinesCenterPoints = [];

    objectsLinesCoordinates.forEach(singleLine => {
      const { lat, lng } = getLineMiddlePoint(singleLine[0], singleLine[1]);

      objectsLinesCenterPoints.push({ lat: lng, lng: lat });
    });

    return objectsLinesCenterPoints;
  },
  _addLastCreationGeometryPointToPreviewLayer() {
    const currentCoords = this._creationGeometryPreviewLayer._latlngs;
    const lastPoint = currentCoords[currentCoords.length - 1];
    this._creationGeometryCoords.push(lastPoint);
    this._creationGeometryPreviewLayer.setLatLngs(this._creationGeometryCoords);
  },
  _removeBasicMouseMapEvents() {
    this.off('mousemove', this._handleMapMouseMove);
    this.off('click', this._handleMapClick);
  },
  _restoreBasicMouseMapEvents() {
    this.on('mousemove', this._handleMapMouseMove);
    this.on('click', this._handleMapClick);
  },
  _reverseGeometryCoordinates(coordinates) {
    return reverseCoordinates(coordinates);
  },
  _isAnyToolEnabled(editionId) {
    const edition = this._getEdition(editionId);

    const tools = [
      edition._isMoveObjectModeActive,
      edition._isMergeObjectsModeActive,
      edition._isSliceObjectModeActive,
      edition._isRotateObjectModeActive,
      edition._isCopyObjectsModeActive,
      edition._isMeasureObjectsModeActive,
      edition._isObjectBufferModeActive,
      edition._isSymbolizationModeActive,
      edition._isLabelingModeActive
    ];

    return tools.some(Boolean);
  }
};

export default utils;
