import { useIntl } from 'react-intl';

const useSymbolizationMessages = () => {
  const { formatMessage } = useIntl();

  const basicMsg = formatMessage({
    id: 'roles_basic',
    defaultMessage: 'Basic'
  });

  const columnMsg = formatMessage({
    id: 'global_select_column',
    defaultMessage: 'Select column'
  });

  const selectCsvType = formatMessage({
    id: 'global_select_csv_data_type',
    defaultMessage: 'Choose CSV data type'
  });

  const colorMsg = formatMessage({
    id: 'global_colour',
    defaultMessage: 'Colour'
  });

  const crsMsg = formatMessage({
    id: 'global_coordinate_system',
    defaultMessage: 'Coordinate system'
  });

  const nameMsg = formatMessage({
    id: 'district_portal_wms_tool_add_form_title_placeholder',
    defaultMessage: 'Enter name.'
  });

  const nameLabel = formatMessage({
    id: 'district_portal_wms_tool_add_form_titl_label',
    defaultMessage: 'Name'
  });

  const sizeMsg = formatMessage({
    id: 'global_size',
    defaultMessage: 'Size'
  });

  const shapeMsg = formatMessage({
    id: 'global_shape',
    defaultMessage: 'Shape'
  });

  const symbolizationMsg = formatMessage({
    id: 'district_portal_csv_layer_symbolization',
    defaultMessage: 'Symbolization'
  });

  const uploadSvgMsg = formatMessage({
    id: 'district_portal_csv_tool_upload_svg',
    defaultMessage: 'Upload SVG'
  });

  const svgSymbolMsg = formatMessage({
    id: 'district_portal_csv_tool_svg_symbol',
    defaultMessage: 'SVG symbol'
  });

  const addNewLayerMsg = formatMessage({
    id: 'district_portal_add_new_layer',
    defaultMessage: 'Add new layer'
  });

  const selectDataTypeMsg = formatMessage({
    id: 'general_data_type',
    defaultMessage: 'Select data type'
  });

  const styleMsg = formatMessage({
    id: 'general_style',
    defaultMessage: 'Style'
  });

  const contourMsg = formatMessage({
    id: 'general_contour',
    defaultMessage: 'Contour'
  });

  const fillMsg = formatMessage({
    id: 'general_fill',
    defaultMessage: 'Fill'
  });

  const solidMsg = formatMessage({
    id: 'general_solid',
    defaultMessage: 'Solid'
  });

  const dashedMsg = formatMessage({
    id: 'general_dashed',
    defaultMessage: 'Dashed'
  });

  const dottedMsg = formatMessage({
    id: 'general_dotted',
    defaultMessage: 'Dotted'
  });

  const inputDataTypeMsg = formatMessage({
    id: 'on_file_type_change',
    defaultMessage: 'Select a data type'
  });

  const inputCsvDelimeterMsg = formatMessage({
    id: 'on_csv_change_delimiter',
    defaultMessage: 'Delimiter'
  });

  const inputCsvInversionMsg = formatMessage({
    id: 'on_csv_change_coordinate_inversion',
    defaultMessage: 'Coordinate inversion'
  });

  const optionNameCsvXY = formatMessage({
    id: 'optin_name_CSV_XY',
    defaultMessage: 'Two columns X and Y'
  });

  const optionNameCsvWKT = formatMessage({
    id: 'optin_name_CSV_WKT',
    defaultMessage: 'WKT geometry column'
  });

  const optionNameCsvA = formatMessage({
    id: 'optin_name_CSV_A',
    defaultMessage: 'Address column'
  });

  const optionNameCsvP = formatMessage({
    id: 'optin_name_CSV_P',
    defaultMessage: 'Plot nubmer column'
  });

  const plotTitleMsg = formatMessage({
    id: 'select_title_msg_plot',
    defaultMessage: 'Plot'
  });

  const addressTitleMsg = formatMessage({
    id: 'select_title_msg_address',
    defaultMessage: 'Address'
  });

  const opacityMsg = formatMessage({
    id: 'district_portal_layer_settings_opacity',
    defaultMessage: 'Opacity'
  });

  const visibilityMsg = formatMessage({
    id: 'district_portal_layer_settings_visibility',
    defaultMessage: 'Visibility'
  });

  const noneMsg = formatMessage({
    id: 'district_portal_layers_name_none',
    defaultMessage: 'None'
  });

  const apiMsg = formatMessage({
    id: 'district_portal_api_msg',
    defaultMessage: 'Enter api link.'
  });

  const dxfOrDwgLayer = formatMessage({
    id: 'district_portal_dxf_layer',
    defaultMessage: 'Layer'
  });

  const dxfOrDwgLayerMsg = formatMessage({
    id: 'district_portal_dxf_layer_msg',
    defaultMessage: 'Select layer'
  });

  return {
    basicMsg,
    columnMsg,
    selectCsvType,
    colorMsg,
    crsMsg,
    nameMsg,
    nameLabel,
    sizeMsg,
    shapeMsg,
    symbolizationMsg,
    uploadSvgMsg,
    svgSymbolMsg,
    addNewLayerMsg,
    selectDataTypeMsg,
    styleMsg,
    contourMsg,
    fillMsg,
    solidMsg,
    dashedMsg,
    dottedMsg,
    inputDataTypeMsg,
    inputCsvDelimeterMsg,
    inputCsvInversionMsg,
    optionNameCsvXY,
    optionNameCsvWKT,
    optionNameCsvA,
    optionNameCsvP,
    plotTitleMsg,
    addressTitleMsg,
    opacityMsg,
    visibilityMsg,
    noneMsg,
    apiMsg,
    dxfOrDwgLayer,
    dxfOrDwgLayerMsg
  };
};

export default useSymbolizationMessages;
