import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { Menu } from 'antd';
import { Loader } from '..';

import { mapPortalSelectors } from '../../store/selectors';

import './MapPortalCompositionsMenu.less';

const MenuItem = Menu.Item;

const MapPortalCompositionsMenu = props => {
  const className = props.menuProps.className
    ? ['map-portal-compositions-menu', ...props.menuProps.className].join(' ')
    : 'map-portal-compositions-menu';

  return props.mapPortalCompositions ? (
    <Menu
      mode="vertical"
      onSelect={props.onChange}
      {...props.menuProps}
      className={className}
    >
      {props.mapPortalCompositions.map(item => (
        <MenuItem
          key={props.getCompositionId ? item.composition.id : item.id}
          title={item.composition.name}
        >
          {item.composition.name}
        </MenuItem>
      ))}
    </Menu>
  ) : (
    <Loader tip="Trwa wczytywanie listy kompozycji portalu mapowego..." />
  );
};

const mapStateToProps = (state, ownProps) => ({
  mapPortalCompositions: mapPortalSelectors.getMapPortalCompositions(
    state,
    ownProps.match.params.prefix,
    null,
    ownProps.mapPortalId
  )
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

MapPortalCompositionsMenu.defaultProps = {
  menuProps: {}
};

MapPortalCompositionsMenu.propTypes = {
  mapPortalCompositions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  mapPortalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuProps: PropTypes.shape({
    className: PropTypes.array
  }).isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MapPortalCompositionsMenu)
);
