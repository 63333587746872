import axios from 'axios';

import { endpointPaths } from '../../config';
import mainRoutePaths from '../../router/portal/paths';

const resetPassword = email =>
  new Promise((resolve, reject) => {
    axios
      .post(
        endpointPaths.resetPasswordEmail,
        {
          email,
          landing_page: window.location.origin + mainRoutePaths.resetPassword
        },
        {
          validateStatus: status =>
            (status >= 200 && status < 300) || status === 404
        }
      )
      .then(response => {
        if (response.status === 404) {
          reject('Podany e-mail nie istnieje!');
        }
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });

export default resetPassword;
