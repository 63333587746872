import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { merge, cloneDeep } from 'lodash';

import config from '../../config';

import { mapPortalSelectors, wmsSelectors } from '../../store/selectors';

const { getPortalPublicWMS } = wmsSelectors;
const { getMapPortalId } = mapPortalSelectors;

const useFindWmsLayerById = () => {
  const { prefix, portal } = useParams();
  const mapPortalId = useSelector(state =>
    getMapPortalId(state, prefix, portal)
  );
  const wmsLibrary = useSelector(state =>
    getPortalPublicWMS(state, mapPortalId)
  );

  const getLocalStorageWms = () => {
    const storageItem = localStorage.getItem(config.localStorageKeys.wms);
    const itemsList = storageItem ? JSON.parse(storageItem) : [];

    return itemsList;
  };

  const findWmsLayerInLocalStorage = layerId => {
    const localStorageWms = getLocalStorageWms();
    const wmsLayer = localStorageWms.find(wms => {
      const layer = wms.layers.find(({ id }) => id.toString() === layerId);
      if (layer) {
        wms.layerName = layer.name;
        wms.layerId = layer.id;
        return wms;
      }
      return null;
    });

    return wmsLayer;
  };

  const findWmsLayerInWmsLibrary = layerId => {
    const wmsLibraryClone = cloneDeep(wmsLibrary);
    const wmsLayer = wmsLibraryClone.find(wms => {
      const layer = wms.wms.layers.find(({ id }) => id.toString() === layerId);
      if (layer) {
        wms.layerName = layer.name;
        wms.layerId = layer.id;
        merge(wms, { ...wms.wms });
        return wms;
      }
      return null;
    });
    return wmsLayer;
  };

  const findWmsLayer = layerId =>
    findWmsLayerInLocalStorage(layerId) || findWmsLayerInWmsLibrary(layerId);

  return { findWmsLayer };
};

export default useFindWmsLayerById;
