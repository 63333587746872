import { Layout } from 'antd';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import useGlobalSettings from '../../hooks/useGlobalSettings';
import mainRoutePaths from '../../router/portal/paths';
import './GlobalFooter.less';
const { Footer } = Layout;

const GlobalFooter = () => {
  const globalSettings = useGlobalSettings();
  const { formatMessage } = useIntl();

  const privacyPolicyText = formatMessage({
    id: 'title_profile_privacy_policy',
    defaultMessage: 'Privacy policy'
  });

  const termsText = formatMessage({
    id: 'title_profile_terms',
    defaultMessage: 'Terms of use'
  });

  const availabilityText = formatMessage({
    id: 'title_profile_availability',
    defaultMessage: 'Declaration of availability'
  });

  return (
    <Footer className="footer-main" style={{ textAlign: 'center' }}>
      {globalSettings.show_rpo_logo}
      <ul class="footer-row">
        {globalSettings.show_availability_statement && (
          <li class="footer-block">
            <NavLink to={mainRoutePaths.availability_statement}>
              {availabilityText}
            </NavLink>
          </li>
        )}
        {globalSettings.show_terms_and_conditions && (
          <li class="footer-block">
            <NavLink to={mainRoutePaths.terms}>{termsText}</NavLink>
          </li>
        )}
        {globalSettings.show_privacy_policy && (
          <li class="footer-block">
            <NavLink to={mainRoutePaths.privacy}>{privacyPolicyText}</NavLink>
          </li>
        )}
      </ul>
      {globalSettings?.show_rpo_logo && (
        <div
          className="portal_list_rpo"
          style={{ backgroundImage: `url(${globalSettings?.rpo_logo})` }}
        />
      )}
    </Footer>
  );
};

export default GlobalFooter;
