import { point } from '@turf/helpers';
import bboxPolygon from '@turf/bbox-polygon';
import isPointInPolygon from '@turf/boolean-point-in-polygon';

/**
 * Sprawdza, czy punkt znajduje się we wskazanym BBOX-ie.
 *
 * @param {Array} clickedPointCoords
 * @param {Array} layerBbox
 */

const isPointInBbox = (clickedPointCoords, layerBbox) => {
  const clickedPoint = point(clickedPointCoords);
  const bbox = bboxPolygon(layerBbox);
  return isPointInPolygon(clickedPoint, bbox);
};

export default isPointInBbox;
