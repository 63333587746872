import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import './SettingsRow.less';

const SettingsRow = ({ description, children }) => {
  return (
    <Row className="settings-row" type="flex" align="middle">
      <Col xs={7}>{description}</Col>
      <Col xs={17}>{children}</Col>
    </Row>
  );
};

SettingsRow.propTypes = {
  description: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default SettingsRow;
