export const RADIO_VALUES = [
  { value: 'PNG', label: 'PNG' },
  { value: 'JPG', label: 'JPG' },
  { value: 'SHP', label: 'SHP' },
  { value: 'GeoJSON', label: 'GeoJSON' }
];

export const GEOMETRY_TYPE_VALUES = [
  { value: 'point', label: 'punkt' },
  { value: 'polygon', label: 'poligon' },
  { value: 'line', label: 'linia' }
];

export const megabyteInBytes = 1048576;
export const maxAttachmentSize = 2147483647;
