export const eventTypes = {
  historyEditionChange: 'historyEditionChange',
  deleteObjectModeStatusChange: 'deleteObjectModeStatusChange',
  rotateObjectModeStatusChange: 'rotateObjectModeStatusChange',
  mergeObjectsModeStatusChange: 'mergeObjectsModeStatusChange',
  sliceObjectModeStatusChange: 'sliceObjectModeStatusChange',
  moveObjectModeStatusChange: 'moveObjectModeStatusChange',
  copyObjectsModeStatusChange: 'copyObjectsModeStatusChange',
  measureObjectsModeStatusChange: 'measureObjectsModeStatusChange',
  updateCopyObjectsAmount: 'updateCopyObjectsAmount',
  objectBufferModeStatusChange: 'objectBufferModeStatusChange',
  symbolizationModeStatusChange: 'symbolizationModeStatusChange',
  labelingModeStatusChange: 'labelingModeStatusChange',
  layerEditionError: 'layerEditionError',
  changeLayerSymbolization: 'changeLayerSymbolization',
  changeLabelingMenuStatus: 'changeLabelingMenuStatus',
  toggleObjectLabelTooltips: 'toggleObjectLabelTooltips',
  changeLabelValue: 'changeLabelValue',
  changeLabelsSymbolization: 'changeLabelsSymbolization',
  changeShowSketchLabels: 'changeShowSketchLabels',
  snapModeStatusChange: 'snapModeStatusChange',
  linearGuidesModeStatusChange: 'linearGuidesModeStatusChange',
  setLinearGuideType: 'setLinearGuideType',
  setLinearGuideAngle: 'setLinearGuideAngle',
  resetLinearGuideType: 'resetLinearGuideType'
};

export const errorTypes = {
  POLYGON_MUST_HAVE_3_COORDS: 'POLYGON_MUST_HAVE_3_COORDS',
  LINESTRING_MUST_HAVE_2_COORDS: 'LINESTRING_MUST_HAVE_2_COORDS',
  POINT_MUST_HAVE_1_COORD: 'POINT_MUST_HAVE_1_COORD',
  OBJECT_SLICING_ERROR: 'OBJECT_SLICING_ERROR'
};

export const commonClassNames = {
  objectLabel: 'object-label',
  reportMarker: 'report-marker'
};

export const emptyGeometries = {
  Point: [0, 0],
  LineString: [
    [0, 0],
    [0, 0]
  ],
  Polygon: [
    [
      [0, 0],
      [0, 0],
      [0, 0],
      [0, 0]
    ]
  ],
  MultiPoint: [],
  MultiLineString: [],
  MultiPolygon: [[undefined]]
};

export const bufferTypes = {
  VERTEX: 'vertex',
  SEGMENT: 'segment'
};

export const snapTargets = {
  OBJECTS: 'OBJECTS',
  LAYER: 'LAYER',
  TEMP_LAYER: 'TEMP_LAYER'
};

export const FILL_TYPE_KEYS = {
  STANDARD: 'standard',
  CIRCLE: 'circle',
  RECTANGULAR: 'rectangular',
  TRIANGULAR: 'triangular',
  HORIZONTAL_STRIPES: 'horizontal_stripes',
  DIAGONAL_STRIPES: 'diagonal_stripes'
};

export const STRIPE_PATTERNS = [
  FILL_TYPE_KEYS.HORIZONTAL_STRIPES,
  FILL_TYPE_KEYS.DIAGONAL_STRIPES
];

export const PATTERN_DIMENSIONS = { width: 30, height: 30 };

export const DASH_ARRAY_LINE_TYPE = {
  0: 'solid',
  5: 'dotted',
  10: 'dashed'
};

export const DEFAULT_LEAFLET_COLOR = 'rgba(77, 136, 247, 1)';

export const INITIALIZED_LAYER_BASIC_PROPERTIES = {
  type: 'Feature',
  properties: {}
};

export const INITIALIZED_CALCULATED_LAYER_BASIC_PROPERTIES = {
  type: 'Feature',
  properties: { name: 'calculatedLayer' }
};

export const INITIALIZED_LAYER_COORDINATES = {
  MultiPoint: [[0, 0]],
  MultiLineString: [
    [
      [0, 0],
      [0, 0]
    ]
  ],
  MultiPolygon: [
    [
      [
        [0, 0],
        [0, 0],
        [0, 0]
      ]
    ]
  ]
};

export const BASIC_BUFFER_VALUES = {
  value: 0,
  unit: 'meters'
};

export const VERTEX_OPACITY = 0.6;
