import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { resetPassword } from '../../services';
import { showError, showSuccess } from '../../store/actions/globalActions';

import { Icon, Popconfirm, Tooltip } from 'antd';
import { Button } from '../';

const ResetPasswordButton = props => {
  const [resetting, setResetting] = useState(false);

  const reset = async () => {
    setResetting(true);

    try {
      await resetPassword(props.email);
      props.showSuccess(
        `Dalsze instrukcje dla użytkownika ${props.name} zostały wysłane w mailu.`
      );
    } catch (err) {
      props.showError('Wystąpił błąd podczas resetowania hasła.');
    } finally {
      setResetting(false);
    }
  };

  return (
    <Tooltip title={'Resetuj hasło'}>
      <Popconfirm
        title={`Czy na pewno chcesz zresetować hasło użytkownika ${props.name}?`}
        onConfirm={reset}
        okText="Tak"
        cancelText="Nie"
      >
        <Button variant="text" loading={resetting}>
          {resetting ? 'Resetowanie...' : <Icon type="reload" />}
        </Button>
      </Popconfirm>
    </Tooltip>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showError,
      showSuccess
    },
    dispatch
  );

ResetPasswordButton.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showError: PropTypes.func.isRequired,
  showSuccess: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResetPasswordButton));
