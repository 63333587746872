import {
  ERROR_SHOW,
  ERROR_HIDE,
  SUCCESS_SHOW,
  SUCCESS_HIDE,
  SIDEBAR_SHOW,
  SIDEBAR_HIDE,
  LOGOUT_COUNTDOWN_TIMER_SHOW,
  LOGOUT_COUNTDOWN_TIMER_HIDE,
  CHANGE_VALUE,
  RESET_SIDEBAR_PANELS_VISIBILITY,
  CHANGE_LANGUAGE,
  HAMBURGER_HIDE,
  HAMBURGER_SHOW,
  SET_PREVIOUS_URL,
  FETCH_GLOBAL_SETTINGS_BEGIN,
  FETCH_GLOBAL_SETTINGS_SUCCESS,
  FETCH_GLOBAL_SETTINGS_FAILED,
  UPDATE_GLOBAL_SETTINGS_BEGIN,
  UPDATE_GLOBAL_SETTINGS_SUCCESS,
  UPDATE_GLOBAL_SETTINGS_FAILED,
  DELETE_HEADER_LINK_SUCCESS,
  DELETE_HEADER_LINK_FAILED,
  DELETE_HEADER_LINK_BEGIN,
  UPDATE_HEADER_LINK_BEGIN,
  UPDATE_HEADER_LINK_FAILED,
  UPDATE_HEADER_LINK_SUCCESS,
  CREATE_HEADER_LINK_BEGIN,
  CREATE_HEADER_LINK_SUCCESS,
  CREATE_HEADER_LINK_FAILED,
  FETCH_HEADER_LINK_BEGIN,
  FETCH_HEADER_LINK_SUCCESS,
  FETCH_HEADER_LINK_FAILED,
  GET_LANGUAGES_BEGIN,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAILED,
  GET_TRANSLATION_BEGIN,
  GET_TRANSLATION_SUCCESS,
  GET_TRANSLATION_FAILED,
  CREATE_TRANSLATION_BEGIN,
  CREATE_TRANSLATION_SUCCESS,
  CREATE_TRANSLATION_FAILED,
  EDIT_TRANSLATION_BEGIN,
  EDIT_TRANSLATION_SUCCESS,
  EDIT_TRANSLATION_FAILED,
  DELETE_TRANSLATION_BEGIN,
  DELETE_TRANSLATION_SUCCESS,
  DELETE_TRANSLATION_FAILED,
  FETCH_USER_SETTING_BEGIN,
  FETCH_USER_SETTING_SUCCESS,
  FETCH_USER_SETTING_FAILED,
  FETCH_CURSOR_CRS_LIST_BEGIN,
  FETCH_CURSOR_CRS_LIST_SUCCESS,
  FETCH_CURSOR_CRS_LIST_FAILED,
  FETCH_EMAIL_SETTINGS_BEGIN,
  FETCH_EMAIL_SETTINGS_SUCCESS,
  FETCH_EMAIL_SETTINGS_FAILED,
  EDIT_EMAIL_SETTINGS_BEGIN,
  EDIT_EMAIL_SETTINGS_SUCCESS,
  EDIT_EMAIL_SETTINGS_FAILED,
  VALIDATE_EMAIL_SETTINGS_BEGIN,
  VALIDATE_EMAIL_SETTINGS_SUCCESS,
  VALIDATE_EMAIL_SETTINGS_FAILED,
  CHECK_IF_OFFER_MODEL_AVAILABLE_BEGIN,
  CHECK_IF_OFFER_MODEL_AVAILABLE_SUCCESS,
  CHECK_IF_OFFER_MODEL_AVAILABLE_FAILED,
  GET_PING_PONG_BEGIN,
  GET_PING_PONG_SUCCESS,
  GET_PING_PONG_FAILED,
  CHECK_DEFAULT_USER_SETTING_REQUEST,
  CHECK_DEFAULT_USER_SETTING_SUCCESS,
  CHECK_DEFAULT_USER_SETTING_FAILED,
  SET_DEFAULT_USER_SETTING_REQUEST,
  SET_DEFAULT_USER_SETTING_SUCCESS,
  SET_DEFAULT_USER_SETTING_FAILED,
  SET_WINDOW_DIMENSIONS,
  GET_CONTEXTUAL_HELP_TOOLTIPS_BEGIN,
  GET_CONTEXTUAL_HELP_TOOLTIPS_SUCCESS,
  GET_CONTEXTUAL_HELP_TOOLTIPS_FAILED,
  SET_RENDERED_COMPONENTS,
  CLEAR_RENDERED_COMPONENTS,
  CHECK_USE_ADVANCED_FEATURES_BEGIN,
  CHECK_USE_ADVANCED_FEATURES_SUCCESS,
  CHECK_USE_ADVANCED_FEATURES_FAILED,
  GET_EBUD_API_KEY_BEGIN,
  GET_EBUD_API_KEY_SUCCESS,
  GET_EBUD_API_KEY_FAILED,
  RESET_SIDEBAR_TOPICAL_SEARCH_VISIBILITY
} from '../constants/globalActionTypes';

import axios from 'axios';
import {
  defaultLang,
  localStorageKeys,
  sessionStorageKeys,
  offerModelName
} from '../../config';

export const showError = errorMessage => {
  return {
    type: ERROR_SHOW,
    errorMessage
  };
};

export const hideError = () => ({
  type: ERROR_HIDE
});

export const showSuccess = successMessage => {
  return {
    type: SUCCESS_SHOW,
    successMessage
  };
};

export const hideSuccess = () => ({
  type: SUCCESS_HIDE
});

export const showSidebar = () => ({
  type: SIDEBAR_SHOW
});

export const hideSidebar = () => ({
  type: SIDEBAR_HIDE
});

export const showLogoutCountdownTimer = () => ({
  type: LOGOUT_COUNTDOWN_TIMER_SHOW
});

export const hideLogoutCountdownTimer = () => ({
  type: LOGOUT_COUNTDOWN_TIMER_HIDE
});

export const changeValueMapPortal = (name, value) => ({
  type: CHANGE_VALUE,
  name,
  value
});

export const resetSidebarPanelsVisibility = () => ({
  type: RESET_SIDEBAR_PANELS_VISIBILITY
});

export const resetSidebarTopicalSearchVisibility = () => ({
  type: RESET_SIDEBAR_TOPICAL_SEARCH_VISIBILITY
});

export const changeLanguage = language => ({
  type: CHANGE_LANGUAGE,
  language
});

export const showHamburger = () => ({
  type: HAMBURGER_SHOW
});

export const hideHamburger = () => ({
  type: HAMBURGER_HIDE
});

export const setPreviousUrl = url => ({
  type: SET_PREVIOUS_URL,
  url
});

export const fetchGlobalSettingsBegin = () => ({
  type: FETCH_GLOBAL_SETTINGS_BEGIN
});

export const fetchGlobalSettingsSuccess = globalSettings => ({
  type: FETCH_GLOBAL_SETTINGS_SUCCESS,
  globalSettings
});

export const fetchGlobalSettingsFailed = () => ({
  type: FETCH_GLOBAL_SETTINGS_FAILED
});

export const fetchGlobalSettings = (admin = false) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchGlobalSettingsBegin());
    const url = admin ? 'settings_edit/' : 'settings/';
    axios
      .get(url)
      .then(response => {
        if (response) {
          dispatch(fetchGlobalSettingsSuccess(response.data));
          resolve(response.data);
        }
      })
      .catch(err => {
        dispatch(fetchGlobalSettingsFailed());
        reject(err);
      });
  });

export const updateGlobalSettingsBegin = () => ({
  type: UPDATE_GLOBAL_SETTINGS_BEGIN
});

export const updateGlobalSettingsSuccess = globalSettings => ({
  type: UPDATE_GLOBAL_SETTINGS_SUCCESS,
  globalSettings
});

export const updateGlobalSettingsFailed = () => ({
  type: UPDATE_GLOBAL_SETTINGS_FAILED
});

export const updateGlobalSettings = (data, hasFiles) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateGlobalSettingsBegin());
    const config = {
      headers: {
        'Content-Type': hasFiles ? 'multipart/form-data' : 'application/json'
      }
    };

    axios
      .patch('settings_edit/', data, config)
      .then(r => {
        dispatch(updateGlobalSettingsSuccess(r.data));
        dispatch(showSuccess('Ustawienia zostały zaktualizowane'));
        resolve();
      })
      .catch(err => {
        dispatch(updateGlobalSettingsFailed());
        reject(err);
      });
  });

export const deleteHeaderLinkBegin = () => ({
  type: DELETE_HEADER_LINK_BEGIN
});

export const deleteHeaderLinkSuccess = () => ({
  type: DELETE_HEADER_LINK_SUCCESS
});

export const deleteHeaderLinkFailed = () => ({
  type: DELETE_HEADER_LINK_FAILED
});

export const deleteHeaderLink = id => dispatch => {
  dispatch(deleteHeaderLinkBegin());

  if (!id) {
    dispatch(deleteHeaderLinkFailed());
    dispatch(showError('Wystąpił błąd podczas usuwania odnośnika!'));
  }

  axios
    .delete(`header_links/${id}/`)
    .then(r => {
      dispatch(deleteHeaderLinkSuccess());
      dispatch(showSuccess('Odnośnik został usunięty.'));
    })
    .catch(err => {
      dispatch(deleteHeaderLinkFailed());
      dispatch(showError('Wystąpił błąd podczas usuwania odnośnika!'));
    });
};

export const updateHeaderLinkBegin = () => ({
  type: UPDATE_HEADER_LINK_BEGIN
});

export const updateHeaderLinkSuccess = () => ({
  type: UPDATE_HEADER_LINK_SUCCESS
});

export const updateHeaderLinkFailed = () => ({
  type: UPDATE_HEADER_LINK_FAILED
});

export const updateHeaderLink = (id, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateHeaderLinkBegin());

    axios
      .patch(`header_links/${id}/`, data)
      .then(r => {
        if (r && r.status === 200) {
          dispatch(updateHeaderLinkSuccess());
          dispatch(showSuccess('Odnośnik został zaktualizowany.'));
          resolve(r);
        }
      })
      .catch(err => {
        dispatch(updateHeaderLink());
        dispatch(showError('Wystąpił błąd podczas aktualizowania odnośnika!'));
        reject(err);
      });
  });

export const getLanguagesBegin = () => ({
  type: GET_LANGUAGES_BEGIN
});

export const getLanguagesSuccess = languages => ({
  type: GET_LANGUAGES_SUCCESS,
  languages
});

export const getLanguagesFailed = () => ({
  type: GET_LANGUAGES_FAILED
});

export const getLanguages = () => dispatch => {
  dispatch(getLanguagesBegin());

  axios
    .get('translations/', {
      headers: {
        Authorization: null
      }
    })
    .then(response => {
      dispatch(getLanguagesSuccess(response.data));
    })
    .catch(err => {
      dispatch(getLanguagesFailed());
      dispatch(showError('An error occured while fetching languages.'));
    });
};

export const getTranslationBegin = () => ({
  type: GET_TRANSLATION_BEGIN
});

export const getTranslationSuccess = (translation, langCode) => ({
  type: GET_TRANSLATION_SUCCESS,
  translation,
  langCode
});

export const getTranslationFailed = () => ({
  type: GET_TRANSLATION_FAILED
});

export const getTranslation = langCode => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getTranslationBegin());

    const defaultLanguage =
      sessionStorage.getItem(sessionStorageKeys.language) || defaultLang;

    axios
      // Jeśli nie został przekazany kod pobieramy domyślne tłumaczenie.
      .get(`translations/${langCode || defaultLanguage}/`)
      .then(response => {
        dispatch(getTranslationSuccess(response.data, langCode));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(getTranslationFailed());
        reject(err);
      });
  });

export const createHeaderLinkBegin = () => ({
  type: CREATE_HEADER_LINK_BEGIN
});

export const createHeaderLinkSuccess = () => ({
  type: CREATE_HEADER_LINK_SUCCESS
});

export const createHeaderLinkFailed = () => ({
  type: CREATE_HEADER_LINK_FAILED
});

export const createHeaderLink = data => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(createHeaderLinkBegin());

    axios
      .post(`header_links/`, data)
      .then(r => {
        dispatch(createHeaderLinkSuccess());
        dispatch(showSuccess('Odnośnik został dodany.'));
        resolve(r);
      })
      .catch(err => {
        dispatch(createHeaderLinkFailed());
        dispatch(showError('Wystąpił błąd podczas dodawania odnośnika!'));
        reject(err);
      });
  });

export const createTranslationBegin = () => ({
  type: CREATE_TRANSLATION_BEGIN
});

export const createTranslationSuccess = translation => ({
  type: CREATE_TRANSLATION_SUCCESS,
  translation
});

export const createTranslationFailed = () => ({
  type: CREATE_TRANSLATION_FAILED
});

export const createTranslation = body => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(createTranslationBegin());

    axios
      .post('translations/', body)
      .then(response => {
        dispatch(createTranslationSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(createTranslationFailed());
        reject(err);
      });
  });

export const fetchHeaderLinkBegin = () => ({
  type: FETCH_HEADER_LINK_BEGIN
});

export const fetchHeaderLinkSuccess = headerLinks => ({
  type: FETCH_HEADER_LINK_SUCCESS,
  headerLinks
});

export const fetchHeaderLinkFailed = () => ({
  type: FETCH_HEADER_LINK_FAILED
});

export const fetchHeaderLinks = (
  district = null,
  map_portal = null,
  map_portal_composition = null
) => dispatch => {
  dispatch(fetchHeaderLinkBegin());

  axios
    .get(
      `${district ? `${district}/` : ''}${
        map_portal ? `map_portal/${map_portal}/` : ''
      }${
        map_portal_composition
          ? `map_portal_composition/${map_portal_composition}/`
          : ''
      }header_links/`
    )
    .then(r => {
      dispatch(fetchHeaderLinkSuccess(r.data));
    })
    .catch(err => {
      dispatch(fetchHeaderLinkFailed());
    });
};
export const editTranslationBegin = () => ({
  type: EDIT_TRANSLATION_BEGIN
});

export const editTranslationSuccess = translation => ({
  type: EDIT_TRANSLATION_SUCCESS,
  translation
});

export const editTranslationFailed = () => ({
  type: EDIT_TRANSLATION_FAILED
});

export const editTranslation = body => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editTranslationBegin());

    const { code } = body;

    axios
      .patch(`translations/${code}/`, body)
      .then(response => {
        dispatch(editTranslationSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(editTranslationFailed());
        reject(err);
      });
  });

export const deleteTranslationBegin = () => ({
  type: DELETE_TRANSLATION_BEGIN
});

export const deleteTranslationSuccess = langCode => ({
  type: DELETE_TRANSLATION_SUCCESS,
  langCode
});

export const deleteTranslationFailed = () => ({
  type: DELETE_TRANSLATION_FAILED
});

export const deleteTranslation = langCode => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteTranslationBegin());

    axios
      .delete(`translations/${langCode}/`)
      .then(() => {
        dispatch(deleteTranslationSuccess(langCode));
        resolve();
      })
      .catch(err => {
        dispatch(deleteTranslationFailed());
        reject(err);
      });
  });

export const fetchUserSettingsBegin = () => ({
  type: FETCH_USER_SETTING_BEGIN
});

export const fetchUserSettingsSuccess = userSettings => ({
  type: FETCH_USER_SETTING_SUCCESS,
  userSettings
});

export const fetchUserSettingsFailed = () => ({
  type: FETCH_USER_SETTING_FAILED
});

export const fetchUserSettings = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchUserSettingsBegin());

    axios
      .get(`user_setting/`)
      .then(r => {
        const settings = {};
        const { data } = r;

        data.length &&
          data.forEach(v => {
            settings[v.name] = v.value;
          });

        dispatch(fetchUserSettingsSuccess(settings));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(fetchUserSettingsFailed());
        reject(err);
      });
  });

export const fetchCursorCrsListBegin = () => ({
  type: FETCH_CURSOR_CRS_LIST_BEGIN
});

export const fetchCursorCrsListSuccess = cursorCrsList => ({
  type: FETCH_CURSOR_CRS_LIST_SUCCESS,
  cursorCrsList
});

export const fetchCursorCrsListFailed = () => ({
  type: FETCH_CURSOR_CRS_LIST_FAILED
});

export const fetchCursorCrsList = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchCursorCrsListBegin());

    axios
      .get('cursor_crs/')
      .then(response => {
        dispatch(fetchCursorCrsListSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(fetchCursorCrsListFailed());
        reject(err);
      });
  });

export const fetchEmailSettingsBegin = () => ({
  type: FETCH_EMAIL_SETTINGS_BEGIN
});

export const fetchEmailSettingsSuccess = () => ({
  type: FETCH_EMAIL_SETTINGS_SUCCESS
});

export const fetchEmailSettingsFailed = () => ({
  type: FETCH_EMAIL_SETTINGS_FAILED
});

export const fetchEmailSettings = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchEmailSettingsBegin());
    axios
      .get('email_settings/')
      .then(response => {
        dispatch(fetchEmailSettingsSuccess());
        resolve(response.data);
      })
      .catch(err => {
        dispatch(fetchEmailSettingsFailed());
        reject(err);
      });
  });

export const editEmailSettingsBegin = () => ({
  type: EDIT_EMAIL_SETTINGS_BEGIN
});

export const editEmailSettingsSuccess = () => ({
  type: EDIT_EMAIL_SETTINGS_SUCCESS
});

export const editEmailSettingsFailed = () => ({
  type: EDIT_EMAIL_SETTINGS_FAILED
});

export const editEmailSettings = settings => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editEmailSettingsBegin());
    axios
      .put('email_settings/', settings)
      .then(() => {
        dispatch(editEmailSettingsSuccess());
        resolve();
      })
      .catch(err => {
        dispatch(editEmailSettingsFailed());
        reject(err);
      });
  });

export const validateEmailSettingsBegin = () => ({
  type: VALIDATE_EMAIL_SETTINGS_BEGIN
});

export const validateEmailSettingsSuccess = () => ({
  type: VALIDATE_EMAIL_SETTINGS_SUCCESS
});

export const validateEmailSettingsFailed = () => ({
  type: VALIDATE_EMAIL_SETTINGS_FAILED
});

export const validateEmailSettings = settings => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(validateEmailSettingsBegin());
    axios
      .post('validate_email_settings/', settings)
      .then(response => {
        dispatch(validateEmailSettingsSuccess());
        resolve(response.data);
      })
      .catch(err => {
        dispatch(validateEmailSettingsFailed());
        reject(err);
      });
  });

export const checkIfOfferModelAvailableBegin = () => ({
  type: CHECK_IF_OFFER_MODEL_AVAILABLE_BEGIN
});

export const checkIfOfferModelAvailableSuccess = isAvailable => ({
  type: CHECK_IF_OFFER_MODEL_AVAILABLE_SUCCESS,
  isAvailable
});

export const checkIfOfferModelAvailableFailed = () => ({
  type: CHECK_IF_OFFER_MODEL_AVAILABLE_FAILED
});

export const checkIfOfferModelAvailable = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(checkIfOfferModelAvailableBegin());

    axios
      .get('searchable_models/')
      .then(response => {
        const isAvailable = !!Object.values(response.data).find(
          ({ model }) => model === offerModelName
        );

        dispatch(checkIfOfferModelAvailableSuccess(isAvailable));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(checkIfOfferModelAvailableFailed());
        reject(err);
      });
  });

export const getPingPongBegin = () => ({
  type: GET_PING_PONG_BEGIN
});

export const getPingPongSuccess = () => ({
  type: GET_PING_PONG_SUCCESS
});

export const getPingPongFailed = () => ({
  type: GET_PING_PONG_FAILED
});

export const getPingPong = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getPingPongBegin());

    axios
      .get('ping/', {
        headers: {
          Authorization: null
        }
      })
      .then(() => {
        dispatch(getPingPongSuccess());
        resolve();
      })
      .catch(() => {
        dispatch(getPingPongFailed());
        reject();
      });
  });

export const checkDefaultUserSettingRequest = () => ({
  type: CHECK_DEFAULT_USER_SETTING_REQUEST
});

export const checkDefaultUserSettingSuccess = payload => ({
  type: CHECK_DEFAULT_USER_SETTING_SUCCESS,
  payload
});

export const checkDefaultUserSettingFailed = () => ({
  type: CHECK_DEFAULT_USER_SETTING_FAILED
});

export const checkDefaultUserSetting = () => dispatch => {
  dispatch(checkDefaultUserSettingRequest());
  axios
    .get('set_default_user_setting/')
    .then(r => {
      dispatch(checkDefaultUserSettingSuccess(r.data));
    })
    .catch(() => {
      dispatch(checkDefaultUserSettingFailed());
    });
};

export const setDefaultUserSettingRequest = () => ({
  type: SET_DEFAULT_USER_SETTING_REQUEST
});

export const setDefaultUserSettingSuccess = () => ({
  type: SET_DEFAULT_USER_SETTING_SUCCESS
});

export const setDefaultUserSettingFailed = () => ({
  type: SET_DEFAULT_USER_SETTING_FAILED
});

export const setDefaultUserSetting = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(setDefaultUserSettingRequest());
    axios
      .post('set_default_user_setting/')
      .then(r => {
        dispatch(setDefaultUserSettingSuccess());
        dispatch(showSuccess('Ustawienia domyślne zostały zastosowane.'));
        resolve(r);
      })
      .catch(error => {
        dispatch(setDefaultUserSettingFailed());
        dispatch(showError('Ustawienia domyślne nie zostały zastosowane.'));
        reject(error);
      });
  });

export const setWindowDimensions = (dimensions, dimensionsType) => ({
  type: SET_WINDOW_DIMENSIONS,
  dimensions,
  dimensionsType
});

export const getContextualHelpTooltipsBegin = () => ({
  type: GET_CONTEXTUAL_HELP_TOOLTIPS_BEGIN
});

export const getContextualHelpTooltipsSuccess = tooltips => ({
  type: GET_CONTEXTUAL_HELP_TOOLTIPS_SUCCESS,
  tooltips
});

export const getContextualHelpTooltipsFailed = () => ({
  type: GET_CONTEXTUAL_HELP_TOOLTIPS_FAILED
});

export const getContextualHelpTooltips = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getContextualHelpTooltipsBegin());

    axios
      .get('contextual_help/tooltips')
      .then(response => {
        dispatch(getContextualHelpTooltipsSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(getContextualHelpTooltipsFailed());
        reject(err);
      });
  });

export const setRenderedComponents = component => ({
  type: SET_RENDERED_COMPONENTS,
  component
});

export const clearRenderedComponents = () => ({
  type: CLEAR_RENDERED_COMPONENTS
});

export const checkUseAdvancedFeaturesBegin = () => ({
  type: CHECK_USE_ADVANCED_FEATURES_BEGIN
});

export const checkUseAdvancedFeaturesSuccess = used => ({
  type: CHECK_USE_ADVANCED_FEATURES_SUCCESS,
  payload: used
});

export const checkUseAdvancedFeaturesFailed = () => ({
  type: CHECK_USE_ADVANCED_FEATURES_FAILED
});

export const checkUseAdvancedFeatures = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(checkUseAdvancedFeaturesBegin());

    axios
      .get('check_use_advanced_features/')
      .then(response => {
        dispatch(checkUseAdvancedFeaturesSuccess(response.data));
        resolve(response.data);
      })
      .catch(error => {
        dispatch(checkUseAdvancedFeaturesFailed());
        reject(error);
      });
  });

export const getEbudApiKeyBegin = () => ({
  type: GET_EBUD_API_KEY_BEGIN
});

export const getEbudApiKeySuccess = apiKey => ({
  type: GET_EBUD_API_KEY_SUCCESS,
  payload: apiKey
});

export const getEbudApiKeyFailed = () => ({
  type: GET_EBUD_API_KEY_FAILED
});

export const getEbudApiKey = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getEbudApiKeyBegin());

    axios
      .get('ebud_api_key/')
      .then(({ data }) => {
        const { ebudToken } = localStorageKeys;
        window.localStorage.setItem(ebudToken, data.API_KEY);
        dispatch(getEbudApiKeySuccess(data));
        resolve(data);
      })
      .catch(() => {
        dispatch(getEbudApiKeyFailed());
        reject();
      });
  });
