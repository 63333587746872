import { useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Collapse } from 'antd';

import { TopicalSearchResultAttributes } from '..';
import { TopicalSearchToolResultColumnShape } from '../../shapes/topicalSearchTool';

import { nestedTopicalSearches } from '../../config';

import './topicalAttribute.less';
const Panel = Collapse.Panel;
const TopicalNestedResultAttributes = ({
  result,
  resultColumns,
  print = false,
  isPortraitOrientation
}) => {
  const [collapsed, setCollapse] = useState([]);

  return Object.keys(result).map(key => {
    const ifNestedAttribute = Object.keys(nestedTopicalSearches).includes(key);
    const nestedAttr = Object.keys(nestedTopicalSearches).find(
      nestedKey => nestedKey === key
    );

    if (ifNestedAttribute) {
      const nestedTitle = nestedTopicalSearches[nestedAttr].displayableName;

      return (
        <Collapse
          key={key}
          activeKey={print ? Object.keys(nestedTopicalSearches) : collapsed}
          className="topical-nested-panel"
          onChange={setCollapse}
          accordion={!print}
        >
          <Panel header={nestedTitle} key={nestedAttr} forceRender={print}>
            {result[key].map(nestedRow => (
              <div className="topical-nested-row" key={Math.random()}>
                {Object.keys(nestedRow).map(nestedKey => (
                  <TopicalSearchResultAttributes
                    key={`${key}__${nestedKey}`}
                    columnKey={`${key}__${nestedKey}`}
                    attribute={nestedRow[nestedKey]}
                    resultColumns={resultColumns}
                    print={print}
                    isPortraitOrientation={isPortraitOrientation}
                  />
                ))}
              </div>
            ))}
          </Panel>
        </Collapse>
      );
    }
    return (
      <TopicalSearchResultAttributes
        key={key}
        columnKey={key}
        attribute={result[key]}
        resultColumns={resultColumns}
        print={print}
        isPortraitOrientation={isPortraitOrientation}
      />
    );
  });
};

TopicalNestedResultAttributes.propTypes = {
  result: PropTypes.object.isRequired,
  resultColumns: PropTypes.oneOfType([
    PropTypes.arrayOf(TopicalSearchToolResultColumnShape),
    ImmutablePropTypes.listOf(TopicalSearchToolResultColumnShape)
  ]).isRequired,
  print: PropTypes.bool,
  isPortraitOrientation: PropTypes.bool
};

export default TopicalNestedResultAttributes;
