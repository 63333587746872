import GeoRasterLayer from 'georaster-layer-for-leaflet';

const getRasterBBox = georaster => {
  var layer = new GeoRasterLayer({
    georaster: georaster,
    opacity: 0.7
  });

  const bbox = [];
  Object.values(layer.getBounds()).forEach(({ lat, lng }) =>
    bbox.push(lng, lat)
  );
  return bbox;
};

export default getRasterBBox;
