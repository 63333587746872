import React from 'react';
import { Button, Icon, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';

import { showSuccess } from '../../store/actions/globalActions';

import useMeasureToolTranslation from '../MeasureTools/components/hooks/useMeasureToolTranslation';

import './CopyCoordinatesButton.less';

const CopyCoordinatesButton = ({ textToCopy }) => {
  const dispatch = useDispatch();
  const { copiedText, toasterCopiedText } = useMeasureToolTranslation();

  const handleCopy = (textToCopy, event) => {
    navigator.clipboard.writeText(textToCopy);
    dispatch(showSuccess(toasterCopiedText));
    event.currentTarget.blur();
  };

  return (
    <Tooltip placement="bottom" title={copiedText}>
      <Button
        type="default"
        className="measure-copy-button"
        onClick={event => handleCopy(textToCopy, event)}
      >
        <Icon type="copy" className="button-copy-icon" />
      </Button>
    </Tooltip>
  );
};

export default CopyCoordinatesButton;
