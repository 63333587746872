const parseTextFromHtml = htmlString => {
  if (!htmlString) return '';

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const text = doc.body.textContent || '';

  return text;
};

export default parseTextFromHtml;
