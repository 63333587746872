import { Row, Col } from 'antd';
import L from 'leaflet';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Map,
  Marker,
  TileLayer,
  Polygon,
  Polyline,
  CircleMarker
} from 'react-leaflet';

import utils from '../MapControl/layer-editor/utils';
import '../../utils/mapUtils/freezer';
import iconImage from 'leaflet/dist/images/marker-icon.png';
import { reverseCoordinates } from '../../utils/mapUtils';

const AdminReportMap = props => {
  const { formatMessage } = useIntl();

  if (!props.report || !props.report.geometry) return null;

  // Processing an object for display on a map taking into account various geometries
  const { geometry } = props.report;
  const getCentroid = report => {
    const centroidArray = utils._getObjectCenterPointCoordinates(report);
    if (centroidArray?.length) {
      return [centroidArray[0].lat, centroidArray[0].lng];
    }
    return [null, null];
  };

  let geometryElement = null;
  let [lat, lng] = [null, null];

  switch (geometry.type) {
    case 'Point':
      [lng, lat] = geometry.coordinates;
      break;

    case 'MultiPolygon':
      [lat, lng] = getCentroid(props.report);
      geometryElement = geometry.coordinates.map((polygonCoords, index) => (
        <Polygon key={index} positions={reverseCoordinates(polygonCoords[0])} />
      ));
      break;

    case 'MultiLineString':
      [lat, lng] = getCentroid(props.report);
      geometryElement = geometry.coordinates.map((lineCoords, index) => (
        <Polyline key={index} positions={reverseCoordinates(lineCoords)} />
      ));
      break;

    case 'MultiPoint':
      [lat, lng] = getCentroid(props.report);
      geometryElement = geometry.coordinates.map((pointCoord, index) => (
        <CircleMarker
          key={index}
          center={reverseCoordinates([pointCoord])[0]}
          radius={10}
          weight={2}
        />
      ));
      break;

    default:
      break;
  }

  const markerPosition = {
    lat,
    lng
  };

  const iconMarker = L.icon({
    iconUrl: iconImage,
    iconSize: [25, 41],
    iconAnchor: [14.5, 41]
  });

  return (
    <Row>
      <Col span={24} className="single-report-col">
        <h2>
          {formatMessage({
            id: 'admin_single_report_map_preview',
            defaultMessage: 'Map preview'
          })}
        </h2>

        <Map
          editable={false}
          zoom={16}
          center={markerPosition}
          zoomControl={false}
          style={{ minHeight: '200px', maxHeight: '400px' }}
          freezer={{
            enabled: true,
            pointerText: formatMessage({
              id: 'freezer_pointer_message',
              defaultMessage: 'Press CTRL and scroll to zoom in on the map.'
            }),
            touchText: formatMessage({
              id: 'freezer_touch_message',
              defaultMessage: 'Use 2 fingers to control the map view.'
            })
          }}
        >
          <TileLayer
            url="//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="//osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker
            position={markerPosition}
            draggable={false}
            icon={iconMarker}
          />
          {geometryElement}
        </Map>
      </Col>
    </Row>
  );
};

AdminReportMap.propTypes = {
  report: PropTypes.object.isRequired
};

export default AdminReportMap;
