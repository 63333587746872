import { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import classNames from 'classnames';

import './ControlsToolbar.less';

const ControlsToolbar = ({ children, noMarginBottom = false }) => (
  <ul
    className={classNames('controls-toolbar', {
      'no-margin-bottom': noMarginBottom
    })}
  >
    {children}
  </ul>
);

ControlsToolbar.propTypes = {
  noMarginBottom: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired
};

export class ControlsToolbarItem extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    return (
      <li>
        <span>{this.props.label}</span>
        {this.props.children}
      </li>
    );
  }
}

ControlsToolbarItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
    .isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default ControlsToolbar;
