import axios from 'axios';

import { showError, showSuccess } from './globalActions';

import {
  FETCH_GROUPS_BEGIN,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILED,
  FETCH_ALL_GROUPS_BEGIN,
  FETCH_ALL_GROUPS_SUCCESS,
  FETCH_ALL_GROUPS_FAILED,
  CLEAR_DISTRICT_ALL_GROUPS,
  DELETE_GROUP_BEGIN,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILED,
  ADD_USER_TO_GROUP_BEGIN,
  ADD_USER_TO_GROUP_SUCCESS,
  ADD_USER_TO_GROUP_FAILED,
  ADD_GROUP_BEGIN,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILED,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_BEGIN,
  FETCH_GROUP_FAILED,
  FETCH_USERS_GROUP_BEGIN,
  FETCH_USERS_GROUP_SUCCESS,
  FETCH_USERS_GROUP_FAILED,
  FETCH_NO_USERS_GROUP_FAILED,
  FETCH_NO_USERS_GROUP_SUCCESS,
  FETCH_NO_USERS_GROUP_BEGIN,
  DELETE_USER_FROM_GROUP_FAILED,
  DELETE_USER_FROM_GROUP_SUCCESS,
  DELETE_USER_FROM_GROUP_BEGIN,
  UPDATE_GROUP_BEGIN,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILED,
  ADD_GROUP_TO_MAP_PORTAL_BEGIN,
  ADD_GROUP_TO_MAP_PORTAL_SUCCESS,
  ADD_GROUP_TO_MAP_PORTAL_FAILED,
  DELETE_GROUP_FROM_MAP_PORTAL_FAILED,
  DELETE_GROUP_FROM_MAP_PORTAL_SUCCESS,
  DELETE_GROUP_FROM_MAP_PORTAL_BEGIN,
  DELETE_GROUP_FROM_COMPOSITION_BEGIN,
  DELETE_GROUP_FROM_COMPOSITION_SUCCESS,
  DELETE_GROUP_FROM_COMPOSITION_FAILED,
  ADD_GROUP_TO_COMPOSITION_BEGIN,
  ADD_GROUP_TO_COMPOSITION_SUCCESS,
  ADD_GROUP_TO_COMPOSITION_FAILED
} from '../constants/groupsActionTypes';
import { parseResponseError } from '../../utils/lib';

export const fetchGroupsBegin = () => ({
  type: FETCH_GROUPS_BEGIN
});

export const fetchGroupsSuccess = groups => ({
  type: FETCH_GROUPS_SUCCESS,
  groups
});

export const fetchGroupsFailed = () => ({
  type: FETCH_GROUPS_FAILED
});

/**
 * Pobiera wszystkie grupy w danej jednostce z limitem 10 wynikow na strone
 *
 * @param {string} district
 * @param {int} page
 * @param {int} resultsPerPage
 * @param {string} search
 */
export const fetchGroups = (
  district,
  page,
  resultsPerPage,
  search
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchGroupsBegin());

    if (!page) {
      dispatch(showError('Wystąpił błąd z pobraniem grup'));
      dispatch(fetchGroupsFailed());
      return;
    }

    axios
      .get(
        `${district}/district_group/?page_size=${
          resultsPerPage ? resultsPerPage : 20
        }&page=${resultsPerPage ? page : 1}${
          search && search.length ? `&search=${search}` : ''
        }`
      )
      .then(r => {
        dispatch(fetchGroupsSuccess(r.data));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(fetchGroupsFailed());
        dispatch(showError('Wystąpił błąd z pobraniem grup'));
        reject(err);
      });
  });

export const fetchAllGroupsBegin = () => ({
  type: FETCH_ALL_GROUPS_BEGIN
});

export const fetchAllGroupsSuccess = ({ district, groups }) => ({
  type: FETCH_ALL_GROUPS_SUCCESS,
  district,
  groups
});

export const fetchAllGroupsFailed = () => ({
  type: FETCH_ALL_GROUPS_FAILED
});

/**
 * Pobiera wszystkie grupy w danej jednostce
 *
 * @param {string} district
 */
export const fetchAllGroups = district => dispatch => {
  dispatch(fetchAllGroupsBegin());

  axios
    .get(`${district}/district_group/`)
    .then(({ data: { results } }) => {
      dispatch(fetchAllGroupsSuccess({ district, groups: results }));
    })
    .catch(err => {
      dispatch(fetchAllGroupsFailed());
      dispatch(showError('Wystąpił błąd z pobraniem grup'));
    });
};

export const clearDistrictAllGroups = district => ({
  type: CLEAR_DISTRICT_ALL_GROUPS,
  district
});

export const deleteGroupBegin = () => ({
  type: DELETE_GROUP_BEGIN
});

export const deleteGroupSuccess = groupId => ({
  type: DELETE_GROUP_SUCCESS,
  groupId
});

export const deleteGroupFailed = () => ({
  type: DELETE_GROUP_FAILED
});

/**
 * Usuwa grupę z bazy danych i aktualizuje obiekt w Reduxie
 *
 * @param {string} district
 * @param {int} id
 */
export const deleteGroup = (district, id) => (dispatch, getState) => {
  dispatch(deleteGroupBegin());

  axios
    .delete(`${district}/district_group/${id}/`)
    .then(r => {
      dispatch(showSuccess('Grupę usunięto pomyślnie.'));
      dispatch(deleteGroupSuccess(id));
    })
    .catch(err => {
      dispatch(deleteGroupFailed());
      dispatch(showError('Wystąpił błąd z pobraniem grup'));
    });
};

export const addUserToGroupBegin = () => ({
  type: ADD_USER_TO_GROUP_BEGIN
});

export const addUserToGroupSuccess = () => ({
  type: ADD_USER_TO_GROUP_SUCCESS
});

export const addUserToGroupFailed = () => ({
  type: ADD_USER_TO_GROUP_FAILED
});

/**
 * Dodaje użytkownika do danej jednostki
 *
 * @param {string} district
 * @param {int} group_id
 * @param {int} user_id
 */
export const addUserToGroup = (district, group_id, user_id) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addUserToGroupBegin());

    axios
      .post(`${district}/district_group/${group_id}/user/${user_id}/`)
      .then(r => {
        dispatch(
          showSuccess('Użytkownik został pomyślnie przypisany do grupy.')
        );
        dispatch(addUserToGroupSuccess());
        resolve(r);
      })
      .catch(err => {
        dispatch(addUserToGroupFailed());
        dispatch(showError('Wystąpił błąd z pobraniem grup'));
        reject(err);
      });
  });

export const deleteUserFromGroupBegin = () => ({
  type: DELETE_USER_FROM_GROUP_BEGIN
});

export const deleteUserFromGroupSuccess = () => ({
  type: DELETE_USER_FROM_GROUP_SUCCESS
});

export const deleteUserFromGroupFailed = () => ({
  type: DELETE_USER_FROM_GROUP_FAILED
});

/**
 * Dodaje użytkownika do danej jednostki
 *
 * @param {string} district
 * @param {int} group_id
 * @param {int} user_id
 */
export const deleteUserFromGroup = (district, groupId, userId) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteUserFromGroupBegin());

    axios
      .delete(`${district}/district_group/${groupId}/user/${userId}/`)
      .then(r => {
        dispatch(showSuccess('Użytkownik został pomyślnie usunięty z grupy.'));
        dispatch(deleteUserFromGroupSuccess());
        resolve(r);
      })
      .catch(err => {
        dispatch(deleteUserFromGroupFailed());
        dispatch(showError('Wystąpił błąd z usunięciem użytkownika z grupy.'));
        reject(err);
      });
  });

export const addGroupBegin = () => ({
  type: ADD_GROUP_BEGIN
});

export const addGroupSuccess = () => ({
  type: ADD_GROUP_SUCCESS
});

export const addGroupFailed = () => ({
  type: ADD_GROUP_FAILED
});

/**
 * Dodaje grupę do danej jednostki
 * @param {string} district
 * @param {object { name: string, district: int }} data
 */
export const addGroup = (district, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addGroupBegin());

    axios
      .post(`${district}/district_group/`, data)
      .then(r => {
        dispatch(showSuccess('Grupa została dodana.'));
        dispatch(addGroupSuccess());
        resolve(r);
      })
      .catch(err => {
        dispatch(addGroupFailed());
        dispatch(showError(err.response.data.name[0].message));
        reject(err);
      });
  });

export const fetchGroupBegin = () => ({
  type: FETCH_GROUP_BEGIN
});

export const fetchGroupSuccess = () => ({
  type: FETCH_GROUP_SUCCESS
});

export const fetchGroupFailed = () => ({
  type: FETCH_GROUP_FAILED
});

/**
 * Pobiera informacje na temat jednej grupy
 *
 * @param {string} district
 * @param {int} id
 */
export const fetchGroup = (district, id) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchGroupBegin());

    if (!id || !district) {
      dispatch(fetchGroupFailed());
      return;
    }

    axios
      .get(`${district}/district_group/${id}/`)
      .then(r => {
        dispatch(fetchGroupSuccess());
        resolve(r);
      })
      .catch(err => {
        dispatch(fetchGroupFailed());
        reject(err);
      });
  });

export const fetchUsersGroupBegin = () => ({
  type: FETCH_USERS_GROUP_BEGIN
});

export const fetchUsersGroupSuccess = () => ({
  type: FETCH_USERS_GROUP_SUCCESS
});

export const fetchUsersGroupFailed = () => ({
  type: FETCH_USERS_GROUP_FAILED
});

/**
 * Pobiera wszystkich uzytkownikow, ktorzy sa przypisani do danej jednostki
 *
 * @param {string} district
 * @param {int} id
 */
export const fetchUsersGroup = ({
  district,
  id,
  resultsPerPage,
  page,
  orderBy,
  searchQuery,
  searchRole
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchUsersGroupBegin());

    if (!id) {
      dispatch(fetchUsersGroupFailed());
      return;
    }

    axios
      .get(`${district}/district_group/${id}/users/`, {
        params: {
          page_size: resultsPerPage,
          page,
          ordering: orderBy,
          search: searchQuery,
          role: searchRole
        }
      })
      .then(r => {
        dispatch(fetchUsersGroupSuccess());
        resolve(r);
      })
      .catch(err => {
        dispatch(fetchUsersGroupFailed());
        reject(err);
      });
  });

export const fetchNoUsersGroupBegin = () => ({
  type: FETCH_NO_USERS_GROUP_BEGIN
});

export const fetchNoUsersGroupSuccess = () => ({
  type: FETCH_NO_USERS_GROUP_SUCCESS
});

export const fetchNoUsersGroupFailed = () => ({
  type: FETCH_NO_USERS_GROUP_FAILED
});

/**
 * Pobiera wszystkich uzytkownikow nie przypisanych do jednostki
 *
 * @param {string} district
 * @param {int} id
 */
export const fetchNoUsersGroup = ({
  district,
  id,
  resultsPerPage,
  page,
  orderBy,
  searchQuery,
  searchRole
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchNoUsersGroupBegin());

    if (!id) {
      dispatch(fetchNoUsersGroupFailed());
      return;
    }

    axios
      .get(`${district}/district_group/${id}/no_users/`, {
        params: {
          page_size: resultsPerPage,
          page,
          ordering: orderBy,
          search: searchQuery,
          role: searchRole
        }
      })
      .then(r => {
        dispatch(fetchNoUsersGroupSuccess());
        resolve(r);
      })
      .catch(err => {
        dispatch(fetchNoUsersGroupFailed());
        reject(err);
      });
  });

export const updateGroupBegin = () => ({
  type: UPDATE_GROUP_BEGIN
});

export const updateGroupSuccess = () => ({
  type: UPDATE_GROUP_SUCCESS
});

export const updateGroupFailed = () => ({
  type: UPDATE_GROUP_FAILED
});

/**
 * Aktualizuje grupę
 *
 * @param {string} district
 * @param {id} id
 * @param {object} data
 */
export const updateGroup = (district, id, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateGroupBegin());

    if (!id) {
      dispatch(updateGroupFailed());
      return;
    }

    axios
      .patch(`${district}/district_group/${id}/`, data)
      .then(r => {
        dispatch(updateGroupSuccess());
        dispatch(showSuccess('Nazwa grupy została zmieniona.'));
        resolve(r);
      })
      .catch(err => {
        dispatch(updateGroupFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const addGroupToMapPortalBegin = () => ({
  type: ADD_GROUP_TO_MAP_PORTAL_BEGIN
});

export const addGroupToMapPortalSuccess = (district, mapPortalId, groupId) => ({
  type: ADD_GROUP_TO_MAP_PORTAL_SUCCESS,
  district,
  mapPortalId,
  groupId
});

export const addGroupToMapPortalFailed = () => ({
  type: ADD_GROUP_TO_MAP_PORTAL_FAILED
});

export const addGroupToMapPortal = (
  district,
  groupId,
  mapPortalId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addGroupToMapPortalBegin());

    axios
      .post(`${district}/district_group/${groupId}/map_portal/${mapPortalId}/`)
      .then(r => {
        dispatch(addGroupToMapPortalSuccess(district, mapPortalId, groupId));
        resolve(r);
      })
      .catch(err => {
        dispatch(addGroupToMapPortalFailed());
        reject(err);
      });
  });

export const deleteGroupFromMapPortalBegin = () => ({
  type: DELETE_GROUP_FROM_MAP_PORTAL_BEGIN
});

export const deleteGroupFromMapPortalSuccess = () => ({
  type: DELETE_GROUP_FROM_MAP_PORTAL_SUCCESS
});

export const deleteGroupFromMapPortalFailed = () => ({
  type: DELETE_GROUP_FROM_MAP_PORTAL_FAILED
});

export const deleteGroupFromMapPortal = (
  district,
  groupId,
  mapPortalId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteGroupFromMapPortalBegin());

    axios
      .delete(
        `${district}/district_group/${groupId}/map_portal/${mapPortalId}/`
      )
      .then(r => {
        dispatch(deleteGroupFromMapPortalSuccess());
        resolve(r);
      })
      .catch(err => {
        dispatch(deleteGroupFromMapPortalFailed());
        reject(err);
      });
  });

export const deleteGroupFromCompositionBegin = () => ({
  type: DELETE_GROUP_FROM_COMPOSITION_BEGIN
});

export const deleteGroupFromCompositionSuccess = () => ({
  type: DELETE_GROUP_FROM_COMPOSITION_SUCCESS
});

export const deleteGroupFromCompositionFailed = () => ({
  type: DELETE_GROUP_FROM_COMPOSITION_FAILED
});

export const deleteGroupFromComposition = (
  district,
  groupId,
  compositionId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteGroupFromCompositionBegin());

    axios
      .delete(
        `${district}/district_group/${groupId}/map_portal_composition/${compositionId}/`
      )
      .then(r => {
        dispatch(deleteGroupFromCompositionSuccess());
        dispatch(showSuccess('Grupa została usunięta z kompozycji'));
        resolve(r);
      })
      .catch(err => {
        dispatch(deleteGroupFromCompositionFailed());

        dispatch(
          showError('Wystąpił błąd podczas uswania grupy z kompozycji.')
        );
        reject(err);
      });
  });

export const addGroupToCompositionBegin = () => ({
  type: ADD_GROUP_TO_COMPOSITION_BEGIN
});

export const addGroupToCompositionSuccess = () => ({
  type: ADD_GROUP_TO_COMPOSITION_SUCCESS
});

export const addGroupToCompositionFailed = () => ({
  type: ADD_GROUP_TO_COMPOSITION_FAILED
});

export const addGroupToComposition = (
  district,
  groupId,
  compositionId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addGroupToCompositionBegin());

    axios
      .post(
        `${district}/district_group/${groupId}/map_portal_composition/${compositionId}/`
      )
      .then(r => {
        dispatch(addGroupToCompositionSuccess());
        dispatch(showSuccess('Grupa została przypisana do kompozycji'));
        resolve(r);
      })
      .catch(err => {
        dispatch(addGroupToCompositionFailed());

        dispatch(
          showError('Wystąpił błąd podczas przypisywania grupy do kompozycji.')
        );
        reject(err);
      });
  });
