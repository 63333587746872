import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { mapSelectors } from '../../store/selectors';

import { getCursorCoordinates } from '../../utils/mapUtils';

import './PrintPreviewMapCoordinates.less';

const { getCurrentCursorCrs, convertCoordinatesToHDMS } = mapSelectors;

const PrintPreviewMapCoordinates = forwardRef(({ left, right }, ref) => {
  const currentCursorCrs = useSelector(state => getCurrentCursorCrs(state));
  const changeCoordinatesToHDMS = useSelector(state =>
    convertCoordinatesToHDMS(state)
  );

  const { lat: leftLat, lng: leftLng } = getCursorCoordinates({
    currentCursorCrs,
    coordinates: [left.lat, left.lng],
    showHDMS: changeCoordinatesToHDMS
  });

  const { lat: rightLat, lng: rightLng } = getCursorCoordinates({
    currentCursorCrs,
    coordinates: [right.lat, right.lng],
    showHDMS: changeCoordinatesToHDMS
  });

  return (
    <div ref={ref} className="preview-print-map-coordinates">
      <span>{`${leftLat}, ${leftLng}`}</span>
      <span>{`${rightLat}, ${rightLng}`}</span>
    </div>
  );
});

PrintPreviewMapCoordinates.propTypes = {
  left: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired,
  right: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired
};

export default PrintPreviewMapCoordinates;
