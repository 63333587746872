const filterModelTranslationsByObjectId = (modelsTranslations, objectId) => {
  const translations = {};

  if (!modelsTranslations) return translations;

  modelsTranslations.forEach(translation => {
    if (translation.object_id !== Number(objectId)) return;
    translations[translation.language] = {
      id: translation.id,
      value: translation.translated_value
    };
  });

  return translations;
};

export default filterModelTranslationsByObjectId;
