import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DATA_FORMAT_NO_TIME } from '../../utils/constants';

import { Checkbox, Input, DatePicker } from 'antd';

import './InputWithCheckbox.less';

const InputWithCheckbox = ({
  value: propsValue,
  onChange,
  disabled,
  checkboxProps = {},
  inputProps: { component: InputComponent = Input, ...inputProps } = {}
}) => {
  const [value, setValue] = useState(propsValue);
  const [checked, setChecked] = useState(!!value);

  const isDatePicker = InputComponent.name === DatePicker.name;
  const dateFormat = isDatePicker && (inputProps.format || DATA_FORMAT_NO_TIME);
  const defaultValue = isDatePicker ? null : '';

  const handleCheckboxChange = e => {
    const { checked: eventChecked } = e.target;

    if (!eventChecked) {
      handleValueChange(null);
    }

    setChecked(eventChecked);
  };

  const handleValueChange = e => {
    let newValue = e;

    if (e && e.target && e.target.value) {
      newValue = e.target.value;
    }

    if (e && isDatePicker) {
      newValue = e.format(dateFormat);
    }

    newValue = newValue === undefined ? defaultValue : newValue;

    onChange && onChange(newValue);
    setValue(newValue);
  };

  const getInputValue = () => {
    if (!isDatePicker) return value;

    if (value) {
      return moment(value, dateFormat);
    }

    return null;
  };

  return (
    <div className="input-with-checkbox-wrapper">
      <Checkbox
        className="input-with-checkbox-checkbox"
        checked={checked}
        onChange={handleCheckboxChange}
        disabled={disabled}
        {...checkboxProps}
      />
      <InputComponent
        value={getInputValue()}
        disabled={disabled || !checked}
        onChange={handleValueChange}
        className="input-with-checkbox-input"
        {...inputProps}
      />
    </div>
  );
};

InputWithCheckbox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checkboxProps: PropTypes.object,
  inputProps: PropTypes.object
};

export default InputWithCheckbox;
