import React from 'react';
import Button from '../Button';
import './mobileSelect.less';

const MobileSelect = props => {
  const {
    className,
    name,
    options,
    onChange,
    placeholder,
    renderOptions,
    style,
    value,
    allowClear
  } = props;

  const generateOptions = () => {
    return options?.map(renderOptions);
  };

  const clearSelect = () => {
    const selectElement = document.getElementById(`select-${name}`);
    selectElement.selectedIndex = 0;
    onChange(undefined);
  };

  return (
    <div className="mobile-select-wrapper">
      {allowClear && value ? (
        <Button
          type="link"
          shape="circle"
          icon="close-circle"
          onClick={e => clearSelect()}
        />
      ) : null}

      <select
        id={`select-${name}`}
        className={className}
        name={name}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        style={style}
        value={value}
      >
        <option value="" disabled selected hidden>
          {placeholder}
        </option>
        {React.Children.map(generateOptions(), child => {
          if (React.isValidElement(child)) {
            return React.cloneElement({ ...child, type: 'option' });
          }
        })}
      </select>
    </div>
  );
};

export default MobileSelect;
