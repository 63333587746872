import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showSidebar, hideSidebar } from '../../store/actions/globalActions';
import { setFullscreenStatus } from '../../store/actions/mapActions';
import { globalSelectors } from '../../store/selectors';

const { sidebarOpen } = globalSelectors;

const MapFullscreen = () => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(state => sidebarOpen(state));
  const initialSidebarStatus = isSidebarOpen;

  const container = document.body;

  const activateFullscreen = () => {
    dispatch(hideSidebar());
    container.requestFullscreen();
  };

  const deactivateFullscreen = () => {
    dispatch(setFullscreenStatus(false));

    if (initialSidebarStatus) dispatch(showSidebar());

    /* istanbul ignore else */
    if (document.fullscreenElement) document.exitFullscreen();
  };

  useEffect(() => {
    activateFullscreen();

    // Handler wykrywa wyjście z trybu pełnego ekranu
    // za pomocą klawiszów (Esc/F11).
    const handleFullScreenChange = () => {
      /* istanbul ignore else */
      if (!document.fullscreenElement) {
        deactivateFullscreen();
      }
    };

    window.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      deactivateFullscreen();
      window.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  return null;
};

export default MapFullscreen;
