export const PRINT_LEGEND_PREVIEW_CLASS_NAME = 'print-legend';

export const SINGLE_PAGE_CLASS_NAME = 'single-page';
const SINGLE_PAGE_LAST_CHILD_SELECTOR = `.${SINGLE_PAGE_CLASS_NAME}:last-child`;
const PAGE_ITEM_CLASS_NAME = 'page-item';

const SINGLE_PAGE_PADDING = 10;
const LEGENDS_SECTION_HEADER_FONT_SIZE = 20;
const IMAGE_CONTAINER_MARGIN = 9;
const IMAGE_WIDTH = 30;
const IMAGE_HEIGHT = 20;
const LEGEND_TITLE_FONT_SIZE = 12;

const getSinglePageStyles = ({ pageHeight, pageWidth }) => `
  height: ${pageHeight}px;
  width: ${pageWidth}px;
  padding: ${SINGLE_PAGE_PADDING}px;
  `;

const legendsSectionHeaderStyles = `
  margin: 0;
  padding: 0;
  font-size: ${LEGENDS_SECTION_HEADER_FONT_SIZE}px;
  width: 100%;
  text-align: center;
  `;

const legendItemStyles = `
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 3px 0;
  `;

const legendIconStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px ${IMAGE_CONTAINER_MARGIN}px;
  `;

const getLegendTitleWidth = pageWidth =>
  pageWidth -
  SINGLE_PAGE_PADDING * 2 -
  (IMAGE_WIDTH + IMAGE_CONTAINER_MARGIN * 2);

const getLegendTitleStyles = legendTitleWidth => `
  height: 100%;
  width: ${legendTitleWidth}px;
  margin: 0;
  text-align: left;
  white-space: pre-wrap;
  word-break: keep-all;
  font-size: ${LEGEND_TITLE_FONT_SIZE}px;
  `;

export const getLegendPreview = () =>
  document.getElementsByClassName(PRINT_LEGEND_PREVIEW_CLASS_NAME)[0];

const getLatestPage = () => {
  const legendPreview = getLegendPreview();
  return legendPreview.querySelectorAll(SINGLE_PAGE_LAST_CHILD_SELECTOR)[0];
};

const createPage = ({ pageHeight, pageWidth }) => {
  const legendPreview = getLegendPreview();
  const singlePage = document.createElement('div');
  singlePage.className = SINGLE_PAGE_CLASS_NAME;

  singlePage.style.cssText = getSinglePageStyles({ pageHeight, pageWidth });

  legendPreview.appendChild(singlePage);
};

const checkLatestPageContentHeight = ({ pageHeight, pageWidth }) => {
  const latestPage = getLatestPage();
  const pageItems = latestPage.getElementsByClassName(PAGE_ITEM_CLASS_NAME);

  let contentHeight = 0;
  for (let i = 0; i < pageItems.length; i++) {
    contentHeight += pageItems[i].offsetHeight;
  }

  const freeSpace =
    latestPage.clientHeight - contentHeight - SINGLE_PAGE_PADDING * 2;

  if (freeSpace < 0) {
    createPage({ pageHeight, pageWidth });
    const newPage = getLatestPage();
    newPage.appendChild(latestPage.lastChild);
    latestPage.removeChild(latestPage.lastChild);
  }
};

const addHeader = ({ title, pageHeight, pageWidth }) => {
  const newHeader = document.createElement('h1');
  newHeader.className = PAGE_ITEM_CLASS_NAME;
  newHeader.style.cssText = legendsSectionHeaderStyles;

  const headerContent = document.createTextNode(title);
  newHeader.appendChild(headerContent);

  const latestPage = getLatestPage();
  latestPage.appendChild(newHeader);

  checkLatestPageContentHeight({ pageHeight, pageWidth });
};

const createLegendIcon = icon => {
  const legendIcon = document.createElement('div');
  legendIcon.style.cssText = legendIconStyles;

  const image = new Image();
  image.width = IMAGE_WIDTH;
  image.height = IMAGE_HEIGHT;
  image.src = `data:image/png;base64, ${icon}`;
  image.alt = 'symbol';
  legendIcon.appendChild(image);

  return legendIcon;
};

const createLegendTitle = ({ pageWidth, title }) => {
  const legendTitle = document.createElement('p');
  const legendTitleContent = document.createTextNode(title);
  const legendTitleWidth = getLegendTitleWidth(pageWidth);

  legendTitle.style.cssText = getLegendTitleStyles(legendTitleWidth);

  legendTitle.appendChild(legendTitleContent);

  return legendTitle;
};

const addLegendItem = ({
  legendItem: { icon, title },
  pageHeight,
  pageWidth
}) => {
  const legendItem = document.createElement('div');
  legendItem.className = PAGE_ITEM_CLASS_NAME;
  legendItem.style.cssText = legendItemStyles;

  const legendIcon = createLegendIcon(icon);
  const legendTitle = createLegendTitle({
    pageWidth,
    title
  });
  legendItem.appendChild(legendIcon);
  legendItem.appendChild(legendTitle);

  const latestPage = getLatestPage();
  latestPage.appendChild(legendItem);

  checkLatestPageContentHeight({ pageHeight, pageWidth });
};

export const convertLegendObjectToPrint = legendObject => {
  if (!legendObject?.nodes) {
    return;
  }

  const legendItems = [];

  legendObject.nodes.forEach(node => {
    if (!node.symbols) {
      if (node.type === 'layer') {
        legendItems.push(node.title);
      }

      return legendItems.push({ icon: node.icon, title: node.title });
    }

    legendItems.push(node.title);
    legendItems.push(...node.symbols);
  });

  return legendItems;
};

export const addElementsToPage = ({ legendItems, pageHeight, pageWidth }) => {
  if (!legendItems || !pageHeight) {
    return;
  }

  const legendPreview = getLegendPreview();
  const legendPages = legendPreview.querySelectorAll(
    `.${SINGLE_PAGE_CLASS_NAME}`
  );

  if (!legendPages.length) {
    createPage({ pageHeight, pageWidth });
  }

  legendItems.forEach(legendItem => {
    if (typeof legendItem === 'string') {
      addHeader({
        title: legendItem,
        pageHeight,
        pageWidth
      });
    } else addLegendItem({ legendItem, pageHeight, pageWidth });
  });
};

export const removeAllPrintPreviewChildren = () => {
  const legendPreview = getLegendPreview();

  if (!legendPreview?.firstChild) {
    return;
  }

  while (legendPreview.firstChild) {
    legendPreview.removeChild(legendPreview.firstChild);
  }
};
