import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Draggable from 'react-draggable';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { Button } from '../';
import { Icon } from 'antd';

import './DraggableWindow.less';

const DraggableWindow = ({
  draggableProps,
  title,
  actions,
  closeFn,
  children
}) => {
  const { formatMessage } = useIntl();

  return (
    <Draggable
      handle=".draggable-window-header"
      defaultPosition={{ x: 10, y: -100 }}
      {...draggableProps}
    >
      <div className="draggable-window">
        <div className="draggable-window-header">
          {title}
          <div>
            {actions}
            <Button
              variant="text"
              onClick={closeFn}
              aria-label={formatMessage({
                id: 'general_close',
                defaultMessage: 'Close'
              })}
            >
              <Icon type="close" />
            </Button>
          </div>
        </div>
        <SimpleBar>
          <div className="draggable-window-simplebar">{children}</div>
        </SimpleBar>
      </div>
    </Draggable>
  );
};

DraggableWindow.propTypes = {
  draggableProps: PropTypes.object,
  title: PropTypes.node.isRequired,
  actions: PropTypes.node,
  closeFn: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default DraggableWindow;
