import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';

import { fetchLegend } from '../../store/actions/mapPortalActions';
import { showError } from '../../store/actions/globalActions';
import { mapSelectors, mapPortalSelectors } from '../../store/selectors';

import handleError from '../../utils/handleError';
import { getMapContainerBboxPolygon } from '../../utils/mapUtils';
import {
  PRINT_LEGEND_PREVIEW_CLASS_NAME,
  convertLegendObjectToPrint,
  removeAllPrintPreviewChildren,
  addElementsToPage
} from './utils';

import { mapLegendDefaultCrs } from '../../mapConfig';

import './PrintLegend.less';

const { getCheckedLayers } = mapSelectors;
const { getLayersIdMap, getCurrentComposition } = mapPortalSelectors;

const PrintLegend = ({ previewPrintMap, setProcessingStatus }) => {
  const { prefix, portal } = useParams();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const checkedLayers = useSelector(state => getCheckedLayers(state));
  const layersIdMap = useSelector(state => getLayersIdMap(state));
  const currentComposition = useSelector(state =>
    getCurrentComposition(state, prefix, portal)
  );

  const [bbox, setBbox] = useState('');
  const getCheckedLayersString = () =>
    checkedLayers
      .toJS()
      .map(layerId => layersIdMap.get(layerId)?.split('-')[0])
      .join(',');

  const initializeMapEvents = () => {
    const getBbox = () => {
      const bbox = getMapContainerBboxPolygon(previewPrintMap, {
        bboxString: true,
        bboxStringCrs: mapLegendDefaultCrs
      });

      setBbox(bbox);
    };

    getBbox();

    previewPrintMap.on('moveend', getBbox);

    return () => {
      previewPrintMap.off('moveend', getBbox);
    };
  };

  useEffect(initializeMapEvents, []);

  const fetchLegendOfPrintedMap = () => {
    if (!bbox) return;

    removeAllPrintPreviewChildren();
    const layers = getCheckedLayersString();
    const { project_id: projectId } = currentComposition.composition;
    const { x: width, y: height } = previewPrintMap.getSize();

    dispatch(
      fetchLegend({
        prefix,
        projectId,
        enabledLayers: layers,
        queryParams: { srs: mapLegendDefaultCrs, bbox, width, height },
        fetchJSON: true
      })
    )
      .then(legendObject => {
        const legendItems = convertLegendObjectToPrint(legendObject);
        addElementsToPage({
          legendItems,
          pageHeight: height,
          pageWidth: width
        });
        setProcessingStatus(null);
      })
      .catch(err => {
        const errorMessage = formatMessage({
          id: 'district_portal_legend_modal_fetching_legend_failed',
          defaultMessage: 'Fetching map legend failed.'
        });

        dispatch(showError(errorMessage));
        handleError(err);
      });
  };

  useEffect(() => {
    fetchLegendOfPrintedMap();
  }, [bbox]);

  return <div className={PRINT_LEGEND_PREVIEW_CLASS_NAME}></div>;
};

PrintLegend.propTypes = {
  previewPrintMap: PropTypes.object.isRequired,
  setProcessingStatus: PropTypes.func.isRequired
};

export default PrintLegend;
