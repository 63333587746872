import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchBasicShapes,
  fetchUserDefinedShapes
} from '../../store/actions/sketchbookActions';

import { sketchbookSelectors } from '../../store/selectors';

const {
  isLoadingBasicShapes,
  isLoadingUserDefinedShapes
} = sketchbookSelectors;

const useFetchShapes = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const params = useParams();

  const isBasicShapesLoading = useSelector(isLoadingBasicShapes);
  const isUserDefinedShapesLoading = useSelector(isLoadingUserDefinedShapes);

  const fetchBasicShapesError = formatMessage({
    id: 'sketchbook_fetching_basic_shapes_error',
    defaultMessage: 'The error occurred while fetching the basic shapes'
  });

  const fetchUserDefinedShapesError = formatMessage({
    id: 'sketchbook_fetching_user_defined_shapes_error',
    defaultMessage: 'The error occurred while fetching the user defined shapes'
  });

  const fetchShapes = useCallback(() => {
    dispatch(
      fetchBasicShapes({
        district: params.prefix,
        portal: params.portal,
        portalId: params.id,
        unique: fetchBasicShapesError
      })
    );

    dispatch(
      fetchUserDefinedShapes({
        district: params.prefix,
        portal: params.portal,
        portalId: params.id,
        unique: fetchUserDefinedShapesError
      })
    );
  }, []);

  useEffect(fetchShapes, [fetchShapes]);

  return { isLoading: isBasicShapesLoading, isUserDefinedShapesLoading };
};

export default useFetchShapes;
