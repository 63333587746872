import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { districtSelectors, mapPortalSelectors } from '../../store/selectors';

import { Breadcrumb, Icon } from 'antd';

import { capitalizeFirstLetter } from '../../utils/lib';

import './AdminBreadcrumb.less';

const { Item: BreadcrumbItem } = Breadcrumb;
const { getDistricts } = districtSelectors;
const { getDistrictQgisProjects } = mapPortalSelectors;

const routerPathsName = {
  Admin: 'Administracja',
  'Portale-mapowe': 'Portale mapowe',
  Uzytkownicy: 'Użytkownicy',
  Zgloszenia: 'Zgłoszenia',
  'Ustawienia kolorow': 'Ustawienia',
  Szczegoly: 'Szczegóły',
  'Potwierdz edycje': 'Potwierdź edycję',
  Ogolne: 'Ogólne',
  Narzedzia: 'Narzędzia',
  'Atrybuty zalacznikow': 'Atrybuty załączników',
  'Podklady mapowe': 'Podkłady mapowe',
  'Os czasu': 'Oś czasu',
  Jezyki: 'Języki',
  'Udostepnij edycje': 'Udostępnij edycję',
  'Edycja warstw atrybutow': 'Edycja danych',
  'Rodzaje zgloszen': 'Rodzaje zgłoszeń',
  WMS: 'Usługi sieciowe'
};

const notRedirect = ['Edytuj', 'Globalne', 'Szczegoly', 'Ustawienia'];

const AdminBreadcrumb = () => {
  const { prefix } = useParams();
  const districts = useSelector(state => getDistricts(state));
  const projects = useSelector(state => getDistrictQgisProjects(state, prefix));
  const { pathname: currentPath } = useLocation();

  const getBreadcrumbItemName = id => {
    if (currentPath.includes('/projekty/')) {
      const project = projects?.find(item => item.get('id') === id);
      const projectFile = project?.get('file');
      const projectName = projectFile?.substring(
        projectFile.lastIndexOf('/') + 1
      );

      return { name: projectName, url: '#' };
    }

    return {};
  };

  const getName = item => {
    const splittedName = capitalizeFirstLetter(item.replace(/-/g, ' '));
    const splittedNameToDiff = splittedName.toLowerCase();

    if (splittedNameToDiff.includes('wms')) {
      return capitalizeFirstLetter(splittedNameToDiff.replace(/wms/m, 'WMS'));
    }

    return splittedName;
  };

  const splited = currentPath.split('/');
  const endpoints = currentPath
    .split('/')
    .filter(elem => districts.indexOf(elem) < 0 && elem !== '')
    .map(elem => {
      // eslint-disable-next-line eqeqeq
      const isNumber = elem == Number(elem);

      if (isNumber) {
        const id = Number(elem);
        return getBreadcrumbItemName(id);
      }

      return {
        name: getName(elem),
        url: splited.slice(0, splited.indexOf(elem) + 1).join('/')
      };
    });

  return (
    <Breadcrumb className="breadcrumb">
      {endpoints.map(({ name, url }) => {
        if (!name) return null;

        const pathName = routerPathsName[name] || name;

        return (
          <BreadcrumbItem key={url}>
            {name === 'Admin' && <Icon type="home" />}{' '}
            {notRedirect.includes(pathName) ? (
              <span className="not-redirect">{pathName}</span>
            ) : (
              <Link to={url}>{pathName}</Link>
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default AdminBreadcrumb;
