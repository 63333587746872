import React, { useRef } from 'react';
import { useLocation, useHistory } from 'react-router';

export const useScrollRestoreReception = node => {
  const { state, pathname } = useLocation();
  const { push } = useHistory();

  React.useEffect(() => {
    if (state?.scrollTop && state.scrollTop !== node.scrollTop) {
      node.scrollTop = state.scrollTop;
    }
  }, []);

  const pushWrapper = (path, state = {}) =>
    push(path, { ...state, scrollTop: node.scrollTop, pathname });

  return pushWrapper;
};

export const useScrollRestoreDispatch = defaultPath => {
  const { state } = useLocation();
  const { push } = useHistory();
  const locationInitialState = useRef(state || {});

  const path = locationInitialState.current.pathname || defaultPath;

  const pushWrapper = (state = {}) =>
    push(path, {
      ...state,
      scrollTop: locationInitialState.current.scrollTop
    });

  return [pushWrapper, locationInitialState.current];
};
