import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '../';

import './SortColumnButton.less';

const SortColumnButton = ({ title, ascending, descending }) => {
  return (
    <Button className="sort-column-button" variant="text">
      <span className="ant-table-column-title">{title}</span>
      <span className="sort-column-button-arrows">
        <span
          className={classNames({
            'sort-column-button-arrows-active': ascending
          })}
        >
          {String.fromCharCode(9650)}
        </span>
        <span
          className={classNames({
            'sort-column-button-arrows-active': descending
          })}
        >
          {String.fromCharCode(9660)}
        </span>
      </span>
    </Button>
  );
};

SortColumnButton.propTypes = {
  title: PropTypes.string.isRequired,
  ascending: PropTypes.bool,
  descending: PropTypes.bool
};

export default SortColumnButton;
