import { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { mapPortalSelectors, mapSelectors } from '../../store/selectors';
import {
  addCompositionWmsEntry,
  insertToAddedWmsList
} from '../../store/actions/mapPortalActions';
import { setCheckedLayers, setTypeClick } from '../../store/actions/mapActions';
import { showError } from '../../store/actions/globalActions';

import IconAddToMap from '../../icons/AddToMap';
import { Button, Icon, Collapse, Tooltip, Popconfirm } from 'antd';

import { WmsShape } from '../../shapes/wms';
import { CompositionShape } from '../../shapes/composition';

import { enumClickTypes } from '../../mapConfig';
import { getWmsInfo } from '../../services';
import handleError from '../../utils/handleError';
import {
  findEntryInComposition,
  getUrlWithoutQueryParams,
  getParamsObjectFromURL
} from '../../utils/lib';
import '../../less/components/tempLayers.less';

const Panel = Collapse.Panel;

class WMSItem extends Component {
  findWmsLayerIdByName = layerName => {
    const layer = this.props.data.layers.find(({ name }) => name === layerName);
    return layer && layer.id;
  };

  getWmsParentGroup = () => {
    const { id, title } = this.props.data;

    let parentGroup = findEntryInComposition(
      this.props.composition.get('legend').toJS(),
      `${id}-group`
    );

    if (!parentGroup) {
      parentGroup = {
        id: `${id}-group`,
        name: title,
        name_en: title,
        is_enabled: true,
        type: 'group',
        wmsGroup: true,
        entries: [],
        combined: false,
        index: 0,
        is_visible: true
      };
    }

    return parentGroup;
  };

  replaceUnderscoreWithSpace = name => {
    return name?.replaceAll('_', ' ');
  };

  getWMSScale = async (data, layerName) => {
    const scales = await getWmsInfo(data)
      .then(response => {
        const layer = response.layers.find(layer => layer.name === layerName);
        return { minScale: layer.max_scale, maxScale: layer.min_scale };
      })
      .catch(err => {
        handleError(err);
        this.props.showError(
          this.props.intl.formatMessage({
            id: 'district_portal_timelines_wms_error',
            defaultMessage:
              'There may be problems with displaying the wms layer.'
          })
        );
      });

    return scales;
  };

  getWmsObject = async layerName => {
    const {
      link: url,
      service_type,
      tileSize,
      version,
      style,
      ...serviceProps
    } = this.props.data;
    const id = this.findWmsLayerIdByName(layerName);
    const serviceType = service_type.toLowerCase();
    const { maxScale, minScale } = await this.getWMSScale(
      {
        link: url,
        serviceType: service_type,
        version
      },
      layerName
    );
    const params = getParamsObjectFromURL(url);

    return {
      id,
      name: this.replaceUnderscoreWithSpace(layerName),
      name_en: this.replaceUnderscoreWithSpace(layerName),
      is_enabled: true,
      type: 'layer',
      is_visible: true,
      maximum_scale: maxScale,
      minimum_scale: minScale,
      [serviceType]: {
        url: getUrlWithoutQueryParams(url),
        layer: layerName,
        tileSize,
        version,
        urlParams: params,
        symbolization: style ? JSON.parse(style) : null,
        crsform: 4326, // WGS:84 code
        ...serviceProps
      }
    };
  };

  setNewCheckedLayersList = wmsId => {
    const checkedLayers = this.props.checkedLayers.toJS();
    this.props.setCheckedLayers([String(wmsId), ...checkedLayers]);
  };

  addWmsLayer = async layerName => {
    const parentGroup = this.getWmsParentGroup();
    const wmsLayerObj = await this.getWmsObject(layerName);
    parentGroup.entries.unshift(wmsLayerObj);

    this.props.addCompositionWmsEntry(parentGroup);
    this.setNewCheckedLayersList(wmsLayerObj.id);
    this.props.setTypeClick(enumClickTypes.wmsIdentification);
  };

  deleteWms = () => {
    const { id } = this.props.data;

    this.props.deleteWms(id);
  };

  isWmsLayerEnabled = wmsId => {
    if (!this.props.addedWms) return false;

    const id = String(wmsId);
    return this.props.addedWms.includes(id);
  };

  isFetchingLayer = name => this.props.fetchngWfsLayers.get(name);

  addAllWmsLayers = async layers => {
    const parentGroup = this.getWmsParentGroup();
    const wmsObjectsId = [];

    for (const { name } of layers) {
      const wmsLayerObj = await this.getWmsObject(name);
      wmsObjectsId.push(String(wmsLayerObj.id));
      parentGroup.entries.unshift(wmsLayerObj);

      this.props.addCompositionWmsEntry(parentGroup);
    }

    const checkedLayers = this.props.checkedLayers.toJS();
    this.props.setCheckedLayers([...wmsObjectsId, ...checkedLayers]);
    this.props.setTypeClick(enumClickTypes.wmsIdentification);
  };

  areAllWmsLayersFetching = layers => {
    layers.forEach(({ name }) => {
      this.isFetchingLayer(name);
    });
  };

  isAddAllWmsEnabled = layers => {
    if (!this.props.addedWms) return false;

    return layers.some(({ id }) => this.props.addedWms.includes(String(id)));
  };

  render() {
    const { id, title, tags, layers } = this.props.data;
    const { formatMessage } = this.props.intl;
    const addWMSButtonChild = this.props.addIcon ? (
      <IconAddToMap
        wrapperProps={{
          style: {
            opacity: 1,
            width: '1.5rem',
            height: '1.5rem'
          }
        }}
        aria-label={formatMessage({
          id: 'district_portal_toolbar_save_map',
          defaultMessage: 'Save map',
          description: 'Save map'
        })}
        role="img"
      />
    ) : (
      <FormattedMessage
        id="district_portal_wms_tool_add_to_map"
        defaultMessage="Add to map"
      />
    );
    const header = (
      <div className="collapse-header">
        <span className="layer-name" title={title}>
          {title}
        </span>
        <span>
          <Button
            className={classNames({ 'delete-btn': this.props.addIcon })}
            onClick={() => this.addAllWmsLayers(layers)}
            size="small"
            htmlType="submit"
            disabled={this.isAddAllWmsEnabled(layers)}
            loading={this.areAllWmsLayersFetching(layers)}
            ghost
          >
            {addWMSButtonChild}
          </Button>
          {this.props.deleteWms && (
            <Tooltip
              placement="bottom"
              title={formatMessage({
                id: 'general_delete',
                defaultMessage: 'Delete'
              })}
            >
              <Popconfirm
                title={formatMessage({
                  id: 'district_portal_wms_tool_delete_wms_confirm',
                  defaultMessage: 'Are you sure you want to delete this WMS?'
                })}
                okText={formatMessage({
                  id: 'general_yes',
                  defaultMessage: 'Yes'
                })}
                cancelText={formatMessage({
                  id: 'general_no',
                  defaultMessage: 'No'
                })}
                onConfirm={this.deleteWms}
              >
                <Button
                  size="small"
                  className="delete-btn"
                  title={formatMessage({
                    id: 'general_delete',
                    defaultMessage: 'Delete'
                  })}
                >
                  <Icon type="delete" />
                </Button>
              </Popconfirm>
            </Tooltip>
          )}
        </span>
      </div>
    );

    return (
      <div className="temp-layers-item">
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel header={header} key={id}>
            <div className="tags">
              {tags &&
                tags.map((tag, index) => (
                  <span key={tag + index} className="tag-label">
                    {'#' + tag}
                  </span>
                ))}
            </div>
            <ul className="temp-layers-item-layers">
              {layers.map(({ id, name, title }) => (
                <li key={name}>
                  <span title={name}>
                    {this.replaceUnderscoreWithSpace(title ?? name)}
                  </span>
                  <Button
                    className={classNames({ 'delete-btn': this.props.addIcon })}
                    onClick={() => this.addWmsLayer(name)}
                    size="small"
                    htmlType="submit"
                    disabled={this.isWmsLayerEnabled(id)}
                    loading={this.isFetchingLayer(name)}
                    ghost
                  >
                    {addWMSButtonChild}
                  </Button>
                </li>
              ))}
            </ul>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

WMSItem.propTypes = {
  data: WmsShape,
  addedWms: ImmutablePropTypes.list,
  composition: CompositionShape,
  checkedLayers: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
  addCompositionWmsEntry: PropTypes.func.isRequired,
  deleteWms: PropTypes.func,
  setCheckedLayers: PropTypes.func.isRequired,
  insertToAddedWmsList: PropTypes.func.isRequired
};

const { getCompositionByID, getAddedWms } = mapPortalSelectors;

const { getCheckedLayers } = mapSelectors;

const mapStateToProps = state => ({
  addedWms: getAddedWms(state),
  composition: getCompositionByID(state),
  checkedLayers: getCheckedLayers(state),
  fetchngWfsLayers: state.map.get('fetchngWfsLayers')
});

const mapDispatchToProps = {
  addCompositionWmsEntry,
  setCheckedLayers,
  insertToAddedWmsList,
  showError,
  setTypeClick
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(WMSItem))
);
