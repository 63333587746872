import { useState } from 'react';
import PropTypes from 'prop-types';

import { Popconfirm } from 'antd';
import { Button } from '../';

const DeleteButton = ({
  confirmMessage = 'Czy na pewno chcesz usunąć?',
  text = 'Usuń',
  onClick
}) => {
  const [deletingStatus, setDeletingStatus] = useState(false);

  const deleteFn = async e => {
    setDeletingStatus(true);
    await onClick(e);
    setDeletingStatus(false);
  };

  return (
    <Popconfirm title={confirmMessage} onConfirm={deleteFn}>
      <Button variant="danger" disabled={deletingStatus} size="small">
        {text}
      </Button>
    </Popconfirm>
  );
};

DeleteButton.propTypes = {
  confirmMessage: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default DeleteButton;
