import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { showError } from '../../store/actions/globalActions';

import { parseResponseError } from '../../utils/lib';

const useOnSuccess = () => {
  const dispatch = useDispatch();

  const handleError = useCallback(
    error => dispatch(showError(parseResponseError(error))),
    [dispatch, showError]
  );

  return handleError;
};

export default useOnSuccess;
