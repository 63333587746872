import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import IconAddToMap from '../../icons/AddToMap';
import { Icon, Tooltip, Popconfirm, Button } from 'antd';

import { setCheckedLayers } from '../../store/actions/mapActions';
import { addCompositionCsvEntry } from '../../store/actions/mapPortalActions';
import { showError } from '../../store/actions/globalActions';

import { mapSelectors, mapPortalSelectors } from '../../store/selectors';

import LeafletMapContext from '../../contexts/LeafletMapContext';

import useEnableEdition from '../../hooks/useEnableEdition';

import getRasterBBox from '../../utils/lib/getRasterBBox';
import getGeoJsonBBox from '../../utils/lib/getGeoJsonBBox';
import { getArrayDepth } from '../../utils/getArrayDepth';

import { CORRECT_ARRAY_DEPTH } from '../../constants/map';

import '../../less/components/tempLayers.less';

const { getCheckedLayers } = mapSelectors;
const { getAddedCsv } = mapPortalSelectors;

const LocalLayersItem = ({
  deleteCsv,
  setEditedLocalLayerId,
  data: {
    id,
    name,
    layerGeoJSON,
    symbol,
    style,
    geomType,
    georaster,
    symbolization,
    labelingState
  }
}) => {
  const { formatMessage } = useIntl();
  const enableLayerEdition = useEnableEdition();
  const dispatch = useDispatch();
  const checkedLayers = useSelector(state => getCheckedLayers(state));
  const addedLayer = useSelector(state => getAddedCsv(state));
  const { getLeafletElement } = useContext(LeafletMapContext);

  const setNewCheckedLayersList = csvId => {
    dispatch(setCheckedLayers([String(csvId), ...checkedLayers.toJS()]));
  };

  const addLocalLayer = ({
    event,
    layerGeoJSON,
    symbol,
    style,
    symbolization,
    labelingState,
    geomType
  }) => {
    const map = getLeafletElement();
    const fillPattern = map.generateFillPattern(style);

    event.stopPropagation();
    const addedLocalLayer = {
      bbox: getGeoJsonBBox(layerGeoJSON),
      layerToAdd: {
        layerGeoJSON,
        symbol,
        style: { ...style, fillPattern },
        symbolization,
        labelingState,
        type: geomType
      },
      id,
      name,
      name_en: name,
      is_enabled: true,
      type: 'layer',
      layer_transparency: 0,
      addedLayers: true,
      is_visible: true,
      tempLayer: true
    };

    dispatch(addCompositionCsvEntry(addedLocalLayer));
    setNewCheckedLayersList(id);
  };

  const addLocalRasterLayer = ({ event, georaster }) => {
    event.stopPropagation();
    const addedLocalLayer = {
      bbox: getRasterBBox(georaster),
      layerToAdd: { georaster },
      id,
      name,
      name_en: name,
      is_enabled: true,
      type: 'raster',
      layer_transparency: 0,
      addedLayers: true,
      is_visible: true,
      tempLayer: true
    };

    dispatch(addCompositionCsvEntry(addedLocalLayer));
    setNewCheckedLayersList(id);
  };

  const isMultiGeometryType = type => type.includes('Multi');

  const getCorrectInitialCoordinates = useCallback(
    ({ geometryType, coordinates }) => {
      const arrayDepth = getArrayDepth(coordinates);
      const correctArrayDepth = CORRECT_ARRAY_DEPTH[geometryType];

      if (arrayDepth === correctArrayDepth + 1) {
        return coordinates;
      }

      if (arrayDepth === correctArrayDepth) {
        return [coordinates];
      }

      return [[coordinates]];
    },
    []
  );

  const getGeometryType = useCallback(
    features => {
      if (geomType) {
        return isMultiGeometryType(geomType) ? geomType : `Multi${geomType}`;
      }

      const initialGeometryType = features[0].geometry.type;

      const isSameGeometry = features.every(
        ({ geometry: { type } }) => initialGeometryType === type
      );

      if (!isSameGeometry) return;

      return isMultiGeometryType(initialGeometryType)
        ? initialGeometryType
        : `Multi${initialGeometryType}`;
    },
    [geomType]
  );

  const getCombinedGeometry = useCallback(
    (geometryType, features) => {
      const {
        geometry: { coordinates: basicCoordinates }
      } = features[0];

      let initialCoordinates;

      if (isMultiGeometryType(geometryType)) {
        initialCoordinates = getCorrectInitialCoordinates({
          geometryType,
          coordinates: basicCoordinates
        });
      } else {
        initialCoordinates = [basicCoordinates];
      }

      const coordinates = features
        .slice(1)
        .reduce(
          (acc, { geometry: { type, coordinates } }) => [
            ...acc,
            isMultiGeometryType(type) ? coordinates.flat() : coordinates
          ],
          initialCoordinates
        );

      const geometry = {
        type: geometryType,
        coordinates
      };

      return geometry;
    },
    [getCorrectInitialCoordinates]
  );

  const getLoadedSketch = useCallback(() => {
    const basicSketchProps = {
      name,
      labelingState,
      shareSketch: null,
      symbolization
    };

    if ('features' in layerGeoJSON) {
      const { features } = layerGeoJSON;
      const geometryType = getGeometryType(features);

      if (!geometryType) {
        return;
      }

      const geometry = getCombinedGeometry(geometryType, features);

      return { ...basicSketchProps, geometry };
    }

    return { ...basicSketchProps, geometry: layerGeoJSON };
  }, [
    name,
    layerGeoJSON,
    labelingState,
    symbolization,
    getCombinedGeometry,
    getGeometryType
  ]);

  const enableEdition = useCallback(() => {
    const loadedSketch = getLoadedSketch();

    if (!loadedSketch) {
      dispatch(
        showError(
          formatMessage({
            id: 'general_layer_not_possible_to_edit',
            defaultMessage: 'Layer not possible to edit'
          })
        )
      );

      return;
    }

    setEditedLocalLayerId(id);
    enableLayerEdition(loadedSketch);
  }, [id, geomType, getLoadedSketch]);

  const isAddedLayerEnabled = csvId => {
    if (!addedLayer) return false;

    const id = String(csvId);
    return addedLayer.includes(id);
  };

  return (
    <div className="temp-layers-item">
      <span className="layer-name" title={name}>
        {name}
      </span>

      <div style={{ float: 'right', display: 'flex' }}>
        <Button
          className="delete-btn"
          disabled={isAddedLayerEnabled(id)}
          ghost
          onClick={event =>
            georaster
              ? addLocalRasterLayer({ event, georaster })
              : addLocalLayer({
                  event,
                  layerGeoJSON,
                  symbol,
                  style,
                  symbolization,
                  labelingState,
                  geomType
                })
          }
          size="small"
          htmlType="submit"
          style={{ float: 'right' }}
        >
          <IconAddToMap
            wrapperProps={{
              style: { opacity: 1, width: '1.5rem', height: '1.5rem' }
            }}
            aria-label={formatMessage({
              id: 'district_portal_toolbar_save_map',
              defaultMessage: 'Save map',
              description: 'Save map'
            })}
            role="img"
          />
        </Button>
        {!georaster && (
          <Tooltip
            placement="bottom"
            title={formatMessage({
              id: 'district_portal_csv_layer_edit_layer',
              defaultMessage: 'Edit layer'
            })}
          >
            <Button onClick={enableEdition} size="small" className="delete-btn">
              <Icon type="edit" />
            </Button>
          </Tooltip>
        )}
        <Tooltip
          placement="bottom"
          title={formatMessage({
            id: 'general_delete',
            defaultMessage: 'Delete'
          })}
        >
          <Popconfirm
            title={formatMessage({
              id: 'district_portal_csv_tool_delete_confirm',
              defaultMessage: 'Are you sure to delete that layer?'
            })}
            okText={formatMessage({
              id: 'general_yes',
              defaultMessage: 'Yes'
            })}
            cancelText={formatMessage({
              id: 'general_no',
              defaultMessage: 'No'
            })}
            onConfirm={deleteCsv}
          >
            <Button
              onClick={event => event.stopPropagation()}
              size="small"
              className="delete-btn"
            >
              <Icon type="delete" />
            </Button>
          </Popconfirm>
        </Tooltip>
      </div>
    </div>
  );
};

LocalLayersItem.propTypes = {
  data: PropTypes.object.isRequired
};

export default LocalLayersItem;
