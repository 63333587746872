import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import queryString from 'query-string';

import { fetchGroups } from '../../store/actions/groupsActions';

import { Select } from 'antd';
import SelectOptionWithTooltip from '../SelectOptionsWithTooltip';

const { Option } = Select;

export const GroupSelector = ({
  districtGroups,
  fetchingGroups,
  groupsFetched,
  fetchGroups,
  ...props
}) => {
  const { prefix } = useParams();
  const [loading, setLoading] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const groups = districtGroups.get('results');

  const handleDropdownVisibleChange = visible => {
    setIsDropdownVisible(visible);
  };

  useEffect(() => {
    if (!groupsFetched && !fetchingGroups) {
      setLoading(true);
      fetchGroups(prefix, 1);
    }
  }, []);

  useEffect(() => {
    const response = districtGroups && districtGroups.toJS();

    if (response.next) {
      setLoading(true);

      const { page: nextPage } = queryString.parseUrl(response.next).query;
      fetchGroups(prefix, nextPage);
    } else {
      setLoading(false);
    }
  }, [districtGroups, fetchGroups, prefix]);

  return (
    <Select
      mode="multiple"
      loading={loading}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      {...props}
    >
      {groups &&
        groups.map(({ id, name }) => (
          <Option key={id} value={id}>
            <SelectOptionWithTooltip
              label={name}
              isDropdownVisible={isDropdownVisible}
            />
          </Option>
        ))}
    </Select>
  );
};

const mapStateToProps = state => ({
  districtGroups: state.groups.get('groups'),
  fetchingGroups: state.groups.get('fetchingGroups'),
  groupsFetched: state.groups.get('groupsFetched')
});

const mapDispatchToProps = { fetchGroups };

GroupSelector.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  districtGroups: ImmutablePropTypes.map,
  fetchingGroups: PropTypes.bool,
  groupsFetched: PropTypes.bool.isRequired,
  fetchGroups: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupSelector);
