import PropTypes from 'prop-types';
import { useState } from 'react';
import { Input, Row, Upload, Icon, Popconfirm, Col } from 'antd';

import { Button } from '../../components';
import UploadedImagesInfo from './UploadedImagesInfo';

const IMAGE_FILE_EXTENTIONS = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.ico',
  '.bmp',
  '.svg'
];

export const FileUploader = ({
  onlyImages = false,
  minimalImageDimensions,
  file,
  name,
  disabled,
  handleDelete,
  deleteName,
  handleChange,
  deleteConfirm
}) => {
  const [dimensions, setDimensions] = useState({});
  const [uploadedFile, setUploadedFile] = useState(null);

  const beforeUpload = file => {
    if (onlyImages) {
      const reader = new FileReader();

      reader.onload = ({ target: { result } }) => {
        const img = new Image();

        img.onload = function() {
          const width = img.width;
          const height = img.height;
          setDimensions({ width, height });
        };

        img.src = result;
      };

      reader.readAsDataURL(file);
    }

    return false;
  };

  return (
    <Col>
      <Row type="flex" align="middle" style={{ flexFlow: 'inherit' }}>
        <Input
          autoComplete="off"
          style={{ margin: '0 5px' }}
          value={file}
          disabled
        />
        <Upload
          accept={onlyImages ? IMAGE_FILE_EXTENTIONS.join(',') : ''}
          name={name}
          customRequest={() => false}
          beforeUpload={beforeUpload}
          onChange={file => {
            setUploadedFile(file.file);
            handleChange(file, name);
          }}
          showUploadList={false}
        >
          <Button variant="secondary" disabled={disabled}>
            <Icon type="upload" /> Dodaj
          </Button>
        </Upload>
        {uploadedFile && onlyImages && (
          <UploadedImagesInfo
            file={uploadedFile}
            dimensions={dimensions}
            minimalImageDimensions={minimalImageDimensions}
          />
        )}
        <Popconfirm
          overlayStyle={{ width: 350 }}
          title={deleteConfirm}
          placement="topRight"
          onConfirm={() => {
            setUploadedFile(null);
            handleDelete(deleteName, name);
          }}
          okText="Usuń"
          cancelText="Anuluj"
        >
          <Button variant="danger" disabled={disabled}>
            Usuń
          </Button>
        </Popconfirm>
      </Row>
    </Col>
  );
};

FileUploader.propTypes = {
  name: PropTypes.string.isRequired,
  deleteName: PropTypes.string.isRequired,
  file: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  deleteConfirm: PropTypes.string.isRequired
};

export default FileUploader;
