import { useState } from 'react';

import { Input } from 'antd';

const IndexInput = ({ defaultValue, onChange, ...props }) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = ({ target: { value } }) => {
    // eslint-disable-next-line eqeqeq
    if (value != Number(value) || value === '0' || value < 0) return;
    setValue(value);
    onChange?.(value);
  };

  return <Input value={value} onChange={handleChange} {...props} />;
};

export default IndexInput;
