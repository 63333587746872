import { Component } from 'react';

const onWindowResizeDrag = (WrappedComponent, className) => {
  class DragggableHoc extends Component {
    componentDidMount() {
      window.addEventListener('resize', this.onWindowResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize = () => {
      const targetNode = document.querySelector(className);
      if (targetNode) {
        triggerMouseEvent(targetNode, 'mouseover');
        triggerMouseEvent(targetNode, 'mousedown');
        triggerMouseEvent(document, 'mousemove');
        triggerMouseEvent(targetNode, 'mouseup');
        triggerMouseEvent(targetNode, 'click');
      }

      function triggerMouseEvent(node, eventType) {
        const clickEvent = document.createEvent('MouseEvents');
        clickEvent.initEvent(eventType, true, true);
        node.dispatchEvent(clickEvent);
      }
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  DragggableHoc.deletePmAndPidQueryParams =
    WrappedComponent.deletePmAndPidQueryParams; // statyczna funkcja InformationModal
  DragggableHoc.downloadQRCode = WrappedComponent.downloadQRCode; // statyczna funkcja InformationModal
  return DragggableHoc;
};

export default onWindowResizeDrag;
