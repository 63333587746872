import { useState, useCallback, useEffect, useMemo } from 'react';
import { eventTypes } from '../../components/MapControl/layer-editor/const';

const { changeLabelValue } = eventTypes;

const DEFAULT_LABEL_COLOR = 'rgba(0, 0, 0, 1)';
const DEFAULT_LABEL_FONT = 'ABeeZee';
const DEFAULT_LABEL_SIZE = '20';
const DEFAULT_LABEL_VISIBLE = false;

const useLabelSymbolization = ({
  map,
  editionId,
  isLabelingMenuActive,
  defaultColor = DEFAULT_LABEL_COLOR,
  defaultFont = DEFAULT_LABEL_FONT,
  defaultSize = DEFAULT_LABEL_SIZE,
  defaultHidden = DEFAULT_LABEL_VISIBLE
} = {}) => {
  const [labelValue, setLabelValue] = useState('');
  const [labelColor, setLabelColor] = useState(null);
  const [labelFont, setLabelFont] = useState('');
  const [labelSize, setLabelSize] = useState(null);
  const [labelHidden, setLabelHidden] = useState(false);

  const handleDefaultValues = useCallback(() => {
    setLabelColor(labelColor ?? defaultColor);
    setLabelFont(!!labelFont ? labelFont : defaultFont);
    setLabelSize(labelSize ?? defaultSize);
    setLabelSize(labelHidden ?? defaultHidden);
  }, [defaultColor, defaultFont, defaultSize, defaultHidden]);

  useEffect(handleDefaultValues, [handleDefaultValues]);

  const labelSymbolization = useMemo(() => {
    if (labelColor && labelFont && labelSize) {
      return {
        labelColor,
        labelFont,
        labelSize,
        labelHidden
      };
    }
  }, [labelColor, labelSize, labelFont, labelHidden]);

  const handleSubmit = useCallback(() => {
    map.fire(
      changeLabelValue,
      {
        editionId,
        labelValue
      },
      [editionId, labelValue]
    );

    setLabelValue('');
  }, [labelValue]);

  // Update edited label value
  const handleLabelValue = useCallback(() => {
    if (editionId && isLabelingMenuActive && map) {
      const currentEdition = map.getEdition(editionId);
      const editedLabelValue = currentEdition?.getEditedLabelValue();

      setLabelValue(editedLabelValue ?? '');
    }
  }, [editionId, isLabelingMenuActive, map]);

  useEffect(handleLabelValue, [handleLabelValue]);

  const updateLabelingStyles = useCallback(
    ({
      labelColor,
      labelFont,
      labelSize,
      labelHidden = DEFAULT_LABEL_VISIBLE
    }) => {
      setLabelColor(labelColor);
      setLabelFont(labelFont);
      setLabelSize(labelSize);
      setLabelHidden(labelHidden);
    },
    []
  );

  const values = useMemo(
    () => ({
      labelValue: labelValue,
      labelColor,
      labelFont,
      labelSize,
      labelHidden,
      disableSubmitButton: !labelColor || !labelFont || !labelSize,
      labelSymbolization,
      setLabelValue,
      setLabelColor,
      setLabelFont,
      setLabelSize,
      setLabelHidden,
      handleSubmit,
      updateLabelingStyles
    }),
    [labelValue, labelColor, labelFont, labelSize, labelHidden]
  );

  return values;
};

export default useLabelSymbolization;
