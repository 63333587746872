import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import autoBind from '../../utils/autoBind';

import { Table, Input } from 'antd';
import { Button, CloseModalButton, ImagePreview } from '../';
import { Resizable } from 'react-resizable';
import Draggable from 'react-draggable';
import { localStorageKeys } from '../../config';
import { parseResponseError } from '../../utils/lib';
import { showError } from '../../store/actions/globalActions';
import onWindowResizeDrag from '../../HOCs/onWindowResizeDrag';

import './FilesLibrary.less';
import '../../../node_modules/react-resizable/css/styles.css';

const Search = Input.Search;
const filesHandleClass = '.files-modal__header';
export class FilesLibrary extends Component {
  columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      align: 'left',
      render: (fileName, file) => (
        <span className="file-item">
          <ImagePreview filePath={file.path} />
          {fileName}
        </span>
      )
    },
    {
      title: 'Rozmiar',
      dataIndex: 'size',
      key: 'size',
      width: 150,
      align: 'left'
    },
    {
      title: 'Wymiary',
      dataIndex: 'dimensions',
      key: 'dimensions',
      width: 150,
      align: 'left',
      render: dimensions => (
        <span>
          {dimensions[0]} x {dimensions[1]}
        </span>
      )
    },
    {
      title: 'Data',
      dataIndex: 'created_time',
      key: 'created_time',
      width: 150,
      align: 'left'
    },
    {
      title: 'Ścieżka',
      dataIndex: 'path',
      key: 'path',
      width: 450,
      align: 'left'
    }
  ];

  dimensions = {
    portal_cover: [2, 1], // 220, 118 wymiary w pixelach
    tile_cover: [3, 2], // 150, 100
    composition_cover: [2, 1], // 350, 163
    legend: [1, 1], // 328,
    additional_image: [1, 1], // 328,
    basemap_cover: [1, 1] //77, 77
  };

  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      loading: false,
      selectedRowKeys: [],
      selectedRow: {},
      width: 500,
      height: 365
    };

    autoBind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.getData();
    this.loadModalSize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    const updatedWidth =
      this.state.width < window.innerWidth
        ? this.state.width
        : window.innerWidth;
    const updatedHeight =
      this.state.height < window.innerHeight
        ? this.state.height
        : window.innerHeight - 80;
    this.setState({ width: updatedWidth, height: updatedHeight });
  }

  setSelectedFile() {
    if (!this.props.selectedFilePath) return;

    const file = this.state.dataSource.find(
      ({ path }) => path === this.props.selectedFilePath
    );

    if (!file) return;

    this.setState({
      selectedRowKeys: [file.key]
    });
  }

  loadModalSize() {
    if (!localStorage) {
      return;
    }

    const lsSize = localStorage.getItem(localStorageKeys.filesModalSize);
    const size = lsSize && JSON.parse(lsSize);

    if (size) {
      this.setState({
        width: size.width,
        height: size.height
      });
    }
  }

  getData() {
    this.setState({ loading: true });
    const url = `${this.props.match.params.prefix}/images/`;
    axios
      .get(url)
      .then(response => {
        this.transformData(response.data);
      })
      .catch(err => {
        const errorMessage =
          err.response.status === 404
            ? 'Brak ścieżki do obrazów'
            : parseResponseError(err);

        this.props.showError(errorMessage);
      })
      .then(() => {
        this.setState({ loading: false });
      });
  }

  transformData(data) {
    data.forEach(elem => {
      elem.key = Math.random();
    });

    this.setState(
      { dataSource: data, immutableData: data },
      this.setSelectedFile
    );
  }

  selectRow(record) {
    const selectedRowKeys = [record.key];
    this.setState({ selectedRowKeys, selectedRow: record });
  }

  onSelectedRowKeysChange(selectedRowKeys, selectedRow) {
    this.setState({
      selectedRowKeys,
      selectedRow: selectedRow[0]
    });
  }

  onSearch(e) {
    const { value: query } = e.target;
    const reg = new RegExp(query, 'i');
    const immutableData = this.state.immutableData;
    if (!immutableData) return;

    this.setState({
      filteredName: !!e.target.value,
      dataSource: immutableData
        .map(record => {
          const nameMatch = record.name.match(reg);
          const lastModifiedMatch = record.created_time.match(reg);
          if (!nameMatch && !lastModifiedMatch) {
            return null;
          }
          return {
            ...record
          };
        })
        .filter(record => !!record)
    });
    if (query === '') {
      this.setState({
        dataSource: immutableData,
        filtered: null
      });
    }
  }

  handleResize(event, { size }) {
    this.setState({ width: size.width, height: size.height });
  }

  saveModalPosition(e, data) {
    const { x, y } = data;

    localStorage.setItem(
      localStorageKeys.filesModalPosition,
      JSON.stringify({ x, y })
    );
  }

  saveModalSize(width, height) {
    localStorage.setItem(
      localStorageKeys.filesModalSize,
      JSON.stringify({ width, height })
    );
  }

  handleResizeStop(e, data) {
    const { width, height } = data.size;

    this.saveModalSize(width, height);
  }

  render() {
    const selectedRowKeys = this.state.selectedRowKeys;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
      type: 'radio'
    };
    const lsModalPosition = localStorage.getItem(
      localStorageKeys.filesModalPosition
    );
    const defaultPosition = lsModalPosition
      ? JSON.parse(lsModalPosition)
      : { x: 0, y: 0 };
    const dimension = this.props.parent && this.dimensions[this.props.parent];
    const headerHeight = 63;
    let filterHeight;
    if (this.state.width >= 705) {
      filterHeight = 70;
    }
    if (this.state.width >= 373 && this.state.width < 705) {
      filterHeight = 90;
    }
    if (this.state.width < 373) {
      filterHeight = 100;
    }
    const tableHeaderHeight = 54;
    const footerHeight = 50;
    //od wysokość okna odejmujemy wysokości innych elementów w oknie: header, filtr. naglowek tabeli, stopka
    const tableHeight =
      this.state.height -
      headerHeight -
      filterHeight -
      tableHeaderHeight -
      footerHeight;
    return (
      <Draggable
        handle={filesHandleClass}
        onStop={this.saveModalPosition}
        defaultPosition={defaultPosition}
        bounds={{
          left: 0,
          top: 0,
          bottom: window.innerHeight - this.state.height - 70,
          right: window.innerWidth - this.state.width
        }}
      >
        <Resizable
          width={this.state.width}
          height={this.state.height}
          onResize={this.handleResize}
          onResizeStop={this.handleResizeStop}
          maxConstraints={[window.innerWidth, window.innerHeight - 70]}
        >
          <div
            className="files-modal"
            style={{
              display: this.props.visible ? 'block' : 'none',
              width: this.state.width,
              height: this.state.height,
              bottom: window.innerHeight - this.state.height - 64,
              left: 0
            }}
          >
            <div className="files-modal__header">
              <span className="bold">Wybierz obraz</span>{' '}
              <CloseModalButton closeFn={this.props.handleCancel} />
            </div>
            <div className="filter">
              {dimension && (
                <span className="file-modal-info">
                  Sugerowane wymiary obrazka są w proporcji {dimension[0]}:
                  {dimension[1]}. Dla innych wymiarów obrazek zostanie
                  rozciągnięty.
                </span>
              )}
              <Search
                id="searchInput"
                onChange={this.onSearch}
                onPressEnter={this.onSearch}
                placeholder="Wyszukaj"
              />
            </div>
            <Table
              className="modal-files-table"
              rowSelection={rowSelection}
              columns={this.columns}
              dataSource={this.state.dataSource}
              scroll={{ x: this.state.width - 50, y: tableHeight }}
              pagination={false}
              onRow={record => ({
                onClick: () => {
                  this.selectRow(record);
                }
              })}
              size="small"
              loading={this.state.loading}
            />
            <div className="modal-files-footer">
              <Button
                className="modal-files-btn"
                key="submit"
                type="primary"
                onClick={() =>
                  this.props.handleOk(
                    this.state.selectedRow,
                    this.props.moreImages
                  )
                }
                disabled={!Object.keys(this.state.selectedRow).length}
              >
                Wybierz
              </Button>
            </div>
          </div>
        </Resizable>
      </Draggable>
    );
  }
}

const mapDispatchToProps = {
  showError
};

FilesLibrary.propTypes = {
  selectedFilePath: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(onWindowResizeDrag(FilesLibrary, filesHandleClass)));
