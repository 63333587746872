import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Switch } from 'antd';

const LabeledSwitch = ({ offLabel, onLabel, ...props }) => {
  const { formatMessage } = useIntl();

  const noTranslation = formatMessage({
    id: 'general_no',
    defaultMessage: 'No'
  });

  const yesTranslation = formatMessage({
    id: 'general_yes',
    defaultMessage: 'Yes'
  });

  return (
    <>
      <span className="switch-label">{offLabel || noTranslation}</span>
      <Switch {...props} />
      <span className="switch-label">{onLabel || yesTranslation}</span>
    </>
  );
};

LabeledSwitch.propTypes = {
  offLabel: PropTypes.string,
  onLabel: PropTypes.string
};

export default LabeledSwitch;
