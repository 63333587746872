import * as L from 'leaflet';
import { GridLayer, withLeaflet } from 'react-leaflet';
import 'tilelayer-canvas';

class ReactCanvasTileLayer extends GridLayer {
  createLeafletElement(props) {
    return new L.TileLayer.Canvas(props.url, this.getOptions(props));
  }

  updateLeafletElement(fromProps, toProps) {
    super.updateLeafletElement(fromProps, toProps);
    if (toProps.url !== fromProps.url) {
      this.leafletElement.setUrl(toProps.url);
    }
  }
}

export default withLeaflet(ReactCanvasTileLayer);
