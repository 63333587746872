import { useCallback, useMemo } from 'react';
import { useTranslations } from '../../../hooks';

import { SEARCH_TYPES } from '../../SearchInput/consts';
import { SEARCH_TYPE_SELECT_TRANSLATIONS } from '../consts';

const useSearchTypeSelect = ({ onChange, searchTools, searchType }) => {
  const {
    ADDRESS_SEARCH_NAME,
    PLOT_SEARCH_NAME,
    GEONETWORK_VERTICAL_SEARCH_NAME,
    GEONETWORK_HORIZONTAL_SEARCH_NAME,
    GEOLOCATION_SEARCH_NAME
  } = useTranslations(SEARCH_TYPE_SELECT_TRANSLATIONS);

  const searchTypesNames = {
    [SEARCH_TYPES.ADDRESS]: ADDRESS_SEARCH_NAME,
    [SEARCH_TYPES.PLOT]: PLOT_SEARCH_NAME,
    [SEARCH_TYPES.GEONETWORK_VERTICAL]: GEONETWORK_VERTICAL_SEARCH_NAME,
    [SEARCH_TYPES.GEONETWORK_HORIZONTAL]: GEONETWORK_HORIZONTAL_SEARCH_NAME,
    [SEARCH_TYPES.GEOLOCATION]: GEOLOCATION_SEARCH_NAME
  };

  const hasSearchTool = useCallback(
    toolName => searchTools.includes(toolName),
    [searchTools]
  );

  const getMenuItemStyle = useCallback(
    searchTypeItem => {
      if (searchTypeItem === searchType) {
        return { background: 'rgba(0, 0, 0, 0.08)' };
      }
    },
    [searchType]
  );

  const menuItems = useMemo(
    () =>
      Object.values(SEARCH_TYPES).map(key => ({
        isVisible: hasSearchTool(key),
        key,
        onClick: () => onChange(key),
        style: getMenuItemStyle(key)
      })),
    [hasSearchTool, getMenuItemStyle, onChange]
  );

  return { searchTypesNames, menuItems };
};

export default useSearchTypeSelect;
