import { mapPortalSelectors } from '../../store/selectors';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

const { getMapPortalTools } = mapPortalSelectors;

export default function useMapPortalTools() {
  const { prefix, portal } = useParams();
  const mapPortalTools = useSelector(state =>
    getMapPortalTools(state, prefix, portal)
  );

  return mapPortalTools ? mapPortalTools : [];
}
