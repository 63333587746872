import { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  showSidebar,
  hideSidebar,
  hideHamburger,
  changeValueMapPortal,
  resetSidebarPanelsVisibility,
  resetSidebarTopicalSearchVisibility
} from '../../store/actions/globalActions';

import sidebarItemsEnums from '../../components/Sidebar/sidebarEnums';

import './NavSidebarItem.less';

class NavSidebarItem extends Component {
  toggleSidebar = () => {
    const toOpen = this.props.toOpen;
    if (sidebarItemsEnums.search_tools !== toOpen) {
      this.props.resetSidebarTopicalSearchVisibility();
    } else {
      this.props.resetSidebarPanelsVisibility();
    }

    if (this.props.sidebarOpen) {
      this.props.hideSidebar();
    } else {
      this.props.showSidebar();
      this.props.hideHamburger();
      this.props.changeValueMapPortal(toOpen, true);
    }
  };

  render() {
    return (
      <div
        href={this.props.href}
        className={
          this.props.mapPortal[this.props.toOpen]
            ? 'nav-sidebar-item_icon nav-sidebar-item_icon-selected'
            : 'nav-sidebar-item_icon'
        }
        onClick={this.toggleSidebar}
      >
        {this.props.icon}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sidebarOpen: state.global.get('sidebarOpen'),
    mapPortal: state.global.get('mapPortal')
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showSidebar,
      hideSidebar,
      hideHamburger,
      changeValueMapPortal,
      resetSidebarPanelsVisibility,
      resetSidebarTopicalSearchVisibility
    },
    dispatch
  );

NavSidebarItem.propTypes = {
  icon: PropTypes.node.isRequired,
  sidebarOpen: PropTypes.bool,
  toOpen: PropTypes.string.isRequired,
  mapPortal: ImmutablePropTypes.map.isRequired,
  showSidebar: PropTypes.func.isRequired,
  hideSidebar: PropTypes.func.isRequired,
  changeValueMapPortal: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(NavSidebarItem);
