import axios from 'axios';
import queryString from 'query-string';

import { setAuthorization } from '../../utils/request';

import {
  CREATE_USER_BEGIN,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  CREATE_USER_RESET,
  USER_LOGIN_BEGINS,
  USER_LOGIN_FINISHED,
  SET_LOGOUT_PROCESSING,
  USER_LOGOUT_FINISHED,
  JWT_CREATE_BEGIN,
  JWT_CREATE_FINISHED,
  FETCH_USERS_BEGIN,
  FETCH_USERS_SUCCESS,
  SAVE_USER_ROLE_BEGIN,
  SAVE_USER_ROLE_SUCCESS,
  SAVE_USER_ROLE_FAILED,
  FETCH_USER_PORTAL_DATA_BEGIN,
  FETCH_USER_PORTAL_DATA_SUCCESS,
  FETCH_USER_PORTAL_DATA_FAILED,
  DELETE_USER_ROLE_FROM_DISTRICT_BEGIN,
  DELETE_USER_ROLE_FROM_DISTRICT_SUCCESS,
  DELETE_USER_ROLE_FROM_DISTRICT_FAILED,
  CREATE_BASIC_ROLE_FOR_USER_BEGIN,
  CREATE_BASIC_ROLE_FOR_USER_SUCCESS,
  CREATE_BASIC_ROLE_FOR_USER_FAILED,
  FETCH_USER_IMAGE_BEGIN,
  FETCH_USER_IMAGE_SUCCESS,
  FETCH_USER_IMAGE_FAILED,
  FETCH_USER_DATA_BEGIN,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAILED,
  FETCH_USER_BEGIN,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILED,
  ADD_USER_TO_LIST,
  FETCH_AUTH_USER_DATA_SUCCESS,
  FETCH_AUTH_USER_DATA_FAILED,
  FETCH_ALL_USERS_BEGIN,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAILED,
  PROMOTE_ADMIN_FAILED,
  PROMOTE_ADMIN_BEGIN,
  PROMOTE_ADMIN_SUCCESS,
  DEMOTE_ADMIN_SUCCESS,
  DEMOTE_ADMIN_FAILED,
  DEMOTE_ADMIN_BEGIN,
  FETCH_ADMINS_BEGIN,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAILED,
  DEACTIVATE_USER_BEGIN,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_FAILED,
  SET_NOT_A_ROBOT
} from '../constants/userActionTypes';

import { showSuccess, showError } from './globalActions';
import { parseResponseError } from '../../utils/lib';
import translations from '../translations';

import {
  clearMapPortalReducerState,
  refetchDistrictMapPortals
} from './mapPortalActions';
import { closeInformationModal } from './popupActions';
import { clearDistrictsDataReducerState } from './districtActions';

import { CustomLogger } from '../../utils/CustomLogger';
import handleError from '../../utils/handleError';
import trackUser from '../../utils/matomo/trackUser';
import untrackUser from '../../utils/matomo/untrackUser';

import { localStorageKeys } from '../../config';

const { jwt, refresh } = localStorageKeys;

export const fetchAuthUserDataSuccess = () => ({
  type: FETCH_AUTH_USER_DATA_SUCCESS
});
export const fetchAuthUserDataFailed = () => ({
  type: FETCH_AUTH_USER_DATA_FAILED
});

export const fetchAuthUserData = userId => dispatch => {
  return new Promise((resolve, reject) => {
    axios
      .get(`auth/users/${userId}/`)
      .then(response => {
        dispatch(fetchAuthUserDataSuccess());
        resolve(response.data);
      })
      .catch(err => {
        dispatch(fetchAuthUserDataFailed());
        handleError(err);
        reject();
      });
  });
};

export const createUser = user => (dispatch, getState) => {
  dispatch({
    type: CREATE_USER_BEGIN
  });

  const userData = {
    ...user,
    landing_page: `${window.location.origin}/aktywacja`
  };

  axios
    .post(`auth/users/`, userData)
    .then(() => {
      dispatch(createUserSuccess());
    })
    .catch(err => {
      const lang = getState().global.get('language');

      dispatch(createUserFailed());
      dispatch(
        showError(
          `${translations.forAll.errorOccured[lang]} ${parseResponseError(err)}`
        )
      );
    });
};

export const createUserSuccess = () => ({
  type: CREATE_USER_SUCCESS
});

export const createUserFailed = () => ({
  type: CREATE_USER_FAILED
});

export const createUserReset = () => ({
  type: CREATE_USER_RESET
});

export const jwtCreateBegins = () => ({
  type: JWT_CREATE_BEGIN
});

export const jwtCreateFinished = () => ({
  type: JWT_CREATE_FINISHED
});

const parseError = errorsObj => {
  const result = [];

  Object.keys(errorsObj).forEach(key => {
    const error = errorsObj[key];
    if (Array.isArray(error)) {
      errorsObj[key].forEach(errorItem => {
        result.push(errorItem);
      });
    }
  });

  return result.length
    ? result.join(' ')
    : 'Wystąpił błąd podczas próby zalogowania.';
};

export const jwtCreate = data => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(jwtCreateBegins());

    const url = Object.keys(data).includes('ad_token')
      ? 'auth/ad/login_by_token/'
      : 'auth/jwt/create/';

    return axios
      .post(url, data)
      .then(response => {
        window.localStorage.setItem(jwt, response.data.access);
        window.localStorage.setItem(refresh, response.data.refresh);
        setAuthorization();

        return Promise.all([
          dispatch(confirmLoginUser()),
          dispatch(jwtCreateFinished())
        ]).then(() => resolve(response));
      })
      .catch(error => {
        if (!error?.response?.data?.non_field_errors?.[0]?.code === 'invalid')
          dispatch(showError(parseError(error)));
        reject(error);
      });
  });

export const logoutUserFinished = callback => {
  if (callback && typeof callback === 'function') {
    callback();
  }

  return {
    type: USER_LOGOUT_FINISHED
  };
};

const setLogoutProcessing = () => ({ type: SET_LOGOUT_PROCESSING });

export const logoutUser = callback => dispatch => {
  dispatch(setLogoutProcessing());

  // Przesuwamy uruchomienie tej akcji na koniec kolejki żeby komponenty Reacta
  // zostały na pewno poinformowane o wywołaniu poprzedniej - setLogoutProcessing.
  setTimeout(() => {
    localStorage.removeItem(jwt);
    localStorage.removeItem(refresh);
    axios.defaults.headers.common.Authorization = null;
    untrackUser();

    dispatch(closeInformationModal());
    dispatch(clearDistrictsDataReducerState());
    dispatch(clearMapPortalReducerState());

    dispatch(logoutUserFinished(callback));
  });
};

export const confirmLoginUserBegins = () => ({
  type: USER_LOGIN_BEGINS
});

export const confirmLoginUserFinished = data => dispatch => {
  dispatch(fetchUserPortalData());

  return dispatch({
    type: USER_LOGIN_FINISHED,
    data
  });
};

export const confirmLoginUser = () => dispatch => {
  dispatch(confirmLoginUserBegins());

  return axios
    .get('auth/users/me/')
    .then(response => {
      trackUser(response.data.email);
      dispatch(confirmLoginUserFinished(response.data));
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        dispatch(logoutUser());
      }
    });
};

export const fetchUserPortalDataBegin = () => ({
  type: FETCH_USER_PORTAL_DATA_BEGIN
});

export const fetchUserPortalDataSuccess = () => ({
  type: FETCH_USER_PORTAL_DATA_SUCCESS
});

export const fetchUserPortalDataFailed = () => ({
  type: FETCH_USER_PORTAL_DATA_FAILED
});

export const fetchUserPortalData = () => dispatch => {
  dispatch(fetchUserPortalDataBegin());
  Promise.all([
    // Tutaj dodajemy wszystkie akcje, które mają się wykonać po zalogowaniu,
    // czyli np. akcje, które wymagają odświeżenia danych, bo zawierają
    // informacje o rolach.
    dispatch(refetchDistrictMapPortals()),
    dispatch(fetchUserImage())
  ])
    .then(() => dispatch(fetchUserPortalDataSuccess()))
    .catch(err => {
      dispatch(fetchUserPortalDataFailed());
    });
};

export const fetchUsersBegin = () => ({
  type: FETCH_USERS_BEGIN
});

export const fetchUsers = config => (dispatch, getState) => {
  dispatch(fetchUsersBegin());

  const { prefix, role, searchQuery, sortBy, callback } = config;

  const queryParams = queryString.stringify({
    role,
    search: searchQuery,
    ordering: sortBy
  });

  const url = queryParams
    ? `${prefix}/users/?${queryParams}`
    : `${prefix}/users/`;

  return axios
    .get(url)
    .then(response => {
      dispatch({
        type: FETCH_USERS_SUCCESS,
        usersData: response.data
      });

      return response.data;
    })
    .catch(err => {
      const lang = getState().global.get('language');

      dispatch(
        showError(
          `${translations.forAll.errorOccured[lang]} ${parseResponseError(err)}`
        )
      );
    })
    .then(callback);
};

export const saveUserRoleBegin = () => ({
  type: SAVE_USER_ROLE_BEGIN
});

export const saveUserRoleSuccess = () => ({
  type: SAVE_USER_ROLE_SUCCESS
});

export const saveUserRoleFailed = () => ({
  type: SAVE_USER_ROLE_FAILED
});

export const saveUserRole = (
  userId,
  district,
  role,
  saveCallback
) => dispatch => {
  dispatch(saveUserRoleBegin());

  axios
    .post(`${district}/assign_role/${userId}/`, { role })
    .then(() => {
      dispatch(showSuccess('Rola zapisana pomyślnie.'));
      dispatch(saveUserRoleSuccess());
      saveCallback && saveCallback(true);
    })
    .catch(err => {
      dispatch(saveUserRoleFailed());
      dispatch(showError('Wystąpił błąd podczas zapisywania roli!'));
      CustomLogger.log(err);
      saveCallback && saveCallback(false);
    });
};
export const deleteUserRoleFromDistrictBegin = () => ({
  type: DELETE_USER_ROLE_FROM_DISTRICT_BEGIN
});

export const deleteUserRoleFromDistrictSuccess = districtId => ({
  type: DELETE_USER_ROLE_FROM_DISTRICT_SUCCESS,
  districtId
});

export const deleteUserRoleFromDistrictFailed = () => ({
  type: DELETE_USER_ROLE_FROM_DISTRICT_FAILED
});

export const deleteUserRoleFromDistrict = (prefix, districtId) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteUserRoleFromDistrictBegin());
    axios
      .post(`${prefix}/delete_role_from_district/`)
      .then(response => {
        dispatch(deleteUserRoleFromDistrictSuccess(districtId));
        resolve(response);
      })
      .catch(err => {
        dispatch(deleteUserRoleFromDistrictFailed());
        reject(err);
      });
  });

export const createBasicRoleForUserBegin = () => ({
  type: CREATE_BASIC_ROLE_FOR_USER_BEGIN
});

export const createBasicRoleForUserSuccess = districtId => ({
  type: CREATE_BASIC_ROLE_FOR_USER_SUCCESS,
  districtId
});

export const createBasicRoleForUserFailed = () => ({
  type: CREATE_BASIC_ROLE_FOR_USER_FAILED
});

export const createBasicRoleForUser = (district, districtId) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(createBasicRoleForUserBegin());
    axios
      .post(`${district}/register/`)
      .then(response => {
        dispatch(createBasicRoleForUserSuccess(districtId));
        resolve(response);
      })
      .catch(error => {
        dispatch(createBasicRoleForUserFailed());
        reject(error);
      });
  });

export const fetchUserImageBegin = () => ({
  type: FETCH_USER_IMAGE_BEGIN
});

export const fetchUserImageSuccess = image => ({
  type: FETCH_USER_IMAGE_SUCCESS,
  image
});

export const fetchUserImageFailed = () => ({
  type: FETCH_USER_IMAGE_FAILED
});

export const fetchUserImage = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchUserImageBegin());
    axios
      .get('/auth/profile_image/')
      .then(response => {
        dispatch(fetchUserImageSuccess(response.data.image));
        resolve(response.data.image);
      })
      .catch(error => {
        dispatch(fetchUserImageFailed());
        reject(handleError(error));
      });
  });

export const fetchUserDataBegin = () => ({
  type: FETCH_USER_DATA_BEGIN
});

export const fetchUserDataSuccess = userData => ({
  type: FETCH_USER_DATA_SUCCESS,
  userData
});

export const fetchUserDataFailed = () => ({
  type: FETCH_USER_DATA_FAILED
});

export const fetchUserData = () => dispatch => {
  dispatch(fetchUserDataBegin());
  return axios
    .get('/auth/users/me/')
    .then(response => {
      dispatch(fetchUserDataSuccess(response.data));
    })
    .catch(error => {
      dispatch(fetchUserDataFailed());
      CustomLogger.log(error);
    });
};

export const fetchUserByIdBegin = () => ({
  type: FETCH_USER_BEGIN
});

export const fetchUserByIdSuccess = user => ({
  type: FETCH_USER_SUCCESS,
  user
});

export const addUserToList = user => ({
  type: ADD_USER_TO_LIST,
  user
});

export const fetchUserByIdFailed = () => ({
  type: FETCH_USER_FAILED
});

export const fetchUserById = (district, id) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchUserByIdBegin());
    axios
      .get(`${district}/auth/users/${id}/`)
      .then(response => {
        dispatch(fetchUserByIdSuccess(response.data));
        dispatch(addUserToList(response.data));
        resolve(response.data);
      })
      .catch(error => {
        CustomLogger.log('err3');
        dispatch(fetchUserByIdFailed());
        reject(error);
      });
  });

export const fetchAllUsersBegin = () => ({
  type: FETCH_ALL_USERS_BEGIN
});

export const fetchAllUsersSuccess = () => ({
  type: FETCH_ALL_USERS_SUCCESS
});

export const fetchAllUsersFailed = () => ({
  type: FETCH_ALL_USERS_FAILED
});

export const fetchAllUsers = config => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchAllUsersBegin());

    axios
      .get(`/auth/users/`, {
        params: config
      })
      .then(r => {
        dispatch(fetchAllUsersSuccess());
        resolve(r.data);
      })
      .catch(err => {
        dispatch(fetchAllUsersFailed());
        reject(err);
      });
  });

export const promoteAdminBegin = () => ({
  type: PROMOTE_ADMIN_BEGIN
});
export const promoteAdminSuccess = () => ({
  type: PROMOTE_ADMIN_SUCCESS
});

export const promoteAdminFailed = () => ({
  type: PROMOTE_ADMIN_FAILED
});

export const promoteAdmin = id => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(promoteAdminBegin());

    axios
      .post(`/auth/admin/${id}/promote/`)
      .then(r => {
        dispatch(promoteAdminSuccess());
        resolve(r);
      })
      .catch(err => {
        dispatch(promoteAdminFailed());
        reject(err);
      });
  });

export const demoteAdminBegin = () => ({
  type: DEMOTE_ADMIN_BEGIN
});
export const demoteAdminSuccess = () => ({
  type: DEMOTE_ADMIN_SUCCESS
});

export const demoteAdminFailed = () => ({
  type: DEMOTE_ADMIN_FAILED
});

export const demoteAdmin = id => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(demoteAdminBegin());

    axios
      .post(`/auth/superadmin/${id}/demote/`)
      .then(r => {
        dispatch(demoteAdminSuccess());
        resolve(r);
      })
      .catch(err => {
        dispatch(demoteAdminFailed());
        reject(err);
      });
  });

export const fetchAdminsBegin = () => ({
  type: FETCH_ADMINS_BEGIN
});
export const fetchAdminsSuccess = adminsList => ({
  type: FETCH_ADMINS_SUCCESS,
  adminsList
});

export const fetchAdminsFailed = () => ({
  type: FETCH_ADMINS_FAILED
});

export const fetchAdminsList = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchAdminsBegin());

    axios
      .get(`/auth/admin_and_superadmin/`)
      .then(r => {
        dispatch(fetchAdminsSuccess(r.data));
        resolve(r);
      })
      .catch(err => {
        dispatch(fetchAdminsFailed());
        reject(err);
      });
  });

export const deactivateUserBegin = () => ({
  type: DEACTIVATE_USER_BEGIN
});

export const deactivateUserSuccess = userId => ({
  type: DEACTIVATE_USER_SUCCESS,
  userId
});

export const deactivateUserFailed = () => ({
  type: DEACTIVATE_USER_FAILED
});

export const deactivateUser = (prefix, id) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deactivateUserBegin());
    axios
      .post(`/${prefix}/deactivate_user/${id}/`)
      .then(() => {
        dispatch(deactivateUserSuccess(id));
        resolve();
      })
      .catch(error => {
        dispatch(deactivateUserFailed());
        reject(error);
      });
  });

export const setNotARobot = () => ({
  type: SET_NOT_A_ROBOT
});
