import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import './PrintPreviewMapComment.less';

const PrintPreviewMapComment = ({ comment, fontSize = 14 }, ref) => (
  <div ref={ref} className="print-map-preview-comment" style={{ fontSize }}>
    {comment}
  </div>
);

const PrintPreviewMapCommentWithRef = forwardRef(PrintPreviewMapComment);

PrintPreviewMapComment.propTypes = {
  comment: PropTypes.string.isRequired,
  fontSize: PropTypes.number
};

export default PrintPreviewMapCommentWithRef;
