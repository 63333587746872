import axios from 'axios';

export default async function getModelName({
  id,
  onSuccess,
  onError,
  onFinish
}) {
  try {
    const { data } = await axios.get(`searchable_models/${id}/`);
    onSuccess?.(data.model);
    if (!onSuccess) return data.model;
  } catch (err) {
    onError?.(err);
  } finally {
    onFinish?.();
  }
}
