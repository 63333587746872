export const PAGE_SIZE = 10;
export const MOVE_USER_ADD = 'add';
export const MOVE_USER_DELETE = 'delete';

export const RESULTS_PER_PAGE_OPTIONS = Object.freeze([
  '5',
  '10',
  '15',
  '20',
  '50'
]);

export const ROLE_NAMES_NO_SUPERADMIN = Object.freeze({
  basic: 'Podstawowa',
  basic_plus: 'Podstawowa Plus',
  advanced: 'Zaawansowana',
  administrator: 'Administrator'
});

export const ROLE_NAMES_WITH_SUPERADMIN = Object.freeze({
  ...ROLE_NAMES_NO_SUPERADMIN,
  superadmin: 'Super Administrator'
});

export const LOCAL_SETTINGS_TABS = Object.freeze({
  general: {
    name: 'Ogólne',
    key: 'ogólne'
  },
  listPortals: {
    name: 'Lista portali',
    key: 'lista-portali'
  },
  qrCodes: {
    name: 'Generator kodów QR',
    key: 'qr-codes'
  }
});

export const COMPONENTS_KEYS = {
  MainView: 'MainView',
  MapPortalView: 'MapPortalView',
  MapControl: 'MapControl',
  Sidebar: 'Sidebar',
  Toolbar: 'Toolbar',
  Captcha: 'Captcha',
  InformationModal: 'InformationModal',
  MapPortals: 'MapPortals',
  UserProfilePasswordForm: 'UserProfilePasswordForm',
  AdminView: 'AdminView',
  DeleteAccountView: 'DeleteAccountView',
  MapPortalsListView: 'MapPortalsListView',
  DistrictHome: 'DistrictHome',
  Home: 'Home',
  ReportUserPanelView: 'ReportUserPanelView',
  UserProfileView: 'UserProfileView',
  PrivacyPolicy: 'PrivacyPolicy',
  UserProfileDistrictView: 'UserProfileDistrictView',
  UserProfileEditView: 'UserProfileEditView',
  UserProfileObjectsView: 'UserProfileObjectsView',
  UserProfileReportsEditView: 'UserProfileReportsEditView',
  UserProfileReportsView: 'UserProfileReportsView'
};

export const COMPONENTS_TO_RENDER = Object.freeze({
  mapPortal: [
    COMPONENTS_KEYS.MapPortalView,
    COMPONENTS_KEYS.MapControl,
    COMPONENTS_KEYS.Sidebar,
    COMPONENTS_KEYS.Toolbar
  ], //'/:district/:portal'
  mapPortalCaptcha: [COMPONENTS_KEYS.MapPortalView, COMPONENTS_KEYS.Captcha], //'/:district/:portal'
  portalWithPopup: [
    COMPONENTS_KEYS.MapPortalView,
    COMPONENTS_KEYS.MapControl,
    COMPONENTS_KEYS.Sidebar,
    COMPONENTS_KEYS.Toolbar,
    COMPONENTS_KEYS.InformationModal
  ], //'/:district/:portal'
  iframeMapPortal: [
    COMPONENTS_KEYS.MapPortalView,
    COMPONENTS_KEYS.MapControl,
    COMPONENTS_KEYS.Toolbar
  ]
});

export const CACHE_LOGS_ACTIONS = {
  cleaning: 'czyszczenie',
  remove_cache_directory: 'usuwanie',
  create_seed_file: 'utworzenie pliku zadania',
  create_cleanup_file:
    'tworzenie pliku konfiguracyjnego do czyszczenia pamięci podręcznej',
  seeding: 'generowanie',
  remove_published_cache: 'usuwanie cache opublikowanej grupy'
};

export const LAYERS_TYPES = {
  layer: 'warstwa',
  group: 'grupa',
  composition: 'kompozycja',
  entry_group: 'grupa',
  subgroup: 'podgrupa'
};

export const DATA_FORMAT_WITH_TIME = Object.freeze('YYYY-MM-DD HH:mm:ss');
export const DATA_FORMAT_WITH_TIME_SUFIX = Object.freeze(
  'DD-MM-YYYY, g. HH:mm:ss'
);
export const DATA_FORMAT_WITH_TIME_NOSEC = Object.freeze('YYYY-MM-DD HH:mm');
export const DATA_FORMAT_NO_TIME = Object.freeze('YYYY-MM-DD');
export const TIME_FORMAT = Object.freeze('HH:mm');

export const YES_NO_CHOICE = [
  { id: 1, value: 0, name: 'Nie' },
  { id: 2, value: 1, name: 'Tak' }
];

export const FREQUENCY_CRON = {
  day: 'Codziennie',
  week: 'Raz w tygodniu',
  workday: 'W dni robocze',
  month: 'Raz w miesiącu',
  quarter: 'Raz na kwartał'
};

export const DAYS_CRON = {
  1: 'Poniedziałek',
  2: 'Wtorek',
  3: 'Środa',
  4: 'Czwartek',
  5: 'Piątek',
  6: 'Sobota',
  0: 'Niedziela'
};

export const CRON_TYPE = {
  is_clear: 'is_clear',
  is_seed: 'is_seed'
};

export const CRON_WEEK_REGEX = Object.freeze(/(\d+)\s(\d+)\s\*\s\*\s(\d)/);
export const SPECIFIED_POINT_COORDINATES = 'WSPÓŁRZĘDNE WSKAZANEGO PUNKTU';
export const COORDS_INFO = 'CoordsInfo';
