export default function renderTriagnleIcon(iconSize, fill) {
  return `<svg 
              fill="${fill}"
              width="${iconSize}px" 
              height="${iconSize}px" 
              version="1.1" 
              id="starIcon" 
              xmlns="http://www.w3.org/2000/svg" 
              xmlns:xlink="http://www.w3.org/1999/xlink" 
              x="0px" y="0px"
              viewBox="0 0 512 512" 
              style="enable-background:new 0 0 512 512;" 
              xml:space="preserve" focusable="false">
              <g>
                  <g>
                      <path d="M507.521,427.394L282.655,52.617c-12.074-20.122-41.237-20.122-53.311,0L4.479,427.394
                          c-12.433,20.72,2.493,47.08,26.655,47.08h449.732C505.029,474.474,519.955,448.114,507.521,427.394z"/>
                  </g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              </svg>`;
}
