import axios from 'axios';

import {
  FETCH_BACKGROUND_WMS_BEGIN,
  FETCH_BACKGROUND_WMS_SUCCESS,
  FETCH_BACKGROUND_WMS_FAILED,
  GET_WMS_BEGIN,
  GET_WMS_SUCCESS,
  GET_WMS_FAILED,
  ADD_WMS_BEGIN,
  ADD_WMS_SUCCESS,
  ADD_WMS_FAILED,
  EDIT_WMS_BEGIN,
  EDIT_WMS_SUCCESS,
  EDIT_WMS_FAILED,
  DELETE_WMS_BEGIN,
  DELETE_WMS_SUCCESS,
  DELETE_WMS_FAILED,
  GET_LIBRARY_WMS_BEGIN,
  GET_LIBRARY_WMS_SUCCESS,
  GET_LIBRARY_WMS_FAILED,
  ADD_WMS_TO_LIBRARY_BEGIN,
  ADD_WMS_TO_LIBRARY_SUCCESS,
  ADD_WMS_TO_LIBRARY_FAILED,
  EDIT_LIBRARY_WMS_BEGIN,
  EDIT_LIBRARY_WMS_SUCCESS,
  EDIT_LIBRARY_WMS_FAILED,
  DELETE_WMS_FROM_LIBRARY_BEGIN,
  DELETE_WMS_FROM_LIBRARY_SUCCESS,
  DELETE_WMS_FROM_LIBRARY_FAILED,
  GET_MAPPORTAL_PUBLIC_WMS_BEGIN,
  GET_MAPPORTAL_PUBLIC_WMS_SUCCESS,
  GET_MAPPORTAL_PUBLIC_WMS_FAILED
} from '../constants/wmsActionTypes';

export const fetchBackgroundWmsBegin = () => ({
  type: FETCH_BACKGROUND_WMS_BEGIN
});

export const fetchBackgroundWmsSuccess = backgroundWms => ({
  type: FETCH_BACKGROUND_WMS_SUCCESS,
  default: backgroundWms
});

export const fetchBackgroundWmsFailed = () => ({
  type: FETCH_BACKGROUND_WMS_FAILED
});

export const fetchBackgroundWms = (district, mapportalId) => dispatch => {
  dispatch(fetchBackgroundWmsBegin());
  const url = `${district}/map_portal/${mapportalId}/background_wms/`;
  return axios
    .get(url)
    .then(response => {
      dispatch(fetchBackgroundWmsSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchBackgroundWmsFailed());
    });
};

export const getWmsBegin = () => ({
  type: GET_WMS_BEGIN
});

export const getWmsSuccess = wms => ({
  type: GET_WMS_SUCCESS,
  wms
});

export const getWmsFailed = () => ({
  type: GET_WMS_FAILED
});

export const getWms = config => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getWmsBegin());

    if (!config) return;

    const { resultsPerPage, pageNumber, searchQuery } = config;

    axios
      .get(`wms/`, {
        params: {
          page_size: resultsPerPage,
          page: pageNumber,
          search: searchQuery
        }
      })
      .then(({ data }) => {
        dispatch(getWmsSuccess(data));
        resolve(data);
      })
      .catch(err => {
        dispatch(getWmsFailed());
        reject(err);
      });
  });

export const addWmsBegin = () => ({
  type: ADD_WMS_BEGIN
});

export const addWmsSuccess = wms => ({
  type: ADD_WMS_SUCCESS,
  wms
});

export const addWmsFailed = () => ({
  type: ADD_WMS_FAILED
});

export const addWms = body => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addWmsBegin());

    axios
      .post('wms/', body)
      .then(({ data }) => {
        dispatch(addWmsSuccess(data));
        resolve(data);
      })
      .catch(err => {
        dispatch(addWmsFailed());
        reject(err);
      });
  });

export const editWmsBegin = () => ({
  type: EDIT_WMS_BEGIN
});

export const editWmsSuccess = wms => ({
  type: EDIT_WMS_SUCCESS,
  wms
});

export const editWmsFailed = () => ({
  type: EDIT_WMS_FAILED
});

export const editWms = (wmsId, body) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editWmsBegin());

    axios
      .patch(`wms/${wmsId}/`, body)
      .then(({ data }) => {
        dispatch(editWmsSuccess(data));
        resolve(data);
      })
      .catch(err => {
        dispatch(editWmsFailed());
        reject(err);
      });
  });

export const deleteWmsBegin = () => ({
  type: DELETE_WMS_BEGIN
});

export const deleteWmsSuccess = wmsId => ({
  type: DELETE_WMS_SUCCESS,
  wmsId
});

export const deleteWmsFailed = () => ({
  type: DELETE_WMS_FAILED
});

export const deleteWms = wmsId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteWmsBegin());

    axios
      .delete(`wms/${wmsId}/`)
      .then(() => {
        dispatch(deleteWmsSuccess(wmsId));
        resolve();
      })
      .catch(err => {
        dispatch(deleteWmsFailed());
        reject(err);
      });
  });

export const getLibraryWmsBegin = () => ({
  type: GET_LIBRARY_WMS_BEGIN
});

export const getLibraryWmsSuccess = (district, wms) => ({
  type: GET_LIBRARY_WMS_SUCCESS,
  district,
  wms
});

export const getLibraryWmsFailed = () => ({
  type: GET_LIBRARY_WMS_FAILED
});

export const getLibraryWms = district => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getLibraryWmsBegin());

    axios
      .get(`${district}/map_portal/public_wms/`)
      .then(({ data }) => {
        dispatch(getLibraryWmsSuccess(district, data));
        resolve(data);
      })
      .catch(err => {
        dispatch(getLibraryWmsFailed());
        reject(err);
      });
  });

export const addWmsToLibraryBegin = () => ({
  type: ADD_WMS_TO_LIBRARY_BEGIN
});

export const addWmsToLibrarySuccess = (district, wms) => ({
  type: ADD_WMS_TO_LIBRARY_SUCCESS,
  district,
  wms
});

export const addWmsToLibraryFailed = () => ({
  type: ADD_WMS_TO_LIBRARY_FAILED
});

export const addWmsToLibrary = (district, body) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addWmsToLibraryBegin());

    axios
      .post(`${district}/map_portal/public_wms/`, body)
      .then(({ data }) => {
        dispatch(addWmsToLibrarySuccess(district, data));
        resolve(data);
      })
      .catch(err => {
        dispatch(addWmsToLibraryFailed());
        reject(err);
      });
  });

export const editLibraryWmsBegin = () => ({
  type: EDIT_LIBRARY_WMS_BEGIN
});

export const editLibraryWmsSuccess = (district, wms) => ({
  type: EDIT_LIBRARY_WMS_SUCCESS,
  district,
  wms
});

export const editLibraryWmsFailed = () => ({
  type: EDIT_LIBRARY_WMS_FAILED
});

export const editLibraryWms = (district, wmsId, body) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editLibraryWmsBegin());

    axios
      .patch(`${district}/map_portal/public_wms/${wmsId}/`, body)
      .then(({ data }) => {
        dispatch(editLibraryWmsSuccess(district, data));
        resolve(data);
      })
      .catch(err => {
        dispatch(editLibraryWmsFailed());
        reject(err);
      });
  });

export const deleteWmsFromLibraryBegin = () => ({
  type: DELETE_WMS_FROM_LIBRARY_BEGIN
});

export const deleteWmsFromLibrarySuccess = (district, wmsId) => ({
  type: DELETE_WMS_FROM_LIBRARY_SUCCESS,
  district,
  wmsId
});

export const deleteWmsFromLibraryFailed = () => ({
  type: DELETE_WMS_FROM_LIBRARY_FAILED
});

export const deleteWmsFromLibrary = (district, wmsId) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteWmsFromLibraryBegin());

    axios
      .delete(`${district}/map_portal/public_wms/${wmsId}/`)
      .then(() => {
        dispatch(deleteWmsFromLibrarySuccess(district, wmsId));
        resolve();
      })
      .catch(err => {
        dispatch(deleteWmsFromLibraryFailed());
        reject(err);
      });
  });

export const getMapPortalPublicWMSBegin = () => ({
  type: GET_MAPPORTAL_PUBLIC_WMS_BEGIN
});

export const getMapPortalPublicWMSSuccess = (mapPortal, wms) => ({
  type: GET_MAPPORTAL_PUBLIC_WMS_SUCCESS,
  mapPortal,
  wms
});

export const getMapPortalPublicWMSFailed = () => ({
  type: GET_MAPPORTAL_PUBLIC_WMS_FAILED
});

export const getMapPortalPublicWMS = (district, mapPortal) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getMapPortalPublicWMSBegin());
    axios
      .get(`${district}/map_portal/${mapPortal}/public_wms/`)
      .then(({ data }) => {
        dispatch(getMapPortalPublicWMSSuccess(mapPortal, data));
        resolve(data);
      })
      .catch(err => {
        dispatch(getMapPortalPublicWMSFailed());
        reject(err);
      });
  });
