import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import { Button } from '../';
import { Row, Popconfirm } from 'antd';

const CRUDButtonsGroup = props => {
  const { formatMessage } = useIntl();

  return (
    <Row type="flex" justify={props.justify} className={props.className}>
      {props.edited ? (
        <Fragment>
          {props.cancelFn && (
            <Button
              className={props.buttonClassName}
              onClick={props.cancelFn}
              variant="settingsDefault"
              // keys potrzebne do unikinięcia stanu „focus” po przełączeniu przycisków
              key="cancelButton"
            >
              {props.cancelText || (
                <FormattedMessage
                  id="general_cancel"
                  defaultMessage="Cancel"
                  description="Cancel"
                />
              )}
            </Button>
          )}
          {props.deleteFn && (
            <Popconfirm
              title={props.deleteMessage}
              onConfirm={props.deleteFn}
              okText="Tak"
              cancelText="Nie"
            >
              <Button
                className={props.buttonClassName}
                variant="danger"
                key="deleteButton"
              >
                {props.deleteText || (
                  <FormattedMessage
                    id="general_delete"
                    defaultMessage="Delete"
                    description="Delete"
                  />
                )}
              </Button>
            </Popconfirm>
          )}
          <Button
            className={props.buttonClassName}
            onClick={props.saveFn}
            variant="secondary"
            key="saveButton"
            disabled={props.saveDisabled}
            loading={props.saving}
          >
            {props.saveText ||
              formatMessage({
                id: 'general_save',
                defaultMessage: 'Save',
                description: 'Save'
              })}
          </Button>
        </Fragment>
      ) : (
        <Button
          className={props.buttonClassName}
          onClick={props.editFn}
          variant="secondary"
          key="editButton"
        >
          {props.editText || (
            <FormattedMessage
              id="general_edit"
              defaultMessage="Edit"
              description="Edit"
            />
          )}
        </Button>
      )}
    </Row>
  );
};

CRUDButtonsGroup.defaultProps = {
  justify: 'end',
  deleteMessage: 'Czy na pewno chcesz usunąć?'
};

CRUDButtonsGroup.propTypes = {
  buttonClassName: PropTypes.string,
  justify: PropTypes.oneOf([
    'start',
    'center',
    'end',
    'space-around',
    'space-between'
  ]),
  className: PropTypes.string,
  edited: PropTypes.bool.isRequired,
  saving: PropTypes.bool,
  saveDisabled: PropTypes.bool,
  cancelFn: PropTypes.func,
  cancelText: PropTypes.string,
  deleteFn: PropTypes.func,
  deleteText: PropTypes.string,
  saveFn: PropTypes.func.isRequired,
  saveText: PropTypes.string,
  editFn: PropTypes.func.isRequired,
  editText: PropTypes.string
};

export default CRUDButtonsGroup;
