import { useEffect, useState } from 'react';
import db from '../../db';
import handleError from '../../utils/handleError';

const useGetLayersFromDb = () => {
  const [dbLayers, setDbLayers] = useState();
  const [fetching, setFetching] = useState(false);

  const getDbLayers = async () => {
    try {
      setFetching(true);
      const { rows: layers } = await db.layersLibrary.allDocs({
        include_docs: true
      });
      setDbLayers(layers.map(({ doc }) => doc));
    } catch (error) {
      handleError(error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getDbLayers();
  }, []);
  return { fetching, dbLayers };
};

export default useGetLayersFromDb;
