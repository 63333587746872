import { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import InformationCardHeader from '../InformationCard/Header';
import { Col, Row } from 'antd';
import GeoJSONPreviewMap from '../GeoJSONPreviewMap';
import TopicalNestedResultAttributes from '../TopicalSearchResultAttributes/nestedAttribute';

import { PrintOrientationTypes } from '../../shapes';
import { TopicalSearchToolResultColumnShape } from '../../shapes/topicalSearchTool';

import './PrintTopicalSearchItem.less';

export class PrintTopicalSearchResultItem extends Component {
  renderLayerFeatureEntry = () => {
    const isPortraitOrientation = this.props.orientation === 'portrait';
    const mapWidth = isPortraitOrientation ? 718 : 1050;
    return (
      <>
        <GeoJSONPreviewMap
          data={this.props.geometry}
          wrapperStyle={{ width: mapWidth }}
        />
        <TopicalNestedResultAttributes
          result={this.props.result}
          resultColumns={this.props.resultColumns}
          print
          isPortraitOrientation={isPortraitOrientation}
        />
      </>
    );
  };

  render() {
    return (
      <div className="item-wrapper">
        <div className="information-card" ref={el => this.props.getRef(el)}>
          <InformationCardHeader />
          <Row className="information-card__content">
            <Col lg={24} className="information-card__content__section">
              {this.props.title}
            </Col>

            <Col
              lg={24}
              className="information-card__content__basic"
              key={`content-${this.props.title}`}
            >
              {this.renderLayerFeatureEntry()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

PrintTopicalSearchResultItem.propTypes = {
  getRef: PropTypes.func.isRequired,
  children: PropTypes.node,
  orientation: PrintOrientationTypes.isRequired,
  title: PropTypes.string.isRequired,
  geometry: PropTypes.object,
  result: PropTypes.object.isRequired,
  resultColumns: PropTypes.oneOfType([
    PropTypes.arrayOf(TopicalSearchToolResultColumnShape),
    ImmutablePropTypes.listOf(TopicalSearchToolResultColumnShape)
  ]).isRequired
};

export default PrintTopicalSearchResultItem;
