import PouchDB from 'pouchdb-browser';

const getPouchDB = (name, adapter = 'idb') => {
  if (adapter === 'memory') {
    PouchDB.plugin(require('pouchdb-adapter-memory').default);
  }

  return new PouchDB(name, { adapter });
};

const getDatabase = name => {
  if (window.indexedDB) return getPouchDB(name);

  return getPouchDB(name, 'memory');
};

export default getDatabase;
