const yearValidator = (year, cb) => {
  if (year?.length === 0 || year === null) return cb();
  if (typeof year !== 'number') {
    return cb(() => 'Rok powinien zawierać wyłącznie cyfry.');
  }
  var current_year = new Date().getFullYear();
  if (year < 1820 || year > current_year) {
    return cb(
      () => 'Rok nie powinien być mniejszy niż 1820 i większy niż obecny rok.'
    );
  }
  return cb();
};

export default yearValidator;
