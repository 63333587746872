import { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../';

const ButtonSwitcher = ({
  children,
  value,
  defaultValue,
  onClick,
  disabled,
  editedPointer,
  buttonProps = {}
}) => {
  const [active, setActive] = useState(
    value !== undefined ? value : defaultValue
  );

  const toggleActive = () =>
    setActive(prevValue => {
      const newValue = !prevValue;
      onClick && onClick(newValue);
      return newValue;
    });

  return (
    <Button
      onClick={toggleActive}
      disabled={disabled}
      style={editedPointer(disabled)}
      variant="text"
      {...buttonProps}
    >
      {/* Możliwe użycie patternu „render props”. */}
      {typeof children === 'function' ? children(active) : children}
    </Button>
  );
};

ButtonSwitcher.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  value: PropTypes.bool,
  defaultValue: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  buttonProps: PropTypes.object
};

export default ButtonSwitcher;
