import axios from 'axios';

const getMeasurementLayerUnits = (prefix, id) =>
  new Promise((resolve, reject) =>
    axios
      .get(`${prefix}/map_portal/${id}/measurment_layers/units/`)
      .then(({ data }) => {
        resolve({
          unit: data,
          diffUnit: data
        });
      })
      .catch(reject)
  );

export default getMeasurementLayerUnits;
