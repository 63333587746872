import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { selectionSelectors, mapPortalSelectors } from '../../store/selectors';

import { fetchSelectionLayers } from '../../store/actions/selectionActions';
import { fetchSelectionOperators } from '../../store/actions/mapPortalActions';

const {
  getCurrentComposition,
  getSelectionOperators,
  getFetchingSelectionOperators
} = mapPortalSelectors;

const useFetchSelectionToolSelectOptions = () => {
  const { prefix, portal } = useParams();
  const dispatch = useDispatch();

  const { id: mapPortalCompositionId } = useSelector(state =>
    getCurrentComposition(state, prefix, portal)
  );

  // Select options
  const selectionOperators = useSelector(getSelectionOperators);

  // Loaders
  const isFetchingLayers = useSelector(
    selectionSelectors.getIsFetchingSelectionLayers
  );

  const fetchingSelectionTypesOperators = useSelector(
    getFetchingSelectionOperators
  );

  // Fetching options
  const fetchLayerOptions = useCallback(() => {
    if (prefix && mapPortalCompositionId) {
      dispatch(
        fetchSelectionLayers({
          district: prefix,
          mapPortalCompositionId
        })
      );
    }
  }, [prefix, mapPortalCompositionId]);

  const fetchSelectionOperatorOptions = useCallback(() => {
    if (!selectionOperators.length) dispatch(fetchSelectionOperators());
  }, [selectionOperators]);

  useEffect(fetchLayerOptions, [fetchLayerOptions, mapPortalCompositionId]);
  useEffect(fetchSelectionOperatorOptions, [fetchSelectionOperatorOptions]);

  return {
    isLoading: isFetchingLayers || fetchingSelectionTypesOperators
  };
};

export default useFetchSelectionToolSelectOptions;
