import { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Icon } from 'antd';
import ImagePreviewModal from './ImagePreviewModal';
import { Button } from '../';

import './ImagePreview.less';
import { getFileUrl } from '../../services';

const ImagePreviewModalPortal = ({
  previewUrl,
  handlePreviewLoad,
  loading,
  top,
  left
}) =>
  ReactDOM.createPortal(
    <ImagePreviewModal
      previewUrl={previewUrl}
      handlePreviewLoad={handlePreviewLoad}
      loading={loading}
      top={top}
      left={left}
    />,
    document.querySelector('body')
  );

export const ImagePreview = props => {
  const containerRef = useRef(null);

  const [previewVisible, setPreviewVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');

  const getImageUrl = async () => {
    setLoading(true);

    const imgUrl = await getFileUrl({
      district: props.match.params.prefix,
      path: props.filePath
    });

    setPreviewUrl(imgUrl);
  };

  const showPreview = () => {
    if (!previewUrl) {
      getImageUrl();
    }

    setPreviewVisibility(true);
  };

  const hidePreview = () => {
    setPreviewVisibility(false);
  };

  const handlePreviewLoad = () => {
    setLoading(false);
  };

  const calculateModalPosition = () => {
    if (!containerRef.current) {
      return { topPosition: 'auto', leftPosition: 'auto' };
    }

    const bounds = containerRef.current.getBoundingClientRect();

    return { topPosition: bounds.top, leftPosition: bounds.right };
  };

  const { topPosition, leftPosition } = calculateModalPosition();

  return (
    <div className="image-preview-wrapper" ref={containerRef}>
      <Button
        className="image-preview-trigger"
        variant="transparent"
        onMouseOver={showPreview}
        onMouseOut={hidePreview}
      >
        <Icon type="zoom-in" />
      </Button>
      {previewVisible && (
        <ImagePreviewModalPortal
          previewUrl={previewUrl}
          handlePreviewLoad={handlePreviewLoad}
          loading={loading}
          top={topPosition}
          left={leftPosition}
        />
      )}
    </div>
  );
};

ImagePreview.propTypes = {
  filePath: PropTypes.string.isRequired
};

export default withRouter(ImagePreview);
