import { Input, Select } from 'antd';
import PropTypes from 'prop-types';

import useSymbolizationMessages from '../../../hooks/useSymbolizationMessages';

import { sizeValidator } from '../../../utils/sizeValidator';

import { ForwardColorSwitcher } from '../../../components/ForwardColorSwitcher';
import { FormItem } from '../../../components';

import '../SymbolizationForm.less';

const { Option } = Select;

const LineSymbolizationForm = ({
  lineColor,
  lineType,
  lineSize,
  setLineColor,
  setLineType,
  setLineSize,
  getFieldDecorator,
  formType = ''
}) => {
  const {
    colorMsg,
    sizeMsg,
    styleMsg,
    solidMsg,
    dashedMsg,
    dottedMsg
  } = useSymbolizationMessages();

  return (
    <div className="symbolization-form">
      <FormItem label={colorMsg}>
        {getFieldDecorator(`outlineColor${formType}`, {
          initialValue: lineColor
        })(
          <ForwardColorSwitcher
            style={{ width: '100%' }}
            onChange={setLineColor}
            placeholder={colorMsg}
          />
        )}
      </FormItem>
      <FormItem label={styleMsg}>
        {getFieldDecorator(`outlineStyle${formType}`, {
          initialValue: lineType
        })(
          <Select placeholder={styleMsg} onChange={setLineType}>
            <Option key="solid">{solidMsg}</Option>
            <Option key="dashed">{dashedMsg}</Option>
            <Option key="dotted">{dottedMsg}</Option>
          </Select>
        )}
      </FormItem>
      <FormItem label={sizeMsg}>
        {getFieldDecorator(`outlineSize${formType}`, {
          rules: [
            {
              validator: sizeValidator
            }
          ],
          initialValue: lineSize
        })(
          <Input
            onChange={e => setLineSize(e.target.value)}
            placeholder={sizeMsg}
          />
        )}
      </FormItem>
    </div>
  );
};

LineSymbolizationForm.propTypes = {
  lineColor: PropTypes.string,
  lineType: PropTypes.string,
  lineSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setLineColor: PropTypes.func.isRequired,
  setLineType: PropTypes.func.isRequired,
  setLineSize: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func.isRequired
};

export default LineSymbolizationForm;
