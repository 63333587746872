import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';

import { SketchPicker } from 'react-color';
import { Row } from 'antd';
import { Button } from '../';

import { COLOR_SWITCHER_VALUE_FORMATS } from './consts';

import './ColorSwitcher.less';

export const ColorSwitcher = ({ disabled, onChangeValueFormat, ...props }) => {
  const [pickerVisible, setPickerVisibility] = useState(false);
  const initialColorValue = props.value || props.initialValue;
  const [colorValue, setColorValue] = useState(initialColorValue);
  const [draftColorValue, setDraftColorValue] = useState(initialColorValue);

  React.useEffect(() => {
    setColorValue(props.value);
  }, [props.value]);

  const togglePicker = () => setPickerVisibility(!pickerVisible);

  const handleColorChange = ({ hex, rgb: { r, g, b, a } }) => {
    const isHexFormat =
      onChangeValueFormat === COLOR_SWITCHER_VALUE_FORMATS.HEX;

    const value = isHexFormat ? hex : `rgba(${r}, ${g}, ${b}, ${a})`;

    setDraftColorValue(value);
  };

  const saveColor = () => {
    if (draftColorValue) {
      setColorValue(draftColorValue);
      props.onChange && props.onChange(draftColorValue);
    }

    closePicker();
  };

  const closePicker = () => {
    setDraftColorValue();
    togglePicker();
  };

  return (
    <div className="color-switcher">
      <button
        className="color-switcher-preview"
        type="button"
        onClick={togglePicker}
        disabled={disabled}
      >
        <div style={{ backgroundColor: colorValue }} />
      </button>
      {pickerVisible && (
        <div className="color-switcher-picker-wrapper">
          <SketchPicker
            color={draftColorValue || colorValue}
            onChangeComplete={handleColorChange}
            disableAlpha
          />
          <Row type="flex" justify="center" style={{ marginTop: 10 }}>
            <Button variant="secondary" onClick={closePicker}>
              Anuluj
            </Button>
            <Button onClick={saveColor}>OK</Button>
          </Row>
        </div>
      )}
    </div>
  );
};

ColorSwitcher.defaultProps = {
  initialValue: 'rgba(255, 255, 255, 1)'
};

ColorSwitcher.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onChangeValueFormat: PropTypes.oneOf(
    Object.values(COLOR_SWITCHER_VALUE_FORMATS)
  )
};

export default memo(ColorSwitcher);
