import { useIntl } from 'react-intl';

const useLabelingMessages = () => {
  const { formatMessage } = useIntl();

  const labelContentMsg = formatMessage({
    id: 'label-content',
    defaultMessage: 'Label content'
  });

  const updateLabelMsg = formatMessage({
    id: 'admin_single_report_button_update',
    defaultMessage: 'Update'
  });

  const labelSymbolizationMsg = formatMessage({
    id: 'label-symbolization',
    defaultMessage: 'Labels symbolization'
  });

  const labelColorMsg = formatMessage({
    id: 'global_colour',
    defaultMessage: 'Colour'
  });

  const labelFontMsg = formatMessage({
    id: 'global_font',
    defaultMessage: 'Font'
  });

  const labelSizeMsg = formatMessage({
    id: 'global_size',
    defaultMessage: 'Size'
  });

  return {
    labelContentMsg,
    updateLabelMsg,
    labelSymbolizationMsg,
    labelColorMsg,
    labelFontMsg,
    labelSizeMsg
  };
};

export default useLabelingMessages;
