import { useState, useCallback, useEffect } from 'react';

import db from '../../../db';

import { CustomLogger } from '../../../utils/CustomLogger';

const useDbSelections = () => {
  const [dbSelections, setDbSelections] = useState([]);

  const getSelectionsFromDb = useCallback(async () => {
    try {
      const { rows } = await db.selectionsDb.allDocs({
        include_docs: true
      });

      setDbSelections(rows);
    } catch (err) {
      CustomLogger.log(err);
    }
  }, []);

  useEffect(() => {
    getSelectionsFromDb();
  }, [getSelectionsFromDb]);

  return { dbSelections, refetchDbSelections: getSelectionsFromDb };
};

export default useDbSelections;
