import { memo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { DATA_FORMAT_WITH_TIME, YES_NO_CHOICE } from '../../utils/constants';
import { Input, Col, Row, Select, DatePicker } from 'antd';
import SelectWrapper from '../SelectWrapper';

import './AdminFilterReports.less';

const { Option } = Select;
const { RangePicker } = DatePicker;

const AdminFilterReports = memo(function AdminFilterReports(props) {
  const { formatMessage } = useIntl();
  const [filterSubcategories, setFilterSubcategories] = useState([]);

  const created_at = props.dataFilter.createdStart || undefined;
  const created_end = props.dataFilter.createdEnd || undefined;

  useEffect(() => {
    setFilterSubcategories(getFilterSubcategories());
  }, [props.reportAllSubcategories]);

  const getFilterSubcategories = () => {
    if (!props.reportAllSubcategories) return [];

    return props.dataFilter.category
      ? props.reportAllSubcategories.filter(
          subcategory => subcategory.category === props.dataFilter.category
        )
      : props.reportAllSubcategories;
  };

  const changeDate = e => {
    props.applyFilters('createdStart', e[0]);
    props.applyFilters('createdEnd', e[1]);
  };

  return (
    <Row className="admin-filter-reports__all">
      <Row gutter={[8, 8]}>
        <Col
          className="admin-filter-reports__title"
          xs={24}
          sm={24}
          md={24}
          lg={3}
          xl={3}
        >
          <FormattedMessage id="general_search" defaultMessage="Search" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={21} xl={21}>
          <Input
            placeholder={formatMessage({
              id: 'admin_filter_reports_title',
              defaultMessage: 'Title'
            })}
            onPressEnter={e => props.applyFilters(e.target.value)}
            onChange={e => props.applyFilters('search', e.target.value)}
            value={props.dataFilter.search}
            allowClear
          />
        </Col>
      </Row>

      <Row className="admin-filter-reports" gutter={[8, 8]}>
        <Col
          className="admin-filter-reports__title"
          xs={24}
          sm={24}
          md={24}
          lg={3}
          xl={3}
        >
          <FormattedMessage
            id="admin_filter_reports_filter"
            defaultMessage="Filtr"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={21} xl={21}>
          <Row className="admin-filter-reports" gutter={[8, 8]}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectWrapper
                allowClear
                name="category"
                options={props.reportAllCategories}
                onChange={e => props.applyFilters('category', e)}
                placeholder={formatMessage({
                  id: 'admin_filter_reports_category',
                  defaultMessage: 'Category'
                })}
                style={{ width: '100%' }}
                value={props.dataFilter.category}
                renderOptions={category => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                )}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectWrapper
                allowClear
                name="subcategory"
                options={filterSubcategories}
                onChange={e => props.applyFilters('subcategory', e)}
                placeholder={formatMessage({
                  id: 'admin_filter_reports_subcategory',
                  defaultMessage: 'Subcategory'
                })}
                style={{ width: '100%' }}
                value={props.dataFilter.subcategory}
                renderOptions={subcategory => (
                  <Option key={subcategory.id} value={subcategory.id}>
                    {subcategory.name}
                  </Option>
                )}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectWrapper
                allowClear
                name="status"
                options={props.reportStatuses}
                onChange={e => props.applyFilters('status', e)}
                placeholder={formatMessage({
                  id: 'admin_filter_reports_status',
                  defaultMessage: 'Status'
                })}
                style={{ width: '100%' }}
                value={props.dataFilter.status}
                renderOptions={value => (
                  <Option key={value.id} value={value.id}>
                    {value.name}
                  </Option>
                )}
              />
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectWrapper
                allowClear
                name="confirmed"
                options={YES_NO_CHOICE}
                onChange={e => props.applyFilters('confirmed', e)}
                placeholder={formatMessage({
                  id: 'report_user_panel_visible_on_map',
                  defaultMessage: 'Visible on the map'
                })}
                style={{ width: '100%' }}
                value={props.dataFilter.confirmed}
                renderOptions={confirmed => (
                  <Option key={confirmed.id} value={confirmed.value}>
                    {confirmed.name}
                  </Option>
                )}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <RangePicker
                style={{ minWidth: 300, width: '100%' }}
                format={DATA_FORMAT_WITH_TIME}
                placeholder={formatMessage({
                  id: 'report_user_panel_created_start',
                  defaultMessage: 'Date created from'
                })}
                defaultValue={[created_at, created_end]}
                onChange={changeDate}
                showTime={{ format: 'HH:mm' }}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectWrapper
                allowClear
                name="type"
                options={props.typeReports}
                onChange={e => props.applyFilters('type', e)}
                placeholder={formatMessage({
                  id: 'district_portal_report_type',
                  defaultMessage: 'Type report'
                })}
                style={{ width: '100%' }}
                value={props.dataFilter.type}
                renderOptions={type => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
});

AdminFilterReports.propTypes = {
  dataFilter: PropTypes.object,
  columns: PropTypes.array,
  reportAllCategories: PropTypes.array,
  reportAllSubcategories: PropTypes.object,
  reportStatuses: PropTypes.array,
  typeReports: ImmutablePropTypes.list,
  applyFilters: PropTypes.func.isRequired
};

export default AdminFilterReports;
