import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { sketchbookSelectors } from '../../store/selectors';

import useMap from '../useMap';

const { getBasicShapes, getUserDefinedShapes } = sketchbookSelectors;

const useShapesList = ({ showOnlyEnabledShapes = true } = {}) => {
  const [selectedShape, setSelectedShape] = useState(null);

  const map = useMap();

  const basicShapes = useSelector(getBasicShapes);
  const userDefinedShapes = useSelector(getUserDefinedShapes);

  const shapes = useMemo(() => {
    const onlyEnabledShapes = basicShapes.filter(({ is_enabled }) =>
      showOnlyEnabledShapes ? is_enabled : true
    );

    return [...onlyEnabledShapes, ...userDefinedShapes];
  }, [showOnlyEnabledShapes, basicShapes, userDefinedShapes]);

  const onClick = iconName => {
    setSelectedShape(iconName);
    const shapeGeometry = shapes.find(({ name }) => name === iconName);
    const geometry = shapeGeometry?.geometry || shapeGeometry?.geom;
    map.setShapeGeometry(geometry);
  };

  return {
    shapes,
    selectedShape,
    onClick
  };
};

export default useShapesList;
