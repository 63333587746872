import axios from 'axios';

const getAllPortalTranslations = () =>
  new Promise((resolve, reject) => {
    axios
      .get('translations_admin/')
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
export default getAllPortalTranslations;
