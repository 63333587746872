import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { mapPortalSelectors, modelsSelectors } from '../../store/selectors';
import { showError } from '../../store/actions/globalActions';
import { fetchSearchableModels } from '../../store/actions/modelsActions';

import useStringifyGeometry from '../useStringifyGeometry';
import useFetchSelectionToolSelectOptions from '../useFetchSelectionToolSelectOptions';

import { parseResponseError } from '../../utils/lib';

import { FORM_TYPES } from '../../components/SelectionTool/hooks/useSelectionTool';

const { getCurrentComposition } = mapPortalSelectors;
const { getPlotSearchableModel, getRoadSearchableModel } = modelsSelectors;
const useGetLayersIds = ({
  drawnGeometry,
  setSelectedValues,
  disableDrawingMode,
  setIsDrawingModeActive,
  layerType,
  setCurrentRoadsGeometry
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useFetchSelectionToolSelectOptions();
  const dispatch = useDispatch();
  const { prefix, portal } = useParams();
  const stringifyGeometry = useStringifyGeometry();
  const plotLayer = useSelector(getPlotSearchableModel);
  const roadLayer = useSelector(getRoadSearchableModel);
  const { id: mapPortalCompositionId } = useSelector(state =>
    getCurrentComposition(state, prefix, portal)
  );

  const getLayersIds = useCallback(async () => {
    const selectedLayer = layerType === 'road' ? roadLayer : plotLayer;
    if (!drawnGeometry || !selectedLayer) return;
    const { id } = selectedLayer;

    setIsLoading(true);

    const geometry = stringifyGeometry(drawnGeometry);

    const requestBody = {
      operator: 'intersects',
      selectedLayer: id,
      map_portal_composition_id: mapPortalCompositionId,
      type: FORM_TYPES.SELECTION_BY_SHAPE,
      geometry
    };

    const getRoadsNumbers = async (features, ids) => {
      const queryArray = ids.map(id => ({
        lookup: 'exact',
        search_fields: ['id'],
        search: id.toString()
      }));

      const operatorsArray = ids.slice(0, -1).map((_, index) => ({
        operator: 'OR',
        index: index + 1
      }));

      const { data } = await axios.get(`/boleslawiec/spatial_api/finndrogi/`, {
        params: {
          query: JSON.stringify({ query: queryArray }),
          operators: JSON.stringify({ operators: operatorsArray })
        }
      });

      if (!data) return;

      const roadNumbers = data.results?.features.map(
        ({ properties: { numer } }) => numer
      );

      setSelectedValues([...new Set(roadNumbers)]);
      setCurrentRoadsGeometry(features.map(item => item.geometry));
    };

    try {
      const {
        data: { features }
      } = await axios.post(`${prefix}/selection_search/${id}/`, requestBody);

      const ids = features.map(({ id }) => id);
      if (layerType === 'road') {
        getRoadsNumbers(features, ids);
      } else {
        setSelectedValues([...new Set(ids)]);
      }
      disableDrawingMode();
      setIsDrawingModeActive(false);
    } catch (err) {
      dispatch(showError(parseResponseError(err)));
    } finally {
      setIsLoading(false);
    }
  }, [drawnGeometry, mapPortalCompositionId]);

  useEffect(() => {
    getLayersIds();
  }, [getLayersIds]);

  useEffect(() => {
    dispatch(fetchSearchableModels());
  }, []);

  return { isLoading };
};

export default useGetLayersIds;
