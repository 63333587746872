export default function calcProportionalSize({
  width,
  height,
  targetWidth,
  targetHeight
}) {
  if (targetWidth) {
    const proportion = (targetWidth * 100) / width;

    return {
      width: targetWidth,
      height: (height * proportion) / 100
    };
  }

  if (targetHeight) {
    const proportion = (targetHeight * 100) / height;

    return {
      width: (width * proportion) / 100,
      height: targetHeight
    };
  }
}
