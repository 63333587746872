import { useIntl } from 'react-intl';

const useSelectionToolTranslations = () => {
  const { formatMessage } = useIntl();

  const toolName = formatMessage({
    id: 'selection_tool_name',
    defaultMessage: 'Selection',
    description: 'Selection'
  });

  const selectionByShape = formatMessage({
    id: 'selection_tool_selection_by_shape',
    defaultMessage: 'Selection by shape'
  });

  const selectionByPredefinedQuery = formatMessage({
    id: 'selection_tool_selection_by_predefined_query',
    defaultMessage: 'Selection by predefined query'
  });

  const selectionByLayer = formatMessage({
    id: 'selection_tool_selection_by_layer',
    defaultMessage: 'Selection by layer'
  });

  const selectionBySketch = formatMessage({
    id: 'selection_tool_selection_by_sketch',
    defaultMessage: 'Selection by sketch'
  });

  const selectionBySelection = formatMessage({
    id: 'selection_tool_selection_by_selection',
    defaultMessage: 'Selection by selection'
  });

  const selectionBySqlQuery = formatMessage({
    id: 'selection_tool_selection_by_sql_query',
    defaultMessage: 'Selection by sql query'
  });

  const selectionQuery = formatMessage({
    id: 'selection_query',
    defaultMessage: 'Query'
  });

  const booleanTrue = formatMessage({
    id: 'boolean_true',
    defaultMessage: 'True'
  });

  const booleanFalse = formatMessage({
    id: 'boolean_false',
    defaultMessage: 'False'
  });

  return {
    toolName,
    selectionByShape,
    selectionByPredefinedQuery,
    selectionByLayer,
    selectionBySketch,
    selectionBySelection,
    selectionBySqlQuery,
    selectionQuery,
    booleanTrue,
    booleanFalse
  };
};

export default useSelectionToolTranslations;
