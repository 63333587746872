import axios from 'axios';

const getTranslation = code =>
  new Promise((resolve, reject) => {
    axios
      .get(`translations_admin/${code}/`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
export default getTranslation;
