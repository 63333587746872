import { deleteLayer } from '../';

/**
 * Opróżnia grupę warstw oraz usuwa składające się
 * na nią warstwy z grupy rodzica.
 *
 * @param {Leaflet} map - obiekt mapy
 * @param {string} layerGroupId - ID grupy
 */
const emptyLayersGroup = (map, layerGroupId) => {
  const layerGroup = map._layers[layerGroupId]._layers;

  // eslint-disable-next-line no-unused-vars
  for (const layerId in layerGroup) {
    deleteLayer(map, layerId);
  }

  map._layers[layerGroupId]._layers = {};
};

export default emptyLayersGroup;
