import { useContext } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  deleteReportAttachment,
  fetchSingleReport
} from '../../store/actions/reportsActions';

import { Popconfirm, List, Row } from 'antd';
import { Button } from '..';
import { SingleReportContext } from '../../views/AdminSingleReportView/SingleReportContext';
import { useIntl } from 'react-intl';

export const AdminReportAttachments = props => {
  const { formatMessage } = useIntl();

  const { prefix, id } = useParams();
  const { report } = useContext(SingleReportContext);

  const getAttachmentsInList = () =>
    report.properties.attachments.map((attachment, i) => ({
      name: `Załącznik ${i + 1}`,
      uuid: attachment.uuid,
      file: attachment.file
    }));

  if (
    !report ||
    !report.properties ||
    !report.properties.attachments ||
    !report.properties.attachments.length
  )
    return null;

  const deleteAttachment = uuid => {
    props.deleteReportAttachment(uuid).then(r => {
      if (r.status === 204) {
        props.fetchSingleReport(prefix, id, true);
      }
    });
  };

  return (
    <Row>
      <h2>
        {formatMessage({
          id: 'admin_single_report_attachments_titile',
          defaultMessage: 'Attachments'
        })}
      </h2>
      <List
        itemLayout="horizontal"
        style={{ width: '50%' }}
        size="large"
        dataSource={getAttachmentsInList()}
        renderItem={item => (
          <List.Item style={{ fontWeight: 'bold' }}>
            {item.name}
            <Popconfirm
              title={formatMessage({
                id: 'admin_single_report_attachments_popconfirm',
                defaultMessage:
                  'Are you sure you want to delete this attachment?'
              })}
              onConfirm={() => deleteAttachment(item.uuid)}
              okText={formatMessage({
                id: 'general_yes',
                defaultMessage: 'Yes'
              })}
              cancelText={formatMessage({
                id: 'general_no',
                defaultMessage: 'no'
              })}
            >
              <Button style={{ float: 'right' }} variant="danger">
                {formatMessage({
                  id: 'admin_single_report_attachments_delete',
                  defaultMessage: 'Delete'
                })}
              </Button>
            </Popconfirm>
            <Button
              style={{
                float: 'right',
                marginRight: '5px'
              }}
              variant="primary"
              onClick={() => {
                window.open(item.file, '_blank');
              }}
            >
              {formatMessage({
                id: 'admin_single_report_attachments_preview',
                defaultMessage: 'Preview'
              })}
            </Button>
          </List.Item>
        )}
      />
    </Row>
  );
};

AdminReportAttachments.propTypes = {
  deleteReportAttachment: PropTypes.func.isRequired,
  fetchSingleReport: PropTypes.func
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteReportAttachment,
      fetchSingleReport
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReportAttachments);
