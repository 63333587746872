import axios from 'axios';

const getModelName = async modelId => {
  const { data: searchableModels } = await axios.get('searchable_models/');
  const layer = searchableModels.find(layer => layer.id === modelId);
  return layer.model;
};

export const getModelsData = async district => {
  const { data } = await axios.get(`${district}/spatial_api/`);
  return data;
};

const getModelDataUrl = (layers, layerName) => {
  let layerDataUrl;

  Object.keys(layers).find(key => {
    const layer = layers[key];
    if (layer.name === layerName) return (layerDataUrl = layer.url);
    return false;
  });

  return layerDataUrl;
};

const getModelData = async ({ modelId, objectId, name, district, getUrl }) => {
  const layerName = name || (await getModelName(modelId));
  const layers = await getModelsData(district);
  const layerDataUrl = getModelDataUrl(layers, layerName);

  const url = objectId ? layerDataUrl + objectId : layerDataUrl;
  if (getUrl) return url;

  const { data: layerData } = await axios.get(url);
  return layerData;
};

export default getModelData;
