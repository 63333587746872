export const JOB_STATUS = {
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS'
};

export const GLOBAL_SETTINGS = {
  NAVIGATION_IN_TOOLBAR: 'navigation_in_toolbar',
  ENABLE_DEFECT_VOTE: 'enable_defect_vote',
  ENABLE_SOCIAL_SHARE_COMPOSITIONS: 'enable_social_share_compositions',
  TEMPORARILY_UNAVAILABLE_MAP_PORTAL_TEXT:
    'temporarily_unavailable_map_portal_text',
  UNOFFICIAL_CHARACTER_OF_PORTAL_INFO_TEXT:
    'unofficial_character_of_portal_info_text',
  HOME_PAGE_IS_DISTRICT_LIST: 'home_page_is_districts_list',
  ENABLE_SHORTEN_URL: 'enable_shorten_url',
  ENABLE_USERS_TO_CREATE_ACCOUNTS: 'enable_users_to_create_accounts',
  DISABLE_PLOT_IDENTIFICATION_NONLOGGED:
    'disable_plot_identification_nonlogged',
  HOME_PAGE_LINK: 'home_page_link',
  PRINTOUT_CLOSURE_TEXT: 'printout_closure_text',
  WMS_INFO_RADIUS: 'wms_info_radius',
  MAX_PLOT_READINGS: 'max_plot_readings',
  CURRENT_THEME: 'current_theme'
};

export const IDENTIFICATION_TYPE = {
  BOTH: 'both',
  PLOT_INFO: 'plot_info',
  OBJECT_INFO: 'object_info'
};

export const SEARCH_ENGINE_SOURCES = {
  GUGIK: 'gugik',
  INTERNAL: 'internal'
};

export default IDENTIFICATION_TYPE;
