import { useIntl } from 'react-intl';

// Example of argument which is passed to useTranslations hook
//   {
//     key: {
//       id: string,
//       defaultMessage: string
//     },
//   }

const useTranslations = translations => {
  const { formatMessage } = useIntl();

  const translationKeys = {};

  Object.keys(translations).forEach(key => {
    translationKeys[key] = formatMessage({
      id: translations[key].id,
      defaultMessage: translations[key].defaultMessage
    });
  });

  return translationKeys;
};

export default useTranslations;
