import PropTypes from 'prop-types';
import { Input } from 'antd';

import debounce from 'lodash/debounce';
import './CustomSearchInput.less';
const Search = Input.Search;

export const CustomSearchInput = ({
  placeholder,
  changePageNumber,
  changeFilterValue,
  onSearch,
  halfRow,
  wrapInput = false
}) => {
  const debouceSetFilterValue = debounce(
    value => changeFilterValue(value),
    500
  );

  const handleSearch = e => {
    debouceSetFilterValue(e.target.value);
    if (changePageNumber) changePageNumber(1);
  };

  if (wrapInput) {
    return (
      <label>
        <Search
          className={halfRow ? 'half-row-custom-serach' : null}
          placeholder={placeholder}
          onChange={handleSearch}
          onPressEnter={onSearch}
          id="siteSearch"
        />
      </label>
    );
  }

  return (
    <>
      <label for="siteSearch" class="sr-only">
        {placeholder}
      </label>
      <Search
        className={halfRow ? 'half-row-custom-serach' : null}
        placeholder={placeholder}
        onChange={handleSearch}
        onPressEnter={onSearch}
        id="siteSearch"
      />
    </>
  );
};

CustomSearchInput.propTypes = {
  changeFilterValue: PropTypes.func.isRequired,
  changePageNumber: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string
};

export default CustomSearchInput;
