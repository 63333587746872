import { adminRoutePaths } from '../../router/admin/paths';

const addPrefix = (prefix, url) => `/${prefix}/admin${url}`;

const getUrlWithoutParams = url => url.split(':')[0];

const isUrlCommon = (url, path) => url.includes(getUrlWithoutParams(path));

export const checkUrl = (prefix, url) => {
  const {
    editSelectedMapPortal,
    browseMapPortals,
    confirmEdits,
    confirmEditsDetails,
    editSelectedTheme,
    browseThemes,
    singleReport,
    reports,
    groupsEdit,
    groups,
    projectSettings,
    browseProjects
  } = adminRoutePaths;

  if (isUrlCommon(url, editSelectedMapPortal))
    return addPrefix(prefix, browseMapPortals);

  if (isUrlCommon(url, confirmEditsDetails))
    return addPrefix(prefix, confirmEdits);

  if (isUrlCommon(url, editSelectedTheme))
    return addPrefix(prefix, browseThemes);

  if (isUrlCommon(url, singleReport)) return addPrefix(prefix, reports);

  if (isUrlCommon(url, projectSettings))
    return addPrefix(prefix, browseProjects);

  if (isUrlCommon(url, groupsEdit)) return addPrefix(prefix, groups);

  return url;
};
