import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { districtSelectors, globalSelectors } from '../../store/selectors';
import { dimensionsTypes } from '../WindowSizeObserver';

import Tooltip from '../Tooltip';
import { BaseMapItemCover } from '../';

import './BaseMapItem.less';

export const BaseMapItem = ({
  coverImage,
  setLayer,
  baseMapChangerAction,
  mapName,
  forceListRerender
}) => {
  const { formatMessage } = useIntl();
  const { portal, prefix } = useParams();
  const { getWindowDimensionsType } = globalSelectors;
  const { MOBILE } = dimensionsTypes;

  const clickAction = () => {
    setLayer();
    baseMapChangerAction();
  };

  const appeareance = useSelector(state =>
    districtSelectors.getPortalAppearance(state, prefix, portal)
  );

  const windowDimensionsType = useSelector(state =>
    getWindowDimensionsType(state)
  );

  const changeMapAriaText = `${formatMessage({
    id: 'tooltip_change_basemap',
    defaultMessage: 'Change base map'
  })} - ${mapName}`;

  const backgroundColor = appeareance?.basemap_tile_border_color;

  const isMobile = windowDimensionsType === MOBILE;

  return (
    <Tooltip
      placement="left"
      title={mapName}
      defaultVisible={isMobile}
      getPopupContainer={isMobile ? node => node : undefined}
    >
      <button
        style={{ backgroundColor }}
        className="base-map-tile"
        onClick={clickAction}
      >
        <span className="sr-only">{changeMapAriaText}</span>
        <BaseMapItemCover coverImage={coverImage} onLoad={forceListRerender} />
      </button>
    </Tooltip>
  );
};

BaseMapItem.propTypes = {
  coverImage: PropTypes.string,
  setLayer: PropTypes.func.isRequired,
  baseMapChangerAction: PropTypes.func.isRequired,
  mapName: PropTypes.string.isRequired
};

export default BaseMapItem;
