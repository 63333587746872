import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import './ColorsPreview.less';

const ColorsPreview = props => (
  <div className="color-preview">
    {props.colors.map(color => (
      <span key={Math.random()} style={{ background: color }} />
    ))}
  </div>
);

ColorsPreview.defaultProps = {
  colors: []
};

ColorsPreview.propTypes = {
  colors: PropTypes.oneOfType([PropTypes.array, ImmutablePropTypes.list])
};

export default ColorsPreview;
