import PropTypes from 'prop-types';

import { Row, Col } from 'antd';
import { MapPortalCompositionsMenu } from '../';

import './CompositionEditorLayout.less';

const CompositionEditorLayout = ({
  subtitle,
  onCompositionChange,
  mapPortalId,
  children
}) => (
  <Row>
    {subtitle && (
      <Col span={24}>
        <span className="admin-panel-subtitle">{subtitle}</span>
      </Col>
    )}
    <Col span={6}>
      <MapPortalCompositionsMenu
        onChange={onCompositionChange}
        mapPortalId={mapPortalId}
      />
    </Col>
    <Col span={18} className="composition-editor-layout-children">
      {children}
    </Col>
  </Row>
);

CompositionEditorLayout.propTypes = {
  subtitle: PropTypes.string,
  mapPortalId: PropTypes.string,
  onCompositionChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default CompositionEditorLayout;
