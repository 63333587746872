export const PRINT_RESOLUTION_VALUES = {
  DPI150: 150,
  DPI300: 300,
  DPI600: 600
};

export const printPreviewMapWithLegendWrapperId =
  'printPreviewMapWithLegendWrapper';

export const printPreviewMapWrapperId = 'printPreviewMapWrapper';

export const printConfig = {
  paperSizes: {
    A0Portrait: {
      // Basic width and height for 90 DPI
      width: '2980px',
      height: '4213px',
      widthCm: '84.1cm',
      heightCm: '118.9cm',
      printMargins: '20mm 0',
      pdfMargins: {
        x: 18,
        y: 70
      }
    },
    A0Landscape: {
      width: '4213px',
      height: '2980px',
      widthCm: '118.9cm',
      heightCm: '84.1cm',
      printMargins: '20mm 0',
      pdfMargins: {
        x: 70,
        y: 19
      }
    },
    A1Portrait: {
      // Basic width and height for 90 DPI
      width: '2105px',
      height: '2979px',
      widthCm: '59.4cm',
      heightCm: '84.1cm',
      printMargins: '21mm 0',
      pdfMargins: {
        x: 18,
        y: 25
      }
    },
    A1Landscape: {
      width: '2980px',
      height: '2104px',
      widthCm: '84.1cm',
      heightCm: '59.4cm',
      printMargins: '16mm 0',
      pdfMargins: {
        x: 26,
        y: 19
      }
    },
    A2Portrait: {
      width: '1488px',
      height: '2104px',
      widthCm: '42cm',
      heightCm: '59.4cm',
      printMargins: '16mm 0',
      pdfMargins: {
        x: 13,
        y: 18
      }
    },
    A2Landscape: {
      width: '2105px',
      height: '1488px',
      widthCm: '59.4cm',
      heightCm: '42cm',
      printMargins: '13mm 0',
      pdfMargins: {
        x: 18,
        y: 13
      }
    },
    A3Portrait: {
      width: '1052px',
      height: '1487px',
      widthCm: '29.7cm',
      heightCm: '42cm',
      printMargins: '11mm 0',
      pdfMargins: {
        x: 9,
        y: 13
      }
    },
    A3Landscape: {
      width: '1488px',
      height: '1052px',
      widthCm: '42cm',
      heightCm: '29.7cm',
      printMargins: '9mm 0',
      pdfMargins: {
        x: 13,
        y: 9
      }
    },
    A4Portrait: {
      width: '744px',
      height: '1052px',
      widthCm: '21cm',
      heightCm: '29.7cm',
      printMargins: '7mm 0',
      pdfMargins: {
        x: 7,
        y: 9
      }
    },
    A4Landscape: {
      width: '1052px',
      height: '743px',
      widthCm: '29.7cm',
      heightCm: '21cm',
      printMargins: '5mm 0',
      pdfMargins: {
        x: 9,
        y: 7
      }
    },
    A5Portrait: {
      width: '524px',
      height: '743px',
      widthCm: '14.8cm',
      heightCm: '21cm',
      printMargins: '4mm 0',
      pdfMargins: {
        x: 5,
        y: 6
      }
    },
    A5Landscape: {
      width: '744px',
      height: '524px',
      widthCm: '21cm',
      heightCm: '14.8cm',
      printMargins: '3mm 0',
      pdfMargins: {
        x: 7,
        y: 5
      }
    }
  },
  printTargets: {
    printer: 'PRINTER',
    pdf: 'PDF',
    jpg: 'JPG',
    png: 'PNG',
    bmp: 'BMP',
    html: 'HTML'
  },
  printingFormats: {
    A0: 'A0',
    A1: 'A1',
    A2: 'A2',
    A3: 'A3',
    A4: 'A4',
    A5: 'A5'
  },
  printingResolutionOptions: [150, 300, 600],
  mapTitlePositions: {
    none: 'none',
    top: 'top',
    bottom: 'bottom'
  },
  fontSizeOptions: Array.from({ length: 38 }, (_, i) => i + 11)
};
