import { Suspense } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { HandleError, LoaderFullScreen } from '../';
import GlobalFooter from '../GlobalFooter';

const SuspenseWrapper = ({
  children,
  fallback,
  showErrorBoard,
  withFooter
}) => {
  const { formatMessage } = useIntl();
  const fallbackComponent = fallback || (
    <LoaderFullScreen
      tip={formatMessage({
        id: 'general_loading',
        defaultMessage: 'Loading...'
      })}
    />
  );

  return (
    <HandleError showBoard={showErrorBoard}>
      <Suspense fallback={fallbackComponent}>
        <>
          {children}
          {withFooter && <GlobalFooter />}
        </>
      </Suspense>
    </HandleError>
  );
};

SuspenseWrapper.propTypes = {
  children: PropTypes.node,
  showErrorBoard: PropTypes.bool,
  fallback: PropTypes.node,
  withFooter: PropTypes.bool
};

export default SuspenseWrapper;
