import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { MapPortalCompositionShape } from './composition';

export const MapPortalPlainObjShape = {
  compositions: PropTypes.array,
  description: PropTypes.string,
  default_composition: PropTypes.number,
  district: PropTypes.number.isRequired,
  has_bdot: PropTypes.bool.isRequired,
  has_ortho: PropTypes.bool.isRequired,
  has_osm: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  is_published: PropTypes.bool.isRequired,
  map_portal_compositions: PropTypes.arrayOf(MapPortalCompositionShape),
  max_zoom: PropTypes.number.isRequired,
  min_zoom: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  required_role: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  use_identification_tool_from_toolbar: PropTypes.bool.isRequired,
  short_name: PropTypes.string.isRequired,
  thumbnailImage: PropTypes.string,
  toolbar_map_tools: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  second_title: PropTypes.string,
  logo: PropTypes.string,
  rpo_logo: PropTypes.string,
  rolled_up_details: PropTypes.bool.isRequired,
  form_presentation_after_change_composition: PropTypes.string.isRequired
};

export const MapPortalShape = ImmutablePropTypes.mapContains(
  MapPortalPlainObjShape
);

export const MapPortalsShape = ImmutablePropTypes.listOf(MapPortalShape);
