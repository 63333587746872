import PropTypes from 'prop-types';

import { Icon } from 'antd';

import './TextWithIcon.less';

const TextWithIcon = props => (
  <span className={['text-with-icon', props.className].join(' ')}>
    <span>
      <Icon type={props.iconName} />
    </span>
    <span>{props.children}</span>
  </span>
);

TextWithIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default TextWithIcon;
