import axios from 'axios';

import { showError, showSuccess } from './globalActions';

import {
  mapPortalSelectors,
  sketchbookSelectors,
  userSelectors
} from '../selectors';

import { parseResponseError } from '../../utils/lib';

import {
  FETCH_BASIC_SHAPES_STARTED,
  FETCH_BASIC_SHAPES_SUCCESS,
  FETCH_BASIC_SHAPES_FAILED,
  FETCH_USER_DEFINED_SHAPES_STARTED,
  FETCH_USER_DEFINED_SHAPES_SUCCESS,
  FETCH_USER_DEFINED_SHAPES_FAILED,
  UPDATE_SHAPE_STARTED,
  UPDATE_SHAPE_SUCCESS,
  UPDATE_SHAPE_FAILED,
  ADD_NEW_SHAPE_STARTED,
  ADD_NEW_SHAPE_SUCCESS,
  ADD_NEW_SHAPE_FAILED,
  DELETE_USER_SHAPE_STARTED,
  DELETE_USER_SHAPE_SUCCESS,
  DELETE_USER_SHAPE_FAILED
} from '../constants/sketchbookActionTypes';

const {
  getShapesDistrict,
  getShapesPortal,
  getUserId,
  getBasicShapes,
  getUserDefinedShapes
} = sketchbookSelectors;

// *** Fetch basic shapes ***
const fetchBasicShapesStarted = () => ({
  type: FETCH_BASIC_SHAPES_STARTED
});

const fetchBasicShapesSuccess = ({ district, portal, basicShapes }) => ({
  type: FETCH_BASIC_SHAPES_SUCCESS,
  district,
  portal,
  basicShapes
});

const fetchBasicShapesFailed = () => ({
  type: FETCH_BASIC_SHAPES_FAILED
});

const basicShapesAreFetched = ({ state, district, portal }) => {
  const shapesDistrict = getShapesDistrict(state);
  const shapesPortal = getShapesPortal(state);
  const basicShapes = getBasicShapes(state);

  return (
    shapesDistrict === district &&
    shapesPortal === portal &&
    !!basicShapes.length
  );
};

export const fetchBasicShapes = ({ district, portal, portalId, unique }) => (
  dispatch,
  getState
) => {
  const state = getState();
  if (basicShapesAreFetched({ state, district, portal })) return;

  dispatch(fetchBasicShapesStarted());

  const mapPortalId =
    portalId ?? mapPortalSelectors.getMapPortalId(state, district, portal);

  axios
    .get(`${district}/sketchbook/${mapPortalId}/basic_shapes/`)
    .then(({ data }) => {
      dispatch(
        fetchBasicShapesSuccess({
          district,
          portal,
          basicShapes: data
        })
      );
    })
    .catch(error => {
      const errorMessage = parseResponseError(error, {
        unique
      });

      dispatch(showError(errorMessage));
      dispatch(fetchBasicShapesFailed());
    });
};

// *** Fetch user defined shapes ***
const fetchUserDefinedShapesStarted = () => ({
  type: FETCH_USER_DEFINED_SHAPES_STARTED
});

const fetchUserDefinedShapesSuccess = ({
  userId,
  district,
  portal,
  userDefinedShapes
}) => ({
  type: FETCH_USER_DEFINED_SHAPES_SUCCESS,
  userId,
  district,
  portal,
  userDefinedShapes
});

const fetchUserDefinedShapesFailed = () => ({
  type: FETCH_USER_DEFINED_SHAPES_FAILED
});

const userDefinedShapesAreFetched = ({ state, userId, district, portal }) => {
  const shapesDistrict = getShapesDistrict(state);
  const shapesPortal = getShapesPortal(state);
  const userDefinedShapes = getUserDefinedShapes(state);
  // Defined shapes of the user with specified id
  const definedShapesOfUserId = getUserId(state);

  return (
    shapesDistrict === district &&
    shapesPortal === portal &&
    definedShapesOfUserId === userId &&
    !!userDefinedShapes.length
  );
};

export const fetchUserDefinedShapes = ({
  district,
  portal,
  portalId,
  unique
}) => (dispatch, getState) => {
  const state = getState();
  const userId = userSelectors.userData(state)?.toJS().id;

  if (
    !userId ||
    userDefinedShapesAreFetched({ state, userId, district, portal })
  )
    return;

  dispatch(fetchUserDefinedShapesStarted());

  const mapPortalId =
    portalId ?? mapPortalSelectors.getMapPortalId(state, district, portal);

  axios
    .get(`${district}/sketchbook/${mapPortalId}/user_defined_shapes/`)
    .then(({ data }) => {
      dispatch(
        fetchUserDefinedShapesSuccess({
          userId,
          district,
          portal,
          userDefinedShapes: data
        })
      );
    })
    .catch(error => {
      const errorMessage = parseResponseError(error, {
        unique
      });

      dispatch(showError(errorMessage));
      dispatch(fetchUserDefinedShapesFailed());
    });
};

// *** Update shape ***
const updateShapeStarted = () => ({
  type: UPDATE_SHAPE_STARTED
});

const updateShapeSuccess = ({ updatedShape }) => ({
  type: UPDATE_SHAPE_SUCCESS,
  updatedShape
});

const updateShapeFailed = () => ({
  type: UPDATE_SHAPE_FAILED
});

export const updateShape = ({
  district,
  portalId,
  unique,
  shapeId,
  requestBody
}) => (dispatch, getState) => {
  dispatch(updateShapeStarted());

  const isBasicShape = 'is_enabled' in requestBody;
  const shapeType = isBasicShape ? 'basic_shapes' : 'user_defined_shapes';

  const params = {
    headers: {
      'Content-Type': isBasicShape ? 'application/json' : 'multipart/form-data'
    }
  };

  axios
    .patch(
      `${district}/sketchbook/${portalId}/${shapeType}/${shapeId}/`,
      requestBody,
      params
    )
    .then(({ data }) => {
      dispatch(showSuccess('Pomyślnie zaktualizowano kształt'));
      dispatch(
        updateShapeSuccess({
          updatedShape: data
        })
      );
    })
    .catch(error => {
      const errorMessage = parseResponseError(error, {
        unique
      });

      dispatch(showError(errorMessage));
      dispatch(updateShapeFailed());
    });
};

// *** Add new shape ***
const addNewShapeStarted = () => ({
  type: ADD_NEW_SHAPE_STARTED
});

const addNewShapeSuccess = ({ addedShape }) => ({
  type: ADD_NEW_SHAPE_SUCCESS,
  addedShape
});

const addNewShapeFailed = () => ({
  type: ADD_NEW_SHAPE_FAILED
});

export const addNewShape = ({ district, portalId, unique, requestBody }) => (
  dispatch,
  getState
) => {
  dispatch(addNewShapeStarted());

  const params = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  axios
    .post(
      `${district}/sketchbook/${portalId}/user_defined_shapes/`,
      requestBody,
      params
    )
    .then(({ data }) => {
      dispatch(showSuccess('Pomyślnie dodano kształt'));
      dispatch(
        addNewShapeSuccess({
          addedShape: data
        })
      );
    })
    .catch(error => {
      const errorMessage = parseResponseError(error, {
        unique
      });

      dispatch(showError(errorMessage));
      dispatch(addNewShapeFailed());
    });
};

// *** Delete user shape ***
const deleteUserShapeStarted = () => ({
  type: DELETE_USER_SHAPE_STARTED
});

const deleteUserShapeSuccess = ({ shapeId }) => ({
  type: DELETE_USER_SHAPE_SUCCESS,
  shapeId
});

const deleteUserShapeFailed = () => ({
  type: DELETE_USER_SHAPE_FAILED
});

export const deleteUserShape = ({ district, portalId, shapeId, unique }) => (
  dispatch,
  getState
) => {
  dispatch(deleteUserShapeStarted());

  axios
    .delete(
      `${district}/sketchbook/${portalId}/user_defined_shapes/${shapeId}/`
    )
    .then(() => {
      dispatch(showSuccess('Pomyślnie usunięto kształt'));
      dispatch(
        deleteUserShapeSuccess({
          shapeId
        })
      );
    })
    .catch(error => {
      const errorMessage = parseResponseError(error, {
        unique
      });

      dispatch(showError(errorMessage));
      dispatch(deleteUserShapeFailed());
    });
};
