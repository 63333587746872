import { Map, List } from 'immutable';
import {
  FETCH_TOPICAL_SEARCH_TOOLS_SUCCESS,
  ADD_TOPICAL_SEARCH_TOOL_SUCCESS,
  EDIT_TOPICAL_SEARCH_TOOL_SUCCESS,
  EDIT_COMPOSITION_TOPICAL_SEARCH_TOOL,
  ASSIGN_TOPICAL_SEARCH_TOOL_TO_COMPOSITION_SUCCESS,
  FETCH_COMPOSITION_TOPICAL_SEARCH_TOOLS_SUCCESS,
  GET_TOPICAL_SEARCH_TOOL_COLUMNS_SUCCESS,
  GET_TOPICAL_SEARCH_TOOL_RESULT_COLUMNS_SUCCESS,
  ADD_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS,
  EDIT_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS,
  DELETE_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS,
  ADD_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS,
  EDIT_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS,
  DELETE_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS,
  GET_LAYER_LOOKUP_FIELDS_SUCCESS,
  GET_LAYER_FIELD_SEARCH_TYPES_SUCCESS,
  GET_LAYER_RESULT_FIELDS_SUCCESS,
  DELETE_TOPICAL_SEARCH_TOOL_SUCCESS,
  UNASSIGN_TOPICAL_SEARCH_TOOL_FROM_COMPOSITION_SUCCESS,
  CHECK_GEONETWORK_SEARCH_TOOL_AVAILABILITY_SUCCESS,
  TOPICAL_SEARCH_SUCCESS,
  FETCH_TOPICAL_SEARCH_RESULTS,
  STOP_FETCH_TOPICAL_SEARCH_RESULTS,
  CLEAR_TOPICAL_SEARCH_RESULTS,
  SET_SEARCHED_COL_VAL,
  TOPICAL_SEARCH_GEOMETRY_SUCCESS,
  TOPICAL_SEARCH_GEOMETRY_ALL_SUCCESS,
  CLEAR_TOPICAL_SEARCH_GEOMETRY_ALL_RESULTS
} from '../constants/searchToolsActionTypes';

export const initialState = Map({
  topicalSearchTools: List([]),
  topicalSearchToolsColumns: Map({}),
  topicalSearchToolsResultColumns: Map({}),
  compositionSearchTools: Map({}),
  isCompositionSearchToolsFetched: Map({}),
  layerLookupFields: Map({}),
  layerResultFields: Map({}),
  layerFieldSearchTypes: Map({}),
  geonetworkSearchToolAvailability: Map({}),
  topicalSearchResults: List([]),
  topicalSearchGeometryResults: List([]),
  topicalSearchGeometryAllResults: List([]),
  topicalSearchResultsNextPage: null,
  fetchingTopicalSearchResults: false,
  searchedColVal: Map({})
});

const fetchTopicalSearchToolsSuccess = (state, action) =>
  state.update('topicalSearchTools', list => {
    const newList = [...list];
    newList.push(...action.topicalSearchTools);
    return List(newList);
  });

const addTopicalSearchToolSuccess = (state, action) =>
  state.update('topicalSearchTools', list => {
    const newList = [...list];
    newList.push(action.tool);
    return List(newList);
  });

const editTopicalSearchToolSuccess = (state, { payload }) =>
  state.update('topicalSearchTools', list => {
    const editedIndex = list.findIndex(item => item.id === payload.tool.id);
    return list.set(editedIndex, payload.tool);
  });

const editCompositionTopicalSearchTool = (state, { payload }) => {
  return state.updateIn(
    ['compositionSearchTools', payload.district, payload.compositionId],
    list => {
      const editedIndex = list.findIndex(item => item.id === payload.tool.id);
      return list.set(editedIndex, payload.tool);
    }
  );
};

const assignTopicalSearchToolToCompositionSuccess = (state, { payload }) =>
  state.updateIn(
    ['compositionSearchTools', payload.district, payload.compositionId],
    List(),
    list => {
      const newList = [...list];
      newList.push(payload.tool);
      return List(newList);
    }
  );

const fetchCompositionTopicalSearchToolsSuccess = (state, action) => {
  const { district, compositionId, tools } = action;
  const newState = state.setIn(
    ['compositionSearchTools', district, compositionId],
    List(tools)
  );
  return newState.setIn(
    ['isCompositionSearchToolsFetched', district, compositionId],
    true
  );
};

const getTopicalSearchToolColumnsSuccess = (state, { payload }) =>
  state.setIn(
    ['topicalSearchToolsColumns', payload.toolId],
    List(payload.columns)
  );

const getTopicalSearchToolResultColumnsSuccess = (state, { payload }) =>
  state.setIn(
    ['topicalSearchToolsResultColumns', payload.toolId],
    List(payload.columns)
  );

const addTopicalSearchToolColumnSuccess = (state, action) =>
  state.updateIn(
    ['topicalSearchToolsColumns', action.column.topical_search_tool],
    List(),
    list => {
      const newList = [...list];
      newList.push(action.column);
      return List(newList);
    }
  );

const editTopicalSearchToolColumnSuccess = (state, action) => {
  return state.updateIn(
    ['topicalSearchToolsColumns', action.column.topical_search_tool],
    list => {
      const newList = [...list];
      const indexToEdit = newList.findIndex(
        column => column.id === action.column.id
      );
      newList[indexToEdit] = action.column;
      return List(newList);
    }
  );
};

const deleteTopicalSearchToolColumnSuccess = (state, { payload }) =>
  state.updateIn(['topicalSearchToolsColumns', payload.toolId], list =>
    list.filter(column => column.id !== payload.columnId)
  );

const addTopicalSearchToolResultColumnSuccess = (state, action) =>
  state.updateIn(
    ['topicalSearchToolsResultColumns', action.column.topical_search_tool],
    List(),
    list => {
      const newList = [...list];
      newList.push(action.column);
      return List(newList);
    }
  );

const editTopicalSearchToolResultColumnSuccess = (state, action) => {
  return state.updateIn(
    ['topicalSearchToolsResultColumns', action.column.topical_search_tool],
    list => {
      const newList = [...list];
      const indexToEdit = newList.findIndex(
        column => column.id === action.column.id
      );
      newList[indexToEdit] = action.column;
      return List(newList);
    }
  );
};

const deleteTopicalSearchToolResultColumnSuccess = (state, { payload }) =>
  state.updateIn(['topicalSearchToolsResultColumns', payload.toolId], list =>
    list.filter(column => column.id !== payload.columnId)
  );

const getLayerLookupFieldsSuccess = (state, { payload }) =>
  state.setIn(
    ['layerLookupFields', payload.contentTypeId],
    List(payload.fields)
  );

const getLayerResultFieldsSuccess = (state, { payload }) =>
  state.setIn(
    ['layerResultFields', payload.contentTypeId],
    List(payload.fields)
  );

const getLayerFieldSearchTypesSuccess = (state, { payload }) =>
  state.setIn(
    ['layerFieldSearchTypes', payload.contentTypeId, payload.fieldName],
    List(payload.searchTypes)
  );

const deleteTopicalSearchToolsSuccess = (state, action) => {
  const { district, toolId } = action;
  let newState = state.update('topicalSearchTools', list =>
    list.filter(topicalSearchTool => topicalSearchTool.id !== toolId)
  );

  const compositions = state.getIn(['compositionSearchTools', district]).toJS();
  const compositionsId = [];
  Object.keys(compositions).forEach(key => {
    const val = compositions[key];
    if (
      val.findIndex(topicalSearchTool => topicalSearchTool.id === toolId) !== -1
    )
      compositionsId.push(key);
  });
  compositionsId.forEach(compositionId => {
    newState = newState.updateIn(
      ['compositionSearchTools', district, Number(compositionId)],
      list => list.filter(topicalSearchTool => topicalSearchTool.id !== toolId)
    );
  });
  return newState;
};

const unassignTopicalSearchToolFromCompositionSuccess = (
  state,
  { payload }
) => {
  const { district, compositionId, toolId } = payload;

  return state.updateIn(
    ['compositionSearchTools', district, compositionId],
    list => list.filter(topicalSearchTool => topicalSearchTool.id !== toolId)
  );
};

const checkGeonetworkSearchToolAvailabilitySuccess = (state, { payload }) =>
  state.update('geonetworkSearchToolAvailability', map => {
    const newMap = { ...map.toJS() };
    newMap[payload.district] = payload.available;
    return Map(newMap);
  });

const fetchTopicalSearchResultsSuccess = (state, action) =>
  state.merge(
    Map({
      topicalSearchResults: List(action.searchResults)
    })
  );

const fetchTopicalSearchResults = state =>
  state.merge(
    Map({
      fetchingTopicalSearchResults: true
    })
  );

const stopFetchingTopicalSearchResults = state =>
  state.merge(
    Map({
      fetchingTopicalSearchResults: false
    })
  );

const clearTopicalSearchResults = state =>
  state.merge(
    Map({
      topicalSearchResults: List([])
    })
  );

const setSearchedColVal = (state, action) =>
  state.merge(
    Map({
      searchedColVal: Map(action.payload)
    })
  );

const fetchTopicalSearchGeometryResultsSuccess = (state, action) =>
  state.merge(
    Map({
      topicalSearchGeometryResults: List(action.searchResults)
    })
  );

const fetchTopicalSearchGeometryAllResultsSuccess = (state, action) =>
  state.merge(
    Map({
      topicalSearchGeometryAllResults: List(action.searchResults)
    })
  );

const clearTopicalSearchGeometryAllResults = state =>
  state.merge(
    Map({
      topicalSearchGeometryAllResults: List([])
    })
  );

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_TOPICAL_SEARCH_TOOLS_SUCCESS]: fetchTopicalSearchToolsSuccess,
      [ADD_TOPICAL_SEARCH_TOOL_SUCCESS]: addTopicalSearchToolSuccess,
      [EDIT_TOPICAL_SEARCH_TOOL_SUCCESS]: editTopicalSearchToolSuccess,
      [EDIT_COMPOSITION_TOPICAL_SEARCH_TOOL]: editCompositionTopicalSearchTool,
      [ASSIGN_TOPICAL_SEARCH_TOOL_TO_COMPOSITION_SUCCESS]: assignTopicalSearchToolToCompositionSuccess,
      [FETCH_COMPOSITION_TOPICAL_SEARCH_TOOLS_SUCCESS]: fetchCompositionTopicalSearchToolsSuccess,
      [GET_TOPICAL_SEARCH_TOOL_COLUMNS_SUCCESS]: getTopicalSearchToolColumnsSuccess,
      [GET_TOPICAL_SEARCH_TOOL_RESULT_COLUMNS_SUCCESS]: getTopicalSearchToolResultColumnsSuccess,
      [ADD_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS]: addTopicalSearchToolColumnSuccess,
      [EDIT_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS]: editTopicalSearchToolColumnSuccess,
      [DELETE_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS]: deleteTopicalSearchToolColumnSuccess,
      [ADD_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS]: addTopicalSearchToolResultColumnSuccess,
      [EDIT_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS]: editTopicalSearchToolResultColumnSuccess,
      [DELETE_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS]: deleteTopicalSearchToolResultColumnSuccess,
      [GET_LAYER_LOOKUP_FIELDS_SUCCESS]: getLayerLookupFieldsSuccess,
      [GET_LAYER_FIELD_SEARCH_TYPES_SUCCESS]: getLayerFieldSearchTypesSuccess,
      [GET_LAYER_RESULT_FIELDS_SUCCESS]: getLayerResultFieldsSuccess,
      [DELETE_TOPICAL_SEARCH_TOOL_SUCCESS]: deleteTopicalSearchToolsSuccess,
      [UNASSIGN_TOPICAL_SEARCH_TOOL_FROM_COMPOSITION_SUCCESS]: unassignTopicalSearchToolFromCompositionSuccess,
      [CHECK_GEONETWORK_SEARCH_TOOL_AVAILABILITY_SUCCESS]: checkGeonetworkSearchToolAvailabilitySuccess,
      [TOPICAL_SEARCH_SUCCESS]: fetchTopicalSearchResultsSuccess,
      [TOPICAL_SEARCH_GEOMETRY_SUCCESS]: fetchTopicalSearchGeometryResultsSuccess,
      [TOPICAL_SEARCH_GEOMETRY_ALL_SUCCESS]: fetchTopicalSearchGeometryAllResultsSuccess,
      [FETCH_TOPICAL_SEARCH_RESULTS]: fetchTopicalSearchResults,
      [STOP_FETCH_TOPICAL_SEARCH_RESULTS]: stopFetchingTopicalSearchResults,
      [CLEAR_TOPICAL_SEARCH_RESULTS]: clearTopicalSearchResults,
      [CLEAR_TOPICAL_SEARCH_GEOMETRY_ALL_RESULTS]: clearTopicalSearchGeometryAllResults,
      [SET_SEARCHED_COL_VAL]: setSearchedColVal
    }[action.type] || (s => s)
  )(state, action);
}
