/**
 * Z przekazanego stringu usuwa słowa rozdzielone separatorem do
 * określonego indeksu i zwraca nowy string.
 *
 * shellSplitString('a_b_c_d', 2, '_') => 'c_d'
 *
 * @param {string} str - string do rozdzielenia
 * @param {number} from - numer indeksu do którego rozdzielać string
 * @param {string} separator - separator na którym string ma zostać podzielony
 * @return {string}
 */
const shellSplitString = (str, from, separator) =>
  str
    .split(separator)
    .slice(from)
    .join(separator);

export default shellSplitString;
