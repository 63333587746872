import PropTypes from 'prop-types';

import './ButtonDividedGroup.less';

const ButtonDividedGroup = props => (
  <div className={['btn-divided-group', props.className].join(' ')}>
    <div className="btn-divided-group-left">{props.leftSide}</div>
    <div className="btn-divided-group-right">{props.rightSide}</div>
  </div>
);

ButtonDividedGroup.propTypes = {
  className: PropTypes.string,
  leftSide: PropTypes.node,
  rightSide: PropTypes.node
};

export default ButtonDividedGroup;
