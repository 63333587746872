import { version } from 'react';

export default function displayReactVersion() {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(
      `%cReact v${version}`,
      'color: blue; font-weight: bold; font-size: 14px'
    );
  }
}
