export const backendModelsTypes = {
  MapPortalComposition: 'mapportalcomposition',
  QgisCompositionEntry: 'qgiscompositionentry',
  TopicalSearchTool: 'topicalsearchtool',
  RegisterConfig: 'registerconfig',
  TopicalSearchToolLookupColumn: 'topicalsearchtoollookupcolumn',
  TopicalSearchToolResultColumn: 'topicalsearchtoolresultcolumn',
  LayerConfig: 'layerconfig',
  LayerAttrs: 'layerattrs',
  FeatureInfoLayerConfiguration: 'featureinfolayerconfiguration',
  FeatureInfoLayerAttribute: 'featureinfolayerattribute',
  FeatureInfoJoin: 'featureinfojoin'
};

export const ACCEPTED_FAVICON_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/vnd.microsoft.icon',
  'image/x-icon',
  'image/x-portable-bitmap',
  'image/x-ms-bmp',
  'image/x-bmp'
];
