import { forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from 'antd';
import { Button } from '../';

import './CloseModalButton.less';

const CloseModalButton = ({ closeFn, title }, ref) => {
  const { formatMessage } = useIntl();

  const closeText = formatMessage({
    id: 'general_close',
    defaultMessage: 'Close'
  });

  return (
    <Button
      ref={ref}
      variant="text"
      className="close-modal"
      onClick={closeFn}
      aria-label={`${closeText} - ${title}`}
    >
      <Icon
        className="close-modal-icon"
        type="close"
        aria-label="close-modal-icon"
      />
    </Button>
  );
};

export default forwardRef(CloseModalButton);
