import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Button } from 'antd';
import './PrintPreviewMapWrapperToolbar.less';

const PrintPreviewMapWrapperToolbar = ({
  zoomInMap,
  maxZoomed,
  zoomOutMap,
  minZoomed
}) => {
  const { formatMessage } = useIntl();

  const zoomInAriaLabel = formatMessage({
    id: 'general_zoom_in',
    defaultMessage: 'Zoom in'
  });

  const zoomOutAriaLabel = formatMessage({
    id: 'general_zoom_out',
    defaultMessage: 'Zoom out'
  });

  return (
    <ul className="print-preview-map-toolbar">
      <li>
        <Button
          onClick={zoomInMap}
          disabled={maxZoomed}
          size="small"
          aria-label={zoomInAriaLabel}
        >
          <span>+</span>
        </Button>
      </li>
      <li>
        <Button
          onClick={zoomOutMap}
          disabled={minZoomed}
          size="small"
          aria-label={zoomOutAriaLabel}
        >
          <span>-</span>
        </Button>
      </li>
    </ul>
  );
};

PrintPreviewMapWrapperToolbar.propTypes = {
  previewZoomed: PropTypes.bool,
  togglePreviewZoom: PropTypes.func.isRequired,
  zoomInMap: PropTypes.func.isRequired,
  zoomOutMap: PropTypes.func.isRequired,
  maxZoomed: PropTypes.bool.isRequired,
  minZoomed: PropTypes.bool.isRequired
};

export default PrintPreviewMapWrapperToolbar;
