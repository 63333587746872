import { useCallback, useEffect } from 'react';

import useMap from '../useMap';

import { eventTypes } from '../../components/MapControl/layer-editor/const';

const useUpdateEditSketchLabels = ({ setEditSketchLabels } = {}) => {
  const map = useMap();

  const updateEditSketchLabels = useCallback(event => {
    setEditSketchLabels(event.isActive);
  }, []);

  const updateFormValues = useCallback(() => {
    map.on(eventTypes.changeShowSketchLabels, updateEditSketchLabels);

    return () => {
      map.off(eventTypes.changeShowSketchLabels, updateEditSketchLabels);
    };
  }, [updateEditSketchLabels]);

  useEffect(updateFormValues, [updateFormValues]);
};

export default useUpdateEditSketchLabels;
