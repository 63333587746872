import PropTypes from 'prop-types';

import './ContentBox.less';

const ContentBox = props => (
  <div
    className={['content-box', props.className].join(' ')}
    style={props.style}
  >
    {props.children}
  </div>
);

ContentBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.object
};

export default ContentBox;
