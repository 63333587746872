/**
 * Łączy ciągi tekstowe przekazane w tablicy do momentu osiągnięcia określonego
 * limitu. Po jego przekroczeniu tworzy nowy złączony ciąg. Może być przydatne
 * przy wysyłaniu zapytań do Mapproxy - niektóre portale mają zaznaczone dużo
 * warstw i w jednym zapytaniu nie możemy przekazać wszystkich (zbyt długi URL).
 * @param {array} stringsArr
 * @param {number} limit
 * @returns {array}
 */

const joinStringsWithLimit = (stringsArr, limit) => {
  if (!limit) return stringsArr;

  const resultsArr = [[]];
  let currentArrayIndex = 0;

  stringsArr.forEach(item => {
    const currentCollection = resultsArr[currentArrayIndex];

    const nextCurrenctCollectionLength = `${currentCollection.toString()},${item}`
      .length;

    if (nextCurrenctCollectionLength > limit) {
      currentArrayIndex++;
      return (resultsArr[currentArrayIndex] = [item]);
    }

    resultsArr[currentArrayIndex].push(item);
  });

  return resultsArr.map(collection => collection.toString()).filter(Boolean);
};

export default joinStringsWithLimit;
