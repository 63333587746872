export const GEOMETRY_TYPES = {
  POINT: 'Point',
  MULTI_POINT: 'MultiPoint',
  LINE_STRING: 'LineString',
  MULTI_LINE_STRING: 'MultiLineString',
  POLYGON: 'Polygon',
  MULTI_POLYGON: 'MultiPolygon',
  RECTANGLE: 'Rectangle',
  CIRCLE: 'Circle'
};

export const CORRECT_ARRAY_DEPTH = {
  [GEOMETRY_TYPES.MULTI_POINT]: 1,
  [GEOMETRY_TYPES.MULTI_LINE_STRING]: 2,
  [GEOMETRY_TYPES.MULTI_POLYGON]: 3
};

export const ANTD_FONTS =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
