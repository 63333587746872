import PropTypes from 'prop-types';

import { Icon } from 'antd';

import './RoundedIcon.less';

const getClassName = variant => {
  switch (variant) {
    case 'success':
      return 'rounded-icon-success';
    case 'danger':
      return 'rounded-icon-danger';
    case 'warning':
      return 'rounded-icon-warning';
    case 'secondary':
      return 'rounded-icon-secondary';
    default:
      return '';
  }
};

const RoundedIcon = props => {
  return (
    <div
      className={[
        'rounded-icon',
        getClassName(props.variant),
        props.className
      ].join(' ')}
    >
      <Icon type={props.type} />
    </div>
  );
};

RoundedIcon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string
};

export default RoundedIcon;
