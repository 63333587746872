import { Icon, Input, Modal, Tooltip } from 'antd';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../Button';

import './CompositionConfigModal.less';

const CompositionConfigModal = ({
  visible,
  onCancel,
  onSave,
  loadConfiguration,
  deleteConfiguration,
  loadConfig,
  configurations
}) => {
  const [name, setName] = useState('');
  const { formatMessage } = useIntl();
  const saveTranslation = formatMessage({
    id: 'general_save',
    defaultMessage: 'Save'
  });
  const cancelTranslation = formatMessage({
    id: 'general_cancel',
    defaultMessage: 'Cancel'
  });
  const saveTitile = formatMessage({
    id: 'save_configuration_confirm',
    defaultMessage: 'Save the configuration of the layer list'
  });
  const loadTitle = formatMessage({
    id: 'load_configuration_confirm',
    defaultMessage: 'Cancel'
  });

  const [nameEmpty, setNameEmpty] = useState(false);
  const [nameTaken, setNameTaken] = useState(false);

  const handleGateways = (isEmpty, isTaken) => {
    setNameEmpty(isEmpty);
    setNameTaken(isTaken);
  };

  const handleCancel = () => {
    handleGateways(false, false);
    onCancel();
  };

  const handleSave = () => {
    if (!name) {
      handleGateways(true, false);
      return;
    }
    const existingConfig = configurations.find(config => config.name === name);
    if (existingConfig) {
      handleGateways(false, true);
      return;
    }
    onSave(name);
    handleGateways(false, false);
  };

  const renderConfigurations = () => {
    if (!configurations?.length) {
      return (
        <FormattedMessage
          id="load_configuration_no_saved"
          defaultMessage="No saved configuration to load"
        />
      );
    }
    return configurations?.map(({ name }) => (
      <div className="config-modal-list" key={name}>
        {name}
        <Tooltip
          overlayStyle={{ zIndex: 10001 }}
          mouseLeaveDelay={0}
          placement="bottom"
          title={
            <FormattedMessage
              id="load_configuration_tooltip"
              defaultMessage="Load configuration"
            />
          }
        >
          <Icon type="eye" onClick={() => loadConfiguration(name)} />
        </Tooltip>
        <Tooltip
          overlayStyle={{ zIndex: 10001 }}
          mouseLeaveDelay={0}
          placement="bottom"
          title={
            <FormattedMessage
              id="delete_configuration_tooltip"
              defaultMessage="Delete configuration"
            />
          }
        >
          <Icon type="delete" onClick={() => deleteConfiguration(name)} />
        </Tooltip>
      </div>
    ));
  };
  return (
    <Modal
      className="custom-modal"
      visible={visible}
      onCancel={onCancel}
      title={loadConfig ? loadTitle : saveTitile}
      footer={null}
      zIndex={10000}
    >
      {loadConfig ? (
        <div>{renderConfigurations()}</div>
      ) : (
        <div className="config-modal-save">
          <FormattedMessage
            id="save_configuration_name"
            defaultMessage="Name"
          />
          <Input
            value={name}
            onChange={({ target: { value } }) => setName(value)}
          />
          <div className="error-msg">
            {nameEmpty && (
              <FormattedMessage
                id="config_save_empty_name"
                defaultMessage="Enter the name for the configuration."
              />
            )}
            {nameTaken && (
              <FormattedMessage
                id="config_name_exist"
                defaultMessage="There is already a configuration with the given name."
              />
            )}
          </div>
          <div className="config-btn">
            <Button variant="primary" onClick={handleSave}>
              {saveTranslation}
            </Button>
            <Button variant="default" onClick={handleCancel}>
              {cancelTranslation}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CompositionConfigModal;
