/**
 * Transformuje klucze w obiektach JS. Funkcja ta jest przydatna np. podczas
 * komunikacji z API, gdy przechowujemy dane w obiektach z kluczami zapisywanymi
 * w notacji camelCase, a na backend potrzebujemy wysłać zapytanie z kluczami
 * zapisanymi w innej notacji, np. snake_case.
 *
 * @param {object} obj - obiekt do przetransformowania
 * @param {function} transformKeyFunction - funkcja transformująca
 * @return {object}
 */
const renameObjectKeys = (obj, transformKeyFunction) => {
  const object = { ...obj };

  Object.keys(object).forEach(key => {
    const newKey = transformKeyFunction(key);

    object[newKey] = object[key];

    if (key !== newKey) {
      delete object[key];
    }
  });

  return object;
};

export default renameObjectKeys;
