import axios from 'axios';

const getFileId = config =>
  new Promise((resolve, reject) => {
    const { district, path } = config;

    axios
      .post(`${district}/get_file_id/`, `path=${path}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });

export default getFileId;
