import { Checkbox, Input, Row } from 'antd';

import useTranslateConfigSelector from '../../hooks/useTranslateConfigSelector';

import FormItem from 'antd/lib/form/FormItem';

import '../GlobalSettingsForForm/GlobalSettingsForForm.less';

export const InputRow = ({
  label,
  value,
  onChange,
  stateKey,
  unit,
  form,
  maxSize,
  minSize
}) => {
  const {
    isRequired,
    cannotExceed,
    valueCannotBeEqual
  } = useTranslateConfigSelector();

  const validationRules = [
    { required: true, message: isRequired },
    {
      validator: (rule, value, callback) => {
        const numericValue = parseFloat(value);

        if (numericValue < minSize) {
          return callback(`${valueCannotBeEqual} ${minSize}`);
        }

        if (maxSize && numericValue > maxSize) {
          return callback(`${cannotExceed} ${maxSize}`);
        }

        return callback();
      }
    }
  ];

  return (
    <Row type="flex" align="middle" className="row-global-settings-input">
      <div>
        <span className="medium">{label}</span>
        <FormItem>
          {form.getFieldDecorator(stateKey, {
            initialValue: value,
            rules: validationRules
          })(
            <Input
              type="number"
              onChange={event => onChange(event, stateKey)}
              min={minSize}
            />
          )}
          {unit}
        </FormItem>
      </div>
    </Row>
  );
};

export const CheckboxGroupRow = ({
  label,
  values,
  selectedValue,
  onChange,
  stateKey,
  form
}) => {
  const { chooseMinimumOneOption } = useTranslateConfigSelector();
  const validationRules = [
    {
      required: true,
      message: chooseMinimumOneOption,
      validator: (rule, value, callback) => {
        if (!value?.length) {
          return callback(chooseMinimumOneOption);
        }

        callback();
      }
    }
  ];

  return (
    <Row type="flex" align="middle" className="row-global-settings-radio">
      <div>
        <span className="medium">{label}</span>
        <FormItem>
          {form.getFieldDecorator(stateKey, {
            initialValue: selectedValue,
            rules: validationRules
          })(
            <Checkbox.Group onChange={event => onChange(event, stateKey)}>
              {values.map(({ value, label: optionLabel }) => (
                <span key={value} className="square-radio">
                  <Checkbox value={value}>
                    {optionLabel === 'SHP' ? 'SHP(zip)' : optionLabel}
                  </Checkbox>
                </span>
              ))}
            </Checkbox.Group>
          )}
        </FormItem>
      </div>
    </Row>
  );
};
