import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';

import { Table, Popconfirm, Icon, Pagination, Spin } from 'antd';

import { fetchGroups, deleteGroup } from '../../store/actions/groupsActions';
import { adminRoutePaths } from '../../router/admin/paths';
import { RESULTS_PER_PAGE_OPTIONS } from '../../utils/constants';
import './GroupsList.less';

export const GroupsList = props => {
  const { prefix } = props.match.params;

  const [groups, setGroups] = useState([]);
  const [formInfo, setFormInfo] = useState(true);
  const [groupPage, setPage] = useState();
  const [resultsPerPage, setResultPerPage] = useState();
  const [groupsCount, setGroupsCount] = useState(0);

  useEffect(() => {
    fetchGroups();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchGroups();
  }, [props.deletingGroup, prefix]);

  const fetchGroups = async (
    groupPage = 1,
    resultsPerPage = Number(RESULTS_PER_PAGE_OPTIONS[0])
  ) => {
    const groups = await props.fetchGroups(prefix, groupPage, resultsPerPage);
    setGroups(groups.results);
    setGroupsCount(groups.count);
    setPage(groupPage);
    setResultPerPage(resultsPerPage);
  };

  const checkGroupHasForm = groupdId => {
    setFormInfo(<Spin />);

    axios
      .get(`${prefix}/admin_layer_forms/count/?group=${groupdId}`)
      .then(r => {
        if (r.data.count) {
          setFormInfo(
            'Grupa posiada przypisane formularze edycji. Gdy usuniesz grupę formularz edycji zostanie także usunięty. Czy na pewno chcesz usunąć grupę?'
          );
        } else {
          setFormInfo('Czy na pewno chcesz usunąć grupę?');
        }
      })
      .catch(err => {
        setFormInfo('Czy na pewno chcesz usunąć grupę?');
      });
  };

  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    {
      title: 'Nazwa',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Opcje',
      dataIndex: '',
      key: 'x',
      render: e => (
        <div>
          <Icon
            type="edit"
            className="groups-icon"
            onClick={() =>
              props.history.push(
                `/${prefix}/admin${adminRoutePaths.groupsEdit.replace(
                  ':id',
                  e.id
                )}`
              )
            }
          />
          <Popconfirm
            title={formInfo}
            onConfirm={() => props.deleteGroup(prefix, e.id)}
            okText="Tak"
            cancelText="Nie"
            id="deleteGroup"
          >
            <Icon
              type="delete"
              className="groups-icon"
              onClick={() => checkGroupHasForm(e.id)}
            />
          </Popconfirm>
        </div>
      )
    }
  ];
  return (
    <div>
      <Table
        columns={columns}
        dataSource={groups}
        pagination={false}
        loading={props.fetchingGroups}
        rowKey="id"
      />
      <Pagination
        className="groups-table-pagination"
        showSizeChanger
        showQuickJumper
        total={groupsCount}
        onChange={fetchGroups}
        onShowSizeChange={fetchGroups}
        current={groupPage}
        pageSize={resultsPerPage ? resultsPerPage : 5}
        pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  fetchingGroups: state.groups.get('fetchingGroups'),
  deletingGroup: state.groups.get('deletingGroup')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGroups,
      deleteGroup
    },
    dispatch
  );

GroupsList.propTypes = {
  fetchingGroups: PropTypes.bool,
  deletingGroup: PropTypes.bool,
  fetchGroups: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupsList)
);
