import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { userSelectors, districtSelectors } from '../../store/selectors';

import { UserDataShape } from '../../shapes/user';

import checkRoleForDistrict from '../../utils/userUtils/checkRoleForDistrict';

class RequiredRole extends Component {
  render() {
    const isAuthorized = this.props.isLogoutProcessing
      ? false
      : Boolean(this.props.userData);

    const userRoles = this.props.userData && this.props.userData.toJS().roles;
    const hasRoleForDistrict = isAuthorized
      ? checkRoleForDistrict(this.props.role, userRoles, this.props.districtId)
      : false;

    return this.props.render(hasRoleForDistrict, isAuthorized);
  }
}

const mapStateToProps = (state, ownProps) => ({
  userData: userSelectors.userData(state),
  districtId: districtSelectors.getDistrictId(
    state,
    ownProps.match.params.prefix
  ),
  isLogoutProcessing: userSelectors.getLogoutProcessing(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

RequiredRole.propTypes = {
  render: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  userData: UserDataShape,
  isLogoutProcessing: PropTypes.bool.isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequiredRole)
);
