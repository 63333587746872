import { rolesNames } from '../';

/**
 * Zwraca nazwę roli dla podanego id.
 *
 * @param {number} id
 * @return {string} - Podstawowa plus
 */
const getRoleNameById = id => rolesNames[id];

export default getRoleNameById;
