import { useEffect, useContext, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { DomEvent } from 'leaflet';
import classNames from 'classnames';

import LeafletMapContext from '../../contexts/LeafletMapContext';

import { CloseModalButton } from '../';

import './MapTooltip.less';

const MapTooltip = ({
  coordinates,
  children,
  className,
  topOffset,
  leftOffset,
  domSelector,
  closeFn,
  onScrollFn
}) => {
  const map = useContext(LeafletMapContext)?.getLeafletElement();
  const ref = useRef();

  const disableMapPropagation = () => {
    if (!ref?.current) return;
    const { on, fakeStop } = DomEvent;
    on(ref.current, 'click dblclick', fakeStop);
  };

  const disableMap = () => {
    map.dragging.disable();
    map.scrollWheelZoom.disable();
  };

  const enableMap = () => {
    map.dragging.enable();
    map.scrollWheelZoom.enable();
  };

  useEffect(() => {
    disableMapPropagation();
    return () => {
      enableMap();
    };
  }, []);

  const { x: left, y: top } = map.latLngToLayerPoint(coordinates);
  const defaultWidth = 208;
  const defaultTopOffset = 10;
  const defaultLeftOffset = defaultWidth / 2;

  return createPortal(
    <div
      className="wrapper"
      style={{
        bottom: `-${top + (topOffset ?? defaultTopOffset)}px`,
        left: `${left - (leftOffset ?? defaultLeftOffset)}px`
      }}
      ref={ref}
      onMouseEnter={() => disableMap()}
      onMouseLeave={() => enableMap()}
    >
      <div
        className={classNames('map-tooltip', className)}
        onScroll={onScrollFn}
      >
        {children}
        {closeFn && <CloseModalButton closeFn={closeFn} />}
      </div>
    </div>,
    document.querySelector(domSelector)
  );
};

MapTooltip.defaultProps = {
  domSelector: '.leaflet-pane.leaflet-marker-pane'
};

MapTooltip.propTypes = {
  coordinates: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  topOffset: PropTypes.number,
  leftOffset: PropTypes.number,
  domSelector: PropTypes.string.isRequired,
  closeFn: PropTypes.func
};

export default MapTooltip;
