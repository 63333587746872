const DEFAULT_LINE_SIZE = '3';

export const getLineSize = lineType => {
  switch (lineType) {
    case 'dotted':
      return 4;
    default:
      return DEFAULT_LINE_SIZE;
  }
};
