export const eventTypes = {
  layerCreated: 'LAYER_CREATED',
  layerCreationError: 'LAYER_CREATION_ERROR',
  layerCreationHistoryChange: 'LAYER_CREATION_HISTORY_CHANGE',
  addShapeModeStatusChange: 'ADD_SHAPE_MODE_STATUS_CHANGE',
  layerCreatorDeleteObjectModeStatusChange:
    'LAYER_CREATOR_DELETE_OBJECT_MODE_STATUS_CHANGE',
  layerCreatorMoveObjectModeStatusChange:
    'LAYER_CREATOR_MOVE_OBJECT_MODE_STATUS_CHANGE',
  layerCreatorCopyObjectModeStatusChange:
    'LAYER_CREATOR_COPY_OBJECT_MODE_STATUS_CHANGE',
  layerCreatorUpdateCopiedObjectsAmount:
    'LAYER_CREATOR_UPDATE_COPIED_OBJECTS_AMOUNT',
  layerCreatorPasteObject: 'LAYER_CREATOR_PASTE_OBJECTS',
  closeLayerCreatorTools: 'CLOSE_LAYER_CREATOR_TOOLS'
};

export const errorTypes = {
  POLYGON_MUST_HAVE_3_COORDS: 'POLYGON_MUST_HAVE_3_COORDS',
  LINESTRING_MUST_HAVE_2_COORDS: 'LINESTRING_MUST_HAVE_2_COORDS',
  POINT_MUST_HAVE_1_COORD: 'POINT_MUST_HAVE_1_COORD',
  OBJECT_SLICING_ERROR: 'OBJECT_SLICING_ERROR'
};
