import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { createLogger } from 'redux-logger';
import Immutable from 'immutable';

import { getLanguages, getPingPong } from './actions/globalActions';
import { fetchDistricts, fetchAppDistrict } from './actions/districtActions';

const logger = createLogger({
  collapsed: true,
  duration: true,
  predicate: (getState, action) => action.type !== 'AUTH_REMOVE_TOKEN',
  stateTransformer: state => {
    const newState = {};

    for (var i of Object.keys(state)) {
      if (Immutable.Iterable.isIterable(state[i])) {
        newState[i] = state[i].toJS();
      } else {
        newState[i] = state[i];
      }
    }

    return newState;
  }
});

export default function configureStore(initialState) {
  const middlewares = [thunk];
  let enhancer;

  if (
    process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION__
  ) {
    if (process.env.REACT_APP_REDUX_LOGGER !== 'false') {
      middlewares.push(logger);
    }

    enhancer = compose(
      applyMiddleware(...middlewares),
      window.__REDUX_DEVTOOLS_EXTENSION__()
    );
  } else {
    enhancer = compose(applyMiddleware(...middlewares));
  }

  const store = createStore(reducers, initialState, enhancer);
  store
    .dispatch(getPingPong())
    .then(() => {
      store.dispatch(getLanguages());
      store.dispatch(fetchDistricts());
      store.dispatch(fetchAppDistrict());
    })
    .catch();
  return store;
}
