const getGlobalSettings = state => state.global.get('globalSettings');

const selectors = {
  getLanguage: state => state.global.get('language'),
  getLanguages: state => state.global.get('languages'),
  getTranslations: state => state.global.get('translations'),
  getTranslationAttribute: (state, languageCode, attribute) => {
    const translations = state.global.get('translations');
    return translations.getIn([languageCode, 'attrs'])?.[attribute];
  },
  showMessage: state => state.global.get('showMessage'),
  message: state => state.global.get('message'),
  messageType: state => state.global.get('messageType'),
  sidebarOpen: state => state.global.get('sidebarOpen'),
  mapPortal: state => state.global.get('mapPortal'),
  previousUrl: state => state.global.get('previousUrl'),
  getGlobalSettings,
  getSystemTitle: state =>
    state.global.getIn(['globalSettings', 'system_title']),
  getCursorCrsList: state => state.global.get('cursorCrsList'),
  getOfferModelAvailability: state => state.global.get('isOfferModelAvailable'),
  getApiAvailability: state => state.global.get('isApiAvailable'),
  isDefaultUserSetting: state => state.global.get('defaultUserSetting'),
  isSetingDefaultUserSetting: state =>
    state.global.get('setingDefaultUserSetting'),
  getWindowDimensions: state => state.global.get('windowDimensions'),
  getWindowDimensionsType: state => state.global.get('windowDimensionsType'),
  getContextualHelpTooltips: state =>
    state.global.get('contextualHelpTooltips'),
  getDefaultGridCrsCode: state => {
    const globalSettings = getGlobalSettings(state);
    return globalSettings?.get('default_grid').crs_def.crs;
  },
  getDefaultInternalSrid: state =>
    state.global.getIn(['globalSettings', 'default_internal_srid']),
  getAdvancedFeaturesUse: state => state.global.get('advancedFeaturesUse'),
  isLogoutCountdownTimerOpen: state =>
    state.global.get('logoutCountdownTimerOpen'),
  getUserSettings: state => state.global.get('userSettings'),
  getSavedEbudApiKey: state => state.global.get('ebudApiKey')
};

export default selectors;
