import PropTypes from 'prop-types';
import arrayMove from 'array-move';

import { Button } from '../';
import { Icon } from 'antd';

import './SortArrows.less';

export const moveItem = (data, index, direction) => {
  const currentAttrIndex =
    typeof index === 'function' ? data.findIndex(index) : index;

  let newAttrIndex;
  if (typeof direction === 'number') {
    newAttrIndex = direction;
  } else {
    newAttrIndex =
      direction === 'up' ? currentAttrIndex - 1 : currentAttrIndex + 1;
  }

  return arrayMove(data, currentAttrIndex, newAttrIndex);
};

const SortArrows = props => {
  const moveDown = e => {
    e.stopPropagation();
    props.moveDown();
  };

  const moveUp = e => {
    e.stopPropagation();
    props.moveUp();
  };

  return (
    <div className="sort-arrows">
      <Button
        variant="text"
        disabled={props.disabled || props.lastItem}
        onClick={moveDown}
        tabIndex={props.tabIndex}
      >
        <Icon type="caret-down" />
      </Button>
      <Button
        variant="text"
        disabled={props.disabled || props.firstItem}
        onClick={moveUp}
        tabIndex={props.tabIndex}
      >
        <Icon type="caret-up" />
      </Button>
    </div>
  );
};

SortArrows.propTypes = {
  moveUp: PropTypes.func.isRequired,
  moveDown: PropTypes.func.isRequired,
  firstItem: PropTypes.bool.isRequired,
  lastItem: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number
};

export default SortArrows;
