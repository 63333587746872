import React, { useState } from 'react';
import PropTypes from 'prop-types';

import regExp from '../../utils/lib/regEx';

import './LineSplitter.less';

/**
 * Dzieli przekazaną treść na linie składające się z określonej
 * liczby znaków. Dodatkowo wrzuca każdą z nich do osobnego elementu
 * <span>. Potrzebne jest to m.in. na potrzeby wydruku, gdzie za pomocą
 * wskazania klasy elementu możemy określić w którym miejscu dozwolone
 * jest łamanie strony.
 */

const processContent = content => {
  const moreThanOneSpaceRegExp = new RegExp(regExp.moreThanOneSpace, 'g');
  return content.replace(moreThanOneSpaceRegExp, '').split(' ');
};

const LineSplitter = ({
  content,
  lineMaxLength = 90,
  renderKey = null,
  className = null
}) => {
  const [splittedContent, setSplittedContent] = useState(null);

  const splitContent = () => {
    const processedContent = processContent(content);
    const lines = [];
    let currentLineIndex = 0;

    const getNewLine = word => ({
      id: Math.random(),
      content: [word]
    });

    const getFirstLine = () => ({
      id: Math.random(),
      content: [renderKey]
    });

    if (renderKey) lines[currentLineIndex] = getFirstLine();

    processedContent.forEach(word => {
      const currentLine = lines[currentLineIndex];

      if (!currentLine) {
        lines[currentLineIndex] = getNewLine(word);
      } else {
        const isGoingToBreakLength =
          [...currentLine.content, word].join(' ').length >= lineMaxLength;

        if (isGoingToBreakLength) {
          currentLineIndex += 1;
          lines[currentLineIndex] = getNewLine(word);
        } else {
          currentLine.content.push(word);
        }
      }
    });

    setSplittedContent(lines);
  };

  React.useEffect(() => {
    splitContent();
  }, [lineMaxLength]);

  if (!splittedContent) return null;

  return splittedContent.map(line => (
    <span className="splitted-line" key={line.id}>
      {line.content.map(word => (
        <span key={Math.random()}>{word}</span>
      ))}
    </span>
  ));
};

LineSplitter.propTypes = {
  content: PropTypes.string.isRequired,
  lineMaxLength: PropTypes.number,
  key: PropTypes.node
};

export default LineSplitter;
