import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import L from 'leaflet';
import { Map, TileLayer, GeoJSON, Marker, FeatureGroup } from 'react-leaflet';

import { DistrictShape } from '../../shapes/district';

import iconImage from 'leaflet/dist/images/marker-icon.png';

import '../../utils/mapUtils/freezer';

import './DistrictDetailsMap.less';

const latLng = {
  lat: 52.2070861,
  lng: 20.884052
};

const iconMarker = L.icon({
  iconUrl: iconImage,
  iconSize: [25, 41],
  iconAnchor: [14, 41]
});

export class DistrictDetailsMap extends Component {
  constructor(props) {
    super(props);

    this.mapRef = createRef();
    this.districtLayerRef = createRef();
    this.groupRef = createRef();
    this.onLayeradd = this.onLayeradd.bind(this);
    this.objectType = this.props.object
      ? this.props.object.geometry?.type
      : null;
  }
  onLayeradd(ref) {
    this.zoomToDistrict(ref);
    this.zoomToMarker();
  }

  zoomToDistrict(ref) {
    this.districtLayerRef = ref;

    if (!this.districtLayerRef) {
      return;
    }

    const bounds =
      this.districtLayerRef.layer &&
      this.districtLayerRef.layer.getBounds &&
      this.districtLayerRef.layer.getBounds();

    if (bounds) {
      this.mapRef.current.leafletElement.fitBounds(bounds);
    }
  }

  zoomToMarker() {
    const markerCords = this.props?.object?.geometry?.coordinates;

    if (this.objectType === 'Point') {
      if (markerCords)
        this.mapRef.current.leafletElement.flyTo(markerCords.reverse(), 16);
    }

    if (this.objectType === 'MultiPoint') {
      const group = this.groupRef?.current?.leafletElement;
      if (group)
        this.mapRef.current?.leafletElement.fitBounds(group.getBounds());
    }
  }

  getMarker = coords => {
    const position = L.GeoJSON.coordsToLatLng(coords);

    return (
      <Marker key={coords.toString()} position={position} icon={iconMarker} />
    );
  };

  renderGeometry = () => {
    if (!this.objectType) return null;

    if (this.objectType === 'Point') {
      return this.getMarker(this.props.object.geometry.coordinates);
    }

    if (this.objectType === 'MultiPoint') {
      return (
        <FeatureGroup ref={this.groupRef}>
          {this.props.object.geometry.coordinates.map(this.getMarker)};
        </FeatureGroup>
      );
    }

    return <GeoJSON data={this.props.object} />;
  };

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <Map
        className="district-map"
        ref={this.mapRef}
        center={latLng}
        zoom={this.props.zoomLevel}
        onLayeradd={this.onLayeradd}
        zoomControl={false}
        freezer={{
          enabled: true,
          pointerText: formatMessage({
            id: 'freezer_pointer_message',
            defaultMessage: 'Press CTRL and scroll to zoom in on the map.'
          }),
          touchText: formatMessage({
            id: 'freezer_touch_message',
            defaultMessage: 'Use 2 fingers to control the map view.'
          })
        }}
      >
        <span className="sr-only">
          {formatMessage({
            id: 'district_details_map',
            defaultMessage: "District's map"
          })}
        </span>
        <TileLayer url="//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {this.props.data && (
          <GeoJSON
            ref={this.handleLocationFound}
            data={this.props.data}
            style={{ fill: false, color: '#000' }}
          />
        )}
        {this.renderGeometry()}
      </Map>
    );
  }
}

DistrictDetailsMap.propTypes = {
  data: PropTypes.oneOfType([DistrictShape, PropTypes.object]),
  zoomLevel: PropTypes.number.isRequired,
  object: PropTypes.object
};

export default injectIntl(DistrictDetailsMap);
