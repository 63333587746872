import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { mapPortalSelectors } from '../../../store/selectors';
import { editMapPortal } from '../../../store/actions/mapPortalActions';

const mapPortalKeys = {
  enableConfigurationModes: 'enable_configuration_modes',
  defaultMode: 'default_mode'
};

const useToolsConfiguration = ({ params }) => {
  const { enableConfigurationModes, defaultMode } = mapPortalKeys;
  const { id: mapPortalId, prefix } = params;
  const dispatch = useDispatch();

  const [values, setValues] = useState({});
  const [saving, setSaving] = useState({
    [defaultMode]: false,
    [enableConfigurationModes]: false
  });

  const mapPortal = useSelector(state =>
    mapPortalSelectors.getDistrictMapPortalByID(
      state,
      prefix,
      Number(mapPortalId)
    )
  );

  useEffect(() => {
    setValues({
      [enableConfigurationModes]: mapPortal.get(enableConfigurationModes),
      [defaultMode]: mapPortal.get(defaultMode)
    });
  }, [mapPortal]);

  const adminToolsConfigurationOptions = [
    { value: 'informative', label: 'Informacyjny' },
    { value: 'advanced', label: 'Zaawansowany' }
  ];

  const handleMode = async (value, key) => {
    setSaving({ ...saving, [key]: true });
    setValues({ ...values, [key]: value });

    const data = { [key]: value };

    try {
      await dispatch(editMapPortal(Number(mapPortalId), data, prefix));
    } finally {
      setSaving({ ...saving, [key]: false });
    }
  };

  return {
    saving,
    adminToolsConfigurationOptions,
    values,
    mapPortalKeys,
    handleMode
  };
};

export default useToolsConfiguration;
