import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';

const SelectOptionWithTooltip = ({
  label,
  isDropdownVisible,
  overlayClassName = 'select-option__tooltip'
}) => {
  const [isTruncated, setIsTruncated] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    if (isDropdownVisible) {
      const { scrollWidth, clientWidth } = ref.current.parentElement;

      // When the options of the Select component are visible,
      // we check if they are truncated text using the condition below.
      // If so Option label with Tooltip is displayed.
      if (scrollWidth > clientWidth) {
        setIsTruncated(true);
      }
    }

    return () => {
      // Tooltips are removed when the dropdown list is hidden.
      setIsTruncated(false);
    };
  }, [isDropdownVisible]);

  if (isTruncated) {
    return (
      <Tooltip
        title={label}
        placement="top"
        getPopupContainer={() => document.body}
        overlayClassName={overlayClassName}
      >
        <span>{label}</span>
      </Tooltip>
    );
  }

  return <span ref={ref}>{label}</span>;
};

SelectOptionWithTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  isDropdownVisible: PropTypes.bool.isRequired,
  overlayClassName: PropTypes.string
};

export default SelectOptionWithTooltip;
