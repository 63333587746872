import PropTypes from 'prop-types';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Control from 'react-leaflet-control';

import { globalSelectors } from '../../store/selectors';

import { PRINTOUT_CLOSURE_TEXT } from '../../constants/keys';

import './PrintPreviewMapNote.less';

const { getGlobalSettings } = globalSelectors;

const PrintPreviewMapNote = ({
  position,
  printingDate,
  resolutionModifier
}) => {
  const { formatMessage } = useIntl();
  const globalSettings = useSelector(getGlobalSettings);
  const mapNoteText = globalSettings?.get(PRINTOUT_CLOSURE_TEXT);

  if (!mapNoteText && !printingDate) return null;

  return (
    <Control position={position}>
      <span className="print-map-preview-note">
        {!!mapNoteText && <span>{mapNoteText}</span>}
        {printingDate ? (
          <span className="display-block">
            <span className="bold-text">
              {formatMessage({
                id: 'district_portal_print_map_printing_date',
                defaultMessage: 'Printing date'
              })}
              :
            </span>
            <br />
            {moment().format('L')}, {moment().format('LT')}
          </span>
        ) : (
          <span />
        )}
      </span>
    </Control>
  );
};

PrintPreviewMapNote.propTypes = {
  printingDate: PropTypes.bool.isRequired,
  position: PropTypes.string.isRequired
};

export default PrintPreviewMapNote;
