import React from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import {
  searchToolsSelectors,
  mapPortalSelectors
} from '../../store/selectors';
import { fetchCompositionTopicalSearchTools } from '../../store/actions/searchToolsActions';

const { getCurrentComposition } = mapPortalSelectors;
const { getCompositionTopicalSearchTools } = searchToolsSelectors;

const useCurrentTopicalSearchTools = () => {
  const { prefix, portal } = useParams();
  const dispatch = useDispatch();

  const composition = useSelector(state =>
    getCurrentComposition(state, prefix, portal)
  );
  const compositionId = composition?.id;
  const topicalSearchTools = useSelector(state =>
    getCompositionTopicalSearchTools(state, prefix, compositionId)
  );

  React.useEffect(() => {
    if (compositionId && !topicalSearchTools) {
      dispatch(
        fetchCompositionTopicalSearchTools({ compositionId, district: prefix })
      );
    }
  }, [compositionId]);

  return topicalSearchTools;
};

export default useCurrentTopicalSearchTools;
