import { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { Table, Icon, Popconfirm, Pagination } from 'antd';

import {
  deleteReportComment,
  fetchReportComments
} from '../../store/actions/reportsActions';
import { districtSelectors, userSelectors } from '../../store/selectors';

import { SingleReportContext } from '../../views/AdminSingleReportView/SingleReportContext';

import { UserDataShape } from '../../shapes/user';
import checkRoleForDistrict from '../../utils/userUtils/checkRoleForDistrict';
import { roles } from '../../config';
import { DATA_FORMAT_WITH_TIME } from '../../utils/constants';

export const AdminReportComments = props => {
  const { report, reportComments, page, setPage } = useContext(
    SingleReportContext
  );

  const deleteComment = commentId => {
    props.deleteReportComment(report.id, commentId).then(() => {
      props.fetchReportComments(report.id);
    });
  };
  const { formatMessage } = useIntl();

  const columns = [
    {
      title: formatMessage({
        id: 'admin_single_report_office_notes_notes',
        defaultMessage: 'Note'
      }),
      dataIndex: 'comment',
      key: 'comment'
    },
    {
      title: formatMessage({
        id: 'admin_single_report_office_notes_author',
        defaultMessage: 'Author'
      }),
      dataIndex: 'user',
      key: 'user',
      render: (value, row, index) => {
        return `${row.user?.first_name || '-'} ${row.user?.last_name || '-'}`;
      }
    },
    {
      title: formatMessage({
        id: 'admin_single_report_office_notes_created_date',
        defaultMessage: 'Created date'
      }),
      dataIndex: 'created',
      key: 'created',
      render: (value, row, index) => {
        return moment(value).format(DATA_FORMAT_WITH_TIME);
      }
    },
    {
      title: formatMessage({
        id: 'admin_single_report_office_notes_options',
        defaultMessage: 'Options'
      }),
      dataIndex: 'options',
      key: 'options',
      render: (value, row, index) => {
        return row.user?.id === props.userData.get('id') ||
          checkRoleForDistrict(
            roles.administrator,
            props.userData.get('roles'),
            props.districtDetails.id
          ) ? (
          <>
            <Popconfirm
              title={formatMessage({
                id: 'admin_single_report_delete_confirm_comment',
                defaultMessage: 'Are you sure you want to delete the comment?'
              })}
              onConfirm={() => deleteComment(row.id)}
              okText={formatMessage({
                id: 'general_yes',
                defaultMessage: 'Yes'
              })}
              cancelText={formatMessage({
                id: 'general_no',
                defaultMessage: 'no'
              })}
            >
              <Icon type="delete" />
            </Popconfirm>
            <Icon type="edit" onClick={() => props.setCommentToEdit(row)} />
          </>
        ) : null;
      }
    }
  ];

  return (
    <Fragment>
      <h2>
        {formatMessage({
          id: 'admin_single_report_office_notes',
          defaultMessage: "Official's notes"
        })}
      </h2>
      <Table
        columns={columns}
        dataSource={reportComments && reportComments.results}
        pagination={false}
        scroll={{ x: 'max-content', y: 250 }}
        bodyStyle={{ overflowX: 'auto' }}
      />
      {reportComments && reportComments.count > 5 ? (
        <Pagination
          defaultCurrent={page}
          pageSize={5}
          total={reportComments.count}
          onChange={e => setPage(e)}
          style={{ marginTop: 15, textAlign: 'center' }}
        />
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  fetchedUsers: state.user.get('fetchedUsers'),
  userData: userSelectors.userData(state),
  districtDetails: districtSelectors.getAppDistrictDetails(
    state,
    ownProps.prefix
  )
});

const mapDispatchToProps = {
  deleteReportComment,
  fetchReportComments
};

AdminReportComments.propTypes = {
  fetchedUsers: PropTypes.object,
  deleteReportComment: PropTypes.func.isRequired,
  fetchReportComments: PropTypes.func.isRequired,
  userData: UserDataShape,
  prefix: PropTypes.string.isRequired,
  setCommentToEdit: PropTypes.func.isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminReportComments)
);
