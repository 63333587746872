import { useState } from 'react';

const useValidation = (initialValue = {}) => {
  const [validationErrors, setValidationErrors] = useState(initialValue);

  const getValidationError = field => validationErrors[field];

  const setValidationError = (field, error) =>
    setValidationErrors(prevErrors => ({
      ...prevErrors,
      [field]: error
    }));

  const clearValidationError = field =>
    setValidationErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });

  const clearValidationErrors = () => setValidationErrors({});

  return {
    getValidationError,
    setValidationError,
    clearValidationError,
    clearValidationErrors
  };
};

export default useValidation;
