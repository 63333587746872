export const polishTranslations = {
  identification: 'Identyfikacja',
  move: 'Przesuwanie mapy',
  previous: 'Poprzedni widok',
  next: 'Następny widok',
  zoom_window: 'Powiększanie oknem',
  measurement: 'Pomiary',
  legend: 'Podkłady mapowe',
  save_map: 'Zapisz mapę',
  print: 'Drukuj',
  language: 'Język',
  zoom_map: 'Skala mapy',
  saved_popups: 'Zapisane punkty identyfikacji',
  manage_wms: 'Dodaj warstwę',
  fit_view: 'Pełen widok',
  fullscreen: 'Pełny ekran',
  add_object: 'Edytuj obiekt',
  defect: 'Lista zgłoszeń',
  timeline: 'Oś czasu',
  nmt_measurement: 'Profil terenu',
  geolocation: 'Lokalizacja',
  investor: 'Inwestor',
  notes: 'Notatki',
  google_street_view: 'Google Street View',
  information: 'Informacje',
  selection: 'Selekcja',
  sketchbook: 'Szkicownik',
  enable_show_pan_arrow: 'Strzałki do przesuwania mapy',
  lataren: 'Latarnik',
  log_in: 'Logowanie',
  portal_3d_link: 'Portal 3D',
  set_a_route: 'Wyznaczanie trasy',
  zoom_in: 'Przybliż',
  zoom_out: 'Oddal',
  embed_map: 'Osadź mapę',
  other_topical_data: 'Inne dane tematyczne',
  bi_raports: 'Raporty BI',
  registers: 'Rejestr',
  finn_synchronization: 'Synchronizacja finn',
  e_construction: 'Rejestr e-budownictwa',
  wms_identification: 'Identyfikacja WMS',
  e_services: 'E-usługi'
};
