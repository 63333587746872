import { isMobile } from '../../utils/lib';
import DesktopSelect from './DesktopSelect';
import MobileSelect from './mobileSelect';

const SelectWrapper = props => {
  return isMobile() ? (
    <MobileSelect {...props} />
  ) : (
    <DesktopSelect {...props} />
  );
};
export default SelectWrapper;
