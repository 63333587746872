import { Component } from 'react';

import PropTypes from 'prop-types';

import { Table } from 'antd';
import { injectIntl } from 'react-intl';

import './PrintReportList.less';

export class PrintReportList extends Component {
  // Komponent klasowy ze względu na to,
  // że pakiet React-to-print
  // nie drukuje komponentów funkcyjnych....
  formatMessage = this.props.intl.formatMessage;

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '7%'
    },
    {
      title: this.formatMessage({
        id: 'admin_filter_reports_category',
        defaultMessage: 'Category'
      }),
      dataIndex: 'category',
      key: 'category',
      textWrap: 'word-break',
      width: '14%',
      align: 'center'
    },
    {
      title: this.formatMessage({
        id: 'admin_filter_reports_subcategory',
        defaultMessage: 'Subcategory'
      }),
      dataIndex: 'subcategory',
      key: 'subcategory',
      textWrap: 'word-break',
      width: '20%',
      align: 'center'
    },
    {
      title: this.formatMessage({
        id: 'report_user_panel_title',
        defaultMessage: 'Title'
      }),
      dataIndex: 'title',
      key: 'title',
      textWrap: 'word-break',
      width: '15%',
      align: 'center'
    },
    {
      title: this.formatMessage({
        id: 'admin_report_list_created_date',
        defaultMessage: 'Created'
      }),
      dataIndex: 'created',
      key: 'creted',
      width: '17%',
      align: 'center'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      textWrap: 'word-break',
      width: '13%',
      align: 'center'
    },
    {
      title: this.formatMessage({
        id: 'reports_visible_in_map',
        defaultMessage: 'Visible on the map'
      }),
      dataIndex: 'confirmed',
      key: 'confirmed',
      textWrap: 'word-break',
      width: '20%',
      align: 'center'
    }
  ];
  render() {
    return (
      <div className="report-card-wrapper">
        <span className="report-card-title">
          {this.formatMessage({
            id: 'reports_card_title',
            defaultMessage: 'Reports card'
          })}
        </span>
        <Table
          columns={this.columns}
          dataSource={this.props.dataSource}
          pagination={false}
        />
      </div>
    );
  }
}

PrintReportList.propTypes = {
  dataSource: PropTypes.array.isRequired
};

export default injectIntl(PrintReportList);
