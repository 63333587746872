import { findParentNode } from '../';

const trRowMatcher = { attribute: 'tagName', value: 'TR' };

export const setRowAsDraggable = e => {
  const trRow = findParentNode(e.target, trRowMatcher);
  if (trRow) trRow.draggable = true;
};

export const setRowAsNotDraggable = e => {
  const trRow = findParentNode(e.target, trRowMatcher);
  if (trRow) trRow.draggable = false;
};

const setRowDraggability = {
  setRowAsDraggable,
  setRowAsNotDraggable
};

export default setRowDraggability;
