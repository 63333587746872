export const FETCH_ALL_REPORTS_BEGIN = 'FETCH_ALL_REPORTS_BEGIN';
export const FETCH_ALL_REPORTS_SUCCESS = 'FETCH_ALL_REPORTS_SUCCESS';
export const FETCH_ALL_REPORTS_FAILED = 'FETCH_ALL_REPORTS_FAILED';

export const FETCH_OPTIONS_REPORTS_BEGIN = 'FETCH_OPTIONS_REPORTS_BEGIN';
export const FETCH_OPTIONS_REPORTS_SUCCESS = 'FETCH_OPTIONS_REPORTS_SUCCESS';
export const FETCH_OPTIONS_REPORTS_FAILED = 'FETCH_OPTIONS_REPORTS_FAILED';

export const FETCH_SINGLE_REPORT_BEGIN = 'FETCH_SINGLE_REPORT_BEGIN';
export const FETCH_SINGLE_REPORT_SUCCESS = 'FETCH_SINGLE_REPORT_SUCCESS';
export const FETCH_SINGLE_REPORT_FAILED = 'FETCH_SINGLE_REPORT_FAILED';

export const FETCH_REPORT_CATEGORIES_BEGIN = 'FETCH_REPORT_CATEGORIES_BEGIN';
export const FETCH_REPORT_CATEGORIES_SUCCESS =
  'FETCH_REPORT_CATEGORIES_SUCCESS';
export const FETCH_REPORT_CATEGORIES_FAILED = 'FETCH_REPORT_CATEGORIES_FAILED';

export const FETCH_REPORT_SUBCATEGORIES_BEGIN =
  'FETCH_REPORT_SUBCATEGORIES_BEGIN';
export const FETCH_REPORT_SUBCATEGORIES_SUCCESS =
  'FETCH_REPORT_SUBCATEGORIES_SUCCESS';
export const FETCH_REPORT_SUBCATEGORIES_FAILED =
  'FETCH_REPORT_SUBCATEGORIES_FAILED';

export const FETCH_REPORT_STATUSES_BEGIN = 'FETCH_REPORT_STATUSES_BEGIN';
export const FETCH_REPORT_STATUSES_SUCCESS = 'FETCH_REPORT_STATUSES_SUCCESS';
export const FETCH_REPORT_STATUSES_FAILED = 'FETCH_REPORT_STATUSES_FAILED';

export const UPDATE_REPORT_BEGIN = 'UPDATE_REPORT_BEGIN';
export const UPDATE_REPORT_END = 'UPDATE_REPORT_END';

export const DELETE_REPORT_BEGIN = 'DELETE_REPORT_BEGIN';
export const DELETE_REPORT_END = 'DELETE_REPORT_END';

export const SET_REPORT_POINT = 'SET_REPORT_POINT';

export const CREATE_REPORT_BEGIN = 'CREATE_REPORT_BEGIN';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_FAILED = 'CREATE_REPORT_FAILED';

export const CREATE_UNREGISTERED_REPORT_BEGIN = 'CREATE_REPORT_BEGIN';
export const CREATE_UNREGISTERED_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_UNREGISTERED_REPORT_FAILED = 'CREATE_REPORT_FAILED';

export const CLEAR_REPORT_POINT = 'CLEAR_REPORT_POINT';

export const UPLOAD_REPORT_ATTACHMENT_BEGIN = 'UPLOAD_REPORT_ATTACHMENT_BEGIN';
export const UPLOAD_REPORT_ATTACHMENT_SUCCESS =
  'UPLOAD_REPORT_ATTACHMENT_SUCCESS';
export const UPLOAD_REPORT_ATTACHMENT_FAILED =
  'UPLOAD_REPORT_ATTACHMENT_FAILED';

export const CREATE_REPORT_COMMENT_BEGIN = 'CREATE_REPORT_COMMENT_BEGIN';
export const CREATE_REPORT_COMMENT_SUCCESS = 'CREATE_REPORT_COMMENT_SUCCESS';
export const CREATE_REPORT_COMMENT_FAILED = 'CREATE_REPORT_COMMENT_FAILED';

export const FETCH_REPORT_COMMENT_BEGIN = 'FETCH_REPORT_COMMENT_BEGIN';
export const FETCH_REPORT_COMMENT_SUCCESS = 'FETCH_REPORT_COMMENT_SUCCESS';
export const FETCH_REPORT_COMMENT_FAILED = 'FETCH_REPORT_COMMENT_FAILED';

export const FETCH_REPORT_HISTORY_BEGIN = 'FETCH_REPORT_HISTORY_BEGIN';
export const FETCH_REPORT_HISTORY_SUCCESS = 'FETCH_REPORT_HISTORY_SUCCESS';
export const FETCH_REPORT_HISTORY_FAILED = 'FETCH_REPORT_HISTORY_FAILED';

export const UPDATE_REPORT_COMMENT_BEGIN = 'UPDATE_REPORT_COMMENT_BEGIN';
export const UPDATE_REPORT_COMMENT_SUCCESS = 'UPDATE_REPORT_COMMENT_SUCCESS';
export const UPDATE_REPORT_COMMENT_FAILED = 'UPDATE_REPORT_COMMENT_FAILED';

export const DELETE_REPORT_COMMENT_BEGIN = 'DELETE_REPORT_COMMENT_BEGIN';
export const DELETE_REPORT_COMMENT_SUCCESS = 'DELETE_REPORT_COMMENT_SUCCESS';
export const DELETE_REPORT_COMMENT_FAILED = 'DELETE_REPORT_COMMENT_FAILED';

export const FETCH_DISTRICT_REPORTS_BEGIN = 'FETCH_DISTRICT_REPORTS_BEGIN';
export const FETCH_DISTRICT_REPORTS_SUCCESS = 'FETCH_DISTRICT_REPORTS_SUCCESS';
export const FETCH_DISTRICT_REPORTS_FAILED = 'FETCH_DISTRICT_REPORTS_FAILED';

export const DISPLAY_REPORTS = 'DISPLAY_REPORTS';

export const FETCH_SINGLE_REPORT_CATEGORY_BEGIN =
  'FETCH_SINGLE_REPORT_CATEGORY_BEGIN';
export const FETCH_SINGLE_REPORT_CATEGORY_SUCCESS =
  'FETCH_SINGLE_REPORT_CATEGORY_SUCCESS';
export const FETCH_SINGLE_REPORT_CATEGORY_FAILED =
  'FETCH_SINGLE_REPORT_CATEGORY_FAILED';

export const FETCH_SINGLE_REPORT_SUBCATEGORY_BEGIN =
  'FETCH_SINGLE_REPORT_SUBCATEGORY_BEGIN';
export const FETCH_SINGLE_REPORT_SUBCATEGORY_SUCCESS =
  'FETCH_SINGLE_REPORT_SUBCATEGORY_SUCCESS';
export const FETCH_SINGLE_REPORT_SUBCATEGORY_FAILED =
  'FETCH_SINGLE_REPORT_SUBCATEGORY_FAILED';

export const FETCH_SINGLE_REPORT_STATUS_BEGIN =
  'FETCH_SINGLE_REPORT_STATUS_BEGIN';
export const FETCH_SINGLE_REPORT_STATUS_SUCCESS =
  'FETCH_SINGLE_REPORT_STATUS_SUCCESS';
export const FETCH_SINGLE_REPORT_STATUS_FAILED =
  'FETCH_SINGLE_REPORT_STATUS_FAILED';

export const ADD_REPORT_VOTE_BEGIN = 'ADD_REPORT_VOTE_BEGIN';
export const ADD_REPORT_VOTE_SUCCESS = 'ADD_REPORT_VOTE_SUCCESS';
export const ADD_REPORT_VOTE_FAILED = 'ADD_REPORT_VOTE_FAILED';

export const DELETE_REPORT_VOTE_BEGIN = 'DELETE_REPORT_VOTE_BEGIN';
export const DELETE_REPORT_VOTE_SUCCESS = 'DELETE_REPORT_VOTE_SUCCESS';
export const DELETE_REPORT_VOTE_FAILED = 'DELETE_REPORT_VOTE_FAILED';

export const DELETE_REPORT_OWNER_BEGIN = 'DELETE_REPORT_OWNER_BEGIN';
export const DELETE_REPORT_OWNER_SUCCESS = 'DELETE_REPORT_OWNER_SUCCESS';
export const DELETE_REPORT_OWNER_FAILED = 'DELETE_REPORT_OWNER_FAILED';

export const DELETE_REPORT_ATTACHMENT = 'DELETE_REPORT_ATTACHMENT';
export const DELETE_REPORT_ATTACHMENT_SUCCESS =
  'DELETE_REPORT_ATTACHMENT_SUCCESS';
export const DELETE_REPORT_ATTACHMENT_FAILED =
  'DELETE_REPORT_ATTACHMENT_FAILED';

export const CREATE_ASSIGN_TO_CATEGORY = 'CREATE_ASSIGN_TO_CATEGORY';
export const CREATE_ASSIGN_TO_CATEGORY_SUCCESS =
  'CREATE_ASSIGN_TO_CATEGORY_SUCCESS';
export const CREATE_ASSIGN_TO_CATEGORY_FAILED =
  'CREATE_ASSIGN_TO_CATEGORY_FAILED';

export const DELETE_ASSIGN_TO_CATEGORY = 'DELETE_ASSIGN_TO_CATEGORY';
export const DELETE_ASSIGN_TO_CATEGORY_SUCCESS =
  'DELETE_ASSIGN_TO_CATEGORY_SUCCESS';
export const DELETE_ASSIGN_TO_CATEGORY_FAILED =
  'DELETE_ASSIGN_TO_CATEGORY_FAILED';

export const UPDATE_ASSIGN_TO_CATEGORY = 'UPDATE_ASSIGN_TO_CATEGORY';
export const UPDATE_ASSIGN_TO_CATEGORY_SUCCESS =
  'UPDATE_ASSIGN_TO_CATEGORY_SUCCESS';
export const UPDATE_ASSIGN_TO_CATEGORY_FAILED =
  'UPDATE_ASSIGN_TO_CATEGORY_FAILED';

export const FETCH_USER_SUBSCRIPTIONS = 'FETCH_USER_SUBSCRIPTIONS';
export const FETCH_USER_SUBSCRIPTIONS_SUCCESS =
  'FETCH_USER_SUBSCRIPTIONS_SUCCESS';
export const FETCH_USER_SUBSCRIPTIONS_FAILED =
  'FETCH_USER_SUBSCRIPTIONS_FAILED';

export const SET_SINGLE_REPORT = 'SET_SINGLE_REPORT';

export const UPDATE_ADMIN_CATEGORY_BEGIN = 'UPDATE_ADMIN_CATEGORY_BEGIN';
export const UPDATE_ADMIN_CATEGORY_SUCCESS = 'UPDATE_ADMIN_CATEGORY_SUCCESS';
export const UPDATE_ADMIN_CATEGORY_FAILED = 'UPDATE_ADMIN_CATEGORY_FAILED';

export const DELETE_ADMIN_CATEGORY_BEGIN = 'DELETE_ADMIN_CATEGORY_BEGIN';
export const DELETE_ADMIN_CATEGORY_SUCCESS = 'DELETE_ADMIN_CATEGORY_SUCCESS';
export const DELETE_ADMIN_CATEGORY_FAILED = 'DELETE_ADMIN_CATEGORY_FAILED';

export const CREATE_ADMIN_CATEGORY_BEGIN = 'CREATE_ADMIN_CATEGORY_BEGIN';
export const CREATE_ADMIN_CATEGORY_SUCCESS = 'CREATE_ADMIN_CATEGORY_SUCCESS';
export const CREATE_ADMIN_CATEGORY_FAILED = 'CREATE_ADMIN_CATEGORY_FAILED';

export const FETCH_ADMIN_CATEGORIES_BEGIN = 'FETCH_ADMIN_CATEGORIES_BEGIN';
export const FETCH_ADMIN_CATEGORIES_SUCCESS = 'FETCH_ADMIN_CATEGORIES_SUCCESS';
export const FETCH_ADMIN_CATEGORIES_FAILED = 'FETCH_ADMIN_CATEGORIES_FAILED';

export const FETCH_ADMIN_SUBCATEGORIES_BEGIN =
  'FETCH_ADMIN_SUBCATEGORIES_BEGIN';
export const FETCH_ADMIN_SUBCATEGORIES_SUCCESS =
  'FETCH_ADMIN_SUBCATEGORIES_SUCCESS';
export const FETCH_ADMIN_SUBCATEGORIES_FAILED =
  'FETCH_ADMIN_SUBCATEGORIES_FAILED';

export const DELETE_CATEGORY_FROM_DISTRICT_BEGIN =
  'DELETE_CATEGORY_FROM_DISTRICT_BEGIN';
export const DELETE_CATEGORY_FROM_DISTRICT_SUCCESS =
  'DELETE_CATEGORY_FROM_DISTRICT_SUCCESS';
export const DELETE_CATEGORY_FROM_DISTRICT_FAILED =
  'DELETE_CATEGORY_FROM_DISTRICT_FAILED';

export const EDIT_CATEGORY_ASSIGNED_TO_DISTRICT_BEGIN =
  'EDIT_CATEGORY_ASSIGNED_TO_DISTRICT_BEGIN';
export const EDIT_CATEGORY_ASSIGNED_TO_DISTRICT_SUCCESS =
  'EDIT_CATEGORY_ASSIGNED_TO_DISTRICT_SUCCESS';
export const EDIT_CATEGORY_ASSIGNED_TO_DISTRICT_FAILED =
  'EDIT_CATEGORY_ASSIGNED_TO_DISTRICT_FAILED';

export const CREATE_ADMIN_SUBCATEGORY_BEGIN = 'CREATE_ADMIN_SUBCATEGORY_BEGIN';
export const CREATE_ADMIN_SUBCATEGORY_SUCCESS =
  'CREATE_ADMIN_SUBCATEGORY_SUCCESS';
export const CREATE_ADMIN_SUBCATEGORY_FAILED =
  'CREATE_ADMIN_SUBCATEGORY_FAILED';

export const EDIT_ADMIN_SUBCATEGORY_BEGIN = 'EDIT_ADMIN_SUBCATEGORY_BEGIN';
export const EDIT_ADMIN_SUBCATEGORY_SUCCESS = 'EDIT_ADMIN_SUBCATEGORY_SUCCESS';
export const EDIT_ADMIN_SUBCATEGORY_FAILED = 'EDIT_ADMIN_SUBCATEGORY_FAILED';

export const DELETE_ADMIN_SUBCATEGORY_BEGIN = 'DELETE_ADMIN_SUBCATEGORY_BEGIN';
export const DELETE_ADMIN_SUBCATEGORY_SUCCESS =
  'DELETE_ADMIN_SUBCATEGORY_SUCCESS';
export const DELETE_ADMIN_SUBCATEGORY_FAILED =
  'DELETE_ADMIN_SUBCATEGORY_FAILED';

export const FETCH_ALL_SUBCATEGORIES_BEGIN = 'FETCH_ALL_SUBCATEGORIES_BEGIN';
export const FETCH_ALL_SUBCATEGORIES_SUCCESS =
  'FETCH_ALL_SUBCATEGORIES_SUCCESS';
export const FETCH_ALL_SUBCATEGORIES_FAILED = 'FETCH_ALL_SUBCATEGORIES_FAILED';

export const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER';
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export const ZOOM_TO_CATEGORY = 'ZOOM_TO_CATEGORY';

export const FETCH_REPORT_DATA_BEGIN = 'FETCH_REPORT_DATA_BEGIN';
export const FETCH_REPORT_DATA_SUCCESS = 'FETCH_REPORT_DATA_SUCCESS';
export const FETCH_REPORT_DATA_FAILED = 'FETCH_REPORT_DATA_FAILED';

export const GET_DEFECTS_SUBSCRIPTIONS_BEGIN =
  'GET_DEFECTS_SUBSCRIPTIONS_BEGIN';
export const GET_DEFECTS_SUBSCRIPTIONS_SUCCESS =
  'GET_DEFECTS_SUBSCRIPTIONS_SUCCESS';
export const GET_DEFECTS_SUBSCRIPTIONS_FAILED =
  'GET_DEFECTS_SUBSCRIPTIONS_FAILED';

export const ENABLE_FILTERS = 'ENABLE_FILTERS';

export const CREATE_REPORT_STATUS_BEGIN = 'CREATE_REPORT_STATUS_BEGIN';
export const CREATE_REPORT_STATUS_SUCCESS = 'CREATE_REPORT_STATUS_SUCCESS';
export const CREATE_REPORT_STATUS_FAILED = 'CREATE_REPORT_STATUS_FAILED';

export const DELETE_REPORT_STATUS_BEGIN = 'DELETE_REPORT_STATUS_BEGIN';
export const DELETE_REPORT_STATUS_SUCCESS = 'DELETE_REPORT_STATUS_SUCCESS';
export const DELETE_REPORT_STATUS_FAILED = 'DELETE_REPORT_STATUS_FAILED';

export const EDIT_REPORT_STATUS_BEGIN = 'EDIT_REPORT_STATUS_BEGIN';
export const EDIT_REPORT_STATUS_SUCCESS = 'EDIT_REPORT_STATUS_SUCCESS';
export const EDIT_REPORT_STATUS_FAILED = 'EDIT_REPORT_STATUS_FAILED';

export const FETCH_REPORT_CONFIG_BEGIN = 'FETCH_REPORT_CONFIG_BEGIN';
export const FETCH_REPORT_CONFIG_SUCCESS = 'FETCH_REPORT_CONFIG_SUCCESS';
export const FETCH_REPORT_CONFIG_FAILED = 'FETCH_REPORT_CONFIG_FAILED';

export const FETCH_REPORT_SINGLE_CONFIG_BEGIN =
  'FETCH_REPORT_SINGLE_CONFIG_BEGIN';
export const FETCH_REPORT_SINGLE_CONFIG_SUCCESS =
  'FETCH_REPORT_SINGLE_CONFIG_SUCCESS';
export const FETCH_REPORT_SINGLE_CONFIG_FAILED =
  'FETCH_REPORT_SINGLE_CONFIG_FAILED';

export const CREATE_REPORT_CONFIG_BEGIN = 'CREATE_REPORT_CONFIG_BEGIN';
export const CREATE_REPORT_CONFIG_SUCCESS = 'CREATE_REPORT_CONFIG_SUCCESS';
export const CREATE_REPORT_CONFIG_FAILED = 'CREATE_REPORT_CONFIG_FAILED';

export const UPDATE_REPORT_CONFIG_BEGIN = 'CREATE_REPORT_CONFIG_BEGIN';
export const UPDATE_REPORT_CONFIG_SUCCESS = 'UPDATE_REPORT_CONFIG_SUCCESS';
export const UPDATE_REPORT_CONFIG_FAILED = 'UPDATE_REPORT_CONFIG_FAILED';

export const DELETE_REPORT_CONFIG_BEGIN = 'DELETE_REPORT_CONFIG_BEGIN';
export const DELETE_REPORT_CONFIG_SUCCESS = 'DELETE_REPORT_CONFIG_SUCCESS';
export const DELETE_REPORT_CONFIG_FAILED = 'DELETE_REPORT_CONFIG_FAILED';

export const FETCH_REPORT_CATEGORY_CONFIG_BEIGN =
  'FETCH_REPORT_CATEGORY_CONFIG_BEIGN';
export const FETCH_REPORT_CATEGORY_CONFIG_SUCCESS =
  'FETCH_REPORT_CATEGORY_CONFIG_SUCCESS';
export const FETCH_REPORT_CATEGORY_CONFIG_FAILED =
  'FETCH_REPORT_CATEGORY_CONFIG_FAILED';

export const FETCH_REPORT_STATUS_CONFIG_BEIGN =
  'FETCH_REPORT_STATUS_CONFIG_BEIGN';
export const FETCH_REPORT_STATUS_CONFIG_SUCCESS =
  'FETCH_REPORT_STATUS_CONFIG_SUCCESS';
export const FETCH_REPORT_STATUS_CONFIG_FAILED =
  'FETCH_REPORT_STATUS_CONFIG_FAILED';

export const CRAETE_REPORT_CATEGORY_COLOR_BEGIN =
  'CRAETE_REPORT_CATEGORY_COLOR_BEGIN';
export const CRAETE_REPORT_CATEGORY_COLOR_SUCCESS =
  'CRAETE_REPORT_CATEGORY_COLOR_SUCCESS';
export const CRAETE_REPORT_CATEGORY_COLOR_FAILED =
  'CRAETE_REPORT_CATEGORY_COLOR_FAILED';

export const CRAETE_REPORT_STATUS_COLOR_BEGIN =
  'CRAETE_REPORT_STATUS_COLOR_BEGIN';
export const CREATE_REPORT_STATUS_COLOR_SUCCESS =
  'CREATE_REPORT_STATUS_COLOR_SUCCESS';
export const CRAETE_REPORT_STATUS_COLOR_FAILED =
  'CRAETE_REPORT_STATUS_COLOR_FAILED';

export const UPDATE_REPORT_CATEGORY_COLOR_BEGIN =
  'UPDATE_REPORT_CATEGORY_COLOR_BEGIN';
export const UPDATE_REPORT_CATEGORY_COLOR_SUCCESS =
  'UPDATE_REPORT_CATEGORY_COLOR_SUCCESS';
export const UPDATE_REPORT_CATEGORY_COLOR_FAILED =
  'UPDATE_REPORT_CATEGORY_COLOR_FAILED';

export const UPDATE_REPORT_STATUS_COLOR_BEGIN =
  'UPDATE_REPORT_STATUS_COLOR_BEGIN';
export const UPDATE_REPORT_STATUS_COLOR_SUCCESS =
  'UPDATE_REPORT_STATUS_COLOR_SUCCESS';
export const UPDATE_REPORT_STATUS_COLOR_FAILED =
  'UPDATE_REPORT_STATUS_COLOR_FAILED';

export const CLEAR_REPORT_COLORS = 'CLEAR_REPORT_COLORS';

export const GET_TYPE_REPORTS_REQUEST = 'GET_TYPE_REPORTS_REQUEST';
export const GET_TYPE_REPORTS_SUCCESS = 'GET_TYPE_REPORTS_SUCCESS';
export const GET_TYPE_REPORTS_FAILED = 'GET_TYPE_REPORTS_FAILED';

export const GET_DEFAULT_STATUS_TYPE_REPORT_REQUEST =
  'GET_DEFAULT_STATUS_TYPE_REPORT_REQUEST';
export const GET_DEFAULT_STATUS_TYPE_REPORT_SUCCESS =
  'GET_DEFAULT_STATUS_TYPE_REPORT_SUCCESS';
export const GET_DEFAULT_STATUS_TYPE_REPORT_FAILED =
  'GET_DEFAULT_STATUS_TYPE_REPORT_FAILED';

export const UPDATE_DEFAULT_STATUS_TYPE_REPORT_REQUEST =
  'UPDATE_DEFAULT_STATUS_TYPE_REPORT_REQUEST';
export const UPDATE_DEFAULT_STATUS_TYPE_REPORT_SUCCESS =
  'UPDATE_DEFAULT_STATUS_TYPE_REPORT_SUCCESS';
export const UPDATE_DEFAULT_STATUS_TYPE_REPORT_FAILED =
  'UPDATE_DEFAULT_STATUS_TYPE_REPORT_FAILED';

export const SHOW_TYPE_REPORT = 'SHOW_TYPE_REPORT';

export const CREATE_TYPE_REPORT_REQUEST = 'CREATE_TYPE_REPORT_REQUEST';
export const CREATE_TYPE_REPORT_SUCCESS = 'CREATE_TYPE_REPORT_SUCCESS';
export const CREATE_TYPE_REPORT_FAILED = 'CREATE_TYPE_REPORT_FAILED';

export const UPDATE_TYPE_REPORT_REQUEST = 'UPDATE_TYPE_REPORT_REQUEST';
export const UPDATE_TYPE_REPORT_SUCCESS = 'UPDATE_TYPE_REPORT_SUCCESS';
export const UPDATE_TYPE_REPORT_FAILED = 'UPDATE_TYPE_REPORT_FAILED';

export const DELETE_TYPE_REPORT_REQUEST = 'DELETE_TYPE_REPORT_REQUEST';
export const DELETE_TYPE_REPORT_SUCCESS = 'DELETE_TYPE_REPORT_SUCCESS';
export const DELETE_TYPE_REPORT_FAILED = 'DELETE_TYPE_REPORT_FAILED';

export const FETCH_DEFECT_CONFIGURATION = 'FETCH_DEFECT_CONFIGURATION';
export const CHANGE_DEFECT_CONFIGURATION = 'CHANGE_DEFECT_CONFIGURATION';
export const FETCH_DEFECT_CONFIGURATION_STARTED =
  'FETCH_DEFECT_CONFIGURATION_STARTED';
export const FETCH_DEFECT_CONFIGURATION_SUCCESS =
  'FETCH_DEFECT_CONFIGURATION_SUCCESS';
export const FETCH_DEFECT_CONFIGURATION_FAILED =
  'FETCH_DEFECT_CONFIGURATION_FAILED';

export const UPDATE_DEFECT_CONFIGURATION_STARTED =
  'UPDATE_DEFECT_CONFIGURATION_STARTED';
export const UPDATE_DEFECT_CONFIGURATION_SUCCESS =
  'UPDATE_DEFECT_CONFIGURATION_SUCCESS';
export const UPDATE_DEFECT_CONFIGURATION_FAILED =
  'UPDATE_DEFECT_CONFIGURATION_FAILED';

export const GET_CATEGORY_ATTRIBUTES_STARTED =
  'GET_CATEGORY_ATTRIBUTES_STARTED';
export const GET_CATEGORY_ATTRIBUTES_SUCCESS =
  'GET_CATEGORY_ATTRIBUTES_SUCCESS';
export const GET_CATEGORY_ATTRIBUTES_FAILED = 'GET_CATEGORY_ATTRIBUTES_FAILED';
export const UPDATE_CATEGORY_ATTRIBUTES_STARTED =
  'UPDATE_CATEGORY_ATTRIBUTES_STARTED';
export const UPDATE_CATEGORY_ATTRIBUTES_SUCCESS =
  'UPDATE_CATEGORY_ATTRIBUTES_SUCCESS';
export const UPDATE_CATEGORY_ATTRIBUTES_FAILED =
  'UPDATE_CATEGORY_ATTRIBUTES_FAILED';
export const DELETE_CATEGORY_ATTRIBUTES = 'DELETE_CATEGORY_ATTRIBUTES';
export const DELETE_CATEGORY_ATTRIBUTES_FAILED =
  'DELETE_CATEGORY_ATTRIBUTES_FAILED';
export const DELETE_CATEGORY_ATTRIBUTES_SUCCESS =
  'DELETE_CATEGORY_ATTRIBUTES_SUCCESS';
export const DELETE_CATEGORY_ATTRIBUTES_STARTED =
  'DELETE_CATEGORY_ATTRIBUTES_STARTED';
export const ADD_CATEGORY_ATTRIBUTE_STARTED = 'ADD_CATEGORY_ATTRIBUTE_STARTED';
export const ADD_CATEGORY_ATTRIBUTE_SUCCESS = 'ADD_CATEGORY_ATTRIBUTE_SUCCESS';
export const ADD_CATEGORY_ATTRIBUTE_FAILED = 'ADD_CATEGORY_ATTRIBUTE_FAILED';
export const CLEAR_CATEGORY_ATTRIBUTES = 'CLEAR_CATEGORY_ATTRIBUTES';
export const UPDATE_CATEGORY_ATTRIBUTE_STARTED =
  'UPDATE_CATEGORY_ATTRIBUTE_STARTED';
export const UPDATE_CATEGORY_ATTRIBUTE_SUCCESS =
  'UPDATE_CATEGORY_ATTRIBUTE_SUCCESS';
export const UPDATE_CATEGORY_ATTRIBUTE_FAILED =
  'UPDATE_CATEGORY_ATTRIBUTE_FAILED';

export const CREATE_ADMIN_REPORT_CATEGORY_LOADING_FINISHED =
  'CREATE_ADMIN_REPORT_CATEGORY_LOADING_FINISHED';
