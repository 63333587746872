import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Menu, Dropdown } from 'antd';

import { useCurrentComposition, useTranslations } from '../../hooks';
import useSearchTypeSelect from './hooks/useSearchTypeSelect';

import { SEARCH_TYPE_SELECT_TRANSLATIONS } from './consts';

import './SearchTypeSelect.less';

const MenuItem = Menu.Item;

const SearchTypeSelect = ({ onChange, searchTools, searchType }) => {
  const [visible, setVisible] = useState(false);
  const currentComposition = useCurrentComposition();

  const { SELECT_SEARCH_TYPE } = useTranslations(
    SEARCH_TYPE_SELECT_TRANSLATIONS
  );

  const { searchTypesNames, menuItems } = useSearchTypeSelect({
    onChange,
    searchTools,
    searchType
  });
  const selectMenu = (
    <Menu>
      {currentComposition &&
        menuItems.map(
          ({ key, isVisible, ...props }) =>
            isVisible && (
              <MenuItem key={key} {...props}>
                {searchTypesNames[key]}
              </MenuItem>
            )
        )}
    </Menu>
  );
  const isMenuVisible =
    menuItems?.filter(({ isVisible }) => isVisible).length > 1;

  if (!isMenuVisible)
    return (
      <button
        className="search-type-select-btn"
        title={SELECT_SEARCH_TYPE}
        aria-label={SELECT_SEARCH_TYPE}
        aria-expanded={visible}
      >
        <span className="search-type-name">{searchTypesNames[searchType]}</span>
      </button>
    );

  return (
    <Dropdown
      overlay={selectMenu}
      trigger={['click']}
      disabled={!searchTools.length}
      onVisibleChange={visible => setVisible(visible)}
      getPopupContainer={trigger => trigger.parentNode}
    >
      <button
        className="search-type-select-btn"
        title={SELECT_SEARCH_TYPE}
        aria-label={SELECT_SEARCH_TYPE}
        aria-expanded={visible}
      >
        <span className="search-type-name">{searchTypesNames[searchType]}</span>
        {isMenuVisible && (
          <Icon
            style={{ paddingLeft: '20%' }}
            type="down"
            aria-label={SELECT_SEARCH_TYPE}
            role="img"
          />
        )}
      </button>
    </Dropdown>
  );
};

SearchTypeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  searchType: PropTypes.string,
  searchTools: PropTypes.arrayOf(PropTypes.string)
};

export default SearchTypeSelect;
