import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { Button } from '../';
import { Icon } from 'antd';

import { getApiUrl, addParamsToUrl } from '../../utils/lib';

import './GalleryThumbs.less';

class GalleryThumbs extends Component {
  offsetStepValue = 300;
  thumbMargins = 20;
  galleryThumbsRef = createRef();

  state = {
    thumbsListOffset: 0
  };

  componentDidMount() {
    this.addResizeListener();
  }

  componentDidUpdate() {
    this.getThumbsListMaxOffset();
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  slideLeft = () => {
    this.setState(prevState => {
      const thumbsListOffset =
        prevState.thumbsListOffset - this.offsetStepValue;

      return {
        thumbsListOffset: thumbsListOffset < 0 ? 0 : thumbsListOffset
      };
    });
  };

  slideRight = () => {
    if (this.state.thumbsListOffset >= this.state.thumbsListMaxOffset) return;

    this.setState(prevState => {
      const newThumbsListOffset =
        prevState.thumbsListOffset + this.offsetStepValue;
      const isMaximumReached =
        newThumbsListOffset >= this.state.thumbsListMaxOffset;

      return {
        thumbsListOffset: isMaximumReached
          ? this.state.thumbsListMaxOffset - this.thumbMargins
          : newThumbsListOffset
      };
    });
  };

  getThumbsListMaxOffset = () => {
    if (!this.galleryThumbsRef.current) return;

    const thumbsListMaxOffset =
      this.galleryThumbsRef.current.scrollWidth -
      this.galleryThumbsRef.current.clientWidth;

    if (this.state.thumbsListMaxOffset !== thumbsListMaxOffset) {
      this.setState({ thumbsListMaxOffset });
    }
  };

  resizeListener = debounce(() => {
    if (this.state.thumbsListOffset >= this.state.thumbsListMaxOffset) {
      this.setState(prevState => ({
        thumbsListOffset: prevState.thumbsListMaxOffset
      }));
    }
  }, 500);

  addResizeListener = () => {
    window.addEventListener('resize', this.resizeListener);
  };

  removeResizeListener = () => {
    this.resizeListener.cancel();
    window.removeEventListener('resize', this.resizeListener);
  };

  hasScroll = () => {
    if (!this.galleryThumbsRef.current) return;
    const { offsetWidth, scrollWidth } = this.galleryThumbsRef.current;
    return scrollWidth > offsetWidth;
  };

  render() {
    const displayScroll = this.hasScroll() ? 'block' : 'none';

    return (
      <div className="gallery-thumbs-container">
        <Button
          onClick={this.slideLeft}
          disabled={this.state.thumbsListOffset <= 0}
          style={{ display: displayScroll }}
        >
          <Icon type="left" />
        </Button>
        <div className="gallery-thumbs-wrapper">
          <ul
            className="gallery-thumbs"
            style={{
              transform: `translateX(-${this.state.thumbsListOffset}px)`
            }}
            ref={this.galleryThumbsRef}
          >
            {this.props.photos.map((photo, i) => {
              if (
                photo.file &&
                photo.file.url &&
                photo.file.type &&
                photo.file.type.includes('image')
              ) {
                const imgUrl = this.props.fullLink
                  ? photo.file.url
                  : `${getApiUrl()}${photo.file.url}`;

                const imgSrc = addParamsToUrl(imgUrl, [['thumbnail', 1]]);

                return (
                  <li className="gallery-thumb" key={i}>
                    <button
                      onClick={() => this.props.setCurrentImage(photo, i)}
                      className="gallery-thumb-item"
                      style={{ backgroundImage: `url(${imgSrc})` }}
                    />
                  </li>
                );
              }
              return true;
            })}
          </ul>
        </div>
        <Button
          onClick={this.slideRight}
          disabled={
            this.state.thumbsListOffset >=
            this.state.thumbsListMaxOffset - this.thumbMargins
          }
          style={{ display: displayScroll }}
        >
          <Icon type="right" />
        </Button>
      </div>
    );
  }
}

GalleryThumbs.propTypes = {
  photos: PropTypes.array.isRequired,
  setCurrentImage: PropTypes.func.isRequired
};

export default GalleryThumbs;
