const slideInputViewToEnd = (input, value) => {
  input.value = value;
  const {
    value: { length: inputLength }
  } = input;
  input.scrollLeft = input.scrollWidth;
  input.setSelectionRange(inputLength, inputLength);
};

export default slideInputViewToEnd;
