import PropTypes from 'prop-types';

export const WmsShape = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tags: PropTypes.array,
  service_type: PropTypes.string.isRequired,
  version: PropTypes.string
}).isRequired;

export const BackgroundWmsLayerDataShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  wms: PropTypes.number.isRequired
});

export const BackgroundWmsDataShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  layers: PropTypes.arrayOf(BackgroundWmsLayerDataShape),
  status: PropTypes.bool.isRequired,
  service_type: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired
});

export const BackgroundWmsShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  cover_image: PropTypes.string,
  wms: BackgroundWmsDataShape.isRequired
});
