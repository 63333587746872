import { createRef, Children, cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';

import { PrintPreviewMapWrapperToolbar, PrintLegend } from '../';

import { responseStatuses } from '../../config';

import {
  printPreviewMapWithLegendWrapperId,
  printPreviewMapWrapperId
} from '../PrintMap/printConfig';

import './PrintPreviewMapWrapper.less';

export default class PrintPreviewMapWrapper extends Component {
  WRAPPER_WIDTH = 459;
  WRAPPER_HEIGHT = 650;
  printPreviewMapWrapperRef = createRef();

  state = {
    previewZoomed: false,
    printing: false,
    maxZoomed: false,
    minZoomed: false
  };

  componentDidUpdate() {
    this.getWrapperHeight();
    this.setPrintingStatus();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  setPrintingStatus = () => {
    if (this.state.printing !== this.props.printing) {
      this.setState({
        previewZoomed: this.props.printing === responseStatuses.PENDING,
        printing: this.props.printing
      });
    }
  };

  togglePreviewZoom = () => {
    this.setState(prevState => ({
      previewZoomed: !prevState.previewZoomed
    }));
  };

  setZoomStatus = (type, value) => {
    this.setState({
      [type]: value
    });
  };

  getWrapperHeight = () => {
    const { current: printPreviewMapWrapper } = this.printPreviewMapWrapperRef;

    if (!printPreviewMapWrapper) {
      return;
    }

    const { clientHeight: wrapperHeight } = printPreviewMapWrapper;

    if (wrapperHeight && wrapperHeight !== this.state.wrapperHeight) {
      this.setState({
        wrapperHeight
      });
    }
  };

  calculateScale = () => {
    const styles = {
      marginBottom: 'auto'
    };

    if (this.state.previewZoomed) {
      return styles;
    }

    const mapWidth = parseInt(this.props.mapSize.width, 10);
    const scaleValue = this.WRAPPER_WIDTH / mapWidth;
    const mapHeight = parseInt(this.props.mapSize.height, 10) * scaleValue;
    const offsetNewMargin = 90;
    const newMargin = mapHeight - this.state.wrapperHeight + offsetNewMargin;

    styles.transform = `scale(${scaleValue})`;
    styles.marginBottom = `${newMargin < 0 ? `${newMargin}px` : 'auto'}`;

    return styles;
  };

  render() {
    const children = Children.map(this.props.children, child =>
      cloneElement(child, {
        mapTransform: this.calculateScale().transform,
        setZoomStatus: this.setZoomStatus
      })
    );
    const { previewZoomed } = this.state;
    const { legend, legendProps, printing } = this.props;
    const isNotPrinting = printing !== responseStatuses.PENDING;

    return (
      <div
        className="print-preview-map-wrapper"
        id={printPreviewMapWithLegendWrapperId}
        onTransitionEnd={this.getWrapperHeight}
        ref={this.printPreviewMapWrapperRef}
      >
        {/* It's necessary, because upper button cover upper coordinates in printing preview */}
        {isNotPrinting && (
          <PrintPreviewMapWrapperToolbar
            previewZoomed={this.state.previewZoomed}
            togglePreviewZoom={this.togglePreviewZoom}
            zoomInMap={this.props.zoomInMap}
            zoomOutMap={this.props.zoomOutMap}
            maxZoomed={this.state.maxZoomed}
            minZoomed={this.state.minZoomed}
          />
        )}
        <div
          id={printPreviewMapWrapperId}
          style={{
            maxWidth: !previewZoomed && `${this.WRAPPER_WIDTH}px`,
            maxHeight: !previewZoomed && `${this.WRAPPER_HEIGHT}px`
          }}
        >
          {children}
        </div>
        {legendProps.enabled && legendProps?.previewPrintMap && (
          <div
            style={{
              position: legend && previewZoomed ? 'static' : 'absolute',
              width: this.props.mapSize.width
            }}
            className="print-preview-map-legend"
          >
            <PrintLegend {...legendProps} />
          </div>
        )}
      </div>
    );
  }
}

PrintPreviewMapWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  enabled: PropTypes.bool,
  mapSize: PropTypes.shape({
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired
  }),
  zoomInMap: PropTypes.func.isRequired,
  zoomOutMap: PropTypes.func.isRequired,
  printing: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  legend: PropTypes.bool.isRequired,
  previewPrintMap: PropTypes.object
};
