import { useState } from 'react';

import { SortColumnButton } from '../../components';

export default function useTableSorter(columns, onSort) {
  const [sortBy, setSortBy] = useState();

  const handleSortChange = columnName => {
    const newColumnName = sortBy === columnName ? `-${columnName}` : columnName;
    setSortBy(newColumnName);
    onSort?.(newColumnName);
  };

  const prepareSortComponents = () =>
    Object.fromEntries(
      Object.keys(columns).map(columnName => [
        columnName,
        props => (
          <SortColumnButton
            ascending={sortBy === columnName}
            descending={sortBy === `-${columnName}`}
            title={columns[columnName]}
            {...props}
          />
        )
      ])
    );

  return [prepareSortComponents(), handleSortChange, sortBy];
}
