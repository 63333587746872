import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { mapPortalSelectors } from '../../store/selectors';

const { getMapPortalComposition } = mapPortalSelectors;

const useMapPortalComposition = compositionId => {
  const { prefix, portal, id } = useParams();
  const currentMapPortal = useSelector(state =>
    getMapPortalComposition(state, prefix, portal, id, compositionId)
  );

  return currentMapPortal;
};

export default useMapPortalComposition;
