import axios from 'axios';

import {
  FETCH_DISTRICTS_BEGIN,
  FETCH_DISTRICTS_SUCCESS,
  FETCH_DISTRICTS_FAILED,
  FETCH_DISTRICT_DETAILS_BEGIN,
  FETCH_DISTRICT_DETAILS_SUCCESS,
  FETCH_PRECINTS_BEGIN,
  FETCH_PRECINTS_SUCCESS,
  FETCH_PARCELS_LIST_BEGIN,
  FETCH_PARCELS_LIST_SUCCESS,
  FETCH_APP_DISTRICT_BEGIN,
  FETCH_APP_DISTRICT_SUCCESS,
  SET_DISTRICT_BOUNDS,
  CLEAR_DISTRICTS_DATA_REDUCER_STATE,
  FETCH_DISTRICT_ROLES_BEGIN,
  FETCH_DISTRICT_ROLES_SUCCESS,
  FETCH_DISTRICT_ROLES_WITH_SUPERUSER_SUCCESS,
  FETCH_DISTRICT_ROLES_WITH_SUPERUSER_BEGIN,
  FETCH_DISTRICT_ROLES_WITH_SUPERUSER_FAILED,
  FETCH_DISTRICT_ROLES_FAILED,
  FETCH_DISTRICT_APPEARANCES_BEGIN,
  FETCH_DISTRICT_APPEARANCES_SUCCESS,
  FETCH_DISTRICT_APPEARANCES_FAILED,
  ADD_DISTRICT_APPEARANCE_BEGIN,
  ADD_DISTRICT_APPEARANCE_SUCCESS,
  ADD_DISTRICT_APPEARANCE_FAILED,
  EDIT_DISTRICT_APPEARANCE_BEGIN,
  EDIT_DISTRICT_APPEARANCE_SUCCESS,
  EDIT_DISTRICT_APPEARANCE_FAILED,
  DELETE_DISTRICT_APPEARANCE_BEGIN,
  DELETE_DISTRICT_APPEARANCE_SUCCESS,
  DELETE_DISTRICT_APPEARANCE_FAILED,
  FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_BEGIN,
  FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_SUCCESS,
  FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_FAILED,
  UPDATE_APP_DISTRICT_FAILED,
  UPDATE_APP_DISTRICT_SUCCESS,
  UPDATE_APP_DISTRICT_BEGIN,
  SAVE_APPLIED_APPEARANCE,
  CLEAR_APPLIED_APPEARANCE,
  FETCH_DISTRICTS_SETTINGS_BEGIN,
  FETCH_DISTRICTS_SETTINGS_SUCCESS,
  FETCH_DISTRICTS_SETTINGS_FAILED,
  FETCH_DISTRICT_SETTINGS_SUCCESS,
  FETCH_DISTRICT_SETTINGS_FAILED,
  UPDATE_DISTRICT_SETTINGS_BEGIN,
  UPDATE_DISTRICT_SETTINGS_SUCCESS,
  UPDATE_DISTRICT_SETTINGS_FAILED,
  GET_ALL_DISTRICT_TAGS_BEGIN,
  GET_ALL_DISTRICT_TAGS_SUCCESS,
  GET_ALL_DISTRICT_TAGS_FAILED,
  UPDATE_DISTRICT_DETAILS_BEGIN,
  UPDATE_DISTRICT_DETAILS_SUCCESS,
  UPDATE_DISTRICT_DETAILS_FAILED,
  CLEAR_DISTRICT_APPEARANCES,
  UPDATE_DISTRICT_BORDER_BEGIN,
  UPDATE_DISTRICT_BORDER_SUCCESS,
  FETCH_PORTAL_3D_SETTINGS_FAILED,
  FETCH_PORTAL_3D_SETTINGS_BEGIN,
  FETCH_PORTAL_3D_SETTINGS_SUCCESS,
  FETCH_OTHER_TOPICAL_DATA_SETTINGS_BEGIN,
  FETCH_OTHER_TOPICAL_DATA_SETTINGS_SUCCESS,
  FETCH_OTHER_TOPICAL_DATA_SETTINGS_FAILED,
  SORT_DISTRICTS_BEGIN,
  SORT_DISTRICTS_SUCCESS,
  SORT_DISTRICTS_FAILED,
  GET_GLOBAL_DISTRICT_SETTINGS_STARTED,
  GET_GLOBAL_DISTRICT_SETTINGS_SUCCESS,
  GET_GLOBAL_DISTRICT_SETTINGS_FAILED,
  GET_DISTRICT_QRCODES_CONFIGURATION_BEGIN,
  GET_DISTRICT_QRCODES_CONFIGURATION_SUCCESS,
  GET_DISTRICT_QRCODES_CONFIGURATION_FAILED,
  SAVE_DISTRICT_QRCODES_CONFIGURATION_BEGIN,
  SAVE_DISTRICT_QRCODES_CONFIGURATION_SUCCESS,
  SAVE_DISTRICT_QRCODES_CONFIGURATION_FAILED,
  DELETE_DISTRICT_QRCODE_CONFIGURATION_BEGIN,
  DELETE_DISTRICT_QRCODE_CONFIGURATION_SUCCESS,
  DELETE_DISTRICT_QRCODE_CONFIGURATION_FAILED,
  CREATE_QRCODES_BEGIN,
  CREATE_QRCODES_FAILED,
  CREATE_QRCODES_SUCCESS,
  CHANGE_SEARCH_INPUT_VALUE,
  FETCH_GLOBAL_THEMES_BEGIN,
  FETCH_GLOBAL_THEMES_FAILED,
  FETCH_GLOBAL_THEMES_SUCCESS,
  DELETE_GLOBAL_THEME_STARTED,
  DELETE_GLOBAL_THEME_SUCCESS,
  DELETE_GLOBAL_THEME_FAILED,
  ADD_GLOBAL_THEME_STARTED,
  GET_SINGLE_GLOBAL_THEME_STARTED,
  GET_SINGLE_GLOBAL_THEME_SUCCESS,
  GET_SINGLE_GLOBAL_THEME_FAILED,
  EDIT_SINGLE_GLOBAL_THEME_STARTED,
  SET_GLOBAL_THEME_LOADING
} from '../constants/districtActionTypes';

import { showError, showSuccess } from './globalActions';

import { getTranslation } from '../translations';

import { parseResponseError } from '../../utils/lib';

const reducerTranslations = getTranslation('district');

/**
 * Begins fetch precints action
 * @param {*} prefix - District unique prefix name
 */
export const fetchPrecintsBegin = prefix => ({
  type: FETCH_PRECINTS_BEGIN,
  prefix
});

export const fetchPrecintsSuccess = (prefix, precints) => ({
  type: FETCH_PRECINTS_SUCCESS,
  precints: precints,
  prefix
});

/**
 * Fetches district precints (obreby)
 * @param {*} prefix - District unique prefix name
 */
export const fetchPrecints = prefix => (dispatch, getState) => {
  dispatch(fetchPrecintsBegin(prefix));

  return axios
    .get(`${prefix}/precinct/`)
    .then(response => {
      dispatch(fetchPrecintsSuccess(prefix, response.data));
    })
    .catch(() => {
      const lang = getState().global.get('language');

      dispatch(
        showError(reducerTranslations('fetchPrecinctErrorMessage', lang))
      );
    });
};

/**
 * Begins fetch precint parcels action
 * @param {*} prefix - District unique prefix name
 * @param {str} precint - precint (obreb) field
 */
export const fetchPrecintPacelsBegin = (prefix, precint) => ({
  type: FETCH_PARCELS_LIST_BEGIN,
  prefix,
  precint
});

export const fetchPrecintPacelsSuccess = (prefix, precint, parcels) => ({
  type: FETCH_PARCELS_LIST_SUCCESS,
  parcels,
  prefix,
  precint
});

/**
 * Fetch precint parcels action
 * @param {str} prefix - District unique prefix name
 * @param {str} precint - precint (obreb) field
 */
export const fetchParcelsList = (prefix, precint = null) => (
  dispatch,
  getState
) => {
  dispatch(fetchPrecintPacelsBegin(prefix, precint));

  const url = precint
    ? `${prefix}/get_parcel/?obreb=${precint}`
    : `${prefix}/get_parcel/`;
  return axios
    .get(url)
    .then(response => {
      dispatch(
        fetchPrecintPacelsSuccess(prefix, precint, response.data.features)
      );
    })
    .catch(() => {
      const lang = getState().global.get('language');

      dispatch(showError(reducerTranslations('fetchParcelErrorMessage', lang)));
    });
};

export const fetchDistrictsBegin = () => ({
  type: FETCH_DISTRICTS_BEGIN
});

export const fetchDistrictsSuccess = districts => ({
  type: FETCH_DISTRICTS_SUCCESS,
  districts
});

export const fetchDistrictsFailed = () => ({
  type: FETCH_DISTRICTS_FAILED
});

export const fetchDistricts = () => (dispatch, getState) => {
  dispatch(fetchDistrictsBegin());

  axios
    .get(`districts/`, {
      headers: {
        Authorization: null
      }
    })
    .then(response => {
      dispatch(fetchDistrictsSuccess(response.data));
    })
    .catch(() => {
      const lang = getState().global.get('language');

      dispatch(fetchDistrictsFailed());
      dispatch(
        showError(
          reducerTranslations('fetchAvailablePortalsErrorMessage', lang)
        )
      );
    });
};

export const sortDistrictsBegin = districts => ({
  type: SORT_DISTRICTS_BEGIN,
  districts
});

export const sortDistrictsSuccess = districts => ({
  type: SORT_DISTRICTS_SUCCESS,
  districts
});

export const sortDistrictsFailed = () => ({
  type: SORT_DISTRICTS_FAILED
});

export const sortDistricts = sortedDistrictsId => dispatch => {
  dispatch(sortDistrictsBegin());
  axios
    .post('app_district/order/', sortedDistrictsId)
    .then(() => dispatch(sortDistrictsSuccess()));
};

export const updateDistrictDetailsBegin = prefix => ({
  type: UPDATE_DISTRICT_DETAILS_BEGIN,
  prefix
});

export const updateDistrictDetailsSuccess = (prefix, data) => ({
  type: UPDATE_DISTRICT_DETAILS_SUCCESS,
  data,
  prefix
});

export const updateDistrictDetailsFailed = () => ({
  type: UPDATE_DISTRICT_DETAILS_FAILED
});

export const updateDetails = (prefix, data) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(updateDistrictDetailsBegin(prefix));
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    axios
      .patch(`${prefix}/details/`, data, config)
      .then(response => {
        dispatch(updateDistrictDetailsSuccess(prefix, response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(updateDistrictDetailsFailed());
        reject(err);
      });
  });

export const fetchDistrictDetailsBegin = prefix => ({
  type: FETCH_DISTRICT_DETAILS_BEGIN,
  prefix
});

export const fetchDistrictDetailsSuccess = (prefix, data) => ({
  type: FETCH_DISTRICT_DETAILS_SUCCESS,
  data,
  prefix
});

export const fetchDetails = (prefix, dispatch = null, getState = null) => {
  dispatch(fetchDistrictDetailsBegin(prefix));

  return axios
    .get(`${prefix}/details/`)
    .then(response => {
      dispatch(fetchDistrictDetailsSuccess(prefix, response.data));
    })
    .catch(() => {
      const lang = getState().global.get('language');

      dispatch(
        showError(reducerTranslations('fetchPortalDataErrorMessage', lang))
      );
    });
};

export const fetchDistrictDetails = prefix => (dispatch, getState) => {
  const state = getState();

  if (!prefix) {
    const jobs = [];
    state.district.toJS().districts.forEach(prefix => {
      const job = fetchDetails(prefix, dispatch, getState);
      jobs.push(job);
    });
    return Promise.all(jobs);
  } else {
    return fetchDetails(prefix, dispatch, getState);
  }
};

export const fetchAppDistrictBegin = () => ({
  type: FETCH_APP_DISTRICT_BEGIN
});

export const fetchAppDistrictSuccess = app_district => ({
  type: FETCH_APP_DISTRICT_SUCCESS,
  app_district
});

export const fetchAppDistrict = (searchQuery = '') => (dispatch, getState) => {
  dispatch(fetchAppDistrictBegin());

  return axios
    .get(`app_district/`, {
      params: searchQuery ? { search: searchQuery } : {},
      headers: {
        Authorization: null
      }
    })
    .then(response => {
      dispatch(fetchAppDistrictSuccess(response.data));
    })
    .catch(() => {
      const lang = getState().global.get('language');

      dispatch(
        showError(
          reducerTranslations('fetchAvailablePortalsErrorMessage', lang)
        )
      );
    });
};

export const updateAppDistrictBegin = () => ({
  type: UPDATE_APP_DISTRICT_BEGIN
});

export const updateAppDistrictSuccess = () => ({
  type: UPDATE_APP_DISTRICT_SUCCESS
});

export const updateAppDistrictFailed = () => ({
  type: UPDATE_APP_DISTRICT_FAILED
});

export const updateAppDistrict = (districtId, data) => (dispatch, getState) => {
  dispatch(updateAppDistrictBegin());
  return axios
    .patch(`app_district/${districtId}/`, data)
    .then(response => {
      dispatch(updateAppDistrictSuccess());
      dispatch(showSuccess('Pomyślnie zaktualizowano ustawienia.'));
    })
    .catch(() => {
      dispatch(showError('Wystąpił błąd podczas aktualizacji.'));
      dispatch(updateAppDistrictFailed());
    });
};

export const fetchDistrictRolesBegin = () => ({
  type: FETCH_DISTRICT_ROLES_BEGIN
});

export const fetchDistrictRolesSuccess = (district, roles) => ({
  type: FETCH_DISTRICT_ROLES_SUCCESS,
  district,
  roles
});

export const fetchDistrictRolesFailed = () => ({
  type: FETCH_DISTRICT_ROLES_FAILED
});

export const fetchDistrictRoles = district => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(fetchDistrictRolesBegin());

    axios
      .get(`${district}/user_roles/`)
      .then(response => {
        dispatch(fetchDistrictRolesSuccess(district, response.data));
        resolve(response.data);
      })
      .catch(err => {
        const lang = getState().global.get('language');

        dispatch(fetchDistrictRolesFailed());
        dispatch(
          showError(reducerTranslations('fetchDistrictRolesErrorMessage', lang))
        );
        reject(err);
      });
  });

export const fetchDistrictRolesWithSuperAdminBegin = () => ({
  type: FETCH_DISTRICT_ROLES_WITH_SUPERUSER_BEGIN
});

export const fetchDistrictRolesWithSuperAdminSuccess = (district, roles) => ({
  type: FETCH_DISTRICT_ROLES_WITH_SUPERUSER_SUCCESS,
  district,
  roles
});

export const fetchDistrictRolesWithSuperAdminFailed = () => ({
  type: FETCH_DISTRICT_ROLES_WITH_SUPERUSER_FAILED
});

export const fetchDistrictRolesWithSuperAdmin = district => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    dispatch(fetchDistrictRolesWithSuperAdminBegin());

    axios
      .get(`${district}/user_roles/?with_admin=true`)
      .then(response => {
        dispatch(
          fetchDistrictRolesWithSuperAdminSuccess(district, response.data)
        );
        resolve(response.data);
      })
      .catch(err => {
        const lang = getState().global.get('language');

        dispatch(fetchDistrictRolesWithSuperAdminFailed());
        dispatch(
          showError(reducerTranslations('fetchDistrictRolesErrorMessage', lang))
        );
        reject(err);
      });
  });

export const setDistrictBounds = (bounds, districtName) => ({
  type: SET_DISTRICT_BOUNDS,
  bounds,
  districtName
});

export const clearDistrictsDataReducerState = () => ({
  type: CLEAR_DISTRICTS_DATA_REDUCER_STATE
});

export const clearDistricAppearances = () => ({
  type: CLEAR_DISTRICT_APPEARANCES
});

export const fetchDistrictAppearancesBegin = () => ({
  type: FETCH_DISTRICT_APPEARANCES_BEGIN
});

export const fetchDistrictAppearancesSuccess = (district, appearances) => ({
  type: FETCH_DISTRICT_APPEARANCES_SUCCESS,
  district,
  appearances
});

export const fetchDistrictAppearancesFailed = () => ({
  type: FETCH_DISTRICT_APPEARANCES_FAILED
});

export const fetchDistrictAppearances = config => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(fetchDistrictAppearancesBegin());
    const { prefix, resultsPerPage, pageNumber } = config;

    axios
      .get(`${prefix}/appearance/`, {
        params: {
          page_size: resultsPerPage,
          page: pageNumber
        }
      })
      .then(response => {
        dispatch(fetchDistrictAppearancesSuccess(prefix, response.data));
        resolve(response.data);
      })
      .catch(err => {
        const lang = getState().global.get('language');

        dispatch(fetchDistrictAppearancesFailed());
        dispatch(
          showError(
            reducerTranslations('fetchDistrictAppearancesErrorMessage', lang)
          )
        );
        reject(err);
      });
  });

export const getGlobalThemesBegin = () => ({
  type: FETCH_GLOBAL_THEMES_BEGIN
});

export const getGlobalThemesSuccess = (themes, count) => ({
  type: FETCH_GLOBAL_THEMES_SUCCESS,
  themes,
  count
});

export const getGlobalThemesFailed = () => ({
  type: FETCH_GLOBAL_THEMES_FAILED
});

export const getGlobalThemes = ({ resultsPerPage, page }) => dispatch => {
  dispatch(getGlobalThemesBegin());

  axios
    .get(`/global_theme/`, {
      params: {
        page_size: resultsPerPage,
        page
      }
    })
    .then(({ data: { results, count } }) => {
      dispatch(getGlobalThemesSuccess(results, count));
    })
    .catch(error => {
      dispatch(getGlobalThemesFailed());
      dispatch(showError(error));
    });
};

export const deleteGlobalThemesStarted = () => ({
  type: DELETE_GLOBAL_THEME_STARTED
});

export const deleteGlobalThemesSuccess = () => ({
  type: DELETE_GLOBAL_THEME_SUCCESS
});

export const deleteGlobalThemesFailed = () => ({
  type: DELETE_GLOBAL_THEME_FAILED
});

export const deleteGlobalThemes = (
  globalThemeId,
  filteredThemes,
  count
) => dispatch => {
  dispatch(deleteGlobalThemesStarted());

  axios
    .delete(`/global_theme/${globalThemeId}`)
    .then(() => {
      dispatch(deleteGlobalThemesSuccess());
      dispatch(showSuccess('Motyw został usunięty'));
      dispatch(getGlobalThemesSuccess(filteredThemes, count));
    })
    .catch(error => {
      dispatch(deleteGlobalThemesFailed());
      dispatch(showError(error));
    });
};

export const setGlobalThemeLoading = isLoading => ({
  type: SET_GLOBAL_THEME_LOADING,
  isLoading
});

export const addGlobalThemeStarted = () => ({
  type: ADD_GLOBAL_THEME_STARTED
});

export const addGlobalTheme = (theme, controller, resetForm) => dispatch => {
  dispatch(addGlobalThemeStarted());
  dispatch(setGlobalThemeLoading(true));

  axios
    .post(`/global_theme/`, theme, { signal: controller.signal })
    .then(() => {
      dispatch(showSuccess('Motyw globalny został dodany'));
      resetForm();
    })
    .catch(error => {
      dispatch(showError(error));
    })
    .finally(() => {
      dispatch(setGlobalThemeLoading(false));
    });
};

export const getSingleGlobalThemeStarted = () => ({
  type: GET_SINGLE_GLOBAL_THEME_STARTED
});

export const getSingleGlobalThemeSuccess = singleTheme => ({
  type: GET_SINGLE_GLOBAL_THEME_SUCCESS,
  singleTheme
});

export const getSingleGlobalThemeFailed = () => ({
  type: GET_SINGLE_GLOBAL_THEME_FAILED
});

export const fetchSingleGlobalTheme = themeId => dispatch => {
  dispatch(getSingleGlobalThemeStarted());

  axios
    .get(`/global_theme/${themeId}/`)
    .then(({ data }) => {
      dispatch(getSingleGlobalThemeSuccess(data));
    })
    .catch(error => {
      dispatch(getSingleGlobalThemeFailed());
      dispatch(showError(error));
    });
};

export const editSingleGlobalThemeStarted = () => ({
  type: EDIT_SINGLE_GLOBAL_THEME_STARTED
});

export const editSingleGlobalTheme = (editedTheme, controller) => dispatch => {
  dispatch(editSingleGlobalThemeStarted());
  dispatch(setGlobalThemeLoading(true));
  const { signal } = controller;

  axios
    .put(`/global_theme/${editedTheme.id}/`, editedTheme, { signal })
    .then(() => {
      dispatch(showSuccess('Edycja motywu przebiegła pomyślnie'));
    })
    .catch(error => {
      dispatch(showError(error));
    })
    .finally(() => dispatch(setGlobalThemeLoading(false)));
};

export const addDistrictAppearanceBegin = () => ({
  type: ADD_DISTRICT_APPEARANCE_BEGIN
});

export const addDistrictAppearanceSuccess = (district, appearance) => ({
  type: ADD_DISTRICT_APPEARANCE_SUCCESS,
  district,
  appearance
});

export const addDistrictAppearanceFailed = () => ({
  type: ADD_DISTRICT_APPEARANCE_FAILED
});

export const addDistrictAppearance = (district, appearance) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    dispatch(addDistrictAppearanceBegin());

    const lang = getState().global.get('language');

    axios
      .post(`${district}/appearance/`, appearance)
      .then(response => {
        dispatch(addDistrictAppearanceSuccess(district, response.data));
        dispatch(
          showSuccess(
            reducerTranslations('addDistrictAppearanceSuccessMessage', lang)
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(addDistrictAppearanceFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const editDistrictAppearanceBegin = () => ({
  type: EDIT_DISTRICT_APPEARANCE_BEGIN
});

export const editDistrictAppearanceSuccess = (district, appearance) => ({
  type: EDIT_DISTRICT_APPEARANCE_SUCCESS,
  district,
  appearance
});

export const editDistrictAppearanceFailed = () => ({
  type: EDIT_DISTRICT_APPEARANCE_FAILED
});

export const editDistrictAppearance = (district, appearance) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    dispatch(editDistrictAppearanceBegin());

    const lang = getState().global.get('language');

    axios
      .patch(`${district}/appearance/${appearance.id}/`, appearance)
      .then(response => {
        dispatch(editDistrictAppearanceSuccess(district, appearance));
        dispatch(
          showSuccess(
            reducerTranslations('editDistrictAppearanceSuccessMessage', lang)
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(editDistrictAppearanceFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const deleteDistrictAppearanceBegin = () => ({
  type: DELETE_DISTRICT_APPEARANCE_BEGIN
});

export const deleteDistrictAppearanceSuccess = (district, appearanceId) => ({
  type: DELETE_DISTRICT_APPEARANCE_SUCCESS,
  district,
  appearanceId
});

export const deleteDistrictAppearanceFailed = () => ({
  type: DELETE_DISTRICT_APPEARANCE_FAILED
});

export const deleteDistrictAppearance = (district, appearanceId) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    dispatch(deleteDistrictAppearanceBegin());

    const lang = getState().global.get('language');

    axios
      .delete(`${district}/appearance/${appearanceId}/`)
      .then(response => {
        dispatch(deleteDistrictAppearanceSuccess(district, appearanceId));
        dispatch(
          showSuccess(
            reducerTranslations('deleteDistrictAppearanceSuccessMessage', lang)
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(deleteDistrictAppearanceFailed());
        dispatch(
          showError(
            reducerTranslations('deleteDistrictAppearanceErrorMessage', lang)
          )
        );
        reject(err);
      });
  });

export const fetchPublicMapPortalAppearanceBegin = () => ({
  type: FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_BEGIN
});

export const fetchPublicMapPortalAppearanceSuccess = (
  district,
  id,
  appearance
) => ({
  type: FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_SUCCESS,
  district,
  id,
  appearance
});

export const fetchPublicMapPortalAppearanceFailed = () => ({
  type: FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_FAILED
});

export const fetchPublicMapPortalAppearance = (district, id) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    dispatch(fetchPublicMapPortalAppearanceBegin());

    axios
      .get(`${district}/appearance_list/${id}/`)
      .then(response => {
        dispatch(
          fetchPublicMapPortalAppearanceSuccess(district, id, response.data)
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(fetchPublicMapPortalAppearanceFailed());
        reject(err);
      });
  });

export const setFetchedPublicMapPortalAppearance = () => dispatch => {
  dispatch(fetchPublicMapPortalAppearanceFailed());
};

export const saveAppliedAppearance = appearance => ({
  type: SAVE_APPLIED_APPEARANCE,
  appearance
});

export const clearAppliedAppearance = () => ({
  type: CLEAR_APPLIED_APPEARANCE
});

export const fetchDistrictsSettingsBegin = () => ({
  type: FETCH_DISTRICTS_SETTINGS_BEGIN
});

export const fetchDistrictsSettingsSuccess = settings => ({
  type: FETCH_DISTRICTS_SETTINGS_SUCCESS,
  settings
});

export const fetchDistrictsSettingsFailed = () => ({
  type: FETCH_DISTRICTS_SETTINGS_FAILED
});

export const fetchDistrictsSettings = () => dispatch =>
  axios
    .get(`district_settings/`)
    .then(response => {
      dispatch(fetchDistrictsSettingsSuccess(response.data));
    })
    .catch(error => {
      dispatch(showError('Pobieranie aktualnych ustawień nie powiodło się!'));
    });

export const updateDistrictSettingsBegin = () => ({
  type: UPDATE_DISTRICT_SETTINGS_BEGIN
});

export const updateDistrictSettingsSuccess = settings => ({
  type: UPDATE_DISTRICT_SETTINGS_SUCCESS,
  settings
});

export const updateDistrictSettingsFailed = () => ({
  type: UPDATE_DISTRICT_SETTINGS_FAILED
});

export const updateDistrictSettings = (id, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateDistrictSettingsBegin());

    axios
      .patch(`district_settings/${id}/`, data)
      .then(response => {
        const { data: settings } = response;
        dispatch(updateDistrictSettingsSuccess(settings));
        resolve(settings);
      })
      .catch(error => {
        dispatch(updateDistrictSettingsFailed());
        reject(error);
      });
  });

export const fetchDistrictSettingsSuccess = settings => ({
  type: FETCH_DISTRICT_SETTINGS_SUCCESS,
  settings
});

export const fetchDistrictSettingsFailed = () => ({
  type: FETCH_DISTRICT_SETTINGS_FAILED
});

export const fetchDistrictSettings = prefix => dispatch =>
  axios
    .get(`${prefix}/settings/`)
    .then(response => {
      dispatch(fetchDistrictSettingsSuccess(response.data));
    })
    .catch(error => {
      dispatch(fetchDistrictSettingsFailed());
    });

export const getGlobalDistrictSettingsStarted = () => ({
  type: GET_GLOBAL_DISTRICT_SETTINGS_STARTED
});
export const getGlobalDistrictSettingsSuccess = globalSettings => ({
  type: GET_GLOBAL_DISTRICT_SETTINGS_SUCCESS,
  globalSettings
});

export const getGlobalDistrictSettingsFailed = () => ({
  type: GET_GLOBAL_DISTRICT_SETTINGS_FAILED
});

export const getGlobalDistrictSettings = () => dispatch =>
  axios
    .get(`settings/`)
    .then(response => {
      dispatch(getGlobalDistrictSettingsSuccess(response));
    })
    .catch(error => {
      dispatch(getGlobalDistrictSettingsFailed());
    });

export const getAllDistrictTagsBegin = () => ({
  type: GET_ALL_DISTRICT_TAGS_BEGIN
});

export const getAllDistrictTagsSuccess = (district, tags) => ({
  type: GET_ALL_DISTRICT_TAGS_SUCCESS,
  district,
  tags
});

export const getAllDistrictTagsFailed = () => ({
  type: GET_ALL_DISTRICT_TAGS_FAILED
});

export const getAllDistrictTags = district => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getAllDistrictTagsBegin());

    axios
      .get(`${district}/tags/map_portal_composition/`)
      .then(({ data }) => {
        dispatch(getAllDistrictTagsSuccess(district, data));
        resolve(data);
      })
      .catch(err => {
        dispatch(getAllDistrictTagsFailed());
        reject(err);
      });
  });

export const updateDistrictBorderBegin = () => ({
  type: UPDATE_DISTRICT_BORDER_BEGIN
});

export const updateDistrictBorderSuccess = () => ({
  type: UPDATE_DISTRICT_BORDER_SUCCESS
});

export const updateDistrictBorderFailed = () => ({
  type: GET_ALL_DISTRICT_TAGS_FAILED
});

export const updateDistrictBorder = (district, data) => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch(updateDistrictBorderBegin());

    try {
      const resp = await axios.post(
        `${district}/settings/update_district_border/`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      dispatch(updateDistrictBorderSuccess());
      resolve(resp);
    } catch (err) {
      dispatch(updateDistrictBorderFailed());
      reject(err);
    }
  });

export const fetchPortal3dSettingsBegin = () => ({
  type: FETCH_PORTAL_3D_SETTINGS_BEGIN
});

export const fetchPortal3dSettingsSuccess = (district, settings) => ({
  type: FETCH_PORTAL_3D_SETTINGS_SUCCESS,
  district,
  settings
});

export const fetchPortal3dSettingsFailed = () => ({
  type: FETCH_PORTAL_3D_SETTINGS_FAILED
});

export const fetchPortal3dSettings = district => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchPortal3dSettingsBegin());

    axios
      .get(`${district}/portal3d/`)
      .then(({ data }) => {
        dispatch(fetchPortal3dSettingsSuccess(district, data.results));
        resolve(data);
      })
      .catch(err => {
        dispatch(fetchPortal3dSettingsFailed());
        reject(err);
      });
  });

export const fetchOtherTopicalDataSettingsBegin = () => ({
  type: FETCH_OTHER_TOPICAL_DATA_SETTINGS_BEGIN
});

export const fetchOtherTopicalDataSettingsSuccess = (district, settings) => ({
  type: FETCH_OTHER_TOPICAL_DATA_SETTINGS_SUCCESS,
  district,
  settings
});

export const fetchOtherTopicalDataSettingsFailed = () => ({
  type: FETCH_OTHER_TOPICAL_DATA_SETTINGS_FAILED
});

export const fetchOtherTopicalDataSettings = district => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchOtherTopicalDataSettingsBegin());

    axios
      .get(`${district}/other_topical_data/`)
      .then(({ data }) => {
        dispatch(fetchOtherTopicalDataSettingsSuccess(district, data.results));
        resolve(data);
      })
      .catch(err => {
        dispatch(fetchOtherTopicalDataSettingsFailed());
        reject(err);
      });
  });

export const fetchQRCodesConfigBegin = () => ({
  type: GET_DISTRICT_QRCODES_CONFIGURATION_BEGIN
});

export const fetchQRCodesConfigSuccess = (district, qrCodesConfig) => ({
  type: GET_DISTRICT_QRCODES_CONFIGURATION_SUCCESS,
  district,
  qrCodesConfig
});

export const fetchQRCodesConfigFailed = () => ({
  type: GET_DISTRICT_QRCODES_CONFIGURATION_FAILED
});

export const fetchQRCodesConfig = config => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchQRCodesConfigBegin());
    const { prefix, resultsPerPage, page } = config;
    axios
      .get(`${prefix}/qrcodes/configuration/`, {
        params: {
          page_size: resultsPerPage,
          page
        }
      })
      .then(({ data }) => {
        dispatch(fetchQRCodesConfigSuccess(prefix, data.results));
        resolve(data);
      })
      .catch(err => {
        dispatch(fetchQRCodesConfigFailed());
        reject(err);
      });
  });

export const saveQRCodesConfigBegin = () => ({
  type: SAVE_DISTRICT_QRCODES_CONFIGURATION_BEGIN
});

export const saveQRCodesConfigSuccess = (district, qrCodesConfig) => ({
  type: SAVE_DISTRICT_QRCODES_CONFIGURATION_SUCCESS,
  district,
  qrCodesConfig
});

export const saveQRCodesConfigFailed = () => ({
  type: SAVE_DISTRICT_QRCODES_CONFIGURATION_FAILED
});

export const saveQRCodesConfig = (district, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(saveQRCodesConfigBegin());

    axios
      .post(`${district}/qrcodes/configuration/`, data)
      .then(({ data }) => {
        dispatch(saveQRCodesConfigSuccess(district, data.results));
        resolve(data);
      })
      .catch(err => {
        dispatch(saveQRCodesConfigFailed());
        reject(err);
      });
  });

export const deleteQRCodeConfigBegin = () => ({
  type: DELETE_DISTRICT_QRCODE_CONFIGURATION_BEGIN
});

export const deleteQRCodeConfigSuccess = (district, qrCodesConfig) => ({
  type: DELETE_DISTRICT_QRCODE_CONFIGURATION_SUCCESS,
  district,
  qrCodesConfig
});

export const deleteQRCodeConfigFailed = () => ({
  type: DELETE_DISTRICT_QRCODE_CONFIGURATION_FAILED
});

export const deleteQRCodeConfig = (district, id) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteQRCodeConfigBegin());

    axios
      .delete(`${district}/qrcodes/configuration/${id}`)
      .then(({ data }) => {
        dispatch(deleteQRCodeConfigSuccess(district, data.results));
        resolve(data);
      })
      .catch(err => {
        dispatch(deleteQRCodeConfigFailed());
        reject(err);
      });
  });

export const createQRCodesBegin = () => ({
  type: CREATE_QRCODES_BEGIN
});

export const createQRCodesSuccess = () => ({
  type: CREATE_QRCODES_SUCCESS
});

export const createQRCodesFailed = () => ({
  type: CREATE_QRCODES_FAILED
});

export const createQRCodes = (district, configId) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(createQRCodesBegin());

    axios
      .post(`${district}/qrcodes/run/${configId}/`)
      .then(({ data }) => {
        dispatch(createQRCodesSuccess());
        resolve(data);
      })
      .catch(err => {
        dispatch(createQRCodesFailed());
        reject(err);
      });
  });

export const changeSearchInputValue = value => ({
  type: CHANGE_SEARCH_INPUT_VALUE,
  value
});
