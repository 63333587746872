import { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Modal, Table, Pagination } from 'antd';

import { fetchReportHistory } from '../../store/actions/reportsActions';

import { nestedCaller, capitalizeFirstLetter } from '../../utils/lib';
import { DATA_FORMAT_WITH_TIME } from '../../utils/constants';
import { SingleReportContext } from '../../views/AdminSingleReportView/SingleReportContext';

import './AdminReportHistory.less';
import { useIntl } from 'react-intl';

export const AdminReportHistory = props => {
  const { formatMessage } = useIntl();

  const { openHistory, setOpenHistory } = useContext(SingleReportContext);

  const reportHistory = nestedCaller(props, 'reportHistory', 'toJS');
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (props.report?.id) {
      props.fetchReportHistory(props.report.id, page);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.report?.id) {
      props.fetchReportHistory(props.report.id, page);
    }
  }, [page, openHistory]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      title: formatMessage({
        id: 'admin_single_report_office_notes_change_field',
        defaultMessage: 'Changed field'
      }),
      dataIndex: 'field_name',
      key: 'field_name'
    },
    {
      title: formatMessage({
        id: 'admin_single_report_office_notes_old_value',
        defaultMessage: 'Previous value'
      }),
      dataIndex: 'old_value',
      key: 'old_value'
    },
    {
      title: formatMessage({
        id: 'admin_single_report_office_notes_new_value',
        defaultMessage: 'New value'
      }),
      dataIndex: 'new_value',
      key: 'new_value'
    },
    {
      title: formatMessage({
        id: 'admin_single_report_office_notes_created',
        defaultMessage: 'Date of change'
      }),
      dataIndex: 'created',
      key: 'created'
    }
  ];

  const data = () => {
    return (
      reportHistory &&
      reportHistory.results &&
      reportHistory.results.map(v => {
        let field = '-';
        let oldValue = '-';
        let newValue = '-';

        if (v.field_name) {
          field = capitalizeFirstLetter(v.field_name);
        }

        if (v.old_value) {
          oldValue = capitalizeFirstLetter(v.old_value);

          if (v.old_value === 'None') oldValue = '-';
          if (v.old_value === 'True') oldValue = 'Tak';
          if (v.old_value === 'False') oldValue = 'Nie';
        }

        if (v.new_value) {
          newValue = capitalizeFirstLetter(v.new_value);

          if (v.new_value === 'None') newValue = '-';
          if (v.new_value === 'True') newValue = 'Tak';
          if (v.new_value === 'False') newValue = 'Nie';
        }

        return {
          field_name: field,
          old_value: oldValue,
          new_value: newValue,
          created: moment(v.created).format(DATA_FORMAT_WITH_TIME)
        };
      })
    );
  };

  return (
    <Modal
      wrapClassName="modal-wraper admin-report-history"
      shadingColor={'white'}
      title={formatMessage({
        id: 'admin_single_report_office_notes_history_report',
        defaultMessage: 'History of report editing'
      })}
      visible={openHistory}
      onCancel={() => setOpenHistory(openHistory => !openHistory)}
      footer={false}
    >
      <Table
        loading={props.fetchingReportHistory}
        columns={columns}
        dataSource={data()}
        size="small"
        pagination={false}
        bodyStyle={{ overflowX: 'scroll' }}
      />
      {reportHistory.count > 5 ? (
        <Pagination
          defaultCurrent={page}
          pageSize={5}
          total={reportHistory.count}
          onChange={e => setPage(e)}
          style={{ marginTop: 15, textAlign: 'center' }}
        />
      ) : null}
    </Modal>
  );
};

const mapStateToProps = state => ({
  reportHistory: state.reports.get('reportHistory'),
  fetchingReportHistory: state.reports.get('fetchingReportHistory')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchReportHistory
    },
    dispatch
  );

AdminReportHistory.propTypes = {
  fetchingReportHistory: PropTypes.bool,
  reportHistory: ImmutablePropTypes.map,
  fetchReportHistory: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminReportHistory));
