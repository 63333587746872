import { useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { feature } from '@turf/helpers';
import { useParams } from 'react-router-dom';

import useMap from '../useMap';

import { setTypeClick } from '../../store/actions/mapActions';
import { mapPortalSelectors } from '../../store/selectors';

import { enumClickTypes } from '../../mapConfig';
import { eventTypes as creationEventTypes } from '../../components/MapControl/layer-creator/const';
import { GEOMETRY_TYPES } from '../../constants/map';
import { IDENTIFICATION_TYPE } from '../../constants/backend-keys';

const { getCurrentComposition } = mapPortalSelectors;

const useHandleDrawingGeometry = ({
  isDrawingModeActive,
  setDrawnGeometry
}) => {
  const map = useMap();
  const dispatch = useDispatch();
  const { prefix, portal } = useParams();

  const { identification_type } = useSelector(state =>
    getCurrentComposition(state, prefix, portal)
  );

  const editionId = useRef(undefined);

  const getCurrentEdition = useCallback(
    () => editionId.current && map.getEdition(editionId.current),
    []
  );

  const closeCurrentEdition = useCallback(() => {
    const currentEdition = getCurrentEdition();
    currentEdition?.close?.();
  }, []);

  const initializeLayerEditor = useCallback((geometry, geometryType) => {
    // Drawing editionVertexes for the points is necessary, as without it, adding points
    // to the map does not work correctly.
    const config = {
      drawEditionVertexes: geometryType.includes(GEOMETRY_TYPES.POINT)
    };

    const id = map.editLayer(feature(geometry), geometryType, config);
    editionId.current = id;
  }, []);

  const getDrawnShapeGeometry = useCallback(
    (geometryType, coords) => {
      const multiGeometryType = `Multi${geometryType}`;
      const currentEdition = getCurrentEdition();

      const geometry = {
        type: multiGeometryType,
        coordinates: [coords]
      };

      if (currentEdition) {
        const editionGeoJson = currentEdition.getLayer().toGeoJSON();
        geometry.coordinates = [
          ...geometry.coordinates,
          ...editionGeoJson.geometry.coordinates
        ];

        currentEdition.close();
      }

      return geometry;
    },
    [getCurrentEdition]
  );

  const handleCreatedLayer = useCallback(
    ({ geometryType, coords }) => {
      const geometry = getDrawnShapeGeometry(geometryType, coords);
      const drawnGeometry = {
        coordinates: geometry.coordinates,
        type: GEOMETRY_TYPES.MULTI_POLYGON
      };

      setDrawnGeometry(drawnGeometry);

      // We move the call to the end of the call stack,
      // because need to wait for the execution of ongoing asynchronous actions/callbacks.
      setTimeout(() => {
        initializeLayerEditor(geometry, geometry.type);
        map.createLayer({
          geometryType
        });
      });
    },
    [getDrawnShapeGeometry, initializeLayerEditor]
  );

  // Drawing mode functions
  const enableDrawingMode = useCallback(() => {
    dispatch(setTypeClick(enumClickTypes.sketchbook));
    map.createLayer({ geometryType: GEOMETRY_TYPES.RECTANGLE });
    map.on(creationEventTypes.layerCreated, handleCreatedLayer);
  }, [handleCreatedLayer]);

  const disableDrawingMode = useCallback(() => {
    map.off(creationEventTypes.layerCreated, handleCreatedLayer);
    closeCurrentEdition();
    map.cancelLayerCreation();
  }, [closeCurrentEdition]);

  useEffect(() => {
    return () => {
      if (identification_type !== IDENTIFICATION_TYPE.OBJECT_INFO) {
        dispatch(setTypeClick(''));
      }
      disableDrawingMode();
    };
  }, []);

  return {
    enableDrawingMode,
    disableDrawingMode
  };
};

export default useHandleDrawingGeometry;
