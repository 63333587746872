import {
  renderCircleIcon,
  renderSquareIcon,
  renderStarIcon,
  renderTriangleIcon
} from '../utils/mapUtils/icons';

export const ICONS_LIST = [
  {
    name: 'StarIcon',
    renderIconFn: renderStarIcon
  },
  {
    name: 'SquareIcon',
    renderIconFn: renderSquareIcon
  },
  {
    name: 'CircleIcon',
    renderIconFn: renderCircleIcon
  },
  {
    name: 'TriangleIcon',
    renderIconFn: renderTriangleIcon
  }
];
