import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { DATA_FORMAT_NO_TIME } from '../../utils/constants';
import { Input, Select, DatePicker } from 'antd';

import './UserFilter.less';

const Search = Input.Search;
const { Option } = Select;

class UsersFilter extends Component {
  debouncedOnSearch = debounce(this.props.onSearch, 500);

  roles = {
    basic: 'Podstawowa',
    basic_plus: 'Podstawowa plus',
    advanced: 'Zaawansowana',
    administrator: 'Administrator'
  };

  terms = {
    Terminowe: false,
    Bezterminowe: true
  };

  activateStatus = {
    Aktywne: true,
    Zablokowane: false
  };

  handleSearch = e => {
    e.persist();
    this.props.changeFilterValue('query', e.target.value);
    this.debouncedOnSearch();
  };

  onSelectChanged = (filter, value) => {
    if (filter === 'term') {
      value = this.terms[value];
    }

    if (filter === 'isActive') {
      value = this.activateStatus[value];
    }
    this.props.changeFilterValue(filter, value);
    this.debouncedOnSearch();
  };

  dateValueFormatter = value => {
    if (value) {
      return value.format(DATA_FORMAT_NO_TIME);
    }
    return null;
  };

  render() {
    return (
      <div className="filterDiv">
        <div className="inputsDiv">
          <p>Filtr</p>
          <Search
            id="searchInput"
            onChange={this.handleSearch}
            onPressEnter={this.props.onSearch}
            placeholder="Szukaj"
          />
        </div>
        <div className="inputsDiv">
          <span style={{ padding: '10px 15px 10px 0' }}>Dodatkowe filtry</span>
          <Select
            allowClear
            placeholder="Rola"
            className="filters-style"
            onChange={value => this.onSelectChanged('roles', value)}
          >
            {Object.keys(this.roles).map((key, id) => (
              <Option key={`role_id_${id}`} value={key}>
                {this.roles[key]}
              </Option>
            ))}
          </Select>
          <DatePicker
            placeholder="Data od"
            className="filters-style"
            onChange={value =>
              this.onSelectChanged('dateFrom', this.dateValueFormatter(value))
            }
          />
          <DatePicker
            placeholder="Data do"
            className="filters-style"
            onChange={value =>
              this.onSelectChanged('dateTo', this.dateValueFormatter(value))
            }
          />
          <Select
            allowClear
            placeholder="Terminowe/Bezterminowe"
            className="filters-style"
            onChange={value => this.onSelectChanged('term', value)}
          >
            {Object.keys(this.terms).map((term, id) => {
              return (
                <Option value={term} key={`term_id_${id}`}>
                  {term}
                </Option>
              );
            })}
          </Select>
          <Select
            allowClear
            placeholder="Aktywne/Zablokowane"
            className="filters-style"
            onChange={value => this.onSelectChanged('isActive', value)}
          >
            {Object.keys(this.activateStatus).map((status, id) => (
              <Option key={`active_id_${id}`} value={status}>
                {status}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    );
  }
}

UsersFilter.propTypes = {
  onSearch: PropTypes.func.isRequired,
  changeFilterValue: PropTypes.func.isRequired
};

export default UsersFilter;
