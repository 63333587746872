import { useIntl } from 'react-intl';
import { FILL_TYPE_KEYS } from '../../components/MapControl/layer-editor/const';

const useFillTypeOptions = () => {
  const { formatMessage } = useIntl();

  const standardFillTypeMsg = formatMessage({
    id: 'standard_fill_type',
    defaultMessage: 'Standard'
  });

  const circleFillTypeMsg = formatMessage({
    id: 'circle_fill_type',
    defaultMessage: 'Circle'
  });

  const rectangularFillTypeMsg = formatMessage({
    id: 'rectangular_fill_type',
    defaultMessage: 'Rectangular'
  });

  const triangularFillTypeMsg = formatMessage({
    id: 'triangular_fill_type',
    defaultMessage: 'Triangular'
  });

  const horizontalStripesFillTypeMsg = formatMessage({
    id: 'horizontal_stripes_fill_type',
    defaultMessage: 'Horizontal stripes'
  });

  const diagonalStripesFillTypeMsg = formatMessage({
    id: 'diagonal_stripes_fill_type',
    defaultMessage: 'Diagonal stripes'
  });

  const FILL_TYPE_OPTIONS = [
    {
      key: FILL_TYPE_KEYS.STANDARD,
      label: standardFillTypeMsg
    },
    {
      key: FILL_TYPE_KEYS.CIRCLE,
      label: circleFillTypeMsg
    },
    {
      key: FILL_TYPE_KEYS.RECTANGULAR,
      label: rectangularFillTypeMsg
    },
    {
      key: FILL_TYPE_KEYS.TRIANGULAR,
      label: triangularFillTypeMsg
    },
    {
      key: FILL_TYPE_KEYS.HORIZONTAL_STRIPES,
      label: horizontalStripesFillTypeMsg
    },
    {
      key: FILL_TYPE_KEYS.DIAGONAL_STRIPES,
      label: diagonalStripesFillTypeMsg
    }
  ];

  return FILL_TYPE_OPTIONS;
};

export default useFillTypeOptions;
