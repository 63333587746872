import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { Button } from 'antd';

import { mapSelectors } from '../../store/selectors';
import { showSuccess } from '../../store/actions/globalActions';

import CopyCoordinatesButton from '../CopyCoordinatesButton';
import { getCursorCoordinates } from '../../utils/mapUtils';
import useMeasureToolTranslation from '../MeasureTools/components/hooks/useMeasureToolTranslation';

import 'simplebar/dist/simplebar.min.css';
import './CoordinatesList.less';

const { getCurrentCursorCrs, convertCoordinatesToHDMS } = mapSelectors;

const style = { maxHeight: 120, width: '95%' };

const CoordinatesList = props => {
  const dispatch = useDispatch();
  const { pointText, toasterCopiedText, copyAll } = useMeasureToolTranslation();

  const list = props.list.items || props.list;

  const currentCursorCoordinates = useSelector(state =>
    getCurrentCursorCrs(state)
  );

  const changeCoordinatesToHDMS = useSelector(state =>
    convertCoordinatesToHDMS(state)
  );

  const generateCoordinateText = ({ lat, lng }, i) => {
    const { lat: cursorLat, lng: cursorLng } = getCursorCoordinates({
      currentCursorCrs: currentCursorCoordinates,
      coordinates: [lat, lng],
      showHDMS: changeCoordinatesToHDMS
    });
    return `${pointText} ${i + 1}: (${cursorLat}, ${cursorLng})`;
  };

  const copyAllPoints = () => {
    const allPointsText = list.map(generateCoordinateText).join('\n');
    navigator.clipboard.writeText(allPointsText);
    dispatch(showSuccess(toasterCopiedText));
  };

  return (
    <div>
      <SimpleBar style={style}>
        <ul className="coordinates-list">
          {list.map((coordinate, i) => {
            const coordinateText = generateCoordinateText(coordinate, i);
            const { value } = coordinate;
            const { unit } = props.list;

            return (
              <div key={Math.random()} className="single-coordinates-container">
                <li>
                  {coordinateText}
                  <br />
                  {value && `${value}${unit}`}
                </li>
                <CopyCoordinatesButton textToCopy={coordinateText} />
              </div>
            );
          })}
        </ul>
      </SimpleBar>
      <div className="copy-all-container">
        <Button onClick={copyAllPoints}>{copyAll}</Button>
      </div>
    </div>
  );
};

CoordinatesList.propTypes = {
  list: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      items: PropTypes.array,
      unit: PropTypes.string
    })
  ]).isRequired
};

export default CoordinatesList;
