import { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';

import { Row, Col, Popconfirm } from 'antd';
import { Button } from '../../components';

import {
  setSingleReport,
  updateReport,
  deleteReport
} from '../../store/actions/reportsActions';
import { isMobile } from '../../utils/lib';
import { showError, showSuccess } from '../../store/actions/globalActions';

import { SingleReportContext } from '../../views/AdminSingleReportView/SingleReportContext';

import handleError from '../../utils/handleError';
import { adminRoutePaths } from '../../router/admin/paths';
import './AdminReportButtons.less';

export const AdminReportButtons = props => {
  const { setSingleReport, updateReport } = props;
  const { formatMessage } = useIntl();
  const history = useHistory();

  const { prefix } = props;
  const {
    report,
    category,
    subcategory,
    status,
    confirmed,
    setOpenHistory,
    statusDetails,
    clearChanges
  } = useContext(SingleReportContext);

  const showUpdateBtn =
    report &&
    report.properties &&
    (category !== report.properties.category ||
      subcategory !== report.properties.subcategory ||
      status !== report.properties.status ||
      confirmed !== report.properties.confirmed ||
      statusDetails !== report.properties.details_status);

  const update = () => {
    updateReport(
      prefix,
      report.id,
      {
        status,
        category,
        subcategory,
        confirmed,
        details_status: statusDetails
      },
      true
    )
      .then(r => {
        props.showSuccess(
          formatMessage({
            id: 'update_report_message_success',
            defaultMessage: 'Application updated successfully!'
          })
        );
        if (r?.status === 200) {
          setSingleReport(r.data);
        }
      })
      .catch(handleError, err => {
        props.showError(
          formatMessage({
            id: 'update_report_message_error',
            defaultMessage: 'The request could not be updated'
          })
        );
      });
  };

  const deleteReport = () => {
    props.deleteReport(prefix, report.id).then(() => {
      props.showSuccess(
        formatMessage({
          id: 'delete_report_message_success',
          defaultMessage: 'The application has been successfully deleted!'
        })
      );
      if (!props.advanceView) {
        history.push(`/${prefix}/admin${adminRoutePaths.reports}`);
      }
    });

    if (props.advanceView) {
      props.clearDataReport(prefix);
    }
  };

  return (
    <Row gutter={[24, 24]} className="admin-popover-report">
      <Col
        xs={18}
        sm={18}
        md={24}
        lg={24}
        xl={24}
        style={{
          display: isMobile() ? 'block' : 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button id="history" onClick={() => setOpenHistory(true)}>
          {formatMessage({
            id: 'admin_single_report_button_history',
            defaultMessage: 'Change history'
          })}
        </Button>
        {showUpdateBtn && (
          <>
            <Button
              id="clearChanges"
              variant="warning"
              onClick={clearChanges}
              style={{ marginTop: isMobile() ? 10 : null }}
            >
              {formatMessage({
                id: 'admin_single_report_button_clear_change',
                defaultMessage: 'Clear changes'
              })}
            </Button>
            <Button id="update" variant="primary" onClick={() => update()}>
              {formatMessage({
                id: 'admin_single_report_button_update',
                defaultMessage: 'Update'
              })}
            </Button>
          </>
        )}
        <Popconfirm
          placement="topLeft"
          title={formatMessage({
            id: 'admin_single_report_delete_confirm',
            defaultMessage: 'Are you sure you want to delete the application?'
          })}
          onConfirm={deleteReport}
          okText={formatMessage({
            id: 'general_yes',
            defaultMessage: 'Yes'
          })}
          cancelText={formatMessage({
            id: 'general_no',
            defaultMessage: 'no'
          })}
        >
          <Button
            id="delete"
            variant="danger"
            style={{ marginTop: isMobile() ? 10 : null }}
          >
            {formatMessage({
              id: 'admin_single_report_button_delete',
              defaultMessage: 'Delete'
            })}
          </Button>
        </Popconfirm>
      </Col>
    </Row>
  );
};

const mapDispatchToProps = {
  deleteReport,
  updateReport,
  setSingleReport,
  showSuccess,
  showError
};

AdminReportButtons.propTypes = {
  updateReport: PropTypes.func.isRequired,
  setSingleReport: PropTypes.func.isRequired,
  deleteReport: PropTypes.func.isRequired,
  showSuccess: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  clearDataReport: PropTypes.func
};

export default connect(null, mapDispatchToProps)(AdminReportButtons);
