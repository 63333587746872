import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { showSuccess } from '../../store/actions/globalActions';

const useOnError = () => {
  const dispatch = useDispatch();

  const handleError = useCallback(message => dispatch(showSuccess(message)), [
    dispatch,
    showSuccess
  ]);

  return handleError;
};

export default useOnError;
