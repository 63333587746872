export default function renderSquareIcon(iconSize, fill) {
  return `<svg 
        id="squareIcon" 
        fill="${fill}"
        width="${iconSize}px"
        height="${iconSize}px" 
        enable-background="new 0 0 506.1 506.1" 
        viewBox="0 0 506.1 506.1" 
        xmlns="http://www.w3.org/2000/svg">
        <path d="m489.609 0h-473.118c-9.108 0-16.491 7.383-16.491 16.491v473.118c0 9.107 7.383 16.491 16.491 16.491h473.119c9.107 0 16.49-7.383 16.49-16.491v-473.118c0-9.108-7.383-16.491-16.491-16.491z"/>
        </svg>`;
}
