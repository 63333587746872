import { Map, List } from 'immutable';

import {
  FETCH_SEARCHABLE_MODELS_SUCCESS,
  FETCH_DISTRICT_SEARCHABLE_MODELS_SUCCESS,
  FETCH_DISTRICT_SEARCHABLE_MODELS_BEGIN,
  FETCH_DISTRICT_SEARCHABLE_MODELS_FAILED,
  FETCH_NAMES_MODELS_BEGIN,
  FETCH_NAMES_MODELS_SUCCESS,
  FETCH_NAMES_MODELS_FAILED,
  FETCH_DATA_MODEL_BEGIN,
  FETCH_DATA_MODEL_SUCCESS,
  FETCH_DATA_MODEL_FAILED,
  FETCH_OPTIONS_MODEL_BEGIN,
  FETCH_OPTIONS_MODEL_SUCCESS,
  FETCH_OPTIONS_MODEL_FAILED,
  UPDATE_DATA_MODEL_BEGIN,
  UPDATE_DATA_MODEL_SUCCESS,
  UPDATE_DATA_MODEL_FAILED,
  GET_MODELS_TRANSLATIONS_SUCCESS,
  ADD_MODEL_TRANSLATION_SUCCESS,
  EDIT_MODEL_TRANSLATION_SUCCESS,
  DELETE_MODEL_TRANSLATION_SUCCESS,
  FETCH_CONTENT_TYPE_LAYERS_BEGIN,
  FETCH_CONTENT_TYPE_LAYERS_SUCCESS,
  FETCH_CONTENT_TYPE_LAYERS_FAILED
} from '../constants/modelsActionTypes';

const initialState = Map({
  searchableModels: List([]),
  districtSearchableModels: Map({}),
  fetchedSearchableModels: false,
  fetchingNamesModels: false,
  fetchedNamesModels: false,
  namesModels: Map({}),
  fetchingDataModel: false,
  dataModels: Map({}),
  optionsModels: Map({}),
  fetchingOptionsModels: false,
  updateingDataModel: false,
  modelsTranslations: Map({}),
  fetchingDistrictSearchableModels: false,
  contentTypesLayers: List([]),
  fetchingContentTypesLayers: false
});

const fetchSearchableModelsSuccess = (state, action) => {
  let newState = state.set('searchableModels', List(action.searchableModels));
  newState = newState.set('fetchedSearchableModels', true);
  return newState;
};

const fetchDistrictSearchableModelsSuccess = (state, { district, data }) =>
  state
    .merge({ fetchingDistrictSearchableModels: false })
    .setIn(['districtSearchableModels', district], List(data));

const fetchDistrictSearchableModelsBegin = state =>
  state.merge({
    fetchingDistrictSearchableModels: true
  });

const fetchDistrictSearchableModelsFailed = state =>
  state.merge({
    fetchingDistrictSearchableModels: false
  });

const fetchNamesModelsBegin = state =>
  state.merge({
    fetchingNamesModels: true,
    fetchedNamesModels: false
  });

const fetchNamesModelsSuccess = (state, { models, compositionId }) => {
  if (compositionId)
    return state
      .merge({
        fetchingNamesModels: false,
        fetchedNamesModels: true
      })
      .setIn(['compositionNameModels', compositionId], Map(models));
  return state.merge({
    fetchingNamesModels: false,
    fetchedNamesModels: true,
    namesModels: Map(models)
  });
};

const fetchNamesModelsFailed = state =>
  state.merge({
    fetchingNamesModels: false,
    fetchedNamesModels: false
  });

const fetchDataModelBegin = state =>
  state.merge({
    fetchingDataModel: true
  });

const fetchDataModelSuccess = (state, action) =>
  state.merge({
    fetchingDataModel: false,
    dataModels: Map(action.dataModel)
  });

const fetchDataModelFailed = (state, action) =>
  state.merge({
    fetchingDataModel: false,
    dataModels: Map({})
  });

const fetchOptionsModelBegin = state =>
  state.merge({
    fetchingOptionsModels: true
  });

const fetchOptionsModelSuccess = (state, action) =>
  state.merge({
    fetchingOptionsModels: false,
    optionsModels: Map(action.options)
  });

const fetchOptionsModelFailed = state =>
  state.merge({
    fetchingOptionsModels: false
  });

const updateDataModelBegin = state =>
  state.merge({
    updateingDataModel: true
  });

const updateDataModelEnd = state =>
  state.merge({
    updateingDataModel: false
  });

const updateDataModelSuccess = (state, action) => {
  if (!action.updatedModel) return state;
  const newModel = { ...state.get('dataModels').toJS() };

  newModel.results.features
    .filter(model => model.id !== action.id)
    .push(action.updatedModel);

  return state.merge({
    dataModels: Map(newModel)
  });
};

const getModelsTranslationsSuccess = (state, { mapPortalId, translations }) =>
  state.setIn(['modelsTranslations', +mapPortalId], List(translations));

const addModelTranslationSuccess = (state, { mapPortalId, translation }) =>
  state.updateIn(['modelsTranslations', +mapPortalId], List(), list =>
    List([...list, translation])
  );

const editModelTranslationSuccess = (state, { mapPortalId, translation }) =>
  state.updateIn(['modelsTranslations', +mapPortalId], list => {
    const editedItemIndex = list.findIndex(({ id }) => id === translation.id);
    return list.set(editedItemIndex, translation);
  });

const deleteModelTranslationSuccess = (state, { mapPortalId, translationId }) =>
  state.updateIn(['modelsTranslations', +mapPortalId], List(), list =>
    list.filter(({ id }) => id !== translationId)
  );

const fetchContentTypeLayersBegin = state =>
  state.merge({
    fetchingContentTypesLayers: true
  });

const fetchContentTypeLayersSuccess = (state, action) =>
  state.merge({
    fetchingContentTypesLayers: false,
    contentTypesLayers: List(action.payload)
  });

const fetchContentTypeLayersFailed = state =>
  state.merge({
    fetchingContentTypesLayers: false
  });

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_SEARCHABLE_MODELS_SUCCESS]: fetchSearchableModelsSuccess,
      [FETCH_DISTRICT_SEARCHABLE_MODELS_BEGIN]: fetchDistrictSearchableModelsBegin,
      [FETCH_DISTRICT_SEARCHABLE_MODELS_FAILED]: fetchDistrictSearchableModelsFailed,
      [FETCH_DISTRICT_SEARCHABLE_MODELS_SUCCESS]: fetchDistrictSearchableModelsSuccess,
      [FETCH_NAMES_MODELS_BEGIN]: fetchNamesModelsBegin,
      [FETCH_NAMES_MODELS_SUCCESS]: fetchNamesModelsSuccess,
      [FETCH_NAMES_MODELS_FAILED]: fetchNamesModelsFailed,
      [FETCH_DATA_MODEL_BEGIN]: fetchDataModelBegin,
      [FETCH_DATA_MODEL_SUCCESS]: fetchDataModelSuccess,
      [FETCH_DATA_MODEL_FAILED]: fetchDataModelFailed,
      [FETCH_OPTIONS_MODEL_BEGIN]: fetchOptionsModelBegin,
      [FETCH_OPTIONS_MODEL_SUCCESS]: fetchOptionsModelSuccess,
      [FETCH_OPTIONS_MODEL_FAILED]: fetchOptionsModelFailed,
      [UPDATE_DATA_MODEL_BEGIN]: updateDataModelBegin,
      [UPDATE_DATA_MODEL_SUCCESS]: updateDataModelEnd,
      [UPDATE_DATA_MODEL_SUCCESS]: updateDataModelSuccess,
      [UPDATE_DATA_MODEL_FAILED]: updateDataModelEnd,
      [GET_MODELS_TRANSLATIONS_SUCCESS]: getModelsTranslationsSuccess,
      [ADD_MODEL_TRANSLATION_SUCCESS]: addModelTranslationSuccess,
      [EDIT_MODEL_TRANSLATION_SUCCESS]: editModelTranslationSuccess,
      [DELETE_MODEL_TRANSLATION_SUCCESS]: deleteModelTranslationSuccess,
      [FETCH_CONTENT_TYPE_LAYERS_BEGIN]: fetchContentTypeLayersBegin,
      [FETCH_CONTENT_TYPE_LAYERS_SUCCESS]: fetchContentTypeLayersSuccess,
      [FETCH_CONTENT_TYPE_LAYERS_FAILED]: fetchContentTypeLayersFailed
    }[action.type] || (s => s)
  )(state, action);
}
