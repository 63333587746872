import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { Icon } from 'antd';

import './DistrictInfo.less';

const DistrictInfo = props => {
  const { prefix } = useParams();

  return (
    <div className="district-home-header-info">
      <FormattedMessage
        id="district_details_title"
        defaultMessage="Informations:"
      />
      <ul>
        {props.www && (
          <li>
            <a href={props.www}>
              <Icon type="global" aria-hidden="true" />{' '}
              <span className="sr-only">{prefix}</span>
              <FormattedMessage
                id="district_details_website"
                defaultMessage="Website"
              />
            </a>
          </li>
        )}
        {props.bip && (
          <li>
            <a href={props.bip}>
              <Icon type="link" aria-hidden="true" />{' '}
              <span className="sr-only">{prefix}</span>
              <FormattedMessage
                id="district_details_bip"
                defaultMessage="BIP"
              />
            </a>
          </li>
        )}
        {props.wiki && (
          <li>
            <a href={props.wiki}>
              <Icon type="link" aria-hidden="true" />{' '}
              <span className="sr-only">{prefix}</span>
              <FormattedMessage
                id="district_details_wikipedia"
                defaultMessage="Wikipedia"
              />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

DistrictInfo.propTypes = {
  www: PropTypes.string,
  bip: PropTypes.string,
  wiki: PropTypes.string
};

export default DistrictInfo;
