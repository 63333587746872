import React from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { mapPortalSelectors } from '../../store/selectors';
import { getCompositionSidebarSettings } from '../../store/actions/mapPortalActions';

import handleError from '../../utils/handleError';

const {
  getCompositionSidebarSettings: getSidebarSettings
} = mapPortalSelectors;

const useSidebarSettings = ({ compositionId, onStart, onEnd }) => {
  const dispatch = useDispatch();
  const { prefix } = useParams();

  const sidebarSettings = useSelector(state =>
    getSidebarSettings(state, prefix, compositionId)
  );

  const fetchSidebarSettings = async () => {
    if (sidebarSettings) return;

    onStart?.();

    try {
      await dispatch(getCompositionSidebarSettings(prefix, compositionId));
    } catch (err) {
      handleError(err);
    } finally {
      onEnd?.();
    }
  };

  React.useEffect(() => {
    fetchSidebarSettings();
  }, []);

  return sidebarSettings;
};

export default useSidebarSettings;
