import { List } from 'immutable';

const selectors = {
  getCompositionRegisters: (state, district, compositionId) =>
    state.registers.getIn(['compositionRegisters', district, compositionId]) ||
    List([]),
  getIsCompositionRegistersFetched: (state, district, compositionId) =>
    state.registers.getIn([
      'isCompositionRegistersFetched',
      district,
      compositionId
    ]) || false,
  getIsOpenRegister: state => state.registers.get('isOpenRegisterTool'),
  getIsRegisterInSeparateWindow: state =>
    state.registers.get('isRegisterInSeparateWindow'),
  getRegisterAttrs: (state, district, compositionId, registerId) =>
    state.registers.getIn([
      'registerAttrs',
      district,
      compositionId,
      registerId
    ]),
  getSelectedLayer: state => state.registers.get('selectedLayer'),
  getSelectedFeatures: state => state.registers.get('selectedFeatures'),
  getIsRegisterDetailsOpen: state =>
    state.registers.get('isRegisterDetailsOpen'),
  getRegisterDetails: state => state.registers.get('registerFinnData'),
  getRegisterModelName: state => state.registers.get('registerToolModelName'),
  getRegisterId: state => state.registers.get('registerToolRegisterId'),
  getRegisterSettings: (state, registerId) =>
    state.registers.getIn(['registerSettings', registerId]),
  getRegisterFinnList: (state, registerId) =>
    state.registers.getIn(['registerFinnList', registerId]),
  getRegisterComponentName: state => state.registers.get('componentName'),
  getOptionId: state => state.registers.get('optionId'),
  getSearchValue: state => state.registers.get('searchValue'),
  getShowFilters: state => state.registers.get('showFilters'),
  getColumnsRegister: state => state.registers.get('columns'),
  getRegisterQuery: state => state.registers.get('registerQuery'),
  getFiltersValues: state => state.registers.get('filtersValues'),
  selectPaginationData: state => state.registers.get('pagination'),
  getRelatedFinnObject: state => state.registers.get('relatedFinnObject'),
  getIsChangeHistoryTableOpen: state =>
    state.registers.get('isChangeHistoryTableOpen'),
  getRecordId: state => state.registers.get('recordId'),
  isFetchingChangeHistory: state =>
    state.registers.get('isFetchingChangeHistory'),
  getChangeHistoryCurrentPage: (state, page) =>
    state.registers.getIn(['changeHistory', String(page)]),
  getChangeHistoryCount: (state, page) =>
    state.registers.get('changeHistoryCount'),
  getIsRegisterEditionEnabled: state =>
    state.registers.get('isRegisterEditionEnabled'),
  getFormEditionId: state => state.registers.get('formEditionId')
};

export default selectors;
