import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { mapPortalSelectors, mapSelectors } from '../../../store/selectors';
import {
  setActiveBackgroundTool,
  setTypeClick
} from '../../../store/actions/mapActions';

import { enumClickTypes } from '../../../mapConfig';
import { IDENTIFICATION_KEYS } from '../../../constants/toolbar';

import { useToolbarItemTranslation } from './useToolbarItemTranslation';
import { IDENTIFICATION_TYPE } from '../../../constants/backend-keys';

const { typeClick, getActiveBackgroundTool } = mapSelectors;
const {
  getActiveToolIndex,
  getCurrentComposition,
  getMapPortalByShortName
} = mapPortalSelectors;

const identificationFromToolbar = 'use_identification_tool_from_toolbar';

const useActiveCloseButton = ({ toolKey, showCloseButton }) => {
  const dispatch = useDispatch();
  const { prefix, portal } = useParams();
  const clickType = useSelector(typeClick);
  const activeBackgroundTool = useSelector(getActiveBackgroundTool);
  const activeToolIndex = useSelector(getActiveToolIndex);

  const { activateTool, deactivateTool } = useToolbarItemTranslation();

  const {
    identification,
    wmsIdentification,
    none,
    plotsIdentificationTool
  } = enumClickTypes;

  const { wmsToolKey, identificationToolKey } = IDENTIFICATION_KEYS;

  const isButtonDisabled = activeToolIndex === identificationToolKey;

  const identificationType = useSelector(
    state => getCurrentComposition(state, prefix, portal)?.identification_type
  );

  const selectMapPortalToolsConfiguration = (state, prefix, portal) => {
    const portalData = getMapPortalByShortName(state, prefix, portal);
    return portalData ? portalData.get(identificationFromToolbar) : null;
  };

  const identificationByToolbar = useSelector(state =>
    selectMapPortalToolsConfiguration(state, prefix, portal)
  );

  const isActiveToolDisabled =
    showCloseButton &&
    (identificationByToolbar ||
      identificationType !== IDENTIFICATION_TYPE.OBJECT_INFO);

  // function responsible for enabling/disabling the close
  // icon of the active tool in various conditions on entire app
  const onCloseIconClick = event => {
    if (identificationType !== IDENTIFICATION_TYPE.OBJECT_INFO) return;

    event.stopPropagation();
    let specialConditionMet = false;

    const isWmsTool = toolKey === wmsToolKey;
    const isIdentificationTool = toolKey === identificationToolKey;

    if (activeBackgroundTool === toolKey && !activeToolIndex) {
      dispatch(setTypeClick(none));
    }

    if (isWmsTool && [none, identification].includes(clickType)) {
      dispatch(setTypeClick(wmsIdentification));
    }

    if (
      !activeBackgroundTool &&
      isIdentificationTool &&
      [none, wmsIdentification].includes(clickType)
    ) {
      dispatch(setTypeClick(identification));
      dispatch(setActiveBackgroundTool(none));
    }

    if (
      isWmsTool &&
      activeBackgroundTool !== wmsToolKey &&
      !activeBackgroundTool
    ) {
      dispatch(setActiveBackgroundTool(wmsToolKey));
      specialConditionMet = true;
    }

    if (
      isIdentificationTool &&
      activeBackgroundTool !== identificationToolKey &&
      identificationType !== IDENTIFICATION_TYPE.PLOT_INFO
    ) {
      dispatch(setActiveBackgroundTool(identificationToolKey));
      specialConditionMet = true;
    }

    if (!specialConditionMet) {
      dispatch(
        setActiveBackgroundTool(activeBackgroundTool === '' ? toolKey : '')
      );
    }
  };

  const isClickTypeForIdentification = () => {
    if (toolKey === wmsToolKey && activeBackgroundTool === wmsToolKey) {
      return [none, wmsIdentification].includes(clickType);
    }

    if (toolKey === identificationToolKey) {
      if (
        activeBackgroundTool === identificationToolKey &&
        [identification, none, plotsIdentificationTool].includes(clickType)
      ) {
        return true;
      } else return false;
    }
  };

  const getTooltipTitle = () => {
    if (isButtonDisabled) {
      return '';
    }
    const tooltipTitle = isClickTypeForIdentification()
      ? deactivateTool
      : activateTool;

    return tooltipTitle;
  };

  const activateBackgroundTool = () => {
    if (!identificationByToolbar) {
      dispatch(setActiveBackgroundTool(identificationToolKey));
    }
  };

  const resetSettings = () => {
    if (!identificationByToolbar) {
      dispatch(setTypeClick(''));
      dispatch(setActiveBackgroundTool(identificationToolKey));
    }
  };

  const reseWmsToolCondition =
    toolKey === wmsToolKey && !activeBackgroundTool && activeToolIndex !== null;

  const resetWmsTool = () => {
    if (reseWmsToolCondition) dispatch(setTypeClick(''));
  };

  return {
    onCloseIconClick,
    isClickTypeForIdentification,
    getTooltipTitle,
    activateBackgroundTool,
    resetSettings,
    resetWmsTool,
    isActiveToolDisabled,
    activeToolIndex,
    identificationToolKey,
    identificationType
  };
};

export default useActiveCloseButton;
