import { isFillTheRole } from '../../lib';

const checkRoleForDistrict = (requiredRole, userRoles = [], districtId) => {
  const _userRoles = Array.isArray(userRoles) ? userRoles : userRoles?.toJS();
  const hasRoleForAllDistricts = _userRoles.find(
    item => item.district === '__all__'
  );

  if (hasRoleForAllDistricts) {
    return isFillTheRole(hasRoleForAllDistricts.name, requiredRole);
  }

  const roleForDistrict = _userRoles.find(item => item.district === districtId);

  if (!roleForDistrict) {
    return false;
  }

  return isFillTheRole(roleForDistrict.name, requiredRole);
};

export default checkRoleForDistrict;
