import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
  hideSidebar,
  showHamburger,
  hideHamburger
} from '../../store/actions/globalActions';

import { Icon } from 'antd';

import { collapseNavSidebar } from '../NavSidebar';

import './NavSidebarHamburger.less';

class NavSidebarHamburger extends Component {
  toggleMenu = () => {
    if (!this.props.hamburgerMenuOpen) {
      this.props.showHamburger();
      this.props.hideSidebar();
      collapseNavSidebar();
    } else {
      this.props.hideHamburger();
    }
  };

  render() {
    return (
      <div
        href={this.props.href}
        className={classNames('nav-sidebar-hamburger_icon', {
          'nav-sidebar-hamburger_icon-selected': this.props.hamburgerMenuOpen
        })}
        onClick={this.toggleMenu}
      >
        <Icon type="menu" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  hamburgerMenuOpen: state.global.get('hamburgerMenuOpen')
});

const mapDispatchToProps = {
  hideSidebar,
  showHamburger,
  hideHamburger
};

NavSidebarHamburger.propTypes = {
  hamburgerMenuOpen: PropTypes.bool.isRequired,
  hideSidebar: PropTypes.func.isRequired,
  hideHamburger: PropTypes.func.isRequired,
  showHamburger: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavSidebarHamburger);
