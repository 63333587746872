import { Checkbox, Input } from 'antd';
import PropTypes from 'prop-types';

import useLabelingMessages from '../../../hooks/useLabelingMessages';

import { ForwardColorSwitcher } from '../../../components/ForwardColorSwitcher';
import GoogleFontSelectList from '../../../components/GoogleFontSelectList';
import { FormItem } from '../../../components';

import { sizeValidator } from '../../../utils/sizeValidator';

import '../SymbolizationForm.less';

const LabelSymbolizationForm = ({
  isLabelVisibleComponent = false,
  labelColor,
  labelFont,
  labelSize,
  labelHidden,
  setLabelColor,
  setLabelFont,
  setLabelSize,
  setLabelHidden,
  getFieldDecorator
}) => {
  const { labelColorMsg, labelFontMsg, labelSizeMsg } = useLabelingMessages();

  return (
    <div className="symbolization-form">
      {isLabelVisibleComponent && (
        <FormItem label="Brak">
          <Checkbox
            checked={labelHidden}
            onChange={e => setLabelHidden(e.target.checked)}
          />
        </FormItem>
      )}
      {(!isLabelVisibleComponent || !labelHidden) && (
        <>
          <FormItem label={labelColorMsg}>
            {getFieldDecorator('labelColor', {
              initialValue: labelColor
            })(
              <ForwardColorSwitcher
                style={{ width: '100%' }}
                onChange={setLabelColor}
                placeholder={labelColorMsg}
              />
            )}
          </FormItem>
          <FormItem label={labelFontMsg}>
            {getFieldDecorator('labelFont', {
              initialValue: labelFont
            })(<GoogleFontSelectList onChange={setLabelFont} />)}
          </FormItem>
          <FormItem label={labelSizeMsg}>
            {getFieldDecorator('labelSize', {
              rules: [
                {
                  validator: sizeValidator
                }
              ],
              initialValue: labelSize
            })(
              <Input
                onChange={e => setLabelSize(e.target.value)}
                placeholder={labelSizeMsg}
              />
            )}
          </FormItem>
        </>
      )}
    </div>
  );
};

LabelSymbolizationForm.propTypes = {
  labelColor: PropTypes.string,
  labelFont: PropTypes.string,
  labelSize: PropTypes.number,
  setLabelColor: PropTypes.func.isRequired,
  setLabelFont: PropTypes.func.isRequired,
  setLabelSize: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func.isRequired
};

export default LabelSymbolizationForm;
