import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ContentBox, Brand, LanguageSelector } from '../';

import './LoginBox.less';

let LoginBox = props => (
  <>
    <ContentBox className="login-box">
      <Brand globalSettings={props.globalSettings} />
      <span className="login-box-title">{props.title}</span>
      {props.children}
    </ContentBox>
    <div className="login-box-language">
      <LanguageSelector />
    </div>
  </>
);

LoginBox.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node
};

const mapStateToProps = state => ({
  globalSettings: state.global.get('globalSettings')
});

LoginBox.propTypes = {
  globalSettings: PropTypes.object.isRequired
};

LoginBox = connect(mapStateToProps, null)(LoginBox);

export default LoginBox;
