export const SEARCH_TYPES = {
  ADDRESS: 'address',
  PLOT: 'plot',
  GEONETWORK_HORIZONTAL: 'geonetworkHorizontal',
  GEONETWORK_VERTICAL: 'geonetworkVertical',
  GEOLOCATION: 'geolocation'
};

export const SEARCH_INPUT_TRANSLATIONS = {
  PLOT_SEARCH_ADVANCED: {
    id: 'plot_search_advanced',
    defaultMessage: 'Additional search options'
  }
};
