import axios from 'axios';

import { localStorageKeys } from '../../config';

export const getStorageNotes = mapPortalId =>
  new Promise((resolve, reject) => {
    const results = [];

    const storageResults = localStorage.getItem(localStorageKeys.notes);
    const storageNotes = storageResults && JSON.parse(storageResults);

    if (!storageNotes || !storageNotes[mapPortalId]) return resolve(results);

    const getNotesPromises = storageNotes[mapPortalId].map(uuid =>
      axios.get(`note/${mapPortalId}/${uuid}/`, {
        validateStatus: status =>
          (status >= 200 && status < 300) || status === 404
      })
    );

    Promise.all(getNotesPromises)
      .then(data => {
        data.forEach(response => {
          if (response.status !== 200) return;
          results.push(response.data);
        });

        resolve(results);
      })
      .catch(reject);
  });

export const addStorageNote = (mapPortalId, noteId) => {
  try {
    const data = localStorage.getItem(localStorageKeys.notes);
    const storageNotes = data ? JSON.parse(data) : {};

    if (!storageNotes[mapPortalId]) {
      storageNotes[mapPortalId] = [];
    }

    storageNotes[mapPortalId] = [...storageNotes[mapPortalId], noteId];

    localStorage.setItem(localStorageKeys.notes, JSON.stringify(storageNotes));
  } catch (err) {
    throw err;
  }
};

export const removeStorageNote = (mapPortalId, noteId) => {
  try {
    const data = localStorage.getItem(localStorageKeys.notes);
    const storageNotes = JSON.parse(data);

    const newStorageNotes = storageNotes[mapPortalId].filter(
      id => id !== noteId
    );

    storageNotes[mapPortalId] = newStorageNotes;

    localStorage.setItem(localStorageKeys.notes, JSON.stringify(storageNotes));
  } catch (err) {
    throw err;
  }
};

export const removeAllStorageNotes = mapPortalId => {
  try {
    const data = localStorage.getItem(localStorageKeys.notes);
    const storageNotes = data ? JSON.parse(data) : {};

    delete storageNotes[mapPortalId];

    localStorage.setItem(localStorageKeys.notes, JSON.stringify(storageNotes));
  } catch (err) {
    throw err;
  }
};
