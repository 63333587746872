export const DISTRICT_BORDER_KEY = 'district_border';
export const CREST_KEY = 'crest';
export const RPO_LOGO = 'rpo_logo';
export const USE_GLOBAL_RPO_LOGO = 'use_global_rpo_logo';
export const NUMER_LOKALU_WLASCICIELA = 'numer_lokalu_wlasciciela';
export const MAX_ATTACHMENT_FILE_SIZE = 5; // MB
export const BASIC_COVER_IMAGE = 'basic_cover_image';
export const ADVANCED_COVER_IMAGE = 'cover_image';
export const PRINTOUT_CLOSURE_TEXT = 'printout_closure_text';

export const WFS_SERVICE = 'WFS';
export const SERVICES_TYPE = ['WMS', 'WMTS', WFS_SERVICE];

export const FILE_TYPES = {
  CSV: 'CSV',
  GML: 'GML',
  KML: 'KML',
  SHP: 'SHP',
  DXF: 'DXF',
  DWG: 'DWG',
  GeoJSON: 'GeoJSON',
  GeoRSS: 'GeoRSS',
  GeoTIFF: 'GeoTIFF'
};

export const portal3dTypes = { ObliView: 'ObliView', VC: 'VC' };
