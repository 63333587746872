export const SET_ZOOM = 'SET_ZOOM';
export const SET_SCALES_DENOMINATORS = 'SET_SCALES_DENOMINATORS';

export const ADD_HISTORY_VIEW = 'ADD_HISTORY_VIEW';
export const CHANGE_NUMBER_HISTORY = 'CHANGE_NUMBER_HISTORY';
export const SET_HISTORY_CLICKED = 'SET_HISTORY_CLICKED';

export const SET_MEASURE_TOOL = 'SET_MEASURE_TOOL';

export const SET_SELECTED_PARCEL = 'SET_SELECTED_PARCEL';
export const CLEAR_SELECTED_PARCEL = 'CLEAR_SELECTED_PARCEL';

export const SET_SELECTED_NEIGHBORING_PARCEL =
  'SET_SELECTED_NEIGHBORING_PARCEL';
export const CLEAR_SELECTED_NEIGHBORING_PARCEL =
  'CLEAR_SELECTED_NEIGHBORING_PARCEL';

export const SET_SELECTED_DEFAULT_PARCELS = 'SET_SELECTED_DEFAULT_PARCELS';
export const CLEAR_SELECTED_DEFAULT_PARCELS = 'CLEAR_SELECTED_DEFAULT_PARCELS';

export const REMOVE_SELECTED_PARCEL = 'REMOVE_SELECTED_PARCEL';

export const SET_BASE_MAP_LAYER = 'SET_BASE_MAP_LAYER';
export const SET_MINI_MAP_BASE_MAP = 'SET_MINI_MAP_BASE_MAP';
export const SET_CHECKED_LAYERS = 'SET_CHECKED_LAYERS';
export const SET_LAYER_OPACITY = 'SET_LAYER_OPACITY';
export const SET_LAYER_STYLE = 'SET_LAYER_STYLE';
export const SET_LAYERS_ORDER = 'SET_LAYERS_ORDER';

export const ADD_TO_INVISIBLE_LAYERS = 'ADD_TO_INVISIBLE_LAYERS';
export const REMOVE_FROM_INVISIBLE_LAYERS = 'REMOVE_FROM_INVISIBLE_LAYERS';

export const ADD_TO_LAYERS_ZOOM = 'ADD_TO_LAYERS_ZOOM';

export const ADD_PRINT_PREVIEW_LOADING_LAYER =
  'ADD_PRINT_PREVIEW_LOADING_LAYER';
export const DELETE_PRINT_PREVIEW_LOADING_LAYER =
  'DELETE_PRINT_PREVIEW_LOADING_LAYER';

export const ZOOM_TO_LAYER_START = 'ZOOM_TO_LAYER_START';
export const ZOOM_TO_LAYER_END = 'ZOOM_TO_LAYER_END';

export const SET_FULLSCREEN_STATUS = 'SET_FULLSCREEN_STATUS';
export const SET_CURRENT_CURSOR_CRS = 'SET_CURRENT_CURSOR_CRS';
export const CONVERT_COORDINATES_TO_HDMS = 'CONVERT_COORDINATES_TO_HDMS';
export const SET_RENDERED_LAYERS_NUMBER = 'SET_RENDERED_LAYERS_NUMBER';
export const SET_STREET_VIEW_COORDINATES = 'SET_STREET_VIEW_COORDINATES';

export const SET_COORDINATES_EVENT = 'SET_COORDINATES_EVENT';

export const SET_MAPLOAD_TRUE = 'SET_MAPLOAD_TRUE';
export const SET_MAPLOAD_FALSE = 'SET_MAPLOAD_FALSE';

export const SET_MAIN_MINI_MAP_DRAGGING_STATUS =
  'SET_MAIN_MINI_MAP_DRAGGING_STATUS';

export const FETCH_EXTERNAL_WFS_ACTIONS = 'FETCH_EXTERNAL_WFS_ACTIONS';

export const SET_SELECTED_POINT = 'SET_SELECTED_POINT';
export const CLEAR_SELECTED_POINT = 'CLEAR_SELECTED_POINT';

export const IS_GEOM_LOADING_ON_MAP = 'IS_GEOM_LOADING_ON_MAP';
export const SET_ACTIVE_BACKGROUND_TOOL = 'SET_ACTIVE_BACKGROUND_TOOL';
export const SET_BACKGROUND_PARCEL_COLOR = ' SET_BACKGROUND_PARCEL_COLOR';
