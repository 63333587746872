import PropTypes from 'prop-types';
import Loader from '../Loader';
import './LoaderLayer.less';

const LoaderLayer = ({ tip, inverse, centeredLoader = true, showTimer }) => (
  <div
    className={`loader-layer loader-layer__${
      centeredLoader ? 'center' : 'top'
    }`}
  >
    <Loader tip={tip} inverse={inverse} showTimer={showTimer} />
  </div>
);

LoaderLayer.propTypes = {
  tip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  inverse: PropTypes.bool
};

export default LoaderLayer;
