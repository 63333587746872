import PropTypes from 'prop-types';

import './MapTitle.less';

const MapTitle = ({ position, title, fontSize = 14 }) => (
  <div
    className={`map-title-container map-title-${position}`}
    style={{ fontSize }}
  >
    {title}
  </div>
);

MapTitle.propTypes = {
  title: PropTypes.string,
  position: PropTypes.string.isRequired,
  fontSize: PropTypes.number
};

export default MapTitle;
