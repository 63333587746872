import PropTypes from 'prop-types';

import './LockUI.less';

const LockUI = props =>
  props.visible ? (
    <div
      className="lock-ui"
      style={{ zIndex: props.zIndex, background: props.background }}
    />
  ) : null;

LockUI.propTypes = {
  visible: PropTypes.bool.isRequired,
  zIndex: PropTypes.number,
  background: PropTypes.string
};

LockUI.defaultProps = {
  visible: true
};

export default LockUI;
