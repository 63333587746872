import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { mapPortalSelectors } from '../../store/selectors';

const {
  getMapPortalByShortName,
  getDistrictMapPortalByID
} = mapPortalSelectors;

const useMapPortal = ({ id, shortName, noErr = false } = {}) => {
  if (!noErr && !id && !shortName) {
    throw new Error(
      "You must specify map portal's ID or short name to useMapPortal hook!"
    );
  }

  const { prefix } = useParams();

  return useSelector(state => {
    if (shortName) {
      return getMapPortalByShortName(state, prefix, shortName);
    }

    return getDistrictMapPortalByID(state, prefix, Number(id));
  });
};

export default useMapPortal;
