import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Input, Table, Form, DatePicker, Select, InputNumber } from 'antd';
import { DATA_FORMAT_NO_TIME } from '../../utils/constants';
import yearValidator from '../../utils/validators/yearValidator';

const { Option } = Select;

const EditableContext = createContext();
EditableContext.displayName = 'EditableRowContext';

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

const EditableCell = props => {
  const [form, setForm] = useState(null);

  useEffect(() => {
    form && form.setFieldsValue({ date: null });
  }, [props.fullDate]);

  const save = e => {
    form.validateFields((error, values) => {
      if (error) {
        return;
      }
      values.year = fullDate ? null : values.date;
      values.date = fullDate
        ? values.date.format(DATA_FORMAT_NO_TIME).toString()
        : null;

      handleSave({ ...record, ...values });
    });
  };

  const getTypeOfColumn = (dataIndex, fullDate) => {
    if (dataIndex === 'date') {
      return fullDate ? (
        <DatePicker onPressEnter={save} onBlur={save} />
      ) : (
        <InputNumber onPressEnter={save} onBlur={save} onChange={save} />
      );
    } else if (dataIndex === 'wms_version') {
      return (
        <Select
          onChange={save}
          onSelect={save}
          style={{ width: 100 }}
          defaultActiveFirstOption
        >
          <Option value="1.1.1">1.1.1</Option>
          <Option value="1.3.0">1.3.0</Option>
        </Select>
      );
    } else {
      return <Input onPressEnter={save} onBlur={save} onChange={save} />;
    }
  };

  const renderCell = form => {
    setForm(form);
    const { children, dataIndex, record, editing, fullDate } = props;
    //bez ponizszej linijki występuje bład przy zmianie roku na pełną datę przy uzupełnionym polu
    if (typeof form?.getFieldValue('date') === 'number' && fullDate)
      form.setFieldsValue({ date: moment() });
    const initialForDate = () =>
      fullDate ? moment(record[dataIndex]) : record.year;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `To pole jest wymagane.`
            },
            {
              validator:
                dataIndex === 'date' && !fullDate
                  ? (field, value, cb) => yearValidator(value, cb)
                  : (field, value, cb) => cb()
            }
          ],
          initialValue:
            dataIndex === 'date' ? initialForDate() : record[dataIndex]
        })(getTypeOfColumn(dataIndex, fullDate))}
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 10 }}>
        {children}
      </div>
    );
  };

  const {
    editable,
    dataIndex,
    title,
    record,
    index,
    handleSave,
    children,
    editing,
    fullDate,
    ...restProps
  } = props;

  return (
    <td {...restProps}>
      {editable ? (
        <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>
      ) : (
        children
      )}
    </td>
  );
};

const RasterTimelineTable = props => {
  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell
    }
  };

  const columnsView = props.columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: props.handleSave,
        editing: props.editing,
        fullDate: props.fullDate
      })
    };
  });

  return (
    <Table
      rowKey="id"
      components={components}
      bordered
      dataSource={props.dataSource}
      columns={columnsView}
      rowClassName="editable-row"
    />
  );
};

RasterTimelineTable.propTypes = {
  dataSource: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  handleSave: PropTypes.func.isRequired,
  editing: PropTypes.bool
};

export default RasterTimelineTable;
