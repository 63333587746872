import { Input } from 'antd';
import PropTypes from 'prop-types';

import useSymbolizationMessages from '../../../hooks/useSymbolizationMessages';

import { sizeValidator } from '../../../utils/sizeValidator';

import { ForwardColorSwitcher } from '../../../components/ForwardColorSwitcher';
import { FormItem, Button } from '../../../components';

import { ICONS_LIST } from '../../../constants/icons-list';

import '../SymbolizationForm.less';

const SELECTED_ICON_STYLES = {
  outline: '2px solid white',
  borderRadius: '10px'
};

const PointSymbolizationForm = ({
  symbol,
  symbolColor,
  symbolSize,
  setSymbol,
  setSymbolColor,
  setSymbolSize,
  selectedItemStyles = SELECTED_ICON_STYLES,
  getFieldDecorator
}) => {
  const { colorMsg, sizeMsg, shapeMsg } = useSymbolizationMessages({});

  const onClickIcon = e => setSymbol(e.currentTarget.value);

  const onSizeChange = e => {
    const sizeValue = e.target.value;
    setSymbolSize(sizeValue && sizeValue <= 100 ? sizeValue : 25);
  };

  return (
    <div className="symbolization-form">
      <FormItem label={shapeMsg}>
        <div style={{ padding: '2%' }}>
          {ICONS_LIST.map(({ name, renderIconFn }) => (
            <Button
              key={name}
              variant="transparent"
              onClick={onClickIcon}
              value={name}
            >
              {
                <img
                  style={symbol === name ? selectedItemStyles : {}}
                  src={`data:image/svg+xml;utf8, ${renderIconFn(
                    25,
                    symbolColor
                  )}`}
                  alt={name}
                />
              }
            </Button>
          ))}
        </div>
      </FormItem>
      <FormItem label={sizeMsg}>
        {getFieldDecorator('size', {
          rules: [
            {
              validator: sizeValidator
            }
          ],
          initialValue: symbolSize
        })(<Input onChange={onSizeChange} placeholder={sizeMsg} />)}
      </FormItem>
      <FormItem label={colorMsg}>
        {getFieldDecorator('color', {
          initialValue: symbolColor
        })(
          <ForwardColorSwitcher
            style={{ width: '100%' }}
            onChange={setSymbolColor}
            placeholder={colorMsg}
          />
        )}
      </FormItem>
    </div>
  );
};

PointSymbolizationForm.propTypes = {
  symbol: PropTypes.string,
  symbolColor: PropTypes.string,
  symbolSize: PropTypes.number,
  setSymbol: PropTypes.func.isRequired,
  setSymbolColor: PropTypes.func.isRequired,
  setSymbolSize: PropTypes.func.isRequired,
  selectedItemStyles: PropTypes.object,
  getFieldDecorator: PropTypes.func.isRequired
};

export default PointSymbolizationForm;
