import { useSelector } from 'react-redux';
import useModelsTranslations from '../useModelsTranslations';
import useMapPortalId from '../useMapPortalId';

import { globalSelectors } from '../../store/selectors';

const { getLanguage } = globalSelectors;

const useFilteredModelsTranslations = filters => {
  const mapPortalId = useMapPortalId();
  const language = useSelector(state => getLanguage(state));
  const translations = useModelsTranslations(mapPortalId, {
    language,
    ...filters
  });

  return translations;
};

export default useFilteredModelsTranslations;
