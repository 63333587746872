import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import moment from 'moment';
import isDate from './helpers/is-date';

import { useFilteredModelsTranslations } from '../../hooks';

import { LinkWithIcon } from '..';
import LineSplitter from '../LineSplitter';

import nonBreakingSpace from '../../utils/lib/nonBreakingSpace';
import parseTextFromHtml from '../../utils/lib/parseTextFromHtml';
import { isHTML } from '../../utils/lib';

import { DATA_FORMAT_WITH_TIME } from '../../utils/constants';
import { backendModelsTypes } from '../../consts';

import { TopicalSearchToolResultColumnShape } from '../../shapes/topicalSearchTool';

const TopicalSearchResultAttributes = ({
  columnKey,
  attribute,
  resultColumns,
  print = false,
  isPortraitOrientation
}) => {
  const appliedAppearance = useSelector(state =>
    state.district.get('appliedAppearance')
  );

  const modelsTranslations = useFilteredModelsTranslations({
    contentType: backendModelsTypes.TopicalSearchToolResultColumn
  });

  const column = resultColumns.find(col => col.name === columnKey);

  if ((!attribute && attribute !== 0) || !column) return null;

  const renderAttribute = () => {
    if (isDate(attribute)) {
      return moment(attribute)
        .format(DATA_FORMAT_WITH_TIME)
        .toString();
    }

    if (isHTML(attribute)) {
      return parseTextFromHtml(attribute);
    }

    if (
      typeof attribute === 'string' &&
      (attribute.startsWith('http://') || attribute.startsWith('https://'))
    ) {
      return (
        <LinkWithIcon href={attribute} text={attribute} icon="link" blank />
      );
    }
    return appliedAppearance?.get('non-breaking-space')
      ? nonBreakingSpace(attribute)
      : attribute;
  };

  const renderKey = () => {
    const translatedName = modelsTranslations.find(
      ({ object_id }) => object_id === column.id
    )?.translated_value;

    return (
      <>
        <span style={{ fontWeight: 'bold' }}>
          {translatedName || column.displayable_name}
        </span>
        :{' '}
      </>
    );
  };

  const renderPlainAttribute = () => (
    <p
      key={column.name}
      className={classNames({
        'text-justify': appliedAppearance?.get('justify-text') ?? false
      })}
    >
      {typeof renderAttribute() === 'string' && print ? (
        <LineSplitter
          content={renderAttribute()}
          lineMaxLength={isPortraitOrientation ? 90 : 135}
          renderKey={renderKey()}
        />
      ) : (
        <>
          {renderKey()}
          {renderAttribute()}
        </>
      )}
    </p>
  );

  return renderPlainAttribute();
};

TopicalSearchResultAttributes.propTypes = {
  columnKey: PropTypes.string.isRequired,
  attribute: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resultColumns: PropTypes.oneOfType([
    PropTypes.arrayOf(TopicalSearchToolResultColumnShape),
    ImmutablePropTypes.listOf(TopicalSearchToolResultColumnShape)
  ]).isRequired,
  print: PropTypes.bool,
  isPortraitOrientation: PropTypes.bool
};

export default TopicalSearchResultAttributes;
