import PropTypes from 'prop-types';

import notFound from '../../images/not_found.png';

import getApiUrl from '../../utils/lib/getApiUrl';

import './BaseMapItemCover.less';

const getLayerCoverPath = (coverImage, defaultCover) => {
  if (!coverImage && defaultCover) return defaultCover;
  if (!coverImage) return notFound;

  if (coverImage.includes('/')) return coverImage;

  return `${getApiUrl()}/files/${coverImage}`;
};

const BaseMapItemCover = ({ coverImage, defaultCover, onLoad }) => (
  <img
    src={getLayerCoverPath(coverImage, defaultCover)}
    alt=""
    onLoad={onLoad}
  />
);

BaseMapItemCover.propTypes = {
  coverImage: PropTypes.string
};

export default BaseMapItemCover;
