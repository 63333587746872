import { Icon, Switch, Tooltip } from 'antd';

import useTranslations from './hooks/useTranslations';
import { useCurrentMapPortal } from '../../hooks';

import Button from '../Button';

import './ToolbarHeader.less';

const ToolbarHeader = ({
  isMapToolActive,
  handleToolHeader,
  handleModeChange,
  checked
}) => {
  const {
    translations: { toolbarName, mode, close, settings }
  } = useTranslations();

  const mapPortal = useCurrentMapPortal();
  const areConfiguraionModesEnabled = mapPortal.get(
    'enable_configuration_modes'
  );

  return (
    <div className="toolbar-header">
      <span>{toolbarName}</span>
      <div>
        {areConfiguraionModesEnabled && (
          <>
            {isMapToolActive && (
              <>
                <span className="switch-label">{mode}</span>
                <Switch
                  size="small"
                  onChange={handleModeChange}
                  checked={checked}
                />
                <Tooltip title={close}>
                  <Button variant="text" onClick={handleToolHeader}>
                    <Icon type="close" />
                  </Button>
                </Tooltip>
              </>
            )}
            {!isMapToolActive && (
              <Tooltip title={settings}>
                <Button variant="text" onClick={handleToolHeader}>
                  <Icon type="tool" />
                </Button>
              </Tooltip>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ToolbarHeader;
