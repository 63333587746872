import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { setActiveIndexToolbar } from '../../store/actions/mapPortalActions';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { CloseModalButton, Button as CustomButton } from '../';
import Button from '../Button';
import { Icon } from 'antd';

import { isMobile } from '../../utils/lib';

import './ToolbarTool.less';

const ToolbarTool = ({
  containerProps = {},
  closeFn,
  children,
  title,
  className,
  returnArrow,
  clearReturnArrow
}) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const dispatch = useDispatch();
  const calculateMaxHeight = () => {
    const windowHeight = window.innerHeight;

    if (isMobile()) return windowHeight / 2;

    const topOffset = 125;
    const bottomOffset = 170;
    const offset = topOffset + bottomOffset;

    return windowHeight - offset;
  };

  const maxHeight = calculateMaxHeight();

  const minimizeTool = () => setIsMinimized(prevValue => !prevValue);

  const closeFnWrapper = () => {
    dispatch(setActiveIndexToolbar(null));
    closeFn();
  };

  return (
    <div
      {...containerProps}
      className={classNames('toolbar-panel', className, {
        'toolbar-panel-minimized': isMinimized
      })}
      role="dialog"
      style={{ maxHeight }}
    >
      <div className="header">
        <span className="toolbar-panel-header">
          {returnArrow && (
            <CustomButton variant="text" onClick={clearReturnArrow}>
              <Icon type="left" />{' '}
            </CustomButton>
          )}
          {title}
        </span>
        <div>
          <Button variant="text" onClick={minimizeTool}>
            <Icon type={isMinimized ? 'border' : 'minus'} />
          </Button>
          <CloseModalButton closeFn={closeFnWrapper} title={title} />
        </div>
      </div>
      <SimpleBar style={{ maxHeight: maxHeight - 50, paddingRight: 16 }}>
        <div
          className={classNames('toolbar-panel-content', {
            'toolbar-panel-content-minimized': isMinimized
          })}
        >
          {children}
        </div>
      </SimpleBar>
    </div>
  );
};

ToolbarTool.propTypes = {
  children: PropTypes.node.isRequired,
  closeFn: PropTypes.func.isRequired,
  containerProps: PropTypes.object,
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default ToolbarTool;
