import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { Icon } from 'antd';
import { Button } from '../';

import './Empty.less';

const Empty = props => {
  const { push } = useHistory();
  const isButton = props.buttonText && props.buttonLink;

  return (
    <div className="empty">
      <Icon
        type={!props.iconType ? 'folder-open' : props.iconType}
        className="empty-icon"
      />
      {props.children}
      {isButton && (
        <Button
          className="add-button"
          onClick={() => push(props.buttonLink)}
          variant="secondary"
        >
          {props.buttonText}
        </Button>
      )}
    </div>
  );
};

Empty.propTypes = {
  children: PropTypes.node.isRequired,
  iconType: PropTypes.string,
  buttonText: PropTypes.node,
  buttonLink: PropTypes.string
};

export default Empty;
