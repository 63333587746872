import { useContext } from 'react';
import { connect } from 'react-redux';

import { ModestSelect } from '../';
import { Select } from 'antd';

import { globalSelectors } from '../../store/selectors';

import LanguageContext from '../../contexts/LanguageContext.js';

import { LanguagesShape } from '../../shapes';

const Option = Select.Option;

export const LanguageSelector = ({ languages }) => {
  const { language, setLanguage } = useContext(LanguageContext);

  return (
    <ModestSelect
      defaultValue={language}
      onChange={language => setLanguage(language)}
    >
      {languages.map(({ code, name }) => (
        <Option value={code} key={code}>
          {name}
        </Option>
      ))}
    </ModestSelect>
  );
};

/* istanbul ignore next */
const mapStateToProps = state => ({
  languages: globalSelectors.getLanguages(state)
});

LanguageSelector.propTypes = {
  languages: LanguagesShape
};

export default connect(mapStateToProps, null)(LanguageSelector);
