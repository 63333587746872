import { useIntl } from 'react-intl';

import { Button, ViewTitle } from '../../components';
import SystemInformationView from '../SystemInformationView';

import { useRedirectToHomePage } from '../../hooks';

const ForbiddenView = () => {
  const { formatMessage } = useIntl();
  const goToHomePage = useRedirectToHomePage();

  return (
    <>
      <ViewTitle
        title={formatMessage({
          id: 'title_forbidden',
          defaultMessage: 'access forbidden'
        })}
      />
      <SystemInformationView
        title={formatMessage({
          id: 'general_no_permissions',
          defaultMessage: 'No permissions'
        })}
        icon="lock"
      >
        <p>
          {formatMessage({
            id: 'title_forbidden_description',
            defaultMessage:
              'You do not have sufficient permissions to view this page. Please contact your administrator for access.'
          })}
        </p>
        <Button variant="secondary" onClick={goToHomePage}>
          {formatMessage({
            id: 'activate_changed_email_back_to_home_page',
            defaultMessage: 'Back to home page'
          })}
        </Button>
      </SystemInformationView>
    </>
  );
};

export default ForbiddenView;
