import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button } from '../';
import classNames from 'classnames';

import nonBreakingSpace from '../../utils/lib/nonBreakingSpace';

import './TextCollapser.less';
const TextCollapser = props => {
  const { formatMessage } = useIntl();
  const buttonLabel = formatMessage(
    props.coil
      ? {
          id: 'general_show_more',
          defaultMessage: 'Show more'
        }
      : {
          id: 'general_show_less',
          defaultMessage: 'Show less'
        }
  );
  const appliedAppearance = useSelector(state =>
    state.district.get('appliedAppearance')
  );

  return (
    <div className="text-collapser-container">
      <div
        className={classNames({
          'text-collapsed': props.coil,
          'text-collapser-container__div': true,
          'text-justify': appliedAppearance?.get('justify-text') ?? false
        })}
      >
        {appliedAppearance?.get('non-breaking-space')
          ? nonBreakingSpace(props.children)
          : props.children}
      </div>
      <Button
        style={{ margin: 'unset' }}
        variant="settingsDefault"
        onClick={props.collapseAction}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

TextCollapser.propTypes = {
  children: PropTypes.node.isRequired,
  coil: PropTypes.bool.isRequired,
  collapseAction: PropTypes.func.isRequired
};

export default TextCollapser;
