import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, withRouter, Redirect } from 'react-router-dom';

import { confirmLoginUser } from '../../store/actions/userActions';
import { userSelectors } from '../../store/selectors';

import { hasJwt } from '../../utils/request';
import { codeUrl } from '../../utils/lib';

import { UserDataShape } from '../../shapes/user';

export class PrivateRoute extends Component {
  renderRoute(props, rest, Component) {
    if (rest.isUserLogged) {
      return <Component {...props} />;
    } else {
      if (!this.props.userData && hasJwt()) {
        this.props.confirmLoginUser();
        return null;
      }

      if (rest.redirect) {
        const pathname = this.props.location.pathname;

        return (
          <Redirect
            to={{
              pathname: `/logowanie?redirect=${codeUrl(pathname)}`,
              state: { from: props.location }
            }}
          />
        );
      } else {
        return <Redirect to="/logowanie" />;
      }
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => this.renderRoute(props, rest, Component)}
      />
    );
  }
}

const mapStateToProps = state => ({
  isUserFetching: userSelectors.isUserFetching(state),
  isUserLogged: userSelectors.isUserLogged(state),
  userData: userSelectors.userData(state)
});

const mapDispatchToProps = {
  confirmLoginUser
};

PrivateRoute.propTypes = {
  isUserFetching: PropTypes.bool.isRequired,
  userData: UserDataShape,
  confirmLoginUser: PropTypes.func.isRequired
};

PrivateRoute.defaultProps = {
  userData: null
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrivateRoute));
