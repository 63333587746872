import PropTypes from 'prop-types';
import { Button } from 'antd';

import './DefaultButton.less';

const DefaultButton = props => (
  <Button
    style={props.style}
    className={['default-button', props.btnType, props.className].join(' ')}
    onClick={!props.disabled ? props.onClick : undefined}
    disabled={props.disabled}
    htmlType={props.submit ? 'submit' : undefined}
    ghost={props.ghost}
    size={props.size}
    loading={props.loading}
  >
    {props.children}
  </Button>
);

DefaultButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
  btnType: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  ghost: PropTypes.bool,
  submit: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'default', 'small'])
};

export default DefaultButton;
