import { Select } from 'antd';

const DesktopSelect = props => {
  const {
    allowClear,
    className,
    name,
    options,
    onChange,
    placeholder,
    renderOptions,
    style,
    value
  } = props;

  return (
    <Select
      allowClear={allowClear}
      className={className}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      style={style}
      value={value}
    >
      {options?.map(renderOptions)}
    </Select>
  );
};
export default DesktopSelect;
