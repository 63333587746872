import { useSelector } from 'react-redux';

import { globalSelectors } from '../../store/selectors';

const { getGlobalSettings } = globalSelectors;

const useGlobalSettings = ({ toJS = true } = {}) => {
  const globalSettings = useSelector(state => getGlobalSettings(state));

  if (toJS) return globalSettings?.toJS();

  return globalSettings;
};

export default useGlobalSettings;
