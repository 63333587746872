import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { mapPortalSelectors } from '../../store/selectors';

const { getMapPortalByShortName } = mapPortalSelectors;

const useCurrentMapPortal = () => {
  const { prefix, portal } = useParams();
  const currentMapPortal = useSelector(state =>
    getMapPortalByShortName(state, prefix, portal)
  );

  return currentMapPortal;
};

export default useCurrentMapPortal;
