import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Map, GeoJSON } from 'react-leaflet';
import CanvasTileLayer from '../CanvasTileLayer';

const GeoJSONPreviewMap = ({
  data,
  onLoading,
  onLoad,
  wrapperClassName,
  wrapperStyle
}) => {
  const mapRef = useRef();
  const refreshMap = () => {
    const map = mapRef.current?.leafletElement;
    if (!map) return;
    map.invalidateSize();
  };

  const setMapPosition = ({ target }) => {
    if (!data.geometry) return;

    const bbox = target.getBounds();
    target._map.fitBounds(bbox, {});
  };

  useEffect(() => {
    // Przy zmianie propsa styli wrappera sprawdzamy, czy Leaflet nie potrzebuje
    // pobrać brakujących kafli - może ich brakować przy zmianie orientacji
    // z pionowej na poziomą.
    refreshMap();
  }, [wrapperStyle]);

  return (
    <div
      className={wrapperClassName}
      style={{ marginBottom: '10px', minHeight: 210, ...wrapperStyle }}
    >
      <Map
        ref={mapRef}
        animate={false}
        fadeAnimation={false}
        zoomAnimation={false}
        center={[52.0646808, 19.3925548]}
        zoom={13}
        zoomControl={false}
        style={{ height: 200 }}
      >
        {/* Używamy canvas, dlatego, że zwykłe kafle <img> wysyłałyby ponownie requesty
            podczas klonowania na potrzeby wydruku. */}
        <CanvasTileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution={
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          }
          crossOrigin="anonymous"
          onLoading={onLoading}
          onLoad={onLoad}
        />
        <GeoJSON data={data} onAdd={setMapPosition} />
      </Map>
    </div>
  );
};

GeoJSONPreviewMap.propTypes = {
  data: PropTypes.object.isRequired,
  onLoading: PropTypes.func,
  onLoad: PropTypes.func,
  wrapperClassName: PropTypes.string,
  wrapperStyle: PropTypes.object
};

export default GeoJSONPreviewMap;
