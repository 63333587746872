import axios from 'axios';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { parseResponseError } from '../../utils/lib';

import { showSuccess, showError } from '../../store/actions/globalActions';
import { globalSelectors } from '../../store/selectors';

import { Modal, Input } from 'antd';
import CopyButton from '../CopyButton';

import './CopyLinkPopup.less';

export class CopyLinkPopup extends Component {
  state = {
    shortUrl: this.props.url
  };

  onCopySuccess = () => {
    this.props.showSuccess(
      this.props.intl.formatMessage({
        id: 'district_portal_save_map_link_copied_success',
        defaultMessage: 'Link copied to clipboard.'
      })
    );
  };

  componentDidMount() {
    !this.props.isNotUrl && this.getShortenUrl();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.url !== this.props.url)
      this.setState({ shortUrl: this.props.url });
  }

  onCopyFailed = () => {
    this.props.showError(
      this.props.intl.formatMessage({
        id: 'district_portal_save_map_link_copied_failed',
        defaultMessage:
          'The link could not be copied - please copy the link yourself.'
      })
    );
  };

  getShortenUrl = () => {
    const { shortUrl: stateShortUrl } = this.state;
    const enableShortenUrl = this.props.globalSettings.get(
      'enable_shorten_url'
    );
    if (!enableShortenUrl) return;
    if (this.props.visible) {
      const urlData = {
        longUrl: this.props.url
      };

      axios
        .post('/giaply/create_short/', urlData)
        .then(res => {
          if (res.data?.shortUrl !== stateShortUrl)
            this.setState({ shortUrl: res.data?.shortUrl });
        })
        .catch(err => {
          showError(parseResponseError(err));
        });
    }
  };

  render() {
    const { shortUrl } = this.state;
    return (
      <Modal
        className="custom-modal copy-link-popup"
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        title={this.props.title}
        footer={null}
        zIndex={10000}
      >
        <div className="copy-link-popup-text">{this.props.children}</div>
        <Input
          className={!!this.props.additionalButton ? 'buttons-group' : ''}
          value={shortUrl}
          addonAfter={
            !!this.props.additionalButton ? (
              <div className="addon-group-buttons">
                <CopyButton
                  textToCopy={shortUrl}
                  onSuccess={this.onCopySuccess}
                  onFailed={this.onCopyFailed}
                />
                {this.props.additionalButton}
              </div>
            ) : (
              <CopyButton
                textToCopy={shortUrl}
                onSuccess={this.onCopySuccess}
                onFailed={this.onCopyFailed}
              />
            )
          }
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  showSuccess,
  showError
};
const mapStateToProps = state => ({
  globalSettings: globalSelectors.getGlobalSettings(state)
});

CopyLinkPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  isNotUrl: PropTypes.bool,
  title: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.element,
  onCancel: PropTypes.func.isRequired,
  showSuccess: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  additionalButton: PropTypes.node
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CopyLinkPopup));
