import { useState, useMemo } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import L from 'leaflet';

import { mapPortalSelectors } from '../../../store/selectors';

import useDbSelections from './useDbSelections';
import useSelectionToolTranslations from './useSelectionToolTranslations';
import useFetchSelectionToolSelectOptions from '../../../hooks/useFetchSelectionToolSelectOptions';

import { LOGICAL_OPERATOR_VALUES } from '../consts';

const layerGroup = new L.LayerGroup();

export const FORM_TYPES = {
  SELECTION_BY_SHAPE: 'shape',
  SELECTION_BY_LAYER: 'layer',
  SELECTION_BY_SKETCH: 'sketch',
  SELECTION_BY_SELECTION: 'selection',
  SELECTION_BY_PREDEFINED_QUERY: 'predefinedSelection',
  SELECTION_BY_SQL_QUERY: 'sqlQuery'
};

const useSelectionTool = ({ closeFn }) => {
  const [geometry, setGeometry] = useState(null);
  const [layer, setLayer] = useState(null);
  const [shapeGeometry, setShapeGeometry] = useState(null);
  const [topical, setTopical] = useState(false);
  const [formType, setFormType] = useState(null);
  const [currentEditionId, setCurrentEditionId] = useState(null);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState();
  const [showAdditionalParameters, setShowAdditionalParameters] = useState(
    false
  );
  const [logicalOperator, setLogicalOperator] = useState(
    LOGICAL_OPERATOR_VALUES.SUM
  );

  const { dbSelections, refetchDbSelections } = useDbSelections();
  const translations = useSelectionToolTranslations();
  const { prefix, portal } = useParams();
  const { isLoading } = useFetchSelectionToolSelectOptions();

  const { id: compositionId } = useSelector(state =>
    mapPortalSelectors.getCurrentComposition(state, prefix, portal)
  );

  const TOOLBAR_FORM_TITLE = {
    [FORM_TYPES.SELECTION_BY_SHAPE]: translations.selectionByShape,
    [FORM_TYPES.SELECTION_BY_LAYER]: translations.selectionByLayer,
    [FORM_TYPES.SELECTION_BY_SKETCH]: translations.selectionBySketch,
    [FORM_TYPES.SELECTION_BY_SELECTION]: translations.selectionBySelection,
    [FORM_TYPES.SELECTION_BY_PREDEFINED_QUERY]:
      translations.selectionByPredefinedQuery,
    [FORM_TYPES.SELECTION_BY_SQL_QUERY]: translations.selectionBySqlQuery
  };

  const title = useMemo(() => {
    if (!formType) return translations.toolName;
    return TOOLBAR_FORM_TITLE[formType];
  }, [formType]);

  const closeModal = () => {
    layerGroup.clearLayers();
    closeFn();
  };
  return {
    title,
    dbSelections,
    geometry,
    shapeGeometry,
    topical,
    formType,
    currentEditionId,
    layerGroup,
    compositionId,
    showAdditionalParameters,
    logicalOperator,
    layer,
    isPrintModalVisible,
    isLoading,
    setLayer,
    setGeometry,
    setShapeGeometry,
    setTopical,
    setFormType,
    setCurrentEditionId,
    refetchDbSelections,
    closeModal,
    setShowAdditionalParameters,
    setLogicalOperator,
    setIsPrintModalVisible
  };
};

export default useSelectionTool;
