import { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import queryString from 'query-string';

import { withRouter } from 'react-router-dom';

import { fetchHeaderLinks } from '../../store/actions/globalActions';
import { userSelectors, globalSelectors } from '../../store/selectors';

import { Layout, Icon, Row, Menu, Dropdown } from 'antd';
import { Brand, Button, FontSizeControl, ContrastSwitcher } from '../';
import UserMenu from '../UserMenu';

import { goToLoginPage, nestedCaller } from '../../utils/lib';

import mainRoutePaths from '../../router/portal/paths';
import { UserDataShape } from '../../shapes/user';

import { GlobalSettingsShape } from '../../shapes';

import './HeaderPortal.less';

const Header = Layout.Header;
const { SubMenu } = Menu;

export class HeaderPortal extends PureComponent {
  constructor(props) {
    super(props);
    this.districts = nestedCaller(this.props, 'districts', 'toJS');
    this.splittedLink = this.props.location.pathname.split('/')[1];

    this.state = {
      prefix: this.districts.includes(this.splittedLink)
        ? this.splittedLink
        : null,
      eservices: [],
      headerLinks: []
    };
  }

  componentDidMount() {
    // this.getEservices();
    this.fetchHeaderLinks();
  }

  fetchHeaderLinks = () => {
    this.props.fetchHeaderLinks(this.state.prefix);
  };

  componentDidUpdate(prevProps, prevState) {
    const currentPrefix = this.props.location.pathname;
    const prevPrefix = prevProps.location.pathname;

    if (currentPrefix !== prevPrefix) {
      this.setState(
        {
          prefix: this.districts.includes(currentPrefix.split('/')[1])
            ? currentPrefix.split('/')[1]
            : null
        },
        () => {
          if (this.state.prefix) this.fetchHeaderLinks();
        }
      );
    }

    if (
      this.props.updatedHeaderLink ||
      this.props.deletedHeaderLink ||
      this.props.createdHeaderLink
    ) {
      this.fetchHeaderLinks();
    }
  }

  goToLoginPage() {
    const { pathname, search } = this.props.location;
    const { push } = this.props.history;

    goToLoginPage(pathname + search, push);
  }

  goToRedirectPage() {
    const { search } = this.props.location;
    const { redirect } = queryString.parse(search);
    const isAuthorized = Boolean(this.props.userData);

    if (!isAuthorized && redirect && redirect.includes('admin')) {
      this.props.history.push(mainRoutePaths.districtList);
      return;
    }

    this.props.history.push(redirect || mainRoutePaths.districtList);
  }

  checkDistrictFromPath = (firstParam, portal) => {
    if (!portal) return false;

    const first = [
      'logwanie',
      'rejestracja',
      'aktywacja',
      'zmien_email',
      'profil',
      'admin',
      'not_found',
      'forbidden',
      'resetuj_haslo',
      'usun_konto'
    ];
    const forbidden = ['admin', 'profil', 'zgloszenia'];

    return first.includes(firstParam) || forbidden.includes(portal)
      ? false
      : true;
  };

  redirectToLink = link => {
    if (!link.link) return;

    if (link.open_in_window) {
      window.open(link.link);
    } else {
      window.location.href = link.link;
    }
  };

  renderUserButtons = () => {
    const isLoginPage = this.props.location.pathname.includes('/logowanie');
    const isMainPage =
      this.props.location.pathname.includes('/lista_jednostek') ||
      this.props.location.pathname === '/';

    // It's necessary to fix WCAG critical error
    const isMapPortal = window.location.pathname.split('/').length === 3;
    const variant = isMapPortal ? 'secondary' : 'custom';
    const buttonClassName = isMapPortal
      ? 'login-button'
      : 'login-button custom-button-styles';

    const returnLoginButton = () => {
      if (isLoginPage)
        return (
          <Button
            className={buttonClassName}
            variant={variant}
            onClick={() => this.goToRedirectPage()}
          >
            <FormattedMessage id="general_back" defaultMessage="Back" />
          </Button>
        );
      if (!isMainPage) {
        return (
          <Button
            className={buttonClassName}
            variant={variant}
            onClick={() => this.goToLoginPage()}
          >
            <FormattedMessage id="general_sign_in" defaultMessage="Sign in" />
          </Button>
        );
      }
    };
    if (this.props.userData) return <UserMenu />;
    return <div className="portal-header-right">{returnLoginButton()}</div>;
  };

  render() {
    const isAdminPanel = this.props.location.pathname.includes('/admin');
    const isPage404 = this.props.location.pathname.includes('/not_found');

    const firstParam = this.props.location.pathname.split('/')[1];
    const portal = this.props.location.pathname.split('/')[2];

    const isHomeLinkEnabled = this.props.globalSettings?.get(
      'enable_home_link'
    );
    const homeLink = this.props.globalSettings?.get('home_link');
    const displayableTitleHomeLink = this.props.globalSettings?.get(
      'displayable_title_home_link'
    );
    const areLinksEnabled = this.props.globalSettings?.get('enable_links');
    const formatMessage = this.props.intl.formatMessage;

    if (
      (this.checkDistrictFromPath(firstParam, portal) &&
        !this.props.fetchedAppearance) ||
      isPage404 ||
      this.props.isMapFullScreenMode
    )
      return null;

    const links = links => {
      return (
        <Menu className="header-link-menu">
          {links.map(v => {
            if (v.sub_links.length) {
              return (
                <SubMenu
                  key={v.id}
                  title={v.display_name}
                  onTitleClick={() => this.redirectToLink(v)}
                >
                  {v.sub_links.map(sub => {
                    return (
                      <Menu.Item
                        key={sub.id}
                        onClick={() => this.redirectToLink(sub)}
                      >
                        {sub.display_name}
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item key={v.id} onClick={() => this.redirectToLink(v)}>
                  {v.display_name}
                </Menu.Item>
              );
            }
          })}
        </Menu>
      );
    };

    return (
      <Header>
        <div className="portal-header">
          <Brand inverse />
          <Row
            type="flex"
            align="middle"
            justify="end"
            style={{ flexWrap: 'nowrap' }}
          >
            {!isAdminPanel && (
              <>
                <FontSizeControl />
                <ContrastSwitcher />
              </>
            )}
            {areLinksEnabled && this.props.headerLinks?.size ? (
              <Dropdown
                className="header-link"
                trigger={['click']}
                overlay={links(this.props.headerLinks)}
              >
                <span>
                  <span className="hrefs">
                    <FormattedMessage
                      id="general_go_to"
                      defaultMessage="Go to"
                    />{' '}
                    <Icon
                      type="caret-down"
                      aria-label={formatMessage({
                        id: 'general_go_to',
                        defaultMessage: 'Go to'
                      })}
                      role="img"
                    />
                  </span>
                  <span className="hrefs-xs">
                    <Icon type="link" />
                  </span>
                </span>
              </Dropdown>
            ) : null}
            {isHomeLinkEnabled && homeLink && (
              <a
                className="homepage-link-a"
                href={homeLink}
                aria-label="Redirection to landing page"
              >
                <span className="homepage-link">
                  {displayableTitleHomeLink}
                </span>
                <span
                  className="homepage-xs-link"
                  aria-label={displayableTitleHomeLink}
                >
                  <Icon type="home" />
                </span>
              </a>
            )}
            {this.renderUserButtons()}
          </Row>
        </div>
      </Header>
    );
  }
}

const mapStateToProps = state => ({
  userData: userSelectors.userData(state),
  headerLinks: state.global.get('headerLinks'),
  updatedHeaderLink: state.global.get('updatedHeaderLink'),
  deletedHeaderLink: state.global.get('deletedHeaderLink'),
  createdHeaderLink: state.global.get('createdHeaderLink'),
  fetchedAppearance: state.district.get('fetchedAppearance'),
  globalSettings: globalSelectors.getGlobalSettings(state),
  districts: state.district.get('districts'),
  isMapFullScreenMode: state.map.get('fullscreen')
});

const mapDispatchToProps = {
  fetchHeaderLinks
};

HeaderPortal.propTypes = {
  userData: UserDataShape,
  headerLinks: PropTypes.oneOfType([PropTypes.array, ImmutablePropTypes.list]),
  updatedHeaderLink: PropTypes.bool,
  deletedHeaderLink: PropTypes.bool,
  createdHeaderLink: PropTypes.bool,
  fetchedAppearance: PropTypes.bool.isRequired,
  globalSettings: GlobalSettingsShape,
  districts: ImmutablePropTypes.list.isRequired,
  isMapFullScreenMode: PropTypes.bool.isRequired,
  fetchHeaderLinks: PropTypes.func.isRequired
};

export default memo(
  injectIntl(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderPortal))
  )
);
