import { getFileId, getFileDetails } from '../';

import { getApiUrl } from '../../utils/lib';

const getFileUrl = (config = {}) =>
  new Promise(async (resolve, reject) => {
    const { district } = config;

    try {
      const { uuid: fileId } = await getFileId(config);

      if (!fileId) {
        throw new Error('File not found!');
      }

      const { url: fileUrl } = await getFileDetails({ district, fileId });

      resolve(`${getApiUrl()}${fileUrl}`);
    } catch (err) {
      reject(err);
    }
  });

export default getFileUrl;
