import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo
} from 'react';

import useUpdateEditSketchLabels from '../hooks/useUpdateEditSketchLabels';
import useMap from '../hooks/useMap';

import { bufferTypes } from '../components/MapControl/layer-editor/const';

const EditorToolsContext = createContext(undefined);

const { VERTEX, SEGMENT } = bufferTypes;
export const INITIAL_BUFFER_VALUES = {
  [SEGMENT]: null,
  [VERTEX]: null
};

export const EditorToolsContextProvider = ({
  isEditorActive,
  setIsEditorActive,
  children
}) => {
  const [editionId, setEditionId] = useState(null);
  const [sketchType, setSketchType] = useState(null);
  const [loadedSketch, setLoadedSketch] = useState(null);
  const [bufferValues, setBufferValues] = useState(INITIAL_BUFFER_VALUES);
  const [isEditorTouched, setIsEditorTouched] = useState(false);
  const [editSketchLabels, setEditSketchLabels] = useState(true);
  const [isImportedLayer, setIsImportedLayer] = useState(false);

  const map = useMap();
  useUpdateEditSketchLabels({ setEditSketchLabels });

  const currentEditionId = useRef(editionId);

  const getCurrentEdition = () =>
    currentEditionId.current && map.getEdition(currentEditionId.current);

  const updateEditionIdRefValue = useCallback(() => {
    currentEditionId.current = editionId;
  }, [editionId]);

  useEffect(updateEditionIdRefValue, [updateEditionIdRefValue]);

  const restoreInitialcContextState = useCallback(() => {
    setEditionId(null);
    setSketchType(null);
    setLoadedSketch(null);
    setBufferValues(INITIAL_BUFFER_VALUES);
    setIsEditorTouched(false);
    setIsImportedLayer(false);
    setEditSketchLabels(true);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isEditorActive,
      setIsEditorActive,
      currentEditionId,
      editionId,
      setEditionId,
      sketchType,
      setSketchType,
      loadedSketch,
      setLoadedSketch,
      bufferValues,
      setBufferValues,
      isEditorTouched,
      setIsEditorTouched,
      editSketchLabels,
      setEditSketchLabels,
      isImportedLayer,
      setIsImportedLayer,
      getCurrentEdition,
      restoreInitialcContextState
    }),
    [
      isEditorActive,
      editionId,
      sketchType,
      loadedSketch,
      bufferValues,
      isEditorTouched,
      editSketchLabels,
      isImportedLayer,
      restoreInitialcContextState
    ]
  );

  return (
    <EditorToolsContext.Provider value={memoizedValue}>
      {children}
    </EditorToolsContext.Provider>
  );
};

export const useEditorToolsContext = () => {
  const ctx = useContext(EditorToolsContext);
  if (ctx === undefined) {
    throw new Error(
      'useEditorToolsContext must be used within a EditorToolsContextProvider'
    );
  }

  return ctx;
};
