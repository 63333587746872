import { createCanvas, getTransparentLines, cropImageHorizontal } from '../';

/**
 * Tnie przekazany obraz na części. Zwraca tablicę z pociętymi fragmentami
 * zapisanymi jako obrazy Base64.
 *
 * @param {object} config
 * @param {string} config.image
 * @param {number} config.imageWidth
 * @param {number} config.chunkHeight
 * @returns {array}
 */

export default function divideImageHorizontal({
  image,
  imageWidth,
  chunkHeight
}) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = async e => {
      try {
        const legendHeight = e.target.height;

        const { ctx } = createCanvas({
          width: imageWidth,
          height: legendHeight
        });
        ctx.drawImage(img, 0, 0);
        const imgData = ctx.getImageData(0, 0, imageWidth, legendHeight);
        const emptyLines = await getTransparentLines(imgData);

        const chunks = [];
        const breakLines = [0];
        while (breakLines[breakLines.length - 1] !== legendHeight - 1) {
          const prevBreakLine = breakLines[breakLines.length - 1];
          const emptyLinesRange = emptyLines.filter(
            line => line >= prevBreakLine && line < prevBreakLine + chunkHeight
          );
          const currentBreakLine = Math.max(...emptyLinesRange);
          const croppedImg = await cropImageHorizontal(
            prevBreakLine,
            currentBreakLine,
            imgData
          );

          breakLines.push(currentBreakLine);

          const { canvas: canvas2, ctx: ctx2 } = createCanvas({
            width: imageWidth,
            height: chunkHeight
          });
          ctx2.putImageData(croppedImg, 0, 0);

          chunks.push(canvas2.toDataURL());
        }

        resolve(chunks);
      } catch (err) {
        reject(err);
      }
    };

    img.src = image;
  });
}
