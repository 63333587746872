import { DomEvent } from 'leaflet';

const disableMapPropagationByRef = ref => {
  if (!ref) return;

  const { on, fakeStop } = DomEvent;
  on(ref, 'click mousedown touchstart dblclick', fakeStop);
};

export default disableMapPropagationByRef;
