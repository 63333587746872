import { withRouter, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { DistrictBadge } from '../';

import { AppDistrictShape } from '../../shapes/district';

import notFoundImage from '../../images/image-not-found.png';

import './DistrictCard.less';

const DistrictCard = ({ district: districtData, isGlobalAdvancedSettings }) => {
  const {
    district,
    cover_image: coverImage,
    crest,
    basic_cover_image,
    prefix,
    advanced_view
  } = districtData;

  const districtUrl = `/${district.prefix}`;
  const { formatMessage } = useIntl();

  const browseMapPortalsText = formatMessage({
    id: 'district_card_browse_map_portals',
    defaultMessage: 'Browse map portals'
  });

  return (
    <div className="district-card">
      {advanced_view && isGlobalAdvancedSettings ? (
        <>
          <div className="district-card-header">
            <DistrictBadge
              district={district.nazwa}
              county={district.powiat}
              badgeFileName={crest}
            />
          </div>
          <div
            className="district-card-thumbnail"
            style={{ backgroundImage: `url(${coverImage || notFoundImage})` }}
          />
          <div className="district-card-footer">
            <Link
              to={districtUrl}
              aria-label={`${browseMapPortalsText} - ${district.nazwa} ${district.powiat}`}
            >
              {browseMapPortalsText}
            </Link>
          </div>
        </>
      ) : (
        <div>
          <Link to={districtUrl}>
            <img
              className="basic-cover-image"
              src={basic_cover_image || notFoundImage}
              alt={prefix}
            />
          </Link>
        </div>
      )}
    </div>
  );
};

DistrictCard.propTypes = {
  district: AppDistrictShape
};

export default withRouter(DistrictCard);
