const coordinatesAreEmpty = coordinates => {
  if (!coordinates.length) {
    return true;
  }

  if (Array.isArray(coordinates[0])) {
    return coordinatesAreEmpty(coordinates[0]);
  }

  return false;
};

export default coordinatesAreEmpty;
