/**
 * Funkcja sprawdzająca czy w grupie występują domyślnie zaznaczone warstwy (is_enabled = true)
 * @param {object} group
 */
const isGroupPartiallyChecked = group => {
  for (const elem of group.entries) {
    if (elem.type === 'group') {
      return isGroupPartiallyChecked(elem);
    } else {
      if (elem.is_enabled) {
        return true;
      }
    }
  }
  return false;
};

export default isGroupPartiallyChecked;
