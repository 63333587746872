import { CustomLogger } from '../../CustomLogger';

/**
 * Przypisuje id wygenerowane przez wskazaną funkcję
 * do każdego obiektu zawartego w tablicy.
 *
 * @param {array} input - tablica z obiektami
 * @param {function} idGeneratorFn - funkcja generująca id (domyślnie Math.random)
 * @return {bool}
 */
const addIdToObjFromArr = (input, idGeneratorFn = Math.random) => {
  if (!Array.isArray(input) || typeof idGeneratorFn !== 'function') {
    CustomLogger.error('invalid addIdToObjFromArr function parameter(s)!');
    return input;
  }

  return input.map(obj => {
    obj.id = idGeneratorFn();
    return obj;
  });
};

export default addIdToObjFromArr;
