import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './ScrollableDiv.less';

const ScrollableDiv = props => {
  const divRef = useRef(null);
  const scrollToBottom = () => {
    divRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  };
  useEffect(() => {
    /* Hook do automatycznego scrollowania na dół diva
        Jeżeli chcemy aby zawartość diva była automatycznie scrollowana 
        do jego ostatniej dodanej wartości, ustawiamy w propsach autoScroll na true
      */
    if (props.autoScroll) {
      scrollToBottom();
    }
  }, [props.children]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={['scrollable-div', props.className].join(' ')}
      style={{
        width: props.width,
        height: props.height,
        ...props.style
      }}
      onScroll={props.onScroll}
    >
      {props.children}
      <div ref={divRef} />
    </div>
  );
};

ScrollableDiv.propTypes = {
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
  className: PropTypes.string
};

export default ScrollableDiv;
