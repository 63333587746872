import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { injectIntl } from 'react-intl';
import { nestedCaller } from '../../utils/lib';

import './PhotoDescription.less';

const PhotoDescription = ({ attachmentsAttributes, photoAttachments }) => {
  const helperObject = {
    annotations_display: 'annotations',
    author_display: 'author',
    date_display: 'date',
    description_display: 'description',
    name_display: 'name',
    ownership_display: 'ownership',
    signature_display: 'signature',
    source_display: 'source'
  };

  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    getAttributes();
  }, [attachmentsAttributes]);

  const transformAttributes = () => {
    const defaultAttributes = nestedCaller(
      { attachmentsAttributes },
      'attachmentsAttributes',
      'toJS'
    );
    const keys = Object.keys(defaultAttributes).sort();
    const data = [];

    for (let i = 0; i < keys.length; i += 3) {
      data.push({
        key: keys[i],
        attribute: helperObject[keys[i]],
        displayName: defaultAttributes[keys[i]],
        index: defaultAttributes[keys[i + 1]],
        visible: defaultAttributes[keys[i + 2]]
      });
    }
    return data;
  };

  const sortAttributes = attributes =>
    [...attributes].sort((a, b) =>
      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    );

  const getAttributes = () => {
    const data = transformAttributes();
    setAttributes(sortAttributes(data));
  };

  return (
    <div className="photo-description">
      <ul>
        {attributes.map((elem, i) => {
          if (!elem.visible) return null;
          return (
            <li key={i}>
              <span className="photo-description-attr-name">
                {elem.displayName}:
              </span>
              {photoAttachments[elem.attribute]}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

PhotoDescription.propTypes = {
  photoAttachments: PropTypes.object.isRequired,
  attachmentsAttributes: ImmutablePropTypes.map
};

export default injectIntl(PhotoDescription);
