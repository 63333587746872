import { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../';

const ButtonPatient = ({ text, pendingText, onClick, buttonProps = {} }) => {
  const [isPending, setIsPending] = useState(false);

  const onClickWrapper = async e => {
    setIsPending(true);
    await onClick(e);
    setIsPending(false);
  };

  return (
    <Button
      onClick={onClickWrapper}
      {...buttonProps}
      disabled={buttonProps.disabled || isPending}
    >
      {isPending ? pendingText || text : text}
    </Button>
  );
};

ButtonPatient.propTypes = {
  text: PropTypes.string.isRequired,
  pendingText: PropTypes.string,
  onClick: PropTypes.func,
  buttonProps: PropTypes.object
};

export default ButtonPatient;
