export const CHANGE_THEME = 'CHANGE_THEME';
export const ERROR_SHOW = 'ERROR_SHOW';
export const ERROR_HIDE = 'ERROR_HIDE';
export const SUCCESS_SHOW = 'SUCCESS_SHOW';
export const SUCCESS_HIDE = 'SUCCESS_HIDE';
export const SIDEBAR_SHOW = 'SIDEBAR_SHOW';
export const SIDEBAR_HIDE = 'SIDEBAR_HIDE';
export const LOGOUT_COUNTDOWN_TIMER_SHOW = 'LOGOUT_COUNTDOWN_TIMER_SHOW';
export const LOGOUT_COUNTDOWN_TIMER_HIDE = 'LOGOUT_COUNTDOWN_TIMER_HIDE';
export const CHANGE_VALUE = 'CHANGE_VALUE';
export const RESET_SIDEBAR_PANELS_VISIBILITY =
  'RESET_SIDEBAR_PANELS_VISIBILITY';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const HAMBURGER_SHOW = 'HAMBURGER_SHOW';
export const HAMBURGER_HIDE = 'HAMBURGER_HIDE';
export const SET_PREVIOUS_URL = 'SET_PREVIOUS_URL';

export const FETCH_GLOBAL_SETTINGS_BEGIN = 'FETCH_GLOBAL_SETTINGS_BEGIN';
export const FETCH_GLOBAL_SETTINGS_SUCCESS = 'FETCH_GLOBAL_SETTINGS_SUCCESS';
export const FETCH_GLOBAL_SETTINGS_FAILED = 'FETCH_GLOBAL_SETTINGS_FAILED';
export const UPDATE_GLOBAL_SETTINGS_BEGIN = 'UPDATE_GLOBAL_SETTINGS_BEGIN';
export const UPDATE_GLOBAL_SETTINGS_SUCCESS = 'UPDATE_GLOBAL_SETTINGS_SUCCESS';
export const UPDATE_GLOBAL_SETTINGS_FAILED = 'UPDATE_GLOBAL_SETTINGS_FAILED';

export const DELETE_HEADER_LINK_BEGIN = 'DELETE_HEADER_LINK_BEGIN';
export const DELETE_HEADER_LINK_SUCCESS = 'DELETE_HEADER_LINK_SUCCESS';
export const DELETE_HEADER_LINK_FAILED = 'DELETE_HEADER_LINK_FAILED';

export const UPDATE_HEADER_LINK_BEGIN = 'UPDATE_HEADER_LINK_BEGIN';
export const UPDATE_HEADER_LINK_SUCCESS = 'UPDATE_HEADER_LINK_SUCCESS';
export const UPDATE_HEADER_LINK_FAILED = 'UPDATE_HEADER_LINK_FAILED';

export const CREATE_HEADER_LINK_BEGIN = 'CREATE_HEADER_LINK_BEGIN';
export const CREATE_HEADER_LINK_SUCCESS = 'CREATE_HEADER_LINK_SUCCESS';
export const CREATE_HEADER_LINK_FAILED = 'CREATE_HEADER_LINK_FAILED';

export const FETCH_HEADER_LINK_BEGIN = 'FETCH_HEADER_LINK_BEGIN';
export const FETCH_HEADER_LINK_SUCCESS = 'FETCH_HEADER_LINK_SUCCESS';
export const FETCH_HEADER_LINK_FAILED = 'FETCH_HEADER_LINK_FAILED';

export const GET_LANGUAGES_BEGIN = 'GET_LANGUAGES_BEGIN';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const GET_LANGUAGES_FAILED = 'GET_LANGUAGES_FAILED';

export const GET_TRANSLATION_BEGIN = 'GET_TRANSLATION_BEGIN';
export const GET_TRANSLATION_SUCCESS = 'GET_TRANSLATION_SUCCESS';
export const GET_TRANSLATION_FAILED = 'GET_TRANSLATION_FAILED';

export const CREATE_TRANSLATION_BEGIN = 'CREATE_TRANSLATION_BEGIN';
export const CREATE_TRANSLATION_SUCCESS = 'CREATE_TRANSLATION_SUCCESS';
export const CREATE_TRANSLATION_FAILED = 'CREATE_TRANSLATION_FAILED';

export const EDIT_TRANSLATION_BEGIN = 'EDIT_TRANSLATION_BEGIN';
export const EDIT_TRANSLATION_SUCCESS = 'EDIT_TRANSLATION_SUCCESS';
export const EDIT_TRANSLATION_FAILED = 'EDIT_TRANSLATION_FAILED';

export const DELETE_TRANSLATION_BEGIN = 'DELETE_TRANSLATION_BEGIN';
export const DELETE_TRANSLATION_SUCCESS = 'DELETE_TRANSLATION_SUCCESS';
export const DELETE_TRANSLATION_FAILED = 'DELETE_TRANSLATION_FAILED';

export const FETCH_USER_SETTING_BEGIN = 'FETCH_USER_SETTING_BEGIN';
export const FETCH_USER_SETTING_SUCCESS = 'FETCH_USER_SETTING_SUCCESS';
export const FETCH_USER_SETTING_FAILED = 'FETCH_USER_SETTING_FAILED';

export const FETCH_CURSOR_CRS_LIST_BEGIN = 'FETCH_CURSOR_CRS_LIST_BEGIN';
export const FETCH_CURSOR_CRS_LIST_SUCCESS = 'FETCH_CURSOR_CRS_LIST_SUCCESS';
export const FETCH_CURSOR_CRS_LIST_FAILED = 'FETCH_CURSOR_CRS_LIST_FAILED';

export const FETCH_EMAIL_SETTINGS_BEGIN = 'FETCH_EMAIL_SETTINGS_BEGIN';
export const FETCH_EMAIL_SETTINGS_SUCCESS = 'FETCH_EMAIL_SETTINGS_SUCCESS';
export const FETCH_EMAIL_SETTINGS_FAILED = 'FETCH_EMAIL_SETTINGS_FAILED';

export const VALIDATE_EMAIL_SETTINGS_BEGIN = 'VALIDATE_EMAIL_SETTINGS_BEGIN';
export const VALIDATE_EMAIL_SETTINGS_SUCCESS =
  'VALIDATE_EMAIL_SETTINGS_SUCCESS';
export const VALIDATE_EMAIL_SETTINGS_FAILED = 'VALIDATE_EMAIL_SETTINGS_FAILED';

export const EDIT_EMAIL_SETTINGS_BEGIN = 'EDIT_EMAIL_SETTINGS_BEGIN';
export const EDIT_EMAIL_SETTINGS_SUCCESS = 'EDIT_EMAIL_SETTINGS_SUCCESS';
export const EDIT_EMAIL_SETTINGS_FAILED = 'EDIT_EMAIL_SETTINGS_FAILED';

export const CHECK_IF_OFFER_MODEL_AVAILABLE_BEGIN =
  'CHECK_IF_OFFER_MODEL_AVAILABLE_BEGIN';
export const CHECK_IF_OFFER_MODEL_AVAILABLE_SUCCESS =
  'CHECK_IF_OFFER_MODEL_AVAILABLE_SUCCESS';
export const CHECK_IF_OFFER_MODEL_AVAILABLE_FAILED =
  'CHECK_IF_OFFER_MODEL_AVAILABLE_FAILED';

export const GET_PING_PONG_BEGIN = 'GET_PING_PONG_BEGIN';
export const GET_PING_PONG_SUCCESS = 'GET_PING_PONG_SUCCESS';
export const GET_PING_PONG_FAILED = 'GET_PING_PONG_FAILED';

export const CHECK_DEFAULT_USER_SETTING_REQUEST =
  'CHECK_DEFAULT_USER_SETTING_REQUEST';
export const CHECK_DEFAULT_USER_SETTING_SUCCESS =
  'CHECK_DEFAULT_USER_SETTING_SUCCESS';
export const CHECK_DEFAULT_USER_SETTING_FAILED =
  'CHECK_DEFAULT_USER_SETTING_FAILED';

export const SET_DEFAULT_USER_SETTING_REQUEST =
  'SET_DEFAULT_USER_SETTING_REQUEST';
export const SET_DEFAULT_USER_SETTING_SUCCESS =
  'SET_DEFAULT_USER_SETTING_SUCCESS';
export const SET_DEFAULT_USER_SETTING_FAILED =
  'SET_DEFAULT_USER_SETTING_FAILED';

export const SET_WINDOW_DIMENSIONS = 'SET_WINDOW_DIMENSIONS';

export const GET_CONTEXTUAL_HELP_TOOLTIPS_BEGIN =
  'GET_CONTEXTUAL_HELP_TOOLTIPS_BEGIN';
export const GET_CONTEXTUAL_HELP_TOOLTIPS_SUCCESS =
  'GET_CONTEXTUAL_HELP_TOOLTIPS_SUCCESS';
export const GET_CONTEXTUAL_HELP_TOOLTIPS_FAILED =
  'GET_CONTEXTUAL_HELP_TOOLTIPS_FAILED';

export const SET_RENDERED_COMPONENTS = 'SET_RENDERED_COMPONENTS';
export const CLEAR_RENDERED_COMPONENTS = 'CLEAR_RENDERED_COMPONENTS';

export const CHECK_USE_ADVANCED_FEATURES_BEGIN =
  'CHECK_USE_ADVANCED_FEATURES_BEGIN';
export const CHECK_USE_ADVANCED_FEATURES_SUCCESS =
  'CHECK_USE_ADVANCED_FEATURES_SUCCESS';
export const CHECK_USE_ADVANCED_FEATURES_FAILED =
  'CHECK_USE_ADVANCED_FEATURES_FAILED';

export const GET_EBUD_API_KEY_BEGIN = 'GET_EBUD_API_KEY_BEGIN';
export const GET_EBUD_API_KEY_SUCCESS = 'GET_EBUD_API_KEY_SUCCESS';
export const GET_EBUD_API_KEY_FAILED = 'GET_EBUD_API_KEY_FAILED';

export const RESET_SIDEBAR_TOPICAL_SEARCH_VISIBILITY =
  'RESET_SIDEBAR_TOPICAL_SEARCH_VISIBILITY';
