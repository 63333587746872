import axios from 'axios';

import { showError } from './globalActions';

import { selectionSelectors } from '../selectors';

import { parseResponseError } from '../../utils/lib';

import {
  FETCH_SELECTION_LAYERS_STARTED,
  FETCH_SELECTION_LAYERS_SUCCESS,
  FETCH_SELECTION_LAYERS_FAILED,
  UPDATE_SELECTION_LAYERS_STARTED,
  UPDATE_SELECTION_LAYERS_SUCCESS,
  UPDATE_SELECTION_LAYERS_FAILED,
  ADD_PREDEFINED_SELECTION_STARTED,
  ADD_PREDEFINED_SELECTION_SUCCESS,
  ADD_PREDEFINED_SELECTION_FAILED,
  FETCH_PREDEFINED_SELECTIONS_STARTED,
  FETCH_PREDEFINED_SELECTIONS_SUCCESS,
  FETCH_PREDEFINED_SELECTIONS_FAILED,
  DELETE_PREDEFINED_SELECTION_STARTED,
  DELETE_PREDEFINED_SELECTION_SUCCESS,
  DELETE_PREDEFINED_SELECTION_FAILED,
  EDIT_PREDEFINED_SELECTION_STARTED,
  EDIT_PREDEFINED_SELECTION_SUCCESS,
  EDIT_PREDEFINED_SELECTION_FAILED,
  IS_IDENTIFICATION_FOR_SINGLE_POINT
} from '../constants/selectionActionTypes';

const {
  getSelectionLayersByCompositionId,
  getPredefinedSelectionsByCompositionId
} = selectionSelectors;

// *** Fetch selection layers ***
const fetchSelectionLayersStarted = () => ({
  type: FETCH_SELECTION_LAYERS_STARTED
});

const fetchSelectionLayersSuccess = ({
  district,
  mapPortalCompositionId,
  data
}) => ({
  type: FETCH_SELECTION_LAYERS_SUCCESS,
  district,
  mapPortalCompositionId,
  data
});

const fetchSelectionLayersFailed = () => ({
  type: FETCH_SELECTION_LAYERS_FAILED
});

export const fetchSelectionLayers = ({ district, mapPortalCompositionId }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const isAlreadyFetched = getSelectionLayersByCompositionId(
    state,
    district,
    mapPortalCompositionId
  );

  if (Array.isArray(isAlreadyFetched)) return;

  dispatch(fetchSelectionLayersStarted());

  axios
    .get(
      `${district}/map_portal_composition/${mapPortalCompositionId}/selection_layers_config/?pagination_false`
    )
    .then(({ data }) => {
      // It's necessary because of SelectionTool form error
      const layersWithContentType = data.filter(
        ({ content_type }) => !!content_type
      );

      dispatch(
        fetchSelectionLayersSuccess({
          district,
          mapPortalCompositionId,
          data: layersWithContentType
        })
      );
    })
    .catch(error => {
      const errorMessage = parseResponseError(error, {
        unique: 'Wystąpił błąd podczas pobierania warstw kompozycji'
      });

      dispatch(showError(errorMessage));
      dispatch(fetchSelectionLayersFailed());
    });
};

// *** Update selection layers ***
const updateSelectionLayersStarted = () => ({
  type: UPDATE_SELECTION_LAYERS_STARTED
});

const updateSelectionLayersSuccess = ({
  district,
  mapPortalCompositionId,
  data
}) => ({
  type: UPDATE_SELECTION_LAYERS_SUCCESS,
  district,
  mapPortalCompositionId,
  data
});

const updateSelectionLayersFailed = () => ({
  type: UPDATE_SELECTION_LAYERS_FAILED
});

export const updateSelectionLayers = ({
  district,
  mapPortalCompositionId,
  updatedLayers
}) => (dispatch, getState) => {
  dispatch(updateSelectionLayersStarted());

  axios
    .patch(
      `${district}/map_portal_composition/${mapPortalCompositionId}/selection_layers_config/`,
      updatedLayers
    )
    .then(({ data: { results } }) => {
      dispatch(
        updateSelectionLayersSuccess({
          district,
          mapPortalCompositionId,
          data: results
        })
      );
    })
    .catch(error => {
      const errorMessage = parseResponseError(error, {
        unique: 'Wystąpił błąd podczas aktualizowania warstw kompozycji'
      });

      dispatch(showError(errorMessage));
      dispatch(updateSelectionLayersFailed());
    });
};

// *** Add predefined selection ***
const addPredefinedSelectionStarted = () => ({
  type: ADD_PREDEFINED_SELECTION_STARTED
});

const addPredefinedSelectionSuccess = ({
  district,
  mapPortalCompositionId,
  data
}) => ({
  type: ADD_PREDEFINED_SELECTION_SUCCESS,
  district,
  mapPortalCompositionId,
  data
});

const addPredefinedSelectionFailed = () => ({
  type: ADD_PREDEFINED_SELECTION_FAILED
});

export const addPredefinedSelection = ({
  district,
  mapPortalCompositionId,
  requestBody,
  onSuccess
}) => (dispatch, getState) => {
  dispatch(addPredefinedSelectionStarted());

  axios
    .post(
      `${district}/map_portal_composition/${mapPortalCompositionId}/predefined_selection/`,
      requestBody
    )
    .then(({ data }) => {
      dispatch(
        addPredefinedSelectionSuccess({
          district,
          mapPortalCompositionId,
          data
        })
      );

      onSuccess();
    })
    .catch(error => {
      const errorMessage = parseResponseError(error, {
        unique: 'Wystąpił błąd podczas dodawania selekcji predefiniowanej'
      });

      dispatch(showError(errorMessage));
      dispatch(addPredefinedSelectionFailed());
    });
};

// *** Fetch predefined selections ***
const fetchPredefinedSelectionsStarted = () => ({
  type: FETCH_PREDEFINED_SELECTIONS_STARTED
});

const fetchPredefinedSelectionsSuccess = ({
  district,
  mapPortalCompositionId,
  data
}) => ({
  type: FETCH_PREDEFINED_SELECTIONS_SUCCESS,
  district,
  mapPortalCompositionId,
  data
});

const fetchPredefinedSelectionsFailed = () => ({
  type: FETCH_PREDEFINED_SELECTIONS_FAILED
});

export const fetchPredefinedSelections = ({
  district,
  mapPortalCompositionId
}) => (dispatch, getState) => {
  const state = getState();
  const isAlreadyFetched = getPredefinedSelectionsByCompositionId(
    state,
    district,
    mapPortalCompositionId
  );

  if (Array.isArray(isAlreadyFetched)) return;

  dispatch(fetchPredefinedSelectionsStarted());

  axios
    .get(
      `${district}/map_portal_composition/${mapPortalCompositionId}/predefined_selection/`
    )
    .then(({ data: { results } }) => {
      dispatch(
        fetchPredefinedSelectionsSuccess({
          district,
          mapPortalCompositionId,
          data: results
        })
      );
    })
    .catch(error => {
      const errorMessage = parseResponseError(error, {
        unique: 'Wystąpił błąd podczas pobierania selekcji predefiniowanych'
      });

      dispatch(showError(errorMessage));
      dispatch(fetchPredefinedSelectionsFailed());
    });
};

// *** Delete predefined selections ***
const deletePredefinedSelectionStarted = () => ({
  type: DELETE_PREDEFINED_SELECTION_STARTED
});

const deletePredefinedSelectionSuccess = ({
  district,
  mapPortalCompositionId,
  predefinedSelectionId
}) => ({
  type: DELETE_PREDEFINED_SELECTION_SUCCESS,
  district,
  mapPortalCompositionId,
  predefinedSelectionId
});

const deletePredefinedSelectionFailed = () => ({
  type: DELETE_PREDEFINED_SELECTION_FAILED
});

export const deletePredefinedSelection = ({
  district,
  mapPortalCompositionId,
  predefinedSelectionId,
  onSuccess
}) => (dispatch, getState) => {
  dispatch(deletePredefinedSelectionStarted());

  axios
    .delete(
      `${district}/map_portal_composition/${mapPortalCompositionId}/predefined_selection/${predefinedSelectionId}/`
    )
    .then(() => {
      dispatch(
        deletePredefinedSelectionSuccess({
          district,
          mapPortalCompositionId,
          predefinedSelectionId
        })
      );

      onSuccess?.();
    })
    .catch(error => {
      const errorMessage = parseResponseError(error, {
        unique: 'Wystąpił błąd podczas usuwania selekcji predefiniowanej'
      });

      dispatch(showError(errorMessage));
      dispatch(deletePredefinedSelectionFailed());
    });
};

// *** Edit predefined selections ***
const editPredefinedSelectionStarted = () => ({
  type: EDIT_PREDEFINED_SELECTION_STARTED
});

const editPredefinedSelectionSuccess = ({
  district,
  mapPortalCompositionId,
  data
}) => ({
  type: EDIT_PREDEFINED_SELECTION_SUCCESS,
  district,
  mapPortalCompositionId,
  data
});

const editPredefinedSelectionFailed = () => ({
  type: EDIT_PREDEFINED_SELECTION_FAILED
});

export const editPredefinedSelection = ({
  district,
  mapPortalCompositionId,
  editedPredefinedSelectionId,
  requestBody,
  onSuccess
}) => (dispatch, getState) => {
  dispatch(editPredefinedSelectionStarted());

  axios
    .patch(
      `${district}/map_portal_composition/${mapPortalCompositionId}/predefined_selection/${editedPredefinedSelectionId}/`,
      requestBody
    )
    .then(({ data }) => {
      dispatch(
        editPredefinedSelectionSuccess({
          district,
          mapPortalCompositionId,
          data
        })
      );

      onSuccess();
    })
    .catch(error => {
      const errorMessage = parseResponseError(error, {
        unique: 'Wystąpił błąd podczas edycji selekcji predefiniowanej'
      });

      dispatch(showError(errorMessage));
      dispatch(editPredefinedSelectionFailed());
    });
};

export const setIsIdentificationForSinglePoint = isIdentificationForSinglePoint => ({
  type: IS_IDENTIFICATION_FOR_SINGLE_POINT,
  isIdentificationForSinglePoint
});
