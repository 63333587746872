import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'react-fast-compare';

import {
  addModelTranslation,
  editModelTranslation,
  deleteModelTranslation
} from '../../store/actions/modelsActions';
import { showSuccess, showError } from '../../store/actions/globalActions';
import { globalSelectors } from '../../store/selectors';

import { Modal, Input } from 'antd';
import { FormItem } from '../';

import { defaultLang as DEFAULT_LANGUAGE } from '../../config';

import handleError from '../../utils/handleError';

const { getLanguages } = globalSelectors;

const TranslationModal = ({
  value = {}, // { pl: { id: 1, value: 'Rzeki' }, en: { id: 2, value: 'Rivers' } };
  contentType,
  objectId,
  contentField,
  mapPortalId,
  showDefaultLanguage,
  closeEditor
}) => {
  const dispatch = useDispatch();
  const languages = useSelector(state => getLanguages(state));

  const [isSaving, setIsSaving] = useState(false);
  const [translations, setTranslations] = useState(value);

  const handleTranslationChange = (code, value) =>
    setTranslations(prevValue => ({
      ...prevValue,
      [code]: {
        ...prevValue[code],
        value
      }
    }));

  const getEditTranslationsPromises = () =>
    Object.keys(translations).map(code => {
      const { id, ...translation } = translations[code];
      const translationInitValue = value[code]?.value;

      // Dodaj tłumaczenie
      if (!translationInitValue && translation.value) {
        const body = {
          content_type: contentType,
          object_id: objectId,
          content_field: contentField,
          language: code,
          translated_value: translation.value,
          mapportal: mapPortalId
        };

        return dispatch(addModelTranslation(mapPortalId, body));
      }

      // Edytuj tłumaczenie
      const isChanged = translationInitValue !== translation.value;
      if (translationInitValue && translation.value && isChanged) {
        return dispatch(
          editModelTranslation(mapPortalId, id, translation.value)
        );
      }

      // Usuń tłumaczenie
      /* istanbul ignore else */
      if (translationInitValue && !translation.value) {
        return dispatch(deleteModelTranslation(mapPortalId, id));
      }

      return null;
    });

  const isChanged = () => !isEqual(value, translations);

  const saveTranslations = async () => {
    setIsSaving(true);

    const editPromises = getEditTranslationsPromises();

    try {
      await Promise.all(editPromises);
      dispatch(showSuccess('Tłumaczenia zapisane pomyślnie!'));
      closeEditor();
    } catch (err) {
      handleError(err);
      dispatch(showError('Wystąpił błąd podczas zapisu tłumaczeń.'));
    } finally {
      setIsSaving(false);
    }
  };

  const filterLanguages = () => {
    if (showDefaultLanguage) return languages;
    return languages.filter(({ code }) => code !== DEFAULT_LANGUAGE);
  };

  return (
    <Modal
      visible
      className="custom-modal"
      title="Tłumaczenia"
      okText="Zapisz"
      okButtonProps={{ loading: isSaving, disabled: !isChanged() }}
      onCancel={closeEditor}
      onOk={saveTranslations}
    >
      {filterLanguages().map(({ code }) => (
        <FormItem
          key={code}
          label={code.toUpperCase()}
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 22 }}
          style={{ marginBottom: 5 }}
        >
          <Input
            value={translations[code]?.value}
            onChange={e => handleTranslationChange(code, e.target.value)}
          />
        </FormItem>
      ))}
    </Modal>
  );
};

TranslationModal.propTypes = {
  value: PropTypes.object,
  contentType: PropTypes.string.isRequired,
  objectId: PropTypes.number.isRequired,
  contentField: PropTypes.string.isRequired,
  mapPortalId: PropTypes.number.isRequired,
  showDefaultLanguage: PropTypes.bool,
  closeEditor: PropTypes.func.isRequired
};

export default TranslationModal;
