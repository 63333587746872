import PropTypes from 'prop-types';

import Control from 'react-leaflet-control';
import './ScaleValueControl.less';

const ScaleValueControl = ({ position, scale }) => (
  <Control position={position} className="scale-value-control">
    <span>{scale}</span>
  </Control>
);

ScaleValueControl.propTypes = {
  scale: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired
};

export default ScaleValueControl;
