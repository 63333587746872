import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';

import { districtSelectors } from '../../store/selectors';

import { Icon } from 'antd';

import { localStorageKeys } from '../../config';

import './ContrastSwitcher.less';

const { getAppliedAppearance } = districtSelectors;

const ContrastSwitcher = () => {
  const { formatMessage } = useIntl();
  const appliedAppearance = useSelector(state => getAppliedAppearance(state));

  const lsHighContrast = localStorage.getItem(localStorageKeys.highContrast);

  const [afterInit, setAfterInit] = useState(false);
  const [enabled, setEnabled] = useState(
    lsHighContrast !== undefined ? JSON.parse(lsHighContrast) : false
  );
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();

  React.useEffect(() => {
    // Jeśli nie ustawimy tej zmiennej to w przypadku, gdy portal
    // posiada swój własny motyw nie zostanie on zaaplikowany
    // (blok "else" instrukcji sprawdzającej "enabled" skasuje wszystkie
    // nadpisane zmienne Less).
    if (!afterInit) setAfterInit(true);

    if (enabled) {
      document.body.classList.add('high-contrast');

      window.less
        .modifyVars({
          'first-color': '#000000',
          'first-text-color': '#ffffff',
          'secondary-color': '#000000',
          'secondary-text-color': '#ffffff',
          'danger-color': '#ff0000',
          'danger-text-color': '#000000',
          'warning-color': 'orange',
          'warning-text-color': '#000000',
          'success-color': 'green',
          'success-text-color': '#000000',
          'no-base-map-color': '#000000',
          'nav-sidebar-background': '#000000'
        })
        .then(() => {
          setLoading(false);
        });
    } else if (afterInit) {
      const appearanceObj = appliedAppearance ? appliedAppearance.toJS() : {};

      document.body.classList.remove('high-contrast');

      window.less.modifyVars(appearanceObj).then(() => setLoading(false));
    }
  }, [enabled, pathname, appliedAppearance]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleContrastSwitch = () => {
    setLoading(true);
    setEnabled(prevValue => {
      const newValue = !prevValue;
      localStorage.setItem(localStorageKeys.highContrast, newValue);
      return newValue;
    });
  };

  const buttonLabel = formatMessage(
    enabled
      ? {
          id: 'accessability_disable_high_contrast',
          defaultMessage: 'Disable high contrast'
        }
      : {
          id: 'accessability_enable_high_contrast',
          defaultMessage: 'Enable high contrast'
        }
  );

  return (
    <button
      onClick={handleContrastSwitch}
      aria-pressed="false"
      aria-label={buttonLabel}
      className="ant-btn btn btn-transp btn-text contrast-switcher-btn"
    >
      {loading && <Icon type="loading" role="img" aria-label={buttonLabel} />}
      {!loading && (
        <Icon
          type={enabled ? 'eye-invisible' : 'eye'}
          role="img"
          aria-label={buttonLabel}
        />
      )}
    </button>
  );
};

export default ContrastSwitcher;
