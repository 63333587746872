import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import autoBind from '../../utils/autoBind';

import { editMapPortalComposition } from '../../store/actions/mapPortalActions';
import { addPopupConfig } from '../../store/actions/popupActions';

import { Input } from 'antd';
import { Button } from '../';

import './AddPopupConfigInput.less';

export class AddPopupConfigInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      addingConfig: false
    };

    autoBind(this);
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  async addPopupConfig() {
    this.setState({ addingConfig: true });

    try {
      const addedConfig = await this.props.addPopupConfig({
        name: this.state.name
      });

      this.assignConfigToMapComposition(addedConfig.id);
    } catch {
      this.setState({ addingConfig: false });
    } finally {
      this.setState({ addingConfig: false });
    }
  }

  async assignConfigToMapComposition(configId) {
    const { id: mapPortalId, prefix } = this.props.match.params;
    const body = { config: configId };

    try {
      await this.props.editMapPortalComposition(
        mapPortalId,
        this.props.currentCompositionId,
        body,
        prefix
      );
      this.setState({ name: '' });
      this.props.onSuccess && this.props.onSuccess();
    } finally {
      this.setState({ addingConfig: false });
    }
  }

  render() {
    return (
      <div className="add-popup-config-input-group">
        <span className="add-popup-config-input-label">
          Nazwa konfiguracji:
        </span>
        <Input value={this.state.name} onChange={this.handleNameChange} />
        <Button
          variant="success"
          onClick={this.addPopupConfig}
          loading={this.state.addingConfig}
        >
          {!this.state.addingConfig ? 'Utwórz' : 'Dodawanie...'}
        </Button>
      </div>
    );
  }
}

const mapDispatchToProps = {
  addPopupConfig,
  editMapPortalComposition
};

AddPopupConfigInput.propTypes = {
  currentCompositionId: PropTypes.number,
  addPopupConfig: PropTypes.func.isRequired,
  editMapPortalComposition: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(AddPopupConfigInput));
