import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Table } from 'antd';

import { moveItem } from '../SortArrows';
import SortControl from '../SortControl';
import CRUDButtonsGroup from '../CRUDButtonsGroup';

import { editMapPortalComposition } from '../../store/actions/mapPortalActions';
import { showSuccess } from '../../store/actions/globalActions';

const CompositionOrder = props => {
  const param = useParams();
  const dispatch = useDispatch();

  const [compositions, setCompositions] = useState();
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);

  const setDefaultComposition = () => {
    const newCompositions = props.compositions
      .sort((a, b) => a.index - b.index)
      .map((v, i) => {
        return {
          index: i + 1,
          id: v.id,
          name: v.composition.name,
          project: v.composition.project_name
        };
      });
    setCompositions(newCompositions);
  };

  useEffect(() => {
    setDefaultComposition();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDefaultComposition();
  }, [props.compositions]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderControlArrows = attr => {
    const index = compositions.findIndex(current => current.id === attr.id);
    const readableIndex = index + 1;

    return (
      <SortControl
        // Źeby sortowanie odświeżało się poprawnie key musi być za każdym razem nowy.
        key={Math.random()}
        inputProps={{
          disabled: !editMode,
          defaultValue: attr.index,
          onPressEnter: e => moveAttr(attr, e.target.value - 1),
          onDragStart: preventDrag,
          style: { width: 45, textAlign: 'right', marginRight: 3 },
          draggable: true
        }}
        arrowsProps={{
          moveDown: () => moveAttr(attr, 'down'),
          moveUp: () => moveAttr(attr, 'up'),
          disabled: !editMode,
          firstItem: index === 0,
          lastItem: readableIndex === compositions.length,
          tabIndex: -1
        }}
      />
    );
  };

  const preventDrag = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  const moveAttr = async (attr, direction) => {
    const sortedCompositions = moveItem(
      compositions,
      item => item.id === attr.id,
      direction
    );

    sortedCompositions.forEach((composition, i) => {
      composition.index = i + 1;
    });

    setCompositions(sortedCompositions);
  };

  const startEdition = () => {
    setEditMode(true);
  };

  const cancelEdition = () => {
    setDefaultComposition();
    setEditMode(false);
  };

  const saveChanges = () => {
    setSaving(true);
    compositions.forEach((v, i) => {
      dispatch(
        editMapPortalComposition(
          param.id,
          v.id,
          { index: v.index - 1 },
          param.prefix,
          null,
          false
        )
      ).then(() => {
        if (compositions.length === i + 1) {
          dispatch(showSuccess('Kolejnośc kompozycji została zapisana.'));
          setSaving(false);
          setEditMode(false);
        }
      });
    });
  };

  const columns = [
    {
      title: 'Kolejność',
      dataIndex: 'order',
      key: 'order',
      render: (field, record) => renderControlArrows(record)
    },
    {
      title: 'Nazwa',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Nazwa projektu',
      dataIndex: 'project',
      key: 'project'
    }
  ];

  return (
    <div>
      <p>Ustaw kolejność kompozycji</p>
      <Table
        loading={saving}
        columns={columns}
        dataSource={compositions}
        size="small"
        rowKey="id"
      />
      <CRUDButtonsGroup
        saving={saving}
        edited={editMode}
        cancelFn={cancelEdition}
        editFn={startEdition}
        saveFn={saveChanges}
      />
    </div>
  );
};

CompositionOrder.propTypes = {
  compositions: PropTypes.array.isRequired
};

export default CompositionOrder;
