import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Select } from 'antd';
import { ColorsPreview } from '../';

import { AppearanceShape } from '../../shapes';

import { sortAlphabetic } from '../../utils/lib';

import './ThemeSelectInput.less';

const Option = Select.Option;

const ThemeSelectInput = props => (
  <Select
    className="theme-select-input"
    dropdownClassName="theme-select-input-dropdown"
    value={props.value}
    onChange={props.onChange}
    placeholder="Wybierz kolorystykę portalu..."
    showSearch
    filterOption={(input, option) =>
      option.props.children.props.name
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    }
    {...props.selectProps}
  >
    <Option key="default" value={null}>
      <ThemeSelectItem name="Domyślny" colors={['#006699', '#0fa4e9']} />
    </Option>
    {props.themes &&
      props.themes
        .sort((a, b) => sortAlphabetic(a, b, 'name'))
        .map(({ id, name, first_color, secondary_color }) => (
          <Option key={id} value={String(id)} title={name}>
            <ThemeSelectItem
              name={name}
              colors={[first_color, secondary_color]}
            />
          </Option>
        ))}
  </Select>
);

const ThemeSelectItem = props => (
  <span className="theme-select-input-option">
    <span>{props.name}</span>
    <ColorsPreview colors={props.colors} />
  </span>
);

ThemeSelectInput.defaultProps = {
  selectProps: {}
};

ThemeSelectInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  themes: ImmutablePropTypes.listOf(AppearanceShape),
  selectProps: PropTypes.object
};

ThemeSelectItem.propTypes = {
  name: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired
};

export { ThemeSelectItem };
export default ThemeSelectInput;
