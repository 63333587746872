import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { registersSelectors } from '../../../store/selectors';

import { isMobile } from '../../../utils/lib';

import { registers } from '../../../components/MapToolbar/toolsEnum';

const { getIsOpenRegister } = registersSelectors;

const useToolbarActive = ({
  mapPortalToolsConfiguration,
  defaultMode,
  toolbarMapTools,
  toolKey,
  disabled
}) => {
  const isOpenRegister = useSelector(getIsOpenRegister);

  const iconOpacity = {
    opacity: disabled ? 0.5 : 1
  };
  const mode = `${defaultMode}_mode`;

  const filteredToolConfiguration = useMemo(
    () =>
      mapPortalToolsConfiguration.filter(tool => tool.name === toolKey)?.[0],
    [mapPortalToolsConfiguration]
  );

  const isRegisterTool = toolKey === registers;
  const isActiveMode = filteredToolConfiguration?.[mode];
  const isMobileMode = filteredToolConfiguration?.is_responsive;
  const isToolActive =
    !toolKey ||
    (toolbarMapTools && isActiveMode && !isMobile()) ||
    (isMobile() && isMobileMode);

  return {
    isToolActive,
    iconOpacity,
    isRegisterToolActive: isRegisterTool && isOpenRegister
  };
};

export default useToolbarActive;
