import { FormattedMessage } from 'react-intl';

export const sizeValidator = (field, value, cb) => {
  if (value && isNaN(value))
    return cb(
      <FormattedMessage
        id="general_enter_number"
        defaultMessage="Enter a number"
        description="Enter a number"
      />
    );
  return value > 100
    ? cb(
        <FormattedMessage
          id="district_portal_csv_tool_size_validation"
          defaultMessage="Size cannot be bigger than 100"
          description="Size cannot be bigger than 100"
        />
      )
    : cb();
};
