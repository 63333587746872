import PropTypes from 'prop-types';

import ScrollableDiv from '../ScrollableDiv';

import './BlankPage.less';

const BlankPage = props => {
  return (
    <ScrollableDiv height="90vh">
      <span className="blank-page-title">{props.title}</span>
      <span className="blank-page-content">{props.content}</span>
    </ScrollableDiv>
  );
};

BlankPage.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
};

export default BlankPage;
