import L from 'leaflet';

import './style.less';

/**
 * Plugin dodający UI zapobiegające przesuwaniu mapy podczas
 * scrollowania na mobile oraz zoomowaniu na desktopie.
 *
 * Aby dodać, należy zaimportować ten plik do komponentu
 * w którym znajduje się mapa i przekazać do mapy jako
 * props "freezer" następujący obiekt konfiguracyjny:
 *
 * {
 *    enabled: boolean;
 *    pointerText?: string;
 *    touchText?: string;
 * }
 *
 * Przykład użycia: <DistrictDetailsMap />
 */

L.Map = L.Map.extend({
  _showPointerOverlay() {
    this._freezePointerContainer.style.opacity = 1;
  },
  _hidePointerOverlay() {
    this._freezePointerContainer.style.opacity = 0;
  },
  _createPointerOverlay() {
    const container = this.getContainer();
    const containerParent = container.parentNode;

    const wrapper = L.DomUtil.create('div', 'freezer-wrapper');
    const overlay = L.DomUtil.create('div', 'freezer-overlay');
    const message = L.DomUtil.create('span', 'freezer-pointer-overlay-content');

    message.textContent =
      this.options.freezer.pointerText ||
      'Press CTRL and scroll to zoom the map.';

    overlay.appendChild(message);
    container.appendChild(overlay);
    wrapper.appendChild(container);
    containerParent.appendChild(wrapper);

    this._freezePointerContainer = overlay;

    this._hidePointerOverlay();
  },
  _handleWheelEvent() {
    const map = this;

    this._freezePointerContainer.addEventListener('wheel', e => {
      map.scrollWheelZoom.disable();

      if (e.ctrlKey) {
        map._hidePointerOverlay();
        map.scrollWheelZoom.enable();
      }
    });
  },
  _handlePointer() {
    this._createPointerOverlay();

    this.on('mouseover', () => {
      this._showPointerOverlay();
      this._handleWheelEvent();
    });

    this.on('mouseout', this._hidePointerOverlay);
  },
  _showTouchOverlay() {
    this._freezeTouchContainer.style.zIndex = 9999;
    this._freezeTouchContainer.style.opacity = 1;
  },
  _hideTouchOverlay() {
    this._freezeTouchContainer.style.zIndex = -1;
    this._freezeTouchContainer.style.opacity = 0;
  },
  _buildTouchOverlay() {
    const { y: mapHeight } = this.getSize();
    const map = this.getContainer();
    const mapParent = map.parentNode;

    const wrapper = L.DomUtil.create('div', 'freezer-wrapper');
    wrapper.style.height = `${mapHeight}px`;

    const overlay = L.DomUtil.create('div', 'freezer-overlay');
    const message = L.DomUtil.create('span', 'freezer-touch-overlay-content');

    message.textContent =
      this.options.freezer.touchText ||
      'Use 2 fingers to control the map view.';

    overlay.appendChild(message);
    wrapper.appendChild(map);
    wrapper.appendChild(overlay);
    mapParent.appendChild(wrapper);

    this._freezeTouchContainer = overlay;
  },
  _handleTouch() {
    this.dragging.disable();

    this._buildTouchOverlay();

    this._freezeTouchContainer.addEventListener('touchstart', () => {
      this._hideTouchOverlay();
      this.dragging.enable();
    });

    this._freezeTouchContainer.addEventListener('touchend', e => {
      this._showTouchOverlay();
      this.dragging.disable();
    });
  },
  _initializeFreezer() {
    if (!this.options.freezer?.enabled) return;

    if (L.Browser.mobile) {
      return this._handleTouch();
    }

    this._handlePointer();
  }
});

L.Map.addInitHook('_initializeFreezer');
