import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Icon, Table } from 'antd';
import axios from 'axios';

import { globalSelectors, mapPortalSelectors } from '../../store/selectors';

import {
  AdminToolsConfigurationModal,
  Button,
  FormItem
} from '../../components';
import SortControl from '../../components/SortControl';
import { moveItem } from '../../components/SortArrows';

import handleError from '../../utils/handleError';

import { polishTranslations } from './polish-translations';

import './MapPortalToolsForm.less';

export const translations = {
  identification: (
    <FormattedMessage
      id="district_portal_toolbar_identification"
      defaultMessage="Identification"
      description="Identification"
    />
  ),
  move: (
    <FormattedMessage
      id="district_portal_toolbar_move_map"
      defaultMessage="Enable move the map"
      description="Move the map"
    />
  ),
  previous: (
    <FormattedMessage
      id="district_portal_toolbar_previous_view"
      defaultMessage="Previous view"
      description="Previous view"
    />
  ),
  next: (
    <FormattedMessage
      id="district_portal_toolbar_next_view"
      defaultMessage="Next view"
      description="Next view"
    />
  ),
  zoom_window: (
    <FormattedMessage
      id="district_portal_toolbar_window_magnification"
      defaultMessage="Window magnification"
      description="Window magnification"
    />
  ),
  measurement: (
    <FormattedMessage
      id="district_portal_toolbar_measurement"
      defaultMessage="Measurement"
      description="Measurement"
    />
  ),
  legend: (
    <FormattedMessage
      id="district_portal_layers_control_layers"
      defaultMessage="Map layers"
    />
  ),
  save_map: (
    <FormattedMessage
      id="district_portal_toolbar_save_map"
      defaultMessage="Save map"
      description="Save map"
    />
  ),
  print: (
    <FormattedMessage
      id="district_portal_toolbar_print_map"
      defaultMessage="Save map"
      description="Save map"
    />
  ),
  language: <FormattedMessage id="district_portal_toolbar_language" />,
  zoom_map: (
    <FormattedMessage
      id="district_portal_toolbar_map_scale"
      defaultMessage="Map scale"
      description="Map scale"
    />
  ),
  saved_popups: <FormattedMessage id="district_portal_toolbar_saved_popups" />,
  manage_wms: (
    <FormattedMessage
      id="district_portal_add_layer_tool"
      defaultMessage="Manage external data"
      description="Manage layers"
    />
  ),
  fit_view: (
    <FormattedMessage
      id="district_portal_toolbar_fit_view"
      defaultMessage="Fit view"
      description="Fit view"
    />
  ),
  fullscreen: (
    <FormattedMessage
      id="district_portal_toolbar_fullscreen"
      defaultMessage="Full screen"
      description="Full screen"
    />
  ),
  add_object: (
    <FormattedMessage
      id="district_portal_toolbar_add_object"
      defaultMessage="Add object"
      description="Add object"
    />
  ),
  defect: (
    <FormattedMessage
      id="district_portal_toolbar_report"
      defaultMessage="Zgłoszenia"
      description="Zgłoszenia"
    />
  ),
  timeline: (
    <FormattedMessage
      id="district_portal_toolbar_timeline"
      defaultMessage="Oś czasu"
      description="Oś czasu"
    />
  ),
  nmt_measurement: (
    <FormattedMessage
      id="district_portal_toolbar_measure_at_point"
      defaultMessage="Pomiar w punkcie"
    />
  ),
  geolocation: (
    <FormattedMessage
      id="search_type_geolocation"
      defaultMessage="Geolokalizacja"
    />
  ),
  investor: (
    <FormattedMessage
      id="district_portal_toolbar_investor"
      defaultMessage="Investor"
    />
  ),
  notes: (
    <FormattedMessage
      id="district_portal_toolbar_notes"
      defaultMessage="Notatki"
    />
  ),
  google_street_view: (
    <FormattedMessage
      id="district_portal_toolbar_google_street_view"
      defaultMessage="Google Street View"
    />
  ),
  information: (
    <FormattedMessage
      id="district_portal_toolbar_informations"
      defaultMessage="Information"
    />
  ),
  selection: (
    <FormattedMessage
      id="district_portal_toolbar_selection"
      defaultMessage="Selection"
    />
  ),
  sketchbook: (
    <FormattedMessage
      id="district_portal_toolbar_sketchbook"
      defaultMessage="Sketchbook"
    />
  ),
  enable_show_pan_arrow: (
    <FormattedMessage
      id="district_portal_toolbar_pan_arrow"
      defaultMessage="Pan map arrows"
    />
  ),
  lataren: (
    <FormattedMessage
      id="district_portal_toolbar_lataren"
      defaultMessage="Lantern"
    />
  ),
  log_in: (
    <FormattedMessage
      id="district_portal_toolbar_log_in"
      defaultMessage="Logowanie"
    />
  ),
  portal_3d_link: (
    <FormattedMessage
      id="district_portal_toolbar_3d_portal"
      defaultMessage="3D portal"
    />
  ),
  set_a_route: (
    <FormattedMessage
      id="district_portal_route_planning"
      defaultMessage="Route planning"
    />
  ),
  zoom_in: (
    <FormattedMessage
      id="district_portal_toolbar_zoom_in"
      defaultMessage="Zoom in"
    />
  ),
  zoom_out: (
    <FormattedMessage
      id="district_portal_toolbar_zoom_out"
      defaultMessage="Zoom out"
    />
  ),
  embed_map: <FormattedMessage id="embed_map" defaultMessage="Embed map" />,
  other_topical_data: (
    <FormattedMessage id="obliView_tool" defaultMessage="ObliView Tool" />
  ),
  bi_raports: (
    <FormattedMessage
      id="district_portal_bi_raports_tool"
      defaultMessage="Show BI raports"
      description="Show BI raports"
    />
  ),
  registers: (
    <FormattedMessage
      id="district_portal_register_tool"
      defaultMessage="Register"
      description="Register"
    />
  ),
  finn_synchronization: (
    <FormattedMessage
      id="district_portal_finn_synchronization_tool"
      defaultMessage="Finn synchronization"
      description="Finn synchronization"
    />
  ),
  e_construction: (
    <FormattedMessage
      id="econstruction_register_tool"
      defaultMessage="E-construction"
      description="E-construction"
    />
  ),
  wms_identification: (
    <FormattedMessage
      id="wms_identification_tool"
      defaultMessage="WMS identification"
      description="WMS identification"
    />
  ),
  e_services: (
    <FormattedMessage
      id="eservices_tool"
      defaultMessage="E-services"
      description="E-services"
    />
  )
};

const ADVANCED_FEATURE_NAMES = {
  INVESTOR: 'investor',
  LATAREN: 'lataren'
};

const ADVANCED_FEATURE_KEYS = {
  [ADVANCED_FEATURE_NAMES.INVESTOR]: 'use_oferta',
  [ADVANCED_FEATURE_NAMES.LATAREN]: 'use_latarnik'
};

class ToolsForm extends Component {
  state = {
    tools: [],
    fetchingToolsList: true,
    editedTool: null,
    selectedRowIds: null,
    initialSelectedRowKeys: null
  };

  constructor(props) {
    super(props);

    this.prevSelectedToolsLength = createRef();
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    this.handleToolsSelection();
  }

  handleToolsSelection = () => {
    const { selectedRowIds } = this.state;
    const { initialSelectedRowKeys, toggleUnableSave } = this.props;

    if (!selectedRowIds) return;

    const orderHasChanged = !selectedRowIds.every(
      (selectedToolId, index) =>
        selectedToolId === initialSelectedRowKeys[index]
    );

    const lengthHasChanged =
      selectedRowIds.length !== initialSelectedRowKeys.length;

    const initialSelectionHasChanged = orderHasChanged || lengthHasChanged;

    toggleUnableSave(!initialSelectionHasChanged);
  };

  getData = () => {
    const url = `map_toolbar_tools/`;
    let allTools = [];
    let editedMapPortalTools = [];

    this.props.fetchMapPortalTools(this.props.mapPortal.id);

    axios
      .get(url)
      .then(response => {
        //bez narzędzia multiselect
        const allToolsResponse = response.data.filter(function(elem) {
          return elem.name !== 'multi_select';
        });

        this.setState({
          allTools: allToolsResponse,
          fetchingToolsList: false
        });

        allTools = allToolsResponse;
        if (this.props.mapPortal) {
          const url2 = `map_toolbar_tools/?map_portal_id=${this.props.mapPortal.id}`;
          axios
            .get(url2)
            .then(response => {
              const editedMapPortalToolsResponse = response.data.filter(
                function(elem) {
                  return elem.name !== 'multi_select';
                }
              );

              this.setState({
                editedMapPortalTools: editedMapPortalToolsResponse
              });
              editedMapPortalTools = editedMapPortalToolsResponse;
              this.prepareToolsData(allTools, editedMapPortalTools);
            })
            .catch(error => {
              handleError(error);
            });
        } else {
          this.prepareToolsData(allTools, []);
        }
      })
      .catch(error => {
        handleError(error);
      });
  };

  getTool(id) {
    const tool = this.props.mapPortalToolsConfiguration.find(
      tool => tool.id === id
    );

    if (tool) this.setState({ editedTool: tool });
  }

  isAdvancedTool = toolName =>
    typeof ADVANCED_FEATURE_KEYS[toolName] === 'string';

  isAdvancedToolEnabled = toolName =>
    this.props.advancedFeaturesUse[ADVANCED_FEATURE_KEYS[toolName]];

  prepareToolsData = (allTools, editedMapPortalTools) => {
    const listOfTools = [];
    const listOfIds = [];

    for (const tool of allTools) {
      const isBasicTool = !this.isAdvancedTool(tool.name);
      const isAdvancedToolEnabled =
        this.isAdvancedTool(tool.name) && this.isAdvancedToolEnabled(tool.name);

      const new_tool = {
        ...tool,
        visible: false,
        displayName: polishTranslations[tool.name],
        index: Infinity
      };

      if (
        editedMapPortalTools.filter(function(elem) {
          return elem.name === tool.name;
        }).length
      ) {
        new_tool.visible = true;
        new_tool.index = editedMapPortalTools.find(
          portalTool => tool.name === portalTool.name
        ).index;
        new_tool.operation = (
          <Button variant="text" onClick={() => this.getTool(tool.id)}>
            <Icon type="edit" />
          </Button>
        );

        if (isBasicTool || isAdvancedToolEnabled)
          listOfIds.push({ id: tool.id, index: new_tool.index });
      }

      if (isBasicTool || isAdvancedToolEnabled) listOfTools.push(new_tool);
    }

    this.setState({
      tools: listOfTools.sort((a, b) => a.index - b.index)
    });

    this.props.onChange(listOfIds);
    this.prevSelectedToolsLength.current = listOfTools.filter(
      ({ visible }) => visible
    ).length;
  };

  closeEditor = () => this.setState({ editedTool: null });

  getLastSelectedToolId = ({
    isNewToolSelected,
    prevSelectedRowIds,
    selectedRowKeys
  }) => {
    if (!isNewToolSelected) return;

    const id = selectedRowKeys.find(
      rowId => !prevSelectedRowIds.includes(rowId)
    );

    return id;
  };

  // Function responsible for changing the order of tools
  // The selected tool is moved to the end of the array of other selected tools.
  regroupTools = (newToolSelectedId, selectedRowIds) => {
    const { tools } = this.state;

    if (!newToolSelectedId) return tools;

    const selectedToolsArr = [];
    const unselectedToolsArr = [];
    const newTool = tools.find(({ id }) => id === newToolSelectedId);

    tools.forEach(tool => {
      if (tool.id === newToolSelectedId) return;

      const isSelected = selectedRowIds.includes(tool.id);

      if (isSelected) selectedToolsArr.push(tool);
      else unselectedToolsArr.push(tool);
    });

    const selectedTools = [
      ...selectedToolsArr,
      { ...newTool, index: selectedRowIds.length + 1 }
    ];

    return { selectedTools, unselectedTools: unselectedToolsArr };
  };

  updateToolsSelection = (selectedRowKeys, selectedRows) => {
    const { selectedRowIds, tools } = this.state;

    const prevSelectedRowIds =
      selectedRowIds ?? this.props.initialSelectedRowKeys;

    const isNewToolSelected =
      prevSelectedRowIds.length < selectedRowKeys.length;

    const lastSelectedToolId = this.getLastSelectedToolId({
      isNewToolSelected,
      prevSelectedRowIds,
      selectedRowKeys
    });

    const { selectedTools, unselectedTools } = this.regroupTools(
      lastSelectedToolId,
      prevSelectedRowIds
    );

    const newSelectedTools = !!lastSelectedToolId
      ? selectedTools
      : selectedRows;

    this.props.onChange(newSelectedTools, false);

    return !!lastSelectedToolId
      ? [...selectedTools, ...unselectedTools]
      : tools;
  };

  getSavedIds = () =>
    this.props.mapPortalToolsConfiguration.map(tool => tool.id);

  getSelectedAllTools = savedIds => {
    let lastIndex = this.state.tools.reduce(
      (acc, { index }) => (isFinite(index) && acc < index ? index : acc),
      0
    );

    const updatedTools = this.state.tools.map(({ id, index, ...restProps }) => {
      if (isFinite(index)) return { id, index, ...restProps };

      lastIndex = lastIndex + 1;
      const isButtonVisible = savedIds.includes(id);
      return {
        ...restProps,
        id,
        index: lastIndex,
        visible: true,
        operation: isButtonVisible ? (
          <Button variant="text" onClick={() => this.getTool(id)}>
            <Icon type="edit" />
          </Button>
        ) : null
      };
    });

    return updatedTools;
  };

  selectRemainingTools = ({ selectedRowKeys, selectedRows, savedIds }) => {
    const selectedAllTools = this.getSelectedAllTools(savedIds);

    this.setState({
      selectedRowIds: selectedRowKeys,
      tools: selectedAllTools
    });

    const rowsWithoutOperation = selectedAllTools.map(
      ({ operation, ...restProps }) => ({ ...restProps })
    );

    this.props.onChange(rowsWithoutOperation);
  };

  getUnselectedAllTools = () =>
    this.state.tools.map(({ index, ...restProps }) => ({
      ...restProps,
      index: Infinity,
      visible: false,
      operation: null
    }));

  unselectAllTools = ({ selectedRowKeys, selectedRows }) => {
    const unselectedAllTools = this.getUnselectedAllTools();

    this.setState({
      selectedRowIds: selectedRowKeys,
      tools: unselectedAllTools
    });

    this.props.onChange(selectedRows);
  };

  handleCheckboxChange = (selectedRowKeys, selectedRows) => {
    const savedIds = this.getSavedIds();

    const selectAllHasBeenUsed =
      Math.abs(this.prevSelectedToolsLength.current - selectedRows.length) > 1;

    this.prevSelectedToolsLength.current = selectedRows.length;

    if (selectAllHasBeenUsed && this.prevSelectedToolsLength.current) {
      return this.selectRemainingTools({
        selectedRowKeys,
        selectedRows,
        savedIds
      });
    }

    if (selectAllHasBeenUsed && !this.prevSelectedToolsLength.current) {
      return this.unselectAllTools({
        selectedRowKeys,
        selectedRows
      });
    }

    const { tools } = this.state;

    this.props.onChange(selectedRows);
    const deactivatedTool = tools.find(
      tool =>
        tool.index >= 0 &&
        tool.index !== Infinity &&
        !selectedRowKeys.includes(tool.id)
    );

    if (deactivatedTool) {
      this.moveAttr(deactivatedTool, tools.length - 1, false);
    }

    const updatedTools = this.updateToolsSelection(
      selectedRowKeys,
      selectedRows
    );

    this.setState({
      selectedRowIds: selectedRowKeys,
      tools: updatedTools.map(tool => {
        const { id } = tool;
        if (selectedRowKeys.includes(id)) {
          const isButtonVisible = savedIds.includes(id);

          return {
            ...tool,
            visible: true,
            operation: isButtonVisible ? (
              <Button variant="text" onClick={() => this.getTool(id)}>
                <Icon type="edit" />
              </Button>
            ) : null
          };
        } else {
          return {
            ...tool,
            visible: false,
            operation: null,
            index: Infinity
          };
        }
      })
    });
  };

  getCheckboxProps = ({ name, visible }) => ({
    name: name,
    checked: visible
  });

  moveAttr = async (attr, direction, sort = true) => {
    const sortedTools = moveItem(
      this.state.tools,
      item => item.id === attr.id,
      direction
    );

    sortedTools.forEach((tool, i) => {
      tool.index = i + 1;
    });

    this.setState({ tools: sortedTools });
    sort && this.props.onChange(sortedTools.filter(tool => tool.visible));
  };

  preventDrag = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  renderControlArrows = attr => {
    const index = this.state.tools.findIndex(current => current.id === attr.id);
    const readableIndex = index + 1;

    return (
      <SortControl
        // Źeby sortowanie odświeżało się poprawnie key musi być za każdym razem nowy.
        key={Math.random()}
        inputProps={{
          defaultValue: attr.index === Infinity ? null : attr.index,
          onPressEnter: e => this.moveAttr(attr, e.target.value - 1),
          onDragStart: this.preventDrag,
          style: { width: 45, textAlign: 'right', marginRight: 3 },
          draggable: true
        }}
        arrowsProps={{
          moveDown: () => this.moveAttr(attr, 'down'),
          moveUp: () => this.moveAttr(attr, 'up'),
          firstItem: index === 0,
          lastItem: readableIndex === this.state.tools.length,
          tabIndex: -1
        }}
      />
    );
  };

  filterRecords = (records, value) =>
    records.filter(({ displayName }) =>
      displayName.toLowerCase().includes(value.toLowerCase())
    );

  renderToolsTable = filterValue => {
    const columns = [
      {
        key: 'index',
        title: 'Kolejność',
        dataIndex: '',
        align: 'center',
        render: attr => this.renderControlArrows(attr),
        width: 120
      },
      {
        title: 'Narzędzia',
        dataIndex: 'displayName',
        sortOrder: false,
        align: 'left'
      },
      {
        title: 'Akcja',
        dataIndex: 'operation',
        align: 'left'
      }
    ];

    const rowSelection = {
      selectedRowKeys: this.state.tools.reduce(
        (acc, { visible, id }) => (visible ? [...acc, id] : acc),
        []
      ),
      onChange: this.handleCheckboxChange,
      getCheckboxProps: this.getCheckboxProps
    };

    const dataSource = filterValue
      ? this.filterRecords(this.state.tools, filterValue)
      : this.state.tools;

    const enableConfigurationModes = this.props.mapPortal
      .enable_configuration_modes;

    return (
      <Table
        className=""
        rowKey={'id'}
        columns={enableConfigurationModes ? columns : columns.slice(0, -1)}
        dataSource={dataSource}
        size="small"
        loading={this.state.fetchingToolsList}
        rowSelection={rowSelection}
        pagination={false}
      />
    );
  };

  render() {
    return (
      <FormItem>
        {this.renderToolsTable(this.props.filterValue)}
        {this.state.editedTool ? (
          <AdminToolsConfigurationModal
            tool={this.state.editedTool}
            closeEditor={this.closeEditor}
            mapPortalId={this.props.mapPortal.id}
          />
        ) : null}
      </FormItem>
    );
  }
}

const { getAdvancedFeaturesUse } = globalSelectors;
const {
  getInitialSelectedRowKeys,
  getFetchingMapPortalToolsConfiguration
} = mapPortalSelectors;

const mapStateToProps = state => ({
  advancedFeaturesUse: getAdvancedFeaturesUse(state),
  initialSelectedRowKeys: getInitialSelectedRowKeys(state),
  isFetchingMapPortalTools: getFetchingMapPortalToolsConfiguration(state)
});

ToolsForm.propTypes = {
  isToolConfigurable: PropTypes.bool,
  filterValue: PropTypes.string,
  advancedFeaturesUse: PropTypes.object,
  fetchMapPortalTools: PropTypes.func,
  mapPortalToolsConfiguration: PropTypes.array
};

export default connect(mapStateToProps)(ToolsForm);
