import { Component } from 'react';
import PropTypes from 'prop-types';

import { Tooltip, Icon } from 'antd';
import { RoundedIcon, PasswordVisibilitySwitcher } from '../';

import './InputWithTitle.less';

class InputWithTitle extends Component {
  static defaultProps = {
    type: 'text',
    passwordVisibilitySwitcher: true
  };

  state = {
    value: '',
    showInputValue: false
  };

  componentDidMount() {
    this.setInitialValue();
  }

  componentDidUpdate() {
    this.checkInputValue();
  }

  setInitialValue = () => {
    const value = this.props.defaultValue || this.props.value;

    if (value) {
      this.setState({ value });
    }
  };

  /**
   * Metoda sprawdza czy wartość pola nie została zmieniona programowo
   * (bez użycia klawiatury), np. za pomocą funkcji czyszczenia formularza.
   * Jeśli tak się stało to aktualizuje lokalną wartość na tą samą, która
   * została przekazana w props.value.
   */
  checkInputValue = () => {
    if (this.props.value !== this.state.value) {
      this.setState({ value: this.props.value });
    }
  };

  onChange = e => {
    this.setState({ value: e.target.value });
    this.props.onChange && this.props.onChange(e);
  };

  handleFocus = () => {
    this.setState({ focused: false });
    this.props.onFocus && this.props.onFocus();
  };

  handleBlur = () => {
    this.setState({ focused: true });
    this.props.onBlur && this.props.onBlur();
  };

  handleKeyPress = e => {
    if (e.charCode === 13 && this.props.onPressEnter) {
      this.props.onPressEnter(e);
    }
  };

  renderInputIcon = () => {
    const hasErrors =
      this.props['data-__field'] &&
      this.props['data-__field'].errors &&
      this.props['data-__field'].errors.length;

    if (this.props.loading) {
      return <Icon type="loading" />;
    }

    if (hasErrors) {
      const errMessage = (
        <ul className="unformatted-list">
          {this.props['data-__field'].errors.map(error => (
            <li key={Math.random()}>{error.message}</li>
          ))}
        </ul>
      );

      return (
        <Tooltip title={errMessage}>
          <div>
            <span className="sr-only" tabIndex={0}>
              {errMessage}
            </span>
            <RoundedIcon type="close" variant="danger" />
          </div>
        </Tooltip>
      );
    }

    if (this.state.value && this.state.focused) {
      return <RoundedIcon type="check" variant="secondary" />;
    }

    return null;
  };

  toggleInputValueVisibility = value => {
    this.setState({ showInputValue: value });
  };

  render() {
    const hasErrors =
      this.props['data-__field'] &&
      this.props['data-__field'].errors &&
      this.props['data-__field'].errors.length;
    const passwordInput = this.props.type === 'password';

    return (
      <div
        className={[
          'titled-input-group',
          hasErrors ? 'titled-input-group-error' : '',
          this.props.groupClassName
        ].join(' ')}
      >
        <label htmlFor={this.props.title} className={this.props.labelClassName}>
          {this.props.title}
        </label>
        <input
          ref={this.props.inputRef || this.props.ref}
          id={this.props.title}
          className={[
            this.props.block ? 'titled-input-block' : '',
            this.props.inputClassName
          ].join(' ')}
          type={this.state.showInputValue ? 'text' : this.props.type}
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyPress={this.handleKeyPress}
          value={this.state.value}
          tabIndex={this.props.tabIndex}
          name={this.props.name}
        />
        {this.renderInputIcon()}
        {this.props.passwordVisibilitySwitcher && passwordInput && (
          <PasswordVisibilitySwitcher
            onChange={this.toggleInputValueVisibility}
          />
        )}
      </div>
    );
  }
}

InputWithTitle.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onPressEnter: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  block: PropTypes.bool,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  name: PropTypes.string,
  passwordVisibilitySwitcher: PropTypes.bool
};

export default InputWithTitle;
