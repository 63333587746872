import { withRouter } from 'react-router';
import { Select, Spin } from 'antd';
import PropTypes from 'prop-types';

import useToolsConfiguration from './hooks/useToolsConfiguration';

import SettingsRow from '../SettingsRow';
import LabeledSwitch from '../LabeledSwitch';
import CustomSearchInput from '../CustomSearchInput';

import './AdminToolsConfiguration.less';

const { Option } = Select;

const AdminToolsConfiguration = ({ match: { params }, changeFilterValue }) => {
  const {
    saving,
    adminToolsConfigurationOptions,
    values,
    mapPortalKeys,
    handleMode
  } = useToolsConfiguration({ params });

  const { enableConfigurationModes, defaultMode } = mapPortalKeys;

  return (
    <div className="admin-tools-configuration">
      <SettingsRow description={'Czy chcesz konfigurować narzędzia w trybach?'}>
        <LabeledSwitch
          onChange={event => handleMode(event, enableConfigurationModes)}
          checked={values[enableConfigurationModes]}
          disabled={saving[enableConfigurationModes]}
        />
        <Spin spinning={saving[enableConfigurationModes]} />
      </SettingsRow>
      <SettingsRow description={'Wskaż domyślny tryb'}>
        <Select
          style={{ minWidth: '250px' }}
          placeholder={'Wybierz tryb'}
          value={values[defaultMode]}
          onChange={event => handleMode(event, defaultMode)}
          disabled={saving[defaultMode]}
        >
          {adminToolsConfigurationOptions.map(({ value, label }) => (
            <Option value={value} key={value}>
              {label}
            </Option>
          ))}
        </Select>
        <Spin spinning={saving[defaultMode]} />
      </SettingsRow>
      <div className="admin-tools-configuration__search-input-container">
        <CustomSearchInput
          placeholder="Wyszukaj"
          changeFilterValue={changeFilterValue}
        />
      </div>
    </div>
  );
};

AdminToolsConfiguration.propTypes = {
  match: PropTypes.object,
  changeFilterValue: PropTypes.func
};

export default withRouter(AdminToolsConfiguration);
