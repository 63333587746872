import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import useTransformCoordinatesToInputValue from '../../hooks/useTransformCoordinatesToInputValue';

import { showError } from '../../store/actions/globalActions';

import { Input, Tooltip, Icon } from 'antd';
import { Button } from '../';

import handleError from '../../utils/handleError';

import './GeolocationInput.less';

const GeolocationInput = ({ coordinates, clearCoordinates, onLocation }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const transformCoordinates = useTransformCoordinatesToInputValue();

  const options = {
    maximumAge: 5 * 60 * 1000 // Cache results for 5 minutes to prevent delay
  };

  const getLocation = () => {
    clearCoordinates();
    if (!navigator?.geolocation) {
      dispatch(
        showError(
          formatMessage({
            id: 'geolocation_failed',
            defaultMessage:
              'The location of this device could not be retrieved.'
          })
        )
      );
      return;
    }
    navigator.geolocation.getCurrentPosition(
      onLocation,
      error => {
        dispatch(
          showError(
            formatMessage({
              id: 'geolocation_failed',
              defaultMessage:
                'The location of this device could not be retrieved.'
            })
          )
        );
        handleError(error);
      },
      options
    );
  };

  const suffix = (
    <>
      {coordinates ? (
        <Tooltip
          title={
            <FormattedMessage
              id="district_portal_report_change_point"
              defaultMessage="Change coordinates"
              description="Change coordinates"
            />
          }
        >
          <Button variant="text" onClick={clearCoordinates}>
            <Icon
              style={{ marginRight: 5 }}
              className="report-form-icons"
              type="close-circle"
            />
          </Button>
        </Tooltip>
      ) : null}
      {navigator?.geolocation && (
        <Tooltip
          title={
            <FormattedMessage
              id="district_portal_report_geolocation"
              defaultMessage="Get geolocation"
              description="Get geolocation"
            />
          }
        >
          <Button variant="text" onClick={getLocation}>
            <Icon type="compass" className="report-form-icons" />
          </Button>
        </Tooltip>
      )}
    </>
  );

  return (
    <Input
      className="geolocation-input"
      value={transformCoordinates(coordinates)}
      suffix={suffix}
    />
  );
};

GeolocationInput.propTypes = {
  coordinates: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }),
  clearCoordinates: PropTypes.func.isRequired,
  onLocation: PropTypes.func.isRequired
};

export default GeolocationInput;
