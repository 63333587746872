import PropTypes from 'prop-types';

import { Select } from 'antd';

import './ModestSelect.less';

const ModestSelect = props => (
  <Select className="modest-select" showArrow={false} {...props}>
    {props.children}
  </Select>
);

ModestSelect.propTypes = {
  children: PropTypes.node.isRequired
};

export default ModestSelect;
