export const SHARED_KEYS = {
  SELECTED_LAYER: 'selectedLayer',
  OPERATOR: 'operator',
  TYPE: 'type',
  LAYER: 'layer'
};

export const FORM_FIELD_TYPE = {
  RADIO: 'radio',
  SELECT: 'select',
  TEXT: 'text'
};

export const FORM_FIELD_KEYS = {
  LOGICAL_OPERATOR: 'logicalOperator',
  SEARCH_FIELDS: 'search_fields',
  LOOKUP: 'lookup',
  SEARCH: 'search'
};

export const LOGICAL_OPERATOR_VALUES = {
  SUM: 'sum',
  INTERSECTION: 'intersection',
  EXCLUSION: 'exclusion'
};

export const LOGICAL_BE_OPERATOR_VALUES = {
  [LOGICAL_OPERATOR_VALUES.SUM]: 'OR',
  [LOGICAL_OPERATOR_VALUES.INTERSECTION]: 'AND'
};

export const ERROR_WRONG_FIELD_KEY = 'Wrong fieldKey';
