import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { mapSelectors } from '../../store/selectors';

import { getCursorCoordinates } from '../../utils/mapUtils';

const { getCurrentCursorCrs, convertCoordinatesToHDMS } = mapSelectors;

export const useTransformCoordinatesToInputValue = () => {
  const currentCursorCrs = useSelector(state => getCurrentCursorCrs(state));

  const changeCoordinatesToHDMS = useSelector(state =>
    convertCoordinatesToHDMS(state)
  );

  const transformCoordinates = useCallback(coordinates => {
    if (!coordinates) return '';

    const { lat, lng } = getCursorCoordinates({
      currentCursorCrs,
      coordinates: [coordinates.lat, coordinates.lng],
      showHDMS: changeCoordinatesToHDMS
    });

    return `[${lat}, ${lng}]`;
  }, []);

  return transformCoordinates;
};

export default useTransformCoordinatesToInputValue;
