/**
 * Funkcja wyszukująca pojedyńcze znaki i dodająca za nimi &nbsp;
 *
 * @param {string} text
 */
const nonBreakingSpace = text => {
  if (!text) return '';
  if (typeof text !== 'string') return text;

  return text.replace(/ (.) /gi, ` $1\xa0`);
};

export default nonBreakingSpace;
