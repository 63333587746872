import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import classNames from 'classnames';

import useGlobalSettings from '../../hooks/useGlobalSettings';
import { useMapPortal } from '../../hooks';
import useComponentsToRender from '../../hooks/useComponentsToRender';

import { Progress } from 'antd';
import PureBrandComponent from '../../components/Brand/PureBrandComponent';

import './ComponentsReadyListener.less';

export const ComponentsReadyListener = () => {
  const [onceComponentPercent, setOneComponentPercent] = useState(0);
  const [percent, setPercent] = useState(0);
  const [isAppReady, setIsAppReady] = useState(false);
  const componentsToRender = useComponentsToRender();

  const renderedComponents = useSelector(state =>
    state.global.get('renderedComponents')
  );
  const { portal } = useParams();

  const mapPortal = useMapPortal({ shortName: portal });

  const {
    unofficial_character_of_portal_info_text: unofficialCharacterOfPortalInfoText,
    system_logo: systemLogo,
    system_title: systemTitle,
    system_second_title: systemSecondTitle,
    system_loader: systemlLoader
  } = useGlobalSettings();

  const portalTitle = mapPortal?.get('name') || undefined;
  const portalSecondTitle = mapPortal?.get('second_title') || undefined;

  const [loaderSrc, setLoaderSrc] = useState(
    localStorage?.getItem('system_loader')
  );

  React.useEffect(() => {
    if (
      componentsToRender.length &&
      (componentsToRender.length !== renderedComponents.size ||
        !renderedComponents.size)
    )
      setIsAppReady(false);
    setOneComponentPercent(100 / componentsToRender.length);
  }, [renderedComponents, componentsToRender]);

  React.useEffect(() => {
    checkComponentsToRender();
  }, [renderedComponents]);

  React.useEffect(() => {
    if (systemlLoader !== loaderSrc) {
      setLoaderSrc(systemlLoader);
    }
  }, [systemlLoader]);

  const checkComponentsToRender = () => {
    const numberRenderedComponents = componentsToRender.filter(component =>
      renderedComponents?.get(component)
    ).length;
    setPercent(parseInt(numberRenderedComponents * onceComponentPercent));
    numberRenderedComponents === componentsToRender.length &&
      setIsAppReady(true);
  };

  return (
    <div
      className={classNames('main-loader-bg', {
        hidden: isAppReady
      })}
    >
      <div className="main-loader">
        <div className="brand-border">
          <PureBrandComponent
            darkTitle
            settingsLogo={systemLogo}
            settingsTitle={portalTitle ?? systemTitle}
            settingsSubtitle={portalSecondTitle ?? systemSecondTitle}
          />
        </div>
        <div className="main-loader-border">
          <span>{unofficialCharacterOfPortalInfoText}</span>
          {loaderSrc && <img src={loaderSrc} alt="Trwa wczytywanie..." />}
        </div>
        <Progress percent={percent} />
      </div>
    </div>
  );
};

export default ComponentsReadyListener;
