import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Button } from '../';
import { Icon } from 'antd';

import './PasswordVisibilitySwitcher.less';

const PasswordVisibilitySwitcher = ({ onChange }) => {
  const { formatMessage } = useIntl();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(prevValue => !prevValue);
  };

  React.useEffect(() => {
    onChange && onChange(showPassword);
  }, [showPassword]);

  return (
    <Button
      variant="text"
      onClick={togglePasswordVisibility}
      className="toggle-password-visibility-btn"
      tabIndex="-1"
    >
      <Icon
        type={showPassword ? 'eye-invisible' : 'eye'}
        aria-label={formatMessage({
          id: 'password_visibility_switcher_show',
          defaultMessage: 'Show password'
        })}
      />
    </Button>
  );
};

PasswordVisibilitySwitcher.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default PasswordVisibilitySwitcher;
