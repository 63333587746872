import PropTypes from 'prop-types';

import { Loader } from '../';

import './LoaderFullScreen.less';

const LoaderFullScreen = props => (
  <div className={['login-loader', props.className].join(' ')}>
    <div className="login-loader-wrapper">
      <Loader tip={props.tip} />
    </div>
  </div>
);

LoaderFullScreen.propTypes = {
  tip: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default LoaderFullScreen;
