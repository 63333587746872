export const DELETE_LOCATION_STARTED = 'DELETE_LOCATION_STARTED';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAILED = 'DELETE_LOCATION_FAILED';

export const GET_ALL_LOCATIONS_STARTED = 'GET_ALL_LOCATIONS_STARTED';
export const GET_ALL_LOCATIONS_SUCCESS = 'GET_ALL_LOCATIONS_SUCCESS';
export const GET_ALL_LOCATIONS_FAILED = 'GET_ALL_LOCATIONS_FAILED';

export const EDIT_LOCATION_STARTED = 'EDIT_LOCATION_STARTED';
export const EDIT_LOCATION_SUCCESS = 'EDIT_LOCATION_SUCCESS';
export const EDIT_LOCATION_FAILED = 'EDIT_LOCATION_FAILURE';

export const ADD_LOCATION_STARTED = 'ADD_LOCATION_STARTED';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_FAILED = 'ADD_LOCATION_FAILURE';

export const GET_SINGLE_LOCATION_STARTED = 'GET_SINGLE_LOCATION_STARTED';
export const GET_SINGLE_LOCATION_SUCCESS = 'GET_SINGLE_LOCATION_SUCCESS';
export const GET_SINGLE_LOCATION_FAILED = 'GET_SINGLE_LOCATION_FAILURE';
