import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { changeValueMapPortal } from '../../store/actions/globalActions';
import { mapPortalSelectors } from '../../store/selectors';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Icon } from 'antd';

import { getSidebarPanelIndex } from '../../utils/lib';

import { sidebarPanelTransitionTime } from '../../config';

import './SidebarItem.less';

class SidebarItem extends Component {
  state = {
    open: false
  };

  showItem = () => {
    this.props.onClick && this.props.onClick();
    const mapPortal = this.props.mapPortal;

    if (mapPortal.get(this.props.nameValue)) {
      this.props.changeValueMapPortal(this.props.nameValue, false);
    } else {
      this.props.changeValueMapPortal(this.props.nameValue, true);
    }
  };

  showInnerItem = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  render() {
    const isOpen = this.props.isOpened || this.state.open;
    const index = getSidebarPanelIndex(
      this.props.nameValue,
      this.props.sidebarSettings?.get('tabs')
    );

    const style = { order: index };

    return (
      <div className="sidebar-item-wrapper" style={style}>
        <button
          className={classNames('sidebar-item', {
            'sidebar-item-selected': isOpen
          })}
          onClick={this.props.innerItem ? this.showInnerItem : this.showItem}
          style={this.props.style}
          aria-expanded={this.props.isOpened || 'false'}
          // TOFIX: Set correct "aria-label" value.
          // https://projekty.giap.pl/project/adamap_mesip/us/840
          aria-label="sidebar"
        >
          {this.props.title}

          {this.props.innerItem ? (
            <Icon
              type="search"
              onClick={event => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
              }}
              aria-label="search-icon"
              role="img"
            />
          ) : null}

          <Icon type="caret-down" aria-label="caret-icon" role="img" />
        </button>

        <ReactCSSTransitionGroup
          transitionName="sidebar-item-animate"
          transitionEnterTimeout={sidebarPanelTransitionTime}
          transitionLeaveTimeout={sidebarPanelTransitionTime}
        >
          {(this.props.isOpened || this.state.open) && (
            <div className="sidebar-item_list">{this.props.children}</div>
          )}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

const {
  getCurrentComposition,
  getCompositionSidebarSettings
} = mapPortalSelectors;

const mapStateToProps = (state, ownProps) => {
  const { prefix, portal } = ownProps.match.params;
  const currentComposition = getCurrentComposition(state, prefix, portal);

  return {
    mapPortal: state.global.get('mapPortal'),
    sidebarSettings: getCompositionSidebarSettings(
      state,
      prefix,
      currentComposition?.id
    )
  };
};

const mapDispatchToProps = {
  changeValueMapPortal
};

SidebarItem.propTypes = {
  children: PropTypes.node,
  changeValueMapPortal: PropTypes.func.isRequired,
  mapPortal: PropTypes.object.isRequired,
  title: PropTypes.node.isRequired,
  isOpened: PropTypes.bool,
  nameValue: PropTypes.string,
  style: PropTypes.object
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarItem)
);
