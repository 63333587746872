import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import useGlobalSettings from '../../hooks/useGlobalSettings';

import { showError } from '../../store/actions/globalActions';

import { Select } from 'antd';

const { Option } = Select;

const GoogleFontSelectList = props => {
  const dispatch = useDispatch();
  const { google_fonts_api_key: fontApiKey } = useGlobalSettings();

  const [fonts, setFonts] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const fetchFonts = async () => {
    setIsFetching(true);

    try {
      const { data } = await axios.get(
        // W przypadku, gdy paramsy są przekazane przez {params: {...}} url w endpoint'ie
        // jest błędny, gdyz uderza link wygląda https: giap.dev...https://www.googleapis...
        // i nie pobiera listy czcionek.
        `https://www.googleapis.com/webfonts/v1/webfonts?sort=ALPHA&key=${fontApiKey}&prettyPrint=true`
      );
      setFonts(data.items);
    } catch {
      dispatch(showError('Wystąpił błąd pobierania listy czcionek!'));
    } finally {
      setIsFetching(false);
    }
  };

  React.useEffect(() => {
    fetchFonts();
  }, []);

  return (
    <Select
      loading={isFetching}
      disabled={!fonts}
      dropdownMatchSelectWidth={false}
      {...props}
    >
      {fonts?.map(({ family }) => (
        <Option key={family}>{family}</Option>
      ))}
    </Select>
  );
};

export default GoogleFontSelectList;
