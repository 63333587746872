import { useSelector } from 'react-redux';

import { globalSelectors } from '../../store/selectors';

import { Tooltip as AntdTooltip } from 'antd';

import { dimensionsTypes } from '../../components/WindowSizeObserver';

const { getWindowDimensionsType } = globalSelectors;

const { MOBILE } = dimensionsTypes;

// W niektórych przeglądarkach mobilnych (np. Safari) powstaje problem z klikaniem w elementy
// owinięte w Ant-Designowy tooltip - żeby wywołać event onClick trzeba kliknąć dwa razy.
// Za pierwszy razem pojawi się tooltip, a dopiero za drugim wywoła się właściwe kliknięcie.
// Poniższy wrapper zapewnia, że w trybie mobilnym wystarczy jedno kliknięcie, żeby wywołać
// event onClick.
const Tooltip = ({ children, ...props }) => {
  const windowDimensionsType = useSelector(state =>
    getWindowDimensionsType(state)
  );

  return (
    <AntdTooltip
      trigger={windowDimensionsType === MOBILE ? 'click' : 'hover'}
      {...props}
    >
      {children}
    </AntdTooltip>
  );
};

export default Tooltip;
