import { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from '../../utils/autoBind';
import './Timer.less';

export default class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ms: 0
    };
    autoBind(this);
  }

  componentDidMount() {
    this.setTimer();
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  setTimer() {
    this.interval = setInterval(() => {
      this.setState(prevState => ({ ms: prevState.ms + 10 }));
    }, 10);
  }

  clearTimer() {
    clearInterval(this.interval);
  }

  formater = ms => {
    var elapsed = Math.round(ms / 10);
    var seconds = (elapsed / 100).toFixed(2);
    return `${seconds} s`;
  };

  render() {
    const { ms } = this.state;

    return (
      <span className="time-span" aria-hidden="true">
        {this.props.title ? this.props.title : 'Czas'}: {this.formater(ms)}
      </span>
    );
  }
}

Timer.propTypes = {
  title: PropTypes.element
};
