import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export const CompositionEntryShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isCreated: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  name_en: PropTypes.string.isRequired,
  pkuid: PropTypes.string.isRequired,
  project: PropTypes.number.isRequired,
  published: PropTypes.bool.isRequired
});

export const CompositionShape = ImmutablePropTypes.mapContains({
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  is_default: PropTypes.bool.isRequired,
  legend: ImmutablePropTypes.list.isRequired,
  name: PropTypes.string.isRequired,
  name_en: PropTypes.string.isRequired,
  portal_id: PropTypes.number.isRequired,
  prefix: PropTypes.string.isRequired
});

export const MapPortalCompositionShape = PropTypes.shape({
  displayable_name: PropTypes.string,
  id: PropTypes.number.isRequired,
  config: PropTypes.number,
  index: PropTypes.number.isRequired,
  is_enabled: PropTypes.bool.isRequired,
  is_layers_view_visible: PropTypes.bool.isRequired,
  use_addresses_search_tool: PropTypes.bool.isRequired,
  use_parcel_search_tool: PropTypes.bool.isRequired,
  map_portal: PropTypes.number.isRequired,
  required_role: PropTypes.number
});

export const MapPortalCompositionSidebarSettingsShape = ImmutablePropTypes.shape(
  {
    id: PropTypes.number.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.number).isRequired,
    default_opened_tab: PropTypes.number
  }
);
