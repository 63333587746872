export default function createCanvas({ width, height } = {}) {
  const canvas = document.createElement('canvas');

  if (width || width === 0) {
    canvas.setAttribute('width', width);
  }

  if (height || height === 0) {
    canvas.setAttribute('height', height);
  }

  return { canvas, ctx: canvas.getContext('2d') };
}
