import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import { useMapPortal } from '../../hooks';
import queryString from 'query-string';

import { districtSelectors } from '../../store/selectors';

import { Row, Col } from 'antd';
import { DistrictBadge } from '../';
import QRCode from 'qrcode.react';
import { FormattedMessage } from 'react-intl';

import { capitalizeFirstLetter } from '../../utils/lib';

const { getAppDistrictDetails } = districtSelectors;

const InformationCardHeader = () => {
  const { prefix, portal } = useParams();
  const location = useLocation();

  const mapPortal = useMapPortal({ shortName: portal });
  const districtDetails = useSelector(state =>
    getAppDistrictDetails(state, prefix)
  );
  const coordinatesToIdentification = useSelector(state =>
    state.popup.get('coordinatesToIdentification')
  );

  const parseCoordinates = () => {
    const coords = coordinatesToIdentification;
    const link = queryString.parse(location.search);
    const { lat, lng } = {
      lat: Number(coords.get('lat')) || Number(link.lat),
      lng: Number(coords.get('lng')) || Number(link.lng)
    };

    return `${lat.toFixed(6)}, ${lng.toFixed(6)}`;
  };

  const portalLink = window.location.href.split('?')[0];

  return (
    <>
      <Row>
        <Col lg={24}>
          <div className="information-card__header">
            <DistrictBadge
              district={capitalizeFirstLetter(districtDetails?.district.nazwa)}
              county={districtDetails?.district.powiat}
              badgeFileName={districtDetails?.crest}
              reverseColor
            />
            <span className="information-card__header__title">
              <FormattedMessage
                id="district_portal_information_modal_object"
                defaultMessage="Information about object"
              />
            </span>
          </div>
        </Col>
      </Row>
      <Row className="information-card__content">
        <Col lg={24} className="information-card__content__basic">
          <Row
            className="information-card__content__section__first"
            type="flex"
            justify="space-around"
          >
            <Col lg={16} className="information-card__content__basic__first">
              <span className="information-card__content__basic__title">
                <FormattedMessage
                  id="district_portal_information_card_basic_informacion"
                  defaultMessage="Basic information"
                />
              </span>
              <div>
                <b>Nazwa portalu:</b> {mapPortal.get('name')}
              </div>
              <div>
                <b>Link portalu:</b> <a href={portalLink}>{portalLink}</a>
              </div>
              <div>
                <b>Współrzędne:</b> {parseCoordinates()}
              </div>
            </Col>
            <Col lg={8} style={{ textAlign: 'right' }}>
              <QRCode value={portalLink} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default InformationCardHeader;
