import axios from 'axios';
const { create } = axios;

// nadpisana funkcja tworzenia nowej instancji axiosa, aby "dziedziczyła" interceptors
export default function createAxiosInstance(...args) {
  const instance = create.apply(this, args);

  for (const type in axios.interceptors) {
    axios.interceptors[type].handlers.forEach(({ fulfilled, rejected }) =>
      instance.interceptors[type].use(fulfilled, rejected)
    );
  }

  return instance;
}
