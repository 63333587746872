import { Component } from 'react';
import isEqual from 'react-fast-compare';

import { Form } from 'antd';

export default class FormItem extends Component {
  /* We need this component to wrap Ant Design <Form.Item />
     to avoid unnecessary rerender and improve application performance.
     Very needed in big forms. */
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    return <Form.Item {...this.props}>{this.props.children}</Form.Item>;
  }
}
