import { Row } from 'antd';
import React, { useEffect } from 'react';
import { Form, Button as AntButton } from 'antd';
import { useSelector } from 'react-redux';

import useTranslateConfigSelector from '../../hooks/useTranslateConfigSelector';
import useGlobalReportConfigsSelector from '../../hooks/useGlobaReportConfigsSelector';
import { reportSelectors } from '../../../../store/selectors';

import LabeledSwitch from '../../../LabeledSwitch';
import { InputRow, CheckboxGroupRow } from '../RowButtons';

import './GlobalSettingsForForm.less';
import { megabyteInBytes } from '../../consts';

const FormItem = Form.Item;

const { getDefectConfiguration } = reportSelectors;

const GlobalSettingsForForm = props => {
  const { form } = props;

  const {
    attachment_size,
    attachments_count,
    multigeometry,
    geometry_types,
    id,
    attachments_formats
  } = useSelector(getDefectConfiguration);

  const {
    handleSwitchChange,
    globalSettingsFormInputs,
    globalSettingsFormRadioGroups,
    handleSave
  } = useGlobalReportConfigsSelector(props);

  const {
    globalSubmissionSettings,
    multipleGeoOption,
    save
  } = useTranslateConfigSelector();

  const setInitialFetchedValue = () => {
    if (id) {
      form.setFieldsValue({
        attachmentsCount: attachments_count,
        attachmentSize: attachment_size / megabyteInBytes,
        attachmentsFormats: attachments_formats,
        geometryTypes: geometry_types,
        switchState: multigeometry
      });
    }
  };

  useEffect(() => {
    setInitialFetchedValue();
  }, [attachment_size]);

  return (
    <div className="global-settings-container">
      <Form onSubmit={handleSave}>
        <div className="global-settings-title">
          <span className="bold">{globalSubmissionSettings}</span>
        </div>

        {globalSettingsFormInputs.map(
          ({ stateKey, label, value, onChange, unit, maxSize, minSize }) => (
            <InputRow
              key={stateKey}
              label={label}
              value={value}
              onChange={onChange}
              stateKey={stateKey}
              unit={unit}
              form={form}
              maxSize={maxSize}
              minSize={minSize}
            />
          )
        )}

        {globalSettingsFormRadioGroups.map(
          ({ stateKey, label, values, selectedValue, onChange }) => (
            <CheckboxGroupRow
              key={stateKey}
              label={label}
              values={values}
              selectedValue={selectedValue}
              onChange={onChange}
              stateKey={stateKey}
              form={form}
            />
          )
        )}

        <Row type="flex" align="middle" className="row-global-settings-radio">
          <div>
            <span className="medium">{multipleGeoOption}</span>
            <FormItem>
              {form.getFieldDecorator('switchState', {
                valuePropName: 'checked'
              })(
                <LabeledSwitch
                  offLabel="Nie"
                  onLabel="Tak"
                  onChange={handleSwitchChange}
                  disabled={false}
                />
              )}
            </FormItem>
          </div>
        </Row>

        <div className="save-defect-configuration-button">
          <AntButton type="primary" htmlType="submit">
            {save}
          </AntButton>
        </div>
      </Form>
    </div>
  );
};

const WrappedGlobalSettingsForForm = Form.create()(GlobalSettingsForForm);

export default WrappedGlobalSettingsForForm;
