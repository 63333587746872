import { useState } from 'react';
import isEqual from 'react-fast-compare';
import cloneDeep from 'lodash/cloneDeep';

const useDraft = prototype => {
  const [_draft, _setDraft] = useState({});

  const deleteUnchangedEntriesFromDraft = (draft, changes) => {
    const cleanedDraft = { ...draft };

    Object.keys(changes).forEach(key => {
      if (!isEqual(draft[key], prototype[key])) return;
      delete cleanedDraft[key];
    });

    return cleanedDraft;
  };

  return {
    getDraft: () => cloneDeep(_draft),
    getChange: key => (key in _draft ? _draft[key] : prototype[key]),
    setChanges: changes =>
      _setDraft(draft => {
        const nextDraft = deleteUnchangedEntriesFromDraft(
          {
            ...draft,
            ...changes
          },
          changes
        );

        return nextDraft;
      }),
    hasChanges: () => !!Object.keys(_draft).length,
    clearDraft: () => _setDraft({})
  };
};

export default useDraft;
