/**
 * Usuwa znaki specjalne oraz polskie litery z przekazanego stringa.
 * @param {string} base_string
 * @return {string}
 */
const removeSpecialCharacters = base_string => {
  const removed_chars = base_string.replace(
    // eslint-disable-next-line
    /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
    ''
  );
  return removed_chars
    .replace('ą', 'a')
    .replace('ć', 'c')
    .replace('ę', 'e')
    .replace('ł', 'l')
    .replace('ń', 'n')
    .replace('ó', 'o')
    .replace('ś', 's')
    .replace('ź', 'z')
    .replace('ż', 'z')
    .replace(' ', '');
};

export default removeSpecialCharacters;
