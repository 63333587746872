import { useState, useEffect } from 'react';
import {
  useParams,
  useRouteMatch,
  useHistory,
  useLocation,
  generatePath
} from 'react-router';
import { useSelector } from 'react-redux';

import { globalSelectors } from '../../store/selectors';

const useTabsRouting = defaultActiveTab => {
  const { prefix, id, tab } = useParams();
  const { path } = useRouteMatch();
  const { replace } = useHistory();
  const { state } = useLocation();
  const advancedFeaturesUse = useSelector(state =>
    globalSelectors.getAdvancedFeaturesUse(state)
  );

  const [activeTab, setActiveTab] = useState(tab || defaultActiveTab);
  const enabledInwestor = !advancedFeaturesUse.use_oferta && tab === 'inwestor';

  const setNewPath = () => {
    const newPath = generatePath(path, { id, prefix, tab: activeTab });
    replace(newPath, state);
  };

  useEffect(() => {
    setNewPath();
  }, [activeTab]);

  useEffect(() => {
    if (tab && enabledInwestor) {
      setActiveTab(defaultActiveTab);
    } else if (tab && tab !== activeTab) setActiveTab(tab);
  }, [tab]);

  return [activeTab, setActiveTab];
};

export default useTabsRouting;
