import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { mapPortalSelectors } from '../../store/selectors';
import { GlobalSettingsShape } from '../../shapes';
import { MapPortalShape } from '../../shapes/mapPortal';

import mainRoutePaths from '../../router/portal/paths';

import PureBrandComponent from './PureBrandComponent';

import './Brand.less';

export const Brand = props => {
  //logo
  const mapPortalSystemLogo = props.mapPortal?.get('logo');
  const globalSettingsLogo = props.globalSettings?.get('system_logo');
  const settingsLogo =
    props.mapPortal && !props.mapPortal?.get('use_global_logo')
      ? mapPortalSystemLogo
      : globalSettingsLogo;

  //title
  const mapPortalTitle = props.mapPortal?.get('title');
  const globalSettingsTitle = props.globalSettings?.get('system_title');
  const settingsTitle =
    props.mapPortal && !props.mapPortal?.get('use_global_title')
      ? mapPortalTitle
      : globalSettingsTitle;

  //subtitle
  const mapPortalSubtitle = props.mapPortal?.get('second_title');
  const globalSettingsSubtitle = props.globalSettings?.get(
    'system_second_title'
  );
  const settingsSubtitle =
    props.mapPortal && !props.mapPortal?.get('use_global_second_title')
      ? mapPortalSubtitle
      : globalSettingsSubtitle;

  // link
  const isLinkEnabled = props.globalSettings?.get('enable_logo_link');
  const link = props.globalSettings?.get('logo_link');

  const component = (
    <PureBrandComponent
      settingsLogo={settingsLogo}
      settingsTitle={settingsTitle}
      settingsSubtitle={settingsSubtitle}
      inverse={props.inverse}
      isLinkEnabled={isLinkEnabled}
      onClick={() => props.history.push(mainRoutePaths.districtList)}
      className={props.className}
    />
  );

  return isLinkEnabled && link ? (
    <a href={link} className="brand-wrapper" aria-label={link}>
      {component}
    </a>
  ) : (
    component
  );
};

/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => ({
  globalSettings: state.global.get('globalSettings'),
  mapPortal: mapPortalSelectors.getMapPortalByShortName(
    state,
    ownProps.location.pathname.split('/')[1],
    ownProps.location.pathname.split('/')[2]
  )
});

Brand.propTypes = {
  globalSettings: GlobalSettingsShape,
  className: PropTypes.string,
  inverse: PropTypes.bool,
  mapPortal: PropTypes.oneOfType([MapPortalShape, PropTypes.object])
};

export default withRouter(connect(mapStateToProps, null)(Brand));
