module.exports = {
  fitViewTool: 'fit_view',
  fullscreenTool: 'fullscreen',
  moveTool: 'move',
  nextTool: 'next',
  previousTool: 'previous',
  zoomMapTool: 'zoom_map',
  zoomWindowTool: 'zoom_window',
  geolocation: 'geolocation',
  streetView: 'google_street_view',
  enableShowPanArrow: 'enable_show_pan_arrow',
  routePlanning: 'set_a_route',
  embedMap: 'embed_map',
  zoomInTool: 'zoom_in',
  zoomOutTool: 'zoom_out',
  registers: 'registers',
  eConstruction: 'e_construction',
  eServices: 'e_services'
};
