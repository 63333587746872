import { mapPortalSelectors, userSelectors } from './';

const getUserZoomLevel = ({ state, rangePrefix, prefix, portal }) => {
  const mapPortal = mapPortalSelectors.getMapPortalByShortName(
    state,
    prefix,
    portal
  );

  const defaultZoomLevel = state.map.get(`${rangePrefix}MapZoomLevel`);
  const isZoomForNonLoggedEnabled = mapPortal.get('zoom_for_nonlogged');
  const isAuthorized = userSelectors.isUserLogged(state);

  if (!isAuthorized && isZoomForNonLoggedEnabled) {
    return mapPortal.get(`${rangePrefix}_zoom_nonlogged`) ?? defaultZoomLevel;
  }

  return mapPortal.get(`${rangePrefix}_zoom`) ?? defaultZoomLevel;
};

const selectors = {
  zoomLevel: state => state.map.get('zoomLevel'),
  getScalesDenominators: state => state.map.get('scalesDenominators'),
  getMinMapZoomLevel: state => state.map.get('minMapZoomLevel'),
  getMaxMapZoomLevel: state => state.map.get('maxMapZoomLevel'),
  getUserZoomLevel,
  historyMap: state => state.map.get('historyMap'),
  numberHistory: state => state.map.get('numberHistory'),
  typeClick: state => state.map.get('typeClick'),
  getBaseMapLayer: state => state.map.get('baseMapLayer'),
  getCheckedLayers: state => state.map.get('checkedLayers'),
  getInvisibleLayers: state => state.map.get('invisibleLayers'),
  getSelectedParcel: state => state.map.get('selectedParcel'),
  getLayersZoom: state => state.map.get('layersZoom'),
  getCurrentCursorCrs: state => state.map.get('currentCursorCrs'),
  convertCoordinatesToHDMS: state => state.map.get('convertCoordinatesToHDMS'),
  getRenderedLayersNumber: state => state.map.get('renderedLayersNumber'),
  getStreetViewCoordinates: state => state.map.get('streetViewCoordinates'),
  getIsMapLoaded: state => state.map.get('isMapLoaded'),
  getMainMiniMapDraggingStatus: state => state.map.get('isMainMiniMapDragging'),
  isGeomLoadingOnMap: state => state.map.get('isGeomLoadingOnMap'),
  getActiveBackgroundTool: state => state.map.get('activeBackgroundTool'),
  getSelectedDefaultParcels: state => state.map.get('selectedDefaultParcels'),
  getSelectedNeighboringParcels: state =>
    state.map.get('selectedNeighboringParcel'),
  getBackgroundParcelColor: state => state.map.get('backgroundParcelColor')
};

export default selectors;
