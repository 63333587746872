import { Icon } from 'antd';
import classNames from 'classnames';

import '../NavSidebar/NavSidebar.less';
import './NavSidebarAdmin.less';

export const NavSidebarAdmin = ({ isSidebarOpen, toggleSidebar }) => {
  return (
    <div className="nav-sidebar">
      <nav className="nav-sidebar_items">
        <div
          className={classNames('nav-sidebar-item_icon', {
            'nav-sidebar-item_icon nav-sidebar-item_icon-selected': isSidebarOpen
          })}
          onClick={() => toggleSidebar('menu')}
        >
          <Icon type="menu" />
        </div>
      </nav>
    </div>
  );
};

export default NavSidebarAdmin;
