import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useTranslateConfigSelector from './useTranslateConfigSelector';
import { districtSelectors, reportSelectors } from '../../../store/selectors';
import {
  fetchDefectConfiguration,
  updateDefectConfiguration
} from '../../../store/actions/reportsActions';

import {
  GEOMETRY_TYPE_VALUES,
  RADIO_VALUES,
  maxAttachmentSize,
  megabyteInBytes
} from '../consts';

const { getDefectConfiguration } = reportSelectors;
const { getDistrictId } = districtSelectors;

// max value / mb in bytes
const maxSizeAttachments = parseInt(
  (maxAttachmentSize / megabyteInBytes).toFixed(2)
);

const useGlobalReportConfigsSelector = ({ form }) => {
  const dispatch = useDispatch();
  const { prefix } = useParams();
  const districtID = useSelector(state => getDistrictId(state, prefix));

  const { id } = useSelector(getDefectConfiguration);

  const {
    maxNumberOfAttachments,
    maxAttachmentsSize,
    attachmentsFormat,
    submissionGeoType,
    updatedSuccessfuly
  } = useTranslateConfigSelector();

  const handleChangeInput = ({ target: { value } }, key) =>
    form.setFieldsValue({ [key]: value });

  const handleChangeCheckboxes = (checkedValues, key) =>
    form.setFieldsValue({ [key]: checkedValues });

  const handleSwitchChange = checked =>
    form.setFieldsValue({ switchState: checked });

  const handleSave = event => {
    event.preventDefault();
    form.validateFields(
      (
        error,
        {
          attachmentSize,
          attachmentsCount,
          attachmentsFormats,
          geometryTypes,
          switchState
        }
      ) => {
        if (error) return;
        const formData = {
          attachments_count: parseInt(attachmentsCount),
          attachment_size: parseInt(attachmentSize) * megabyteInBytes,
          attachments_formats: attachmentsFormats,
          geometry_types: geometryTypes,
          multigeometry: switchState,
          district: districtID
        };

        dispatch(
          updateDefectConfiguration({
            district: prefix,
            id,
            formData,
            successMessage: updatedSuccessfuly
          })
        );
      }
    );
  };

  const globalSettingsFormInputs = [
    {
      label: maxNumberOfAttachments,
      value: form.getFieldValue('attachmentsCount'),
      onChange: handleChangeInput,
      stateKey: 'attachmentsCount',
      maxSize: 5,
      minSize: 1
    },
    {
      label: `${maxAttachmentsSize}`,
      value: form.getFieldValue('attachmentSize') / megabyteInBytes,
      onChange: handleChangeInput,
      stateKey: 'attachmentSize',
      unit: 'MB',
      maxSize: maxSizeAttachments,
      minSize: 1
    }
  ];

  const globalSettingsFormRadioGroups = [
    {
      label: attachmentsFormat,
      values: RADIO_VALUES,
      selectedValue: form.getFieldValue('attachmentsFormats'),
      onChange: handleChangeCheckboxes,
      stateKey: 'attachmentsFormats'
    },
    {
      label: submissionGeoType,
      values: GEOMETRY_TYPE_VALUES,
      selectedValue: form.getFieldValue('geometryTypes'),
      onChange: handleChangeCheckboxes,
      stateKey: 'geometryTypes'
    }
  ];

  useEffect(() => {
    dispatch(fetchDefectConfiguration(prefix));
  }, []);

  return {
    handleSwitchChange,
    globalSettingsFormInputs,
    globalSettingsFormRadioGroups,
    handleSave
  };
};

export default useGlobalReportConfigsSelector;
