export const SEARCH_TYPE_SELECT_TRANSLATIONS = {
  ADDRESS_SEARCH_NAME: {
    id: 'search_type_address',
    defaultMessage: 'Address'
  },
  PLOT_SEARCH_NAME: {
    id: 'search_type_plot',
    defaultMessage: 'Plot'
  },
  GEONETWORK_VERTICAL_SEARCH_NAME: {
    id: 'search_type_warp_vertical',
    defaultMessage: 'Warp v.'
  },
  GEONETWORK_HORIZONTAL_SEARCH_NAME: {
    id: 'search_type_warp_horizontal',
    defaultMessage: 'Warp h.'
  },
  SELECT_SEARCH_TYPE: {
    id: 'search_select_type',
    defaultMessage: 'Select a search type'
  },
  GEOLOCATION_SEARCH_NAME: {
    id: 'search_type_geolocation',
    defaultMessage: 'Geolocation'
  }
};
