import PropTypes from 'prop-types';

import { Icon } from 'antd';

import './InfoWithIcon.less';

const InfoWithIcon = ({ children, className, iconName, iconClassName }) => (
  <div className={['info-with-icon', className].join(' ')}>
    <Icon type={iconName} className={iconClassName} />
    {children}
  </div>
);

InfoWithIcon.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  iconClassName: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default InfoWithIcon;
