import PropTypes from 'prop-types';

import { Row } from 'antd';
import IndexInput from '../IndexInput';
import SortArrows from '../SortArrows';

const SortControl = ({ containerProps, inputProps, arrowsProps }) => {
  return (
    <Row type="flex" align="middle" {...containerProps}>
      <IndexInput type="text" {...inputProps} />
      <SortArrows tabIndex={-1} {...arrowsProps} />
    </Row>
  );
};

SortControl.propTypes = {
  containerProps: PropTypes.object,
  inputProps: PropTypes.object.isRequired,
  arrowsProps: PropTypes.object.isRequired
};

export default SortControl;
