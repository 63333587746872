import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';

import { Tooltip, Popconfirm, Popover, Icon } from 'antd';
import { Button, SuspenseWrapper } from '../';
import CompositionConfigModal from '../CompositionConfigModal';

import useFindWmsLayerById from '../../hooks/useFindWmsLayerById';
import useGetLayersFromDb from '../../hooks/useGetLayersFromDb';

import {
  refetchComposition,
  clearAddedWmsList,
  clearAddedCsvList,
  addCompositionWmsEntry,
  addCompositionCsvEntry
} from '../../store/actions/mapPortalActions';
import { getMapPortalPublicWMS } from '../../store/actions/wmsActions';
import {
  setCheckedLayers,
  setLayerOpacity,
  setLayerStyle
} from '../../store/actions/mapActions';

import { mapPortalSelectors, wmsSelectors } from '../../store/selectors';
import { showError, showSuccess } from '../../store/actions/globalActions';

import config from '../../config';

import {
  getRasterBBox,
  getGeoJsonBBox,
  findEntryInComposition,
  getUrlWithoutQueryParams
} from '../../utils/lib';

import './ResetCompositionButton.less';

const {
  getCurrentCompositionId,
  getAddedWms,
  getCompositionByID,
  getMapPortalId
} = mapPortalSelectors;
const { getPortalPublicWMS } = wmsSelectors;

const ResetCompositionButton = ({ setComposition }) => {
  const dispatch = useDispatch();
  const { prefix, portal } = useParams();
  const { formatMessage } = useIntl();
  const { findWmsLayer } = useFindWmsLayerById();
  const { dbLayers: dbLayersFetched } = useGetLayersFromDb();

  const [dbLayers, setDbLayers] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const [loadConfig, setLoadConfig] = useState(false);

  const currentCompositionId = useSelector(state =>
    getCurrentCompositionId(state)
  );
  const checkedLayers = useSelector(state => state.map.get('checkedLayers'));
  const layersOpacity = useSelector(state => state.map.get('layersOpacity'));
  const layersStyle = useSelector(state => state.map.get('layersStyle'));
  const mapPortalId = useSelector(state =>
    getMapPortalId(state, prefix, portal)
  );
  const wmsLibrary = useSelector(state =>
    getPortalPublicWMS(state, mapPortalId)
  );
  const addedWms = useSelector(state =>
    getAddedWms(state, currentCompositionId)
  );
  const composition = useSelector(state =>
    getCompositionByID(state, currentCompositionId)
  );

  const compositionsConfigurations = localStorage.getItem(
    config.localStorageKeys.compositionLayersConfig
  );
  const parsedCompositionsConfigurations = JSON.parse(
    compositionsConfigurations
  );
  const configurations =
    parsedCompositionsConfigurations?.[currentCompositionId] || [];

  const resetComposition = () => {
    batch(() => {
      dispatch(refetchComposition(prefix, portal));
      dispatch(clearAddedWmsList());
      dispatch(clearAddedCsvList());
    });
    setComposition(currentCompositionId);
  };

  const getLayersFromDb = () => {
    setDbLayers(dbLayersFetched);
  };

  const getWmsLibraryLayers = async () => {
    if (wmsLibrary && wmsLibrary.size) return;
    await dispatch(getMapPortalPublicWMS(prefix, mapPortalId));
  };

  useEffect(() => {
    getWmsLibraryLayers();
  }, []);

  useEffect(getLayersFromDb, [dbLayersFetched]);

  const saveLayersWithOptions = layers => {
    return layers.map(layer => ({
      id: layer,
      opacity: layersOpacity.toJS()[layer],
      style: layersStyle.toJS()[layer]
    }));
  };

  const saveConfiguration = name => {
    const wmsLayers = checkedLayers.filter(id => addedWms?.includes(id));
    const dbLayersIsd = checkedLayers.filter(id =>
      dbLayers?.map(({ id }) => id).includes(id)
    );

    const layersConfig = saveLayersWithOptions(
      checkedLayers
        .toJS()
        .filter(
          id =>
            !addedWms?.toJS()?.includes(id) &&
            !dbLayersIsd?.toJS()?.includes(id)
        )
    );

    const configLayers = {
      name,
      layers: layersConfig,
      wms: wmsLayers.toJS(),
      dbLayers: dbLayersIsd.toJS()
    };

    if (compositionsConfigurations && parsedCompositionsConfigurations) {
      localStorage.setItem(
        config.localStorageKeys.compositionLayersConfig,
        JSON.stringify({
          ...parsedCompositionsConfigurations,
          [currentCompositionId]: [...configurations, configLayers]
        })
      );
    } else {
      localStorage.setItem(
        config.localStorageKeys.compositionLayersConfig,
        JSON.stringify({ [currentCompositionId]: [configLayers] })
      );
    }

    dispatch(
      showSuccess(
        formatMessage({
          id: 'saved_configuration',
          defaultMessage: 'Configuration saved.'
        })
      )
    );
    setVisibleModal(false);
  };

  const getWmsObject = wmsLayer => {
    const {
      id,
      layerId,
      link: url,
      service_type,
      tileSize,
      version,
      layerName,
      title,
      ...serviceProps
    } = wmsLayer;

    const serviceType = service_type.toLowerCase();

    return {
      id: layerId,
      wmsId: id,
      name: layerName,
      name_en: layerName,
      is_enabled: true,
      type: 'layer',
      is_visible: true,
      title,
      [serviceType]: {
        url: getUrlWithoutQueryParams(url),
        layer: layerName,
        tileSize,
        version,
        ...serviceProps
      }
    };
  };

  const getWmsParentGroup = wms => {
    const { wmsId: id, title } = wms;

    let parentGroup = findEntryInComposition(
      composition.get('legend').toJS(),
      `${id}-group`
    );

    if (!parentGroup) {
      parentGroup = {
        id: `${id}-group`,
        name: title,
        name_en: title,
        is_enabled: true,
        type: 'group',
        wmsGroup: true,
        entries: []
      };
    }

    return parentGroup;
  };

  const addWmsLayer = (layerId, object) => {
    const wmsLayer = findWmsLayer(layerId);
    if (!wmsLayer) return;

    const wmsLayerObj = getWmsObject(wmsLayer);

    object[wmsLayerObj.wmsId]
      ? object[wmsLayerObj.wmsId].push(wmsLayerObj)
      : (object[wmsLayerObj.wmsId] = [wmsLayerObj]);
    return wmsLayer;
  };

  const addWmsGroup = wmsObjects => {
    Object.keys(wmsObjects).forEach(wmsId => {
      const parentGroup = getWmsParentGroup(wmsObjects[wmsId][0]);
      parentGroup.entries = parentGroup.entries.concat(wmsObjects[wmsId]);

      dispatch(addCompositionWmsEntry(parentGroup));
    });
  };

  const addLocalLayer = ({
    id,
    name,
    layerGeoJSON,
    symbol,
    style,
    symbolization,
    geomType
  }) => {
    const addedLocalLayer = {
      bbox: getGeoJsonBBox(layerGeoJSON),
      layerToAdd: {
        layerGeoJSON,
        symbol,
        style,
        symbolization,
        type: geomType
      },
      id,
      name,
      name_en: name,
      is_enabled: true,
      type: 'layer',
      layer_transparency: 0,
      addedLayers: true,
      is_visible: true
    };

    dispatch(addCompositionCsvEntry(addedLocalLayer));
  };

  const addLocalRasterLayer = ({ id, name, georaster }) => {
    const addedLocalLayer = {
      bbox: getRasterBBox(georaster),
      layerToAdd: { georaster },
      id,
      name,
      name_en: name,
      is_enabled: true,
      type: 'raster',
      layer_transparency: 0,
      addedLayers: true,
      is_visible: true
    };

    dispatch(addCompositionCsvEntry(addedLocalLayer));
  };

  const addDbLayers = layerId => {
    const layer = dbLayers.find(layer => layer.id === layerId);
    layer.georaster ? addLocalRasterLayer(layer) : addLocalLayer(layer);
  };

  const loadOpacityAndStyle = layers => {
    return layers.map(({ id, opacity, style }) => {
      if (opacity) dispatch(setLayerOpacity(id, opacity));
      if (style) dispatch(setLayerStyle(id, style));
      return id;
    });
  };

  const loadConfiguration = name => {
    const configuration = configurations.find(config => config.name === name);
    if (!configuration) {
      dispatch(
        showError(
          formatMessage({
            id: 'load_configuration_no_saved',
            defaultMessage: 'No saved configuration to load'
          })
        )
      );
      return;
    }

    const { layers: defaultLayers, wms, dbLayers: dbLayersIds } = configuration;
    const wmsObjects = {};
    const wmsIds = wms
      ?.map(elem => addWmsLayer(elem, wmsObjects))
      .filter(Boolean);

    dbLayersIds?.forEach(elem => addDbLayers(elem));
    addWmsGroup(wmsObjects);
    const defaultLayersIds = loadOpacityAndStyle(defaultLayers);

    dispatch(
      setCheckedLayers([
        ...defaultLayersIds,
        ...wmsIds?.map(object => object.layerId.toString()),
        ...(dbLayersIds || [])
      ])
    );
    setVisibleModal(false);
  };

  const deleteConfiguration = name => {
    const otherConfigs = configurations.filter(config => config.name !== name);

    if (otherConfigs.length) {
      localStorage.setItem(
        config.localStorageKeys.compositionLayersConfig,
        JSON.stringify({
          ...parsedCompositionsConfigurations,
          [currentCompositionId]: [...otherConfigs]
        })
      );
    } else {
      delete parsedCompositionsConfigurations[currentCompositionId];
      localStorage.setItem(
        config.localStorageKeys.compositionLayersConfig,
        JSON.stringify({ ...parsedCompositionsConfigurations })
      );
    }

    dispatch(
      showSuccess(
        formatMessage({
          id: 'delete_configuration',
          defaultMessage: 'Configuration deleted.'
        })
      )
    );
  };

  const resetCompositionTranslation = formatMessage({
    id: 'reset_composition',
    defaultMessage: 'Reset configuration'
  });

  const saveConfigurationTranslation = formatMessage({
    id: 'save_configuration',
    defaultMessage: 'Save configuration'
  });

  const loadConfigurationTranslation = formatMessage({
    id: 'load_configuration',
    defaultMessage: 'Load configuration'
  });

  const content = (
    <div className="reset-composition-section">
      <Button
        onClick={() => {
          setVisibleModal(true);
          setLoadConfig(false);
        }}
      >
        {saveConfigurationTranslation}
      </Button>
      <Button
        onClick={() => {
          setVisibleModal(true);
          setLoadConfig(true);
        }}
      >
        {loadConfigurationTranslation}
      </Button>
      <Popconfirm
        title={formatMessage({
          id: 'reset_composition_confirm',
          defaultMessage: 'Are you sure you want to reset the composition?'
        })}
        okText={formatMessage({ id: 'general_yes', defaultMessage: 'Yes' })}
        cancelText={formatMessage({ id: 'general_no', defaultMessage: 'No' })}
        onConfirm={resetComposition}
      >
        <Button>{resetCompositionTranslation}</Button>
      </Popconfirm>
    </div>
  );

  return (
    <>
      <Tooltip title={resetCompositionTranslation}>
        <Popover
          content={content}
          title="Wybierz opcję wczytywania konfiguracji listy warstw"
        >
          <Button variant="text" className="reset-composition-btn">
            <Icon type="setting" />
          </Button>
        </Popover>
      </Tooltip>
      <SuspenseWrapper>
        <CompositionConfigModal
          visible={visibleModal}
          onCancel={() => setVisibleModal(false)}
          onSave={saveConfiguration}
          loadConfiguration={loadConfiguration}
          configurations={configurations}
          loadConfig={loadConfig}
          deleteConfiguration={deleteConfiguration}
        />
      </SuspenseWrapper>
    </>
  );
};

export default ResetCompositionButton;
