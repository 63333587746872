import { useState, useEffect, useCallback } from 'react';
import { Checkbox, Select, Slider } from 'antd';
import PropTypes from 'prop-types';

import useSymbolizationMessages from '../../../hooks/useSymbolizationMessages';
import useFillTypeOptions from '../../../hooks/useFillTypeOptions';
import { DEFAULT_FILL_OPACITY_VALUE } from '../../../hooks/usePolygonSymbolization';

import { ForwardColorSwitcher } from '../../../components/ForwardColorSwitcher';
import { FormItem } from '../../../components';

import '../SymbolizationForm.less';

const { Option } = Select;

const FillSymbolizationForm = ({
  withoutFill,
  fillType,
  fillColor,
  fillOpacity,
  setWithoutFill,
  setFillType,
  setFillColor,
  setFillOpacity,
  getFieldDecorator
}) => {
  const [sliderValue, setSliderValue] = useState(DEFAULT_FILL_OPACITY_VALUE);

  const {
    colorMsg,
    styleMsg,
    visibilityMsg,
    noneMsg
  } = useSymbolizationMessages();

  const fillTypeOptions = useFillTypeOptions();

  const handleOpacity = value => {
    if (isNaN(value) || !setFillOpacity) {
      return;
    }

    setFillOpacity(value);
  };

  const convertToPercentage = sliderValue => {
    const valueInPercentage = (sliderValue * 100).toFixed(0);
    return `${valueInPercentage}%`;
  };

  // Funkcja odpowiadająca za update wartości slider'a podczas edycji szkicu,
  // który zawiera zdefiniowaną wartość przezroczystości inną niz defaultowa.
  const updateSliderValue = useCallback(() => {
    if (fillOpacity !== sliderValue) {
      setSliderValue(fillOpacity);
    }
  }, [fillOpacity]);

  useEffect(updateSliderValue, [updateSliderValue]);

  return (
    <div className="symbolization-form">
      <FormItem label={noneMsg}>
        <Checkbox
          checked={withoutFill}
          onChange={e => setWithoutFill(e.target.checked)}
        />
      </FormItem>
      {!!setFillType && !withoutFill && (
        <FormItem label={styleMsg}>
          {getFieldDecorator('fillStyle', {
            initialValue: fillType
          })(
            <Select placeholder={styleMsg} onChange={setFillType}>
              {fillTypeOptions.map(({ key, label }) => (
                <Option key={key}>{label}</Option>
              ))}
            </Select>
          )}
        </FormItem>
      )}
      {!withoutFill && (
        <FormItem label={colorMsg}>
          {getFieldDecorator('fillColor', {
            initialValue: fillColor
          })(
            <ForwardColorSwitcher
              style={{ width: '100%' }}
              onChange={setFillColor}
              placeholder={colorMsg}
            />
          )}
        </FormItem>
      )}
      {!!setFillOpacity && !withoutFill && (
        <FormItem label={visibilityMsg}>
          <div className="slider-wrapper">
            {getFieldDecorator('opacity', {
              initialValue: fillOpacity
            })(
              <Slider
                className="slider-container"
                min={0}
                max={1}
                onChange={value => setSliderValue(value)}
                onAfterChange={handleOpacity}
                step={0.01}
                tooltipVisible={false}
              />
            )}
            <span className="slider-value-container">
              {convertToPercentage(sliderValue)}
            </span>
          </div>
        </FormItem>
      )}
    </div>
  );
};

FillSymbolizationForm.propTypes = {
  noFill: PropTypes.bool,
  fillType: PropTypes.string,
  fillColor: PropTypes.string,
  fillOpacity: PropTypes.number,
  setNoFill: PropTypes.func,
  setFillType: PropTypes.func,
  setFillColor: PropTypes.func.isRequired,
  setFillOpacity: PropTypes.func,
  getFieldDecorator: PropTypes.func.isRequired
};

export default FillSymbolizationForm;
