/**
 * Oblicza i zwraca przybliżoną aktualną skalę mapy w formacie liczbowym
 *
 * @param {L.Map} map
 * @return {string} - skala mapy w formacie liczbowym (np. 1:1500)
 */

let div;

const calculateScale = (map, { avoidRound } = {}) => {
  /* Based on https://github.com/MarcChasse/leaflet.ScaleFactor/blob/master/leaflet.scalefactor.js */
  const pxToMm = px => {
    if (!div) {
      div = document.createElement('div');
      div.style.height = '1mm';
      document.body.appendChild(div);
    }

    const pxPerMm = div.getBoundingClientRect().height;

    return px / pxPerMm;
  };

  if (!map) return;

  const centerOfMap = map.getSize().y / 2;
  const realWorlMetersPer100Pixels = map.distance(
    map.containerPointToLatLng([0, centerOfMap]),
    map.containerPointToLatLng([100, centerOfMap])
  );
  const screenMetersPer100Pixels = pxToMm(100) / 1000;
  const scaleFactor = realWorlMetersPer100Pixels / screenMetersPer100Pixels;

  if (avoidRound) return `1:${parseInt(scaleFactor)}`;

  return `1:${parseInt(scaleFactor)}`;
};

export default calculateScale;
