/**
 * Implementation based on
 * https://ant.design/components/table/#components-table-demo-drag-sorting
 */
import { Children } from 'react';
import classNames from 'classnames';
import { DropTarget, DragSource } from 'react-dnd';

import {
  setRowAsDraggable,
  setRowAsNotDraggable
} from '../../utils/lib/setRowDraggability';

import './DraggableBodyRow.less';

// Funkcja dodaje/usuwa atrybut "draggable" po hoverze elementu
// komórki. Jeśli tego nie zrobimy w przeciągalnym wierszu
// nie będzie można np. kontrolować ustawiania kursora w inpucie
// lub zaznaczyć tekstu do skopiowania.
const removePropagationFromCellElems = children =>
  Children.forEach(children, child => {
    const { column, record } = child.props;
    const { render, dataIndex } = column;

    child.props.column.render = (...params) => (
      <span
        onMouseMove={setRowAsNotDraggable}
        onMouseLeave={setRowAsDraggable}
        className={classNames({ 'cursor-text': !render })}
      >
        {render?.(...params) || record[dataIndex]}
      </span>
    );
  });

let dragingIndex = -1;

const BodyRow = ({
  isOver,
  connectDragSource,
  connectDropTarget,
  moveRow,
  disabled,
  ...restProps
}) => {
  const style = { ...restProps.style, cursor: disabled ? 'auto' : 'move' };
  let { className } = restProps;

  if (isOver && !disabled) {
    if (restProps.index > dragingIndex) {
      className += ' drop-over-downward';
    }
    if (restProps.index < dragingIndex) {
      className += ' drop-over-upward';
    }
  }

  removePropagationFromCellElems(restProps.children);

  return connectDragSource(
    connectDropTarget(<tr {...restProps} className={className} style={style} />)
  );
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex || props.disabled) return;

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index
    };
  }
};

export default DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource()
  }))(BodyRow)
);
