import { Component } from 'react';
import PropTypes from 'prop-types';

import { Steps } from 'antd';
import { RoleChanger, RequiredRole } from '../';

import { getRoleById } from '../../utils/lib';
import { roles } from '../../config';
import './RoleBar.less';

const Step = Steps.Step;

const rolebarStyles = {
  portalRole: 'roleSteps',
  userRole: ''
};

class RoleBar extends Component {
  static defaultProps = {
    editable: true,
    requiredRoleToEdit: ''
  };

  state = {
    stepsStatus: 0,
    editing: false
  };

  roleNames = {
    publicRole: ['Publiczna', ''],
    basicRole: ['Podstawowa', 'basic'],
    basicPlusRole: ['Podstawowa Plus', 'basic_plus'],
    advancedRole: ['Zaawansowana', 'advanced'],
    adminRole: ['Administrator', 'administrator'],
    superAdminRole: ['Super Administrator', 'superadmin']
  };
  items = [
    {
      title: this.roleNames.publicRole[0],
      name: this.roleNames.publicRole[1]
    },
    {
      title: this.roleNames.basicRole[0],
      name: this.roleNames.basicRole[1]
    },
    {
      title: this.roleNames.basicPlusRole[0],
      name: this.roleNames.basicPlusRole[1]
    },
    {
      title: this.roleNames.advancedRole[0],
      name: this.roleNames.advancedRole[1]
    },
    {
      title: this.roleNames.adminRole[0],
      name: this.roleNames.adminRole[1]
    }
  ];

  requiedRole = roles.administrator;

  UNSAFE_componentWillMount() {
    this.selectVisibleItems();
  }

  componentDidMount() {
    if (this.props.role) {
      this.setState({ stepsStatus: this.checkUsersRole(this.props.role) });
      this.setRoleId(this.props.role);
      this.setRequiedRole();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.role !== this.props.role) {
      this.setState({ stepsStatus: this.checkUsersRole(this.props.role) });
      this.setRoleId(this.props.role);
    }
  }

  checkUsersRole = role => {
    if (typeof role === 'number') {
      const newRole = this.props.allRoles.find(el => el.id === role);
      for (const key in this.items) {
        if (
          this.items[key]?.title.toLowerCase() === newRole.name?.toLowerCase()
        )
          role = this.items[key].name;
      }
    }

    let stepsStatus;
    switch (role) {
      case this.roleNames.publicRole[1]:
        stepsStatus = this.items.findIndex(
          item => item.name === this.roleNames.publicRole[1]
        );
        break;
      case this.roleNames.basicRole[1]:
        stepsStatus = this.items.findIndex(
          item => item.name === this.roleNames.basicRole[1]
        );
        break;
      case this.roleNames.basicPlusRole[1]:
        stepsStatus = this.items.findIndex(
          item => item.name === this.roleNames.basicPlusRole[1]
        );
        break;
      case this.roleNames.advancedRole[1]:
        stepsStatus = this.items.findIndex(
          item => item.name === this.roleNames.advancedRole[1]
        );
        break;
      case this.roleNames.adminRole[1]:
        stepsStatus = this.items.findIndex(
          item => item.name === this.roleNames.adminRole[1]
        );
        break;
      case this.roleNames.superAdminRole[1]:
        stepsStatus = this.items.findIndex(
          item => item.name === this.roleNames.superAdminRole[1]
        );
        break;
      default:
        stepsStatus = 0;
    }
    return stepsStatus;
  };

  setRoleId = roleName => {
    if (this.props.allRoles)
      // eslint-disable-next-line no-unused-vars
      for (const key in this.props.allRoles) {
        const currentRole = this.props.allRoles[key];
        if (typeof roleName === 'number') {
          if (currentRole.id === roleName)
            this.props.onChange && this.props.onChange(currentRole.id);
        } else {
          if (currentRole.name.toLowerCase() === roleName.toLowerCase())
            this.props.onChange && this.props.onChange(currentRole.id);
        }
      }
  };

  clicked = (role, title = null) => {
    if (this.props.disabled) return;
    if (this.props.editing || this.state.editing) {
      const stepsStatus = this.checkUsersRole(role);
      this.setState({ stepsStatus });
      this.setRoleId(title);
      if (!this.props.allRoles)
        this.props.onChange && this.props.onChange(role, stepsStatus);
    }
  };

  activeEditingMode = () => {
    this.setState(prevState => ({
      editing: true,
      prevStepStatus: prevState.stepsStatus
    }));
  };

  deactiveEditingMode = () => {
    this.setState(prevState => ({
      editing: false,
      stepsStatus: prevState.prevStepStatus
    }));
  };

  saveRole = () => {
    const roleName = getRoleById(this.state.stepsStatus);
    this.props.saveChanges(roleName, this.saveRoleCallback);
  };

  saveRoleCallback = success => {
    this.setState({
      editing: false
    });

    if (success) {
      this.setState({
        prevStepStatus: null
      });
    }

    if (!success) {
      this.setState(prevState => ({
        stepsStatus: prevState.prevStepStatus
      }));
    }
  };

  selectVisibleItems = () => {
    // Funkcja do ukrywania publicznej roli w pasku ról
    const items = [...this.items];
    if (!this.props.isPublicRoleVisible) {
      items.splice(0, 1);
      this.items = items;
    }
  };

  setRequiedRole = () => {
    if (this.props.requiredRoleToEdit) {
      this.requiedRole = this.props.requiredRoleToEdit;
    }
  };

  render() {
    return (
      <>
        <div
          className={[
            'role-bar',
            this.props.inverseTheme ? ' role-bar-inverse' : ''
          ].join(' ')}
        >
          <Steps
            className={[
              rolebarStyles[this.props.rolebarStyleType],
              this.props.disabled ? 'roleStepsDisabled' : ''
            ].join(' ')}
            size="small"
            current={this.state.stepsStatus}
            progressDot
          >
            {this.items.map((item, index) => {
              return (
                <Step
                  key={index + '_' + item.name}
                  title={this.props.displayTitle ? item.title : null}
                  onClick={() =>
                    this.clicked(
                      item.name,
                      this.props.allRoles ? item.title : null
                    )
                  }
                />
              );
            })}
          </Steps>
          {this.props.editable && (
            <RequiredRole
              role={this.requiedRole}
              render={hasRequiredRole =>
                hasRequiredRole && (
                  <RoleChanger
                    onEditClick={this.activeEditingMode}
                    onCancelClick={this.deactiveEditingMode}
                    onSaveClick={this.saveRole}
                  />
                )
              }
            />
          )}
        </div>
        {this.props.centerTitle ? (
          <div>
            <p>{this.items[this.state.stepsStatus]?.title}</p>
          </div>
        ) : null}
      </>
    );
  }
}

RoleBar.propTypes = {
  role: PropTypes.string,
  editing: PropTypes.bool,
  editable: PropTypes.bool.isRequired,
  requiredRoleToEdit: PropTypes.string,
  onChange: PropTypes.func,
  inverseTheme: PropTypes.bool,
  saveChanges: PropTypes.func,
  isPublicRoleVisible: PropTypes.bool,
  disabled: PropTypes.bool,
  rolebarStyleType: PropTypes.string
};

export default RoleBar;
