import PropTypes from 'prop-types';

const ImagePreviewModal = props => (
  <div
    className="image-preview-container"
    style={{ top: props.top, left: props.left }}
  >
    <img
      style={{ display: props.previewUrl ? 'block' : 'none' }}
      src={props.previewUrl}
      alt=""
      className="image-preview"
      // onLoad w celu uniknięcia brzydkiego „błyśnięcia” w momencie
      // pomiędzy wstawieniem obrazka, a wyłączeniem loadera.
      onLoad={props.handlePreviewLoad}
    />
    {(!props.previewUrl || props.loading) && (
      <span>Trwa wczytywanie podglądu...</span>
    )}
  </div>
);

ImagePreviewModal.propTypes = {
  previewUrl: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  handlePreviewLoad: PropTypes.func.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired
};

export default ImagePreviewModal;
