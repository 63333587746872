import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { mapPortalSelectors } from '../../store/selectors';

const { getCurrentComposition } = mapPortalSelectors;

const useCurrentComposition = () => {
  const { prefix, portal } = useParams();
  const currentComposition = useSelector(state =>
    getCurrentComposition(state, prefix, portal)
  );

  return currentComposition;
};

export default useCurrentComposition;
