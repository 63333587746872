import { useState, useCallback, useEffect, useMemo } from 'react';
import { getDashSize } from '../../utils/getDashSize';
import { getLineSize } from '../../utils/getLineSize';
import {
  DASH_ARRAY_LINE_TYPE,
  DEFAULT_LEAFLET_COLOR
} from '../../components/MapControl/layer-editor/const';

const DEFAULT_LINE_TYPE = 'solid';

const useLineSymbolization = ({
  defaultColor = DEFAULT_LEAFLET_COLOR
} = {}) => {
  const [lineColor, setLineColor] = useState(null);
  const [lineType, setLineType] = useState(null);
  const [lineSize, setLineSize] = useState(null);

  const handleDefaultValues = useCallback(() => {
    setLineColor(lineColor ?? defaultColor);
    setLineType(lineType ?? DEFAULT_LINE_TYPE);
    setLineSize(lineSize ?? getLineSize(lineType));
  }, [defaultColor]);

  useEffect(handleDefaultValues, [handleDefaultValues]);

  const lineStyles = useMemo(() => {
    return {
      color: lineColor,
      weight: lineSize,
      dashArray: getDashSize(lineType)
    };
  }, [lineColor, lineSize, lineType]);

  const updateValues = useCallback(
    ({ customLineStyles: { color, dashArray, weight } }) => {
      setLineColor(color);
      setLineType(DASH_ARRAY_LINE_TYPE[dashArray]);
      setLineSize(weight);
    },
    []
  );

  const values = useMemo(
    () => ({
      lineColor,
      lineType,
      lineSize,
      lineStyles,
      setLineColor,
      setLineType,
      setLineSize,
      updateValues
    }),
    [lineColor, lineType, lineSize, lineStyles]
  );

  return values;
};

export default useLineSymbolization;
