import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './DistrictBadge.less';

const defaultBadge = require('../../images/coa-not-found.png');

const DistrictBadge = memo(props => {
  const divClass = classNames('district-badge-title', {
    'district-badge-title-reverse': props.reverseColor
  });

  return (
    <div className="district-badge">
      <div className="district-badge-img">
        <img
          className="district-badge-img-element"
          src={props.badgeFileName || defaultBadge}
          alt={`Herb ${props.district} ${props.county}`}
        />
      </div>
      <div className={divClass}>
        <span className="district-badge-name">{props.district}</span>
        <br />
        <span>{props.county}</span>
      </div>
    </div>
  );
});

DistrictBadge.propTypes = {
  district: PropTypes.string.isRequired,
  county: PropTypes.string.isRequired,
  badgeFileName: PropTypes.string,
  reverseColor: PropTypes.bool
};

export default DistrictBadge;
