import { Map, List } from 'immutable';
import {
  ADD_COMPOSITION_REGISTER_SUCCESS,
  CLOSE_REGISTER_DETAILS,
  CLOSE_REGISTER_TOOL,
  DELETE_COMPOSITION_REGISTER_SUCCESS,
  EDIT_COMPOSITION_REGISTER_SUCCESS,
  EDIT_REGISTER_ATTRIBUTTE_SUCCESS,
  FETCH_COMPOSITION_REGISTERS_SUCCESS,
  FETCH_REGISTER_ATTRIBUTTES_SUCCESS,
  GET_FINN_LIST_SUCCESS,
  GET_FINN_OBJECT_SUCCESS,
  GET_FINN_SETTINGS_SUCCESS,
  OPEN_REGISTER_DETAILS,
  OPEN_REGISTER_TOOL,
  SELECTED_REGISTER_FEATURES,
  SELECTED_REGISTER_LAYER,
  SET_REGISTER_TOOL_IN_SEPARATE_WINDOW,
  REGISTER_TOOL_COMPONENT,
  CHANGE_OPTION_ID,
  SET_SEARCH_VALUE,
  SET_SHOW_FILTERS,
  SET_REGISTER_QUERY,
  RESET_REGISTER_DETAILS,
  SET_FILTERS_VALUES,
  SET_PAGINATION_VALUES,
  CHANGE_REGISTER_COMPOSITION,
  FETCH_RELATED_FINN_OBJECT_SUCCESS,
  OPEN_CHANGE_HISTORY_TABLE,
  CLOSE_CHANGE_HISTORY_TABLE,
  FETCH_CHANGE_HISTORY_BEGIN,
  FETCH_CHANGE_HISTORY_SUCCESS,
  FETCH_CHANGE_HISTORY_FAILED,
  RESTORE_REGISTER_INITIAL_STATE,
  CHANGE_REGISTER_LAYER,
  IS_REGISTER_EDITION_ENABLED,
  FORM_EDITION_ID
} from '../constants/registersActionTypes';

const paginationInitial = Map({
  resultsPerPage: '5',
  totalPage: 0,
  page: 1
});

export const initialState = Map({
  compositionRegisters: Map({}),
  isCompositionRegistersFetched: Map({}),
  isOpenRegisterTool: false,
  isRegisterInSeparateWindow: false,
  registerAttrs: Map({}),
  selectedLayer: null,
  selectedFeatures: null,
  isRegisterDetailsOpen: false,
  registerFinnData: null,
  registerToolModelName: null,
  registerToolRegisterId: null,
  layerId: null,
  optionId: null,
  searchValue: '',
  showFilters: false,
  registerQuery: null,
  filtersValues: Map({}),
  pagination: paginationInitial,
  relatedFinnObject: List([]),
  isChangeHistoryTableOpen: false,
  recordId: null,
  isFetchingChangeHistory: false,
  changeHistory: Map({}),
  changeHistoryCount: null,
  isRegisterEditionEnabled: false,
  formEditionId: null
});

const fetchCompositionRegistersSuccess = (state, action) => {
  const { district, compositionId, registers } = action;
  const newState = state.setIn(
    ['compositionRegisters', district, compositionId],
    List(registers)
  );
  return newState.setIn(
    ['isCompositionRegistersFetched', district, compositionId],
    true
  );
};

const addCompositionRegisterSuccess = (
  state,
  { district, compositionId, register }
) =>
  state.updateIn(
    ['compositionRegisters', district, compositionId],
    List(),
    list => {
      const newList = [...list];
      newList.push(register);
      return List(newList);
    }
  );

const deleteCompositionRegisterSuccess = (
  state,
  { district, compositionId, registerId }
) =>
  state.updateIn(['compositionRegisters', district, compositionId], list =>
    list.filter(({ id }) => id !== registerId)
  );

const editCompositionRegisterSuccess = (
  state,
  { district, compositionId, register }
) =>
  state.updateIn(['compositionRegisters', district, compositionId], list => {
    const newList = [...list];
    const indexToEdit = newList.findIndex(({ id }) => id === register.id);
    newList[indexToEdit] = register;
    return List(newList);
  });

const openRegisterTool = state => state.set('isOpenRegisterTool', true);

const closeRegisterTool = state => state.set('isOpenRegisterTool', false);

const setRegisterInSeparateWindow = (state, { isInSeparateWindow }) =>
  state.set('isRegisterInSeparateWindow', isInSeparateWindow);

const fetchRegisterAttributesSuccess = (
  state,
  { attrs, registerId, district, compositionId }
) =>
  state.setIn(
    ['registerAttrs', district, compositionId, registerId],
    List(attrs)
  );

const setSelectedLayer = (state, { layerId }) =>
  state.set('selectedLayer', layerId);

const setSelectedFeatures = (state, { features }) =>
  state.set('selectedFeatures', features);

const openRegisterDetails = (state, { modelName, registerId }) =>
  state.merge(
    Map({
      isRegisterDetailsOpen: true,
      registerToolModelName: modelName,
      registerToolRegisterId: registerId
    })
  );

const closeRegisterDetails = state =>
  state.merge(
    Map({
      isRegisterDetailsOpen: false,
      registerToolModelName: null,
      registerToolRegisterId: null,
      registerFinnData: null
    })
  );

const saveFinnObjectSuccess = (state, { data }) =>
  state.set('registerFinnData', data);

const saveFinnSettingsSuccess = (state, { registerId, data }) =>
  state.setIn(['registerSettings', registerId], List(data));

const saveFinnListSuccess = (state, { registerId, data }) =>
  state.setIn(['registerFinnList', registerId], data);

const setRegisterComponent = (state, { componentName }) =>
  state.set('componentName', componentName);

const changeOptionId = (state, { optionId }) => state.set('optionId', optionId);

const setSearchValue = (state, { searchValue, page }) => {
  return state
    .set('searchValue', searchValue)
    .set('pagination', state.get('pagination').merge({ page: page || 1 }));
};

const setShowFilters = (state, { showFilters }) =>
  state.set('showFilters', showFilters);

const setRegisterQuery = (state, { registerQuery }) =>
  state.set('registerQuery', registerQuery);

const closeRegisterTable = state =>
  state.merge({
    isOpenRegisterTool: false,
    searchValue: '',
    optionId: null,
    filtersValues: Map(),
    pagination: paginationInitial,
    registerQuery: null,
    showFilters: false,
    isRegisterEditionEnabled: false
  });

const changeRegisterComposition = state =>
  state.merge({
    searchValue: '',
    optionId: null,
    filtersValues: Map(),
    pagination: paginationInitial,
    registerQuery: null,
    showFilters: false,
    isRegisterEditionEnabled: false
  });

const changeRegisterLayer = state =>
  state
    .set('searchValue', '')
    .set('filtersValues', Map())
    .set('pagination', paginationInitial);

const setFiltersValues = (state, { filtersValues }) =>
  state.set('filtersValues', Map(filtersValues));

const setPaginationValues = (state, { values }) =>
  state.update('pagination', pagination => pagination.merge(Map(values)));

const fetchRelatedFinnModelSuccess = (state, { objects }) =>
  state.set('relatedFinnObject', List(objects));

const openChangeHistoryTable = (state, { payload: { recordId } }) =>
  state.set('recordId', recordId).set('isChangeHistoryTableOpen', true);

const closeChangeHistoryTable = state =>
  state
    .set('changeHistoryCount', null)
    .set('recordId', null)
    .set('changeHistory', Map({}))
    .set('isChangeHistoryTableOpen', false);

const fetchChangeHistoryBegin = state =>
  state.set('isFetchingChangeHistory', true);

const fetchChangeHistorySuccess = (
  state,
  { payload: { count, page, records } }
) =>
  state
    .set('changeHistoryCount', count)
    .setIn(['changeHistory', page], records)
    .set('isFetchingChangeHistory', false);

const fetchChangeHistoryFailed = state =>
  state.set('isFetchingChangeHistory', false);

const setIsRegisterEditionEnabled = (state, { isRegisterEditionEnabled }) =>
  state.set('isRegisterEditionEnabled', isRegisterEditionEnabled);

const setFormEditionId = (state, { formEditionId }) =>
  state.set('formEditionId', formEditionId);

const restoreRegisterInitialState = state => state.merge(initialState);

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_COMPOSITION_REGISTERS_SUCCESS]: fetchCompositionRegistersSuccess,
      [OPEN_REGISTER_TOOL]: openRegisterTool,
      [CLOSE_REGISTER_TOOL]: closeRegisterTool,
      [SET_REGISTER_TOOL_IN_SEPARATE_WINDOW]: setRegisterInSeparateWindow,
      [ADD_COMPOSITION_REGISTER_SUCCESS]: addCompositionRegisterSuccess,
      [EDIT_COMPOSITION_REGISTER_SUCCESS]: editCompositionRegisterSuccess,
      [DELETE_COMPOSITION_REGISTER_SUCCESS]: deleteCompositionRegisterSuccess,
      [FETCH_REGISTER_ATTRIBUTTES_SUCCESS]: fetchRegisterAttributesSuccess,
      [EDIT_REGISTER_ATTRIBUTTE_SUCCESS]: fetchRegisterAttributesSuccess,
      [SELECTED_REGISTER_LAYER]: setSelectedLayer,
      [SELECTED_REGISTER_FEATURES]: setSelectedFeatures,
      [OPEN_REGISTER_DETAILS]: openRegisterDetails,
      [CLOSE_REGISTER_DETAILS]: closeRegisterDetails,
      [GET_FINN_OBJECT_SUCCESS]: saveFinnObjectSuccess,
      [GET_FINN_SETTINGS_SUCCESS]: saveFinnSettingsSuccess,
      [GET_FINN_LIST_SUCCESS]: saveFinnListSuccess,
      [REGISTER_TOOL_COMPONENT]: setRegisterComponent,
      [CHANGE_OPTION_ID]: changeOptionId,
      [SET_SEARCH_VALUE]: setSearchValue,
      [SET_SHOW_FILTERS]: setShowFilters,
      [SET_REGISTER_QUERY]: setRegisterQuery,
      [RESET_REGISTER_DETAILS]: closeRegisterTable,
      [CHANGE_REGISTER_COMPOSITION]: changeRegisterComposition,
      [CHANGE_REGISTER_LAYER]: changeRegisterLayer,
      [SET_FILTERS_VALUES]: setFiltersValues,
      [SET_PAGINATION_VALUES]: setPaginationValues,
      [FETCH_RELATED_FINN_OBJECT_SUCCESS]: fetchRelatedFinnModelSuccess,
      [OPEN_CHANGE_HISTORY_TABLE]: openChangeHistoryTable,
      [CLOSE_CHANGE_HISTORY_TABLE]: closeChangeHistoryTable,
      [FETCH_CHANGE_HISTORY_BEGIN]: fetchChangeHistoryBegin,
      [FETCH_CHANGE_HISTORY_SUCCESS]: fetchChangeHistorySuccess,
      [FETCH_CHANGE_HISTORY_FAILED]: fetchChangeHistoryFailed,
      [RESTORE_REGISTER_INITIAL_STATE]: restoreRegisterInitialState,
      [IS_REGISTER_EDITION_ENABLED]: setIsRegisterEditionEnabled,
      [FORM_EDITION_ID]: setFormEditionId
    }[action.type] || (s => s)
  )(state, action);
}
