import { useIntl } from 'react-intl';

const useMeasureToolTranslation = () => {
  const { formatMessage } = useIntl();

  const translations = {
    copiedText: formatMessage({
      id: 'general_copy',
      defaultMessage: 'Copied'
    }),
    toasterCopiedText: formatMessage({
      id: 'data_copied_to_the_clipboard',
      defaultMessage: 'Data copied to the clipboard'
    }),
    pointText: formatMessage({
      id: 'district_portal_measure_tools_point',
      defaultMessage: 'Point'
    }),
    copyAll: formatMessage({
      id: 'copy_all',
      defaultMessage: 'Copy all'
    })
  };

  return translations;
};

export default useMeasureToolTranslation;
