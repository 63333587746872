import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getModelsTranslations } from '../../store/actions/modelsActions';
import { modelsSelectors } from '../../store/selectors';

const { getMapPortalModelsTranslations } = modelsSelectors;

const useModelsTranslations = (mapPortalId, filters) => {
  const dispatch = useDispatch();
  const translations = useSelector(state =>
    getMapPortalModelsTranslations(state, Number(mapPortalId), filters)
  );

  const fetchTranslations = () => {
    if (translations) return;
    dispatch(getModelsTranslations(mapPortalId));
  };

  React.useEffect(() => {
    fetchTranslations();
  }, [translations]);

  return translations;
};

export default useModelsTranslations;
