import { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Icon, Tooltip } from 'antd';

import useActiveCloseButton from './hooks/useActiveCloseButton';

import mapPortalSelectors from '../../store/selectors/mapPortal';

import useToolbarActive from './hooks/useToolbarActive';

import './ToolbarItem.less';

const ToolbarItem = ({
  mapPortalToolsConfiguration,
  defaultMode,
  toolbarMapTools,
  toolKey,
  disabled,
  title,
  onClick,
  antdIcon,
  dot,
  activeIndexToolbar,
  showCloseButton,
  onClose,
  onCloseTool,
  ...props
}) => {
  const { isToolActive, iconOpacity, isRegisterToolActive } = useToolbarActive({
    mapPortalToolsConfiguration,
    defaultMode,
    toolbarMapTools,
    toolKey,
    disabled
  });

  const {
    onCloseIconClick,
    isClickTypeForIdentification,
    getTooltipTitle,
    activateBackgroundTool,
    resetSettings,
    resetWmsTool,
    isActiveToolDisabled,
    activeToolIndex,
    identificationToolKey
  } = useActiveCloseButton({ toolKey, showCloseButton });

  const closeIdentificationButtonClass = isClickTypeForIdentification()
    ? 'close-identification-button'
    : 'close-identification-button-disabled';

  useEffect(() => {
    activateBackgroundTool();

    return () => {
      resetSettings();
    };
  }, []);

  useEffect(() => {
    resetWmsTool();
  }, [activeToolIndex]);

  return isToolActive ? (
    <Tooltip placement="bottom" title={title} mouseLeaveDelay={0}>
      <button
        className={classNames(
          activeIndexToolbar === toolKey || isRegisterToolActive
            ? 'toolbar-item-display'
            : 'toolbar-item',
          'btn-primary toolbar-item-block toolbar-item__transparent-button'
        )}
        onClick={!disabled ? onClick : undefined}
        aria-pressed="false"
        aria-expanded="false"
        aria-label={title}
      >
        {props.icon && (
          <props.icon
            wrapperProps={{ style: iconOpacity }}
            aria-label={title}
            role="img"
          />
        )}
        {antdIcon ? (
          <Icon
            type={antdIcon}
            style={iconOpacity}
            theme="outlined"
            aria-label={title}
            role="img"
            className="toolbar_icon"
          />
        ) : null}
        {isActiveToolDisabled && (
          <button
            className="close-button"
            onClick={onClose}
            disabled={activeToolIndex === identificationToolKey}
          >
            <Tooltip title={getTooltipTitle()}>
              <Icon
                type="close"
                className={closeIdentificationButtonClass}
                onClick={onCloseIconClick}
              />
            </Tooltip>
          </button>
        )}
        {dot && <div className="toolbar-item-dot" />}
      </button>
    </Tooltip>
  ) : null;
};

ToolbarItem.propTypes = {
  toolbarMapTools: PropTypes.array.isRequired,
  toolKey: PropTypes.string,
  title: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.func,
  antdIcon: PropTypes.string,
  disabled: PropTypes.bool,
  dot: PropTypes.bool,
  activeIndexToolbar: PropTypes.string,
  mapPortalToolsConfiguration: PropTypes.array.isRequired,
  defaultMode: PropTypes.string.isRequired
};

const {
  getMapPortalByShortName,
  getMapPortalToolsConfiguration,
  getDefaultMode
} = mapPortalSelectors;

const mapStateToProps = (state, ownProps) => ({
  toolbarMapTools: getMapPortalByShortName(
    state,
    ownProps.match.params.prefix,
    ownProps.match.params.portal
  ).get('toolbar_map_tools'),
  defaultMode: getDefaultMode(state),
  activeIndexToolbar: state.mapPortals.get('activeToolIndex'),
  mapPortalToolsConfiguration: getMapPortalToolsConfiguration(state)
});

export default memo(withRouter(connect(mapStateToProps, null)(ToolbarItem)));
