import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export function checkAuth(WrappedComponent, forceRender, redirectPath) {
  return props => {
    const isAuthorized = Boolean(props.userData);

    if (isAuthorized || (!isAuthorized && forceRender)) {
      return <WrappedComponent {...props} isAuthorized={isAuthorized} />;
    }

    if (!isAuthorized && redirectPath) {
      props.history.push(redirectPath);
    }

    return null;
  };
}

const mapStateToProps = state => ({
  userData: state.user.get('userData')
});

export default compose(connect(mapStateToProps), withRouter, checkAuth);
