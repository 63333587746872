import { useSelector } from 'react-redux';
import proj4 from 'proj4';
import { stringify } from 'wellknown';
import { reproject } from 'reproject';

import { globalSelectors } from '../../store/selectors';

const useStringifyGeometry = () => {
  const globalSettings = useSelector(state =>
    globalSelectors.getGlobalSettings(state)
  );

  const mapCrs = `EPSG:${globalSettings.get('default_internal_srid')}`;

  const stringifyGeometry = geometry => {
    const reprojected = reproject(geometry, proj4.WGS84, mapCrs);
    return stringify(reprojected);
  };

  return stringifyGeometry;
};

export default useStringifyGeometry;
