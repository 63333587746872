/**
 * Oblicza wartości max i min przekazanych współrzędnych.
 *
 * @param {object} object - obiekt ze współrzędnymi
 * @return {array} - tablica z max/min x/y
 */

const findCoordsArray = (entry, results) => {
  // Wyszukiwanie tablicy ze wspołrzędnymi
  // niezależnie jak jest zagnieżdżona

  if (Array.isArray(entry[0][0])) findCoordsArray(entry[0], results);
  else {
    entry.forEach(v => {
      results.cordsX.push(v[0]);
      results.cordsY.push(v[1]);
    });
  }
};

const getCords = object => {
  const results = {
    cordsX: [],
    cordsY: []
  };

  if (object.type === 'Point') {
    return object.coordinates;
  }

  if (object.type === 'MultiLineString') {
    return [
      [object.coordinates[0][0][1], object.coordinates[0][0][0]],
      [object.coordinates[0][1][1], object.coordinates[0][1][0]]
    ];
  }

  findCoordsArray(object.coordinates, results);
  const { cordsX, cordsY } = results;

  const maxX = Math.max(...cordsX);
  const maxY = Math.max(...cordsY);
  const minX = Math.min(...cordsX);
  const minY = Math.min(...cordsY);

  return [
    [maxY, maxX],
    [minY, minX]
  ];
};

export default getCords;
