import { useState, useCallback, useEffect, useMemo } from 'react';
import { getDashSize } from '../../utils/getDashSize';
import { getLineSize } from '../../utils/getLineSize';
import {
  FILL_TYPE_KEYS,
  DASH_ARRAY_LINE_TYPE,
  DEFAULT_LEAFLET_COLOR
} from '../../components/MapControl/layer-editor/const';

const DEFAULT_OUTLINE_TYPE = 'solid';
const DEFAULT_FILL_TYPE_VALUE = FILL_TYPE_KEYS.STANDARD;
export const DEFAULT_FILL_OPACITY_VALUE = 0.2;

const usePolygonSymbolization = ({
  defaultColor = DEFAULT_LEAFLET_COLOR,
  defaultFillColor = DEFAULT_LEAFLET_COLOR,
  defaultOpacity = DEFAULT_FILL_OPACITY_VALUE
} = {}) => {
  const [outlineColor, setOutlineColor] = useState(null);
  const [outlineType, setOutlineType] = useState(null);
  const [outlineSize, setOutlineSize] = useState(null);
  const [withoutFill, setWithoutFill] = useState(null);
  const [fillType, setFillType] = useState(null);
  const [fillColor, setFillColor] = useState(null);
  const [fillOpacity, setFillOpacity] = useState(null);

  const handleDefaultValues = useCallback(() => {
    setOutlineColor(outlineColor ?? defaultColor);
    setOutlineType(outlineType ?? DEFAULT_OUTLINE_TYPE);
    setOutlineSize(outlineSize ?? getLineSize(outlineType));
    setFillType(fillType ?? FILL_TYPE_KEYS.STANDARD);
    setFillColor(fillColor ?? defaultFillColor);
    setFillOpacity(fillOpacity ?? defaultOpacity);
  }, [defaultColor, defaultFillColor, defaultOpacity]);

  useEffect(handleDefaultValues, [handleDefaultValues]);

  const polygonStyles = useMemo(() => {
    return {
      color: outlineColor,
      weight: outlineSize,
      dashArray: getDashSize(outlineType),
      fill: !withoutFill,
      fillColor,
      fillType,
      fillOpacity
    };
  }, [
    outlineColor,
    outlineSize,
    outlineType,
    withoutFill,
    fillColor,
    fillOpacity,
    fillType
  ]);

  const updateValues = useCallback(
    ({
      customPolygonStyles: {
        color,
        dashArray,
        weight,
        fill,
        fillColor,
        fillOpacity,
        fillType
      }
    }) => {
      setOutlineColor(color);
      setOutlineType(DASH_ARRAY_LINE_TYPE[dashArray]);
      setOutlineSize(weight);
      setWithoutFill(!fill);
      setFillType(fillType);
      setFillColor(fillColor);
      setFillOpacity(fillOpacity);
    },
    []
  );

  const values = useMemo(
    () => ({
      outlineColor,
      outlineType,
      outlineSize,
      withoutFill,
      fillType: withoutFill ? DEFAULT_FILL_TYPE_VALUE : fillType,
      fillColor,
      fillOpacity: fillOpacity ?? defaultOpacity,
      polygonStyles,
      setOutlineColor,
      setOutlineType,
      setOutlineSize,
      setWithoutFill,
      setFillType,
      setFillColor,
      setFillOpacity,
      updateValues
    }),
    [
      outlineColor,
      outlineType,
      outlineSize,
      withoutFill,
      fillType,
      fillColor,
      fillOpacity,
      polygonStyles,
      updateValues
    ]
  );

  return values;
};

export default usePolygonSymbolization;
