import { withRouter } from 'react-router-dom';
import { useParams, useHistory } from 'react-router';
import { Tabs } from 'antd';

import { AppDistrictsShape } from '../../shapes/district';

import './DistrictsTabs.less';

import { checkUrl } from './utils';

const TabPane = Tabs.TabPane;

export const DistrictsTabs = ({ appDistrict }) => {
  const { prefix } = useParams();
  const { push } = useHistory();
  const pathUrl = checkUrl(prefix, window.location.pathname);

  const urlReplace = pathUrl.replace(/^.+?[/]/, '');
  const redirectUrlReplace = urlReplace !== '/' ? urlReplace : 'admin';

  const getMenuItemClassName = menuItemPrefix =>
    menuItemPrefix === prefix ? 'districts-tabs-menu-active' : null;

  const onTabClick = key => {
    const { prefix: districtPrefix } = appDistrict.find(
      ({ prefix }) => prefix === key
    );

    push(`/${districtPrefix}/${redirectUrlReplace}`);
  };

  return (
    <div className="districts-tabs-wrapper">
      <div className="districts-tabs-spot" />
      <div className="districts-tabs-menu-wrapper">
        <Tabs
          type="card"
          className="districts-tabs-menu"
          defaultActiveKey={prefix}
          onTabClick={onTabClick}
        >
          {appDistrict.map(({ district }) => (
            <TabPane
              className={getMenuItemClassName(district.prefix)}
              key={district.prefix}
              tab={district.nazwa}
            />
          ))}
        </Tabs>
      </div>
    </div>
  );
};

DistrictsTabs.propTypes = {
  appDistrict: AppDistrictsShape
};

export default withRouter(DistrictsTabs);
