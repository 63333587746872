import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchNamesModels } from '../../store/actions/modelsActions';

import { antdSelectFilterOption } from '../../utils/lib';

import { Select } from 'antd';

const Option = Select.Option;

export let NamesModelsList = ({
  compositionId,
  setModel,
  setSelectedRow,
  disabled,
  match,
  model
}) => {
  const dispatch = useDispatch();
  const propsNamesModels = useSelector(state =>
    state.models.get('namesModels')
  );
  const compositionNamesModels = useSelector(state =>
    state.models.getIn(['compositionNameModels', compositionId])
  );

  const [namesModels, setNamesModels] = useState();

  const onChange = value => {
    setModel(value);

    if (!!setSelectedRow) {
      setSelectedRow({});
    }
  };

  useEffect(() => {
    setNamesModels(null);
    if (match && !propsNamesModels?.size && !compositionId) {
      dispatch(fetchNamesModels(match.params.prefix, true));
    }
    if (match && compositionId && !compositionNamesModels?.size) {
      dispatch(fetchNamesModels(match.params.prefix, false, compositionId));
    }
  }, [propsNamesModels, compositionNamesModels, compositionId]);

  useEffect(() => {
    setSourceData();
  }, [propsNamesModels, compositionId, compositionNamesModels]);

  const setSourceData = () => {
    if (compositionId && compositionNamesModels)
      return setNamesModels(compositionNamesModels.toJS());
    if (!compositionId && propsNamesModels) {
      return setNamesModels(propsNamesModels.toJS());
    }
  };

  const sortNamesModels = (a, b) => {
    if (namesModels[a].name < namesModels[b].name) {
      return -1;
    }
    if (namesModels[a].name > namesModels[b].name) {
      return 1;
    }
    return 0;
  };

  return (
    <div>
      <Select
        style={{ width: '100%', marginBottom: '15px' }}
        placeholder="Wybierz warstwę"
        onChange={onChange}
        disabled={disabled}
        filterOption={antdSelectFilterOption}
        showSearch
        defaultValue={model}
      >
        {namesModels &&
          Object.keys(namesModels)
            .sort(sortNamesModels)
            .map((v, i) => {
              return (
                <Option value={v} key={i}>
                  {namesModels[v].name}
                </Option>
              );
            })}
      </Select>
    </div>
  );
};

export const NamesModelsListTest = NamesModelsList;

NamesModelsList.propTypes = {
  fetchingDataModel: PropTypes.bool,
  fetchNamesModels: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,
  setSelectedRow: PropTypes.func,
  namesModels: ImmutablePropTypes.map
};

NamesModelsList = withRouter(NamesModelsList);

export default NamesModelsList;
