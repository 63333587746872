import { lazy } from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import { districtSelectors, globalSelectors } from '../../store/selectors';

import {
  LocationListener,
  HeaderPortal,
  LanguageRecognizer,
  SuspenseWrapper,
  LogoutCountdownTimer
} from '../../components';

import ForbiddenView from '../../views/ForbiddenView';

import DistrictRouter from '../../router/district';

import mainRoutePaths from './paths';
import isItIframe from '../../utils/isItIframe';

const HomeView = lazy(() => import('../../views/Home'));
const LoginView = lazy(() => import('../../views/Login'));
const RegistrationView = lazy(() => import('../../views/Registration'));
const ConfirmEmailView = lazy(() => import('../../views/ConfirmEmail'));
const UserProfileView = lazy(() => import('../../views/UserProfile'));
const MainAdminPanelView = lazy(() => import('../../views/MainAdminPanel'));
const ResetPasswordView = lazy(() => import('../../views/ResetPassword'));
const ConfirmEmailFromAdminView = lazy(() =>
  import('../../views/ConfirmEmailFromAdmin')
);
const SetNewPasswordView = lazy(() => import('../../views/SetNewPassword'));
const ActivateChangedEmailView = lazy(() =>
  import('../../views/ActivateChangedEmail')
);
const Page404 = lazy(() => import('../../views/Page404'));
const PopUpDetailsView = lazy(() => import('../../views/PopUpDetailsView'));
const MaintenancePage = lazy(() => import('../../views/MaintenancePage'));
const Regulations = lazy(() => import('../../views/Regulations'));

const { getDistricts } = districtSelectors;
const { getGlobalSettings } = globalSelectors;

const PortalRouter = () => {
  const districts = useSelector(state => getDistricts(state));
  const globalSettings = useSelector(state => getGlobalSettings(state));
  const districtRegex = districts.join('|');
  const isDistrictListHomePage = globalSettings.get(
    'home_page_is_districts_list'
  );

  mainRoutePaths.districtList = isDistrictListHomePage
    ? mainRoutePaths.homePage
    : mainRoutePaths.districtList;
  return (
    <Router>
      <LocationListener />
      {!isItIframe() && <HeaderPortal />}
      <LanguageRecognizer />
      <Switch>
        <Route
          exact
          path={mainRoutePaths.districtList}
          render={() => (
            <SuspenseWrapper withFooter>
              <HomeView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.login}
          render={() => (
            <SuspenseWrapper withFooter>
              <LoginView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.registration}
          render={() => (
            <SuspenseWrapper withFooter>
              <RegistrationView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.activation}
          render={() => (
            <SuspenseWrapper withFooter>
              <ConfirmEmailView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.adminActivation}
          render={() => (
            <SuspenseWrapper withFooter>
              <ConfirmEmailFromAdminView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.profile}
          render={() => (
            <SuspenseWrapper>
              <UserProfileView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.admin}
          render={() => (
            <SuspenseWrapper>
              <MainAdminPanelView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.availability_statement}
          render={() => (
            <SuspenseWrapper withFooter>
              <Regulations
                regulations={mainRoutePaths.availability_statement}
              />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.terms}
          render={() => (
            <SuspenseWrapper withFooter>
              <Regulations regulations={mainRoutePaths.terms} />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.privacy}
          render={() => (
            <SuspenseWrapper withFooter>
              <Regulations regulations={mainRoutePaths.privacy} />
            </SuspenseWrapper>
          )}
        />
        <Route
          exact
          path={mainRoutePaths.resetPassword}
          render={() => (
            <SuspenseWrapper>
              <ResetPasswordView withFooter />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.setNewPassword}
          render={() => (
            <SuspenseWrapper>
              <SetNewPasswordView withFooter />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.activateChangedEmail}
          render={() => (
            <SuspenseWrapper>
              <ActivateChangedEmailView withFooter />
            </SuspenseWrapper>
          )}
        />
        <Route path={`/:prefix(${districtRegex})`} component={DistrictRouter} />
        <Route path={mainRoutePaths.forbidden} component={ForbiddenView} />
        <Route
          exact
          path={'/popup_details'}
          render={() => (
            <SuspenseWrapper>
              <PopUpDetailsView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.notFound}
          render={() => (
            <SuspenseWrapper>
              <Page404 />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.maintenancePage}
          render={props => (
            <SuspenseWrapper>
              <MaintenancePage {...props} />
            </SuspenseWrapper>
          )}
        />
        <Route
          component={() => (
            <Redirect
              to={{
                pathname: mainRoutePaths.notFound
              }}
            />
          )}
        />
      </Switch>
      <LogoutCountdownTimer />
    </Router>
  );
};

export default PortalRouter;
