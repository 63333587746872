import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Select, Col, Popconfirm } from 'antd';

import { deleteReportConfig } from '../../store/actions/reportsActions';

import { Button } from '..';
import { antdSelectFilterOption, sortAlphabetic } from '../../utils/lib';

import WrappedGlobalSettingsForForm from './components/GlobalSettingsForForm';

const { Option } = Select;

export const ReportConfigsSelector = props => {
  const { prefix } = props.match.params;
  const configs = useSelector(state => state.reports.get('reportConfigs'));
  const deleteingReportConfig = useSelector(state =>
    state.reports.get('deleteingReportConfig')
  );
  const dispatch = useDispatch();

  const toggleAddNewConfigForm = () => {
    if (props.openEdit) props.setOpenEdit(false);
    props.setOpenNew(prevOpen => !prevOpen);
    props.setConfig(undefined);
  };

  const toggleEditConfigForm = () => {
    props.setOpenEdit(prevEdit => !prevEdit);
    if (props.openNew) props.setOpenNew(false);
  };

  const deleteReport = () => {
    props.setDeleteingConfig(true);

    dispatch(deleteReportConfig(prefix, props.config))
      .then(() => {
        props.setConfig(undefined);
      })
      .finally(() => {
        props.setDeleteingConfig(false);
        props.setOpenEdit(false);
      });
  };

  const onChangeSelect = id => {
    props.setOpenNew(false);
    props.setOpenEdit(false);
    props.setConfig(id);
  };

  return (
    <>
      <WrappedGlobalSettingsForForm />
      <Row type="flex" align="middle">
        <Col xs={8}>
          <span className="bold">Wybierz konfigurację kolorów:</span>
        </Col>
        <Col xs={16}>
          <Select
            style={{ width: '100%' }}
            value={props.config}
            onChange={onChangeSelect}
            placeholder="Wyszukiwarka..."
            showSearch
            filterOption={antdSelectFilterOption}
          >
            {configs
              .sort((a, b) => sortAlphabetic(a, b, 'name'))
              .map(({ id, name }) => (
                <Option key={`${id}-${name}`} value={id}>
                  {name}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" style={{ marginTop: 10 }}>
        <Button variant="primary" onClick={toggleAddNewConfigForm}>
          Dodaj nową
        </Button>
        <div>
          {props.config ? (
            <Popconfirm
              title="Czy na pewno chcesz usunąć aktualnie wybraną konfigurację?"
              onConfirm={deleteReport}
              okText="Tak"
              cancelText="Nie"
            >
              <Button variant="danger" loading={deleteingReportConfig}>
                Usuń
              </Button>
            </Popconfirm>
          ) : null}
          {props.config ? (
            <Button variant="primary" onClick={toggleEditConfigForm}>
              {!props.openEdit ? 'Otwórz edycje' : 'Zamknij edycje'}
            </Button>
          ) : null}
        </div>
      </Row>
    </>
  );
};

ReportConfigsSelector.propTypes = {
  config: PropTypes.number.isRequired,
  openEdit: PropTypes.bool.isRequired,
  openNew: PropTypes.bool.isRequired,
  setConfig: PropTypes.func.isRequired,
  setOpenEdit: PropTypes.func.isRequired,
  setOpenNew: PropTypes.func.isRequired
};

export default withRouter(ReportConfigsSelector);
