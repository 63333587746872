import { CustomLogger } from '../CustomLogger';

function createHiddenInput(text) {
  const input = document.createElement('input');
  input.value = text;
  input.style.dispaly = 'none';
  return input;
}

export default function copy(value) {
  try {
    const isValueString = typeof value === 'string';
    const input = isValueString ? createHiddenInput(value) : value;

    if (!(isValueString || value instanceof HTMLElement)) {
      throw Error('Passed value must be a string or a HTMLElement!');
    }

    isValueString && document.body.appendChild(input);

    input.select();
    document.execCommand('copy');

    isValueString && document.body.removeChild(input);

    return true;
  } catch (err) {
    CustomLogger.error(err);
    return false;
  }
}
