import { createCanvas, getBase64ImageSize } from '../';

/**
 * Zmienia rozmiar przekazanego obrazu Base64.
 *
 * @param {string} img
 * @param {number} width
 * @param {number} height
 * @returns {string}
 */
export default function resizeBase64Image(img, width, height) {
  return new Promise((resolve, reject) => {
    try {
      const imgElem = new Image();

      imgElem.onload = async () => {
        const { width: imgWidth, height: imgHeight } = await getBase64ImageSize(
          img
        );

        const { canvas, ctx } = createCanvas({
          width: imgWidth,
          height: imgHeight
        });
        ctx.drawImage(imgElem, 0, 0, width, height);

        // Przycinamy canvas
        const { canvas: canvas2, ctx: ctx2 } = createCanvas({ width, height });
        ctx2.drawImage(canvas, 0, 0);

        resolve(canvas2.toDataURL());
      };

      imgElem.src = img;
    } catch (err) {
      reject(err);
    }
  });
}
