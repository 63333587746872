import { createContext, createRef } from 'react';

const LeafletMapContext = createContext({
  leafletMap: createRef(),
  getLeafletElement: () => null,
  toggleGeolocation: map => null
});

LeafletMapContext.displayName = 'LeafletMapContext';

export default LeafletMapContext;
