import mainRoutePaths from '../../../router/portal/paths';

/**
 * Funkcja zwraca adres domyślnej strony głównej dla danego portalu.
 *
 * @param {Immutable.Map} globalSettings - obiekt ustawień globalnych.
 */

const getHomePageUrl = globalSettings => {
  const isDistrictsListAsHomePage = globalSettings.get(
    'home_page_is_districts_list'
  );
  return isDistrictsListAsHomePage
    ? mainRoutePaths.districtList
    : globalSettings.get('home_page_link');
};

export default getHomePageUrl;
