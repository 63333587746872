import { Component } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import autoBind from '../../utils/autoBind';
import handleError from '../../utils/handleError';
import {
  fetchUserSettings,
  showLogoutCountdownTimer,
  hideLogoutCountdownTimer
} from '../../store/actions/globalActions';
import { fetchDistrictSettings } from '../../store/actions/districtActions';
import debounce from 'lodash/debounce';

import { logoutUser } from '../../store/actions/userActions';

import { districtSelectors, userSelectors } from '../../store/selectors';
import { UserDataShape } from '../../shapes/user';

import getAdAswVariables from '../../utils/lib/getAdAswVariables';
import { DistrictSettingsShape } from '../../shapes/district';

export class SessionProvider extends Component {
  constructor(props) {
    super(props);
    this.aswIframe = React.createRef();

    this.state = {
      logoutTime: null,
      warningTime: null,
      userSettings: {},
      adAswVar: {}
    };

    this.events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'wheel',
      'keydown'
    ];

    autoBind(this);
  }

  componentDidMount() {
    this.fetchDetails();
    if (this.props.userData) {
      this.getData().then(() => {
        this.setListeners();
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.fetchDetails();
    if (this.props.userData !== prevProps.userData) {
      if (this.props.userData) {
        this.getData().then(() => {
          this.setListeners();
        });
      } else {
        this.destroy();
      }
    }
    if (this.props.districtSettings !== prevProps.districtSettings) {
      const prefix = window.location.pathname?.split('/')[1];
      this.setState({
        adAswVar: getAdAswVariables(
          this.props.districtSettings,
          window.location.origin,
          prefix
        )
      });
    }
    if (
      prevState.adAswVar.isAdAsw !== this.state.adAswVar.isAdAsw &&
      this.state.adAswVar.isAdAsw
    ) {
      window.addEventListener('message', this.messageHandler);
      const intervalId = setInterval(this.callAswInput, 2000);
      this.setState({ intervalId });
    }
  }

  componentWillUnmount() {
    if (this.state.adAswVar.isAdAsw) {
      window.removeEventListener('message', this.messageHandler);
      clearInterval(this.state.intervalId);
    }
  }

  messageHandler(event) {
    if (
      // eslint-disable-next-line eqeqeq
      event.data == 'False' &&
      localStorage.getItem('jwt') &&
      localStorage.getItem('id_token') &&
      localStorage.getItem('isAdAsw')
    ) {
      // localStorage.clear();
      // window.location.reload();
    }
  }

  sendAswCommand(command) {
    const data = {
      check: command
    };
    const domain = '*';
    // eslint-disable-next-line
    // document.getElementById('iframeOP').contentWindow.postMessage(data, domain);
    this.aswIframe.current.contentWindow.postMessage(data, domain);
  }

  callAswInput() {
    this.sendAswCommand('login');
  }

  getData() {
    return new Promise((resolve, reject) => {
      this.props
        .fetchUserSettings()
        .then(data => {
          const settings = {};

          data.length &&
            data.forEach(v => {
              settings[v.name] = v.value;
            });

          //zamieniamy minuty na milisekundy; warning pokazujemy minute przed wylogowaniem
          this.setState({
            userSettings: settings,
            logoutTime: settings.IDLE_TIMEOUT_MINUTES
              ? parseInt(settings.IDLE_TIMEOUT_MINUTES) * 60 * 1000
              : null,
            warningTime: settings.IDLE_MESSAGE_MINUTES
              ? parseInt(settings.IDLE_MESSAGE_MINUTES) * 60 * 1000
              : null
          });

          resolve(data);
        })
        .catch(err => {
          handleError(err);
          reject(err);
        });
    });
  }

  fetchDetails = () => {
    const prefix = window.location.pathname?.split('/')[1];
    if (this.props.districtSettings || !prefix) return;

    this.props.fetchDistrictSettings(prefix);
  };

  setListeners() {
    this.events.forEach(event => {
      window.addEventListener(event, this.resetTimeout);
      window.addEventListener(event, this.setTimeout);
    });

    window.addEventListener('storage', this.logoutInAllTabs);
    const timestamp = Date.now();
    localStorage.setItem('sessionTime', timestamp);
  }

  logoutInAllTabs(e) {
    if (e.key === 'jwt' && e.oldValue && !e.newValue) {
      this.logout();
    }
  }

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout = debounce(() => {
    this.warnTimeout = setTimeout(this.checkTime, this.state.warningTime);
    this.logoutTimeout = setTimeout(this.checkTime, this.state.logoutTime);
  }, 250);

  resetTimeout = debounce(() => {
    this.checkTime(true);
  }, 250);

  checkTime(setToStorage = false) {
    const date1 = localStorage.getItem('sessionTime');
    const timestamp = Date.now();
    const difference = timestamp - date1;
    //dodajemy dwie sekundy aby tylko w tym przedziale czasowym pokazal sie warning
    const warningTime = this.state.warningTime + 2000;

    if (difference <= this.state.warningTime) {
      this.props.hideLogoutCountdownTimer();
    }

    if (this.state.logoutTime && difference >= this.state.logoutTime) {
      this.logout();
    }
    if (
      this.state.warningTime &&
      difference >= this.state.warningTime &&
      difference <= warningTime
    ) {
      this.props.showLogoutCountdownTimer();
    }

    
    //wpisujemy co 2 sekundy nowy czas
    if (difference >= 2 * 1000 && setToStorage) {
      localStorage.setItem('sessionTime', timestamp);
      this.clearTimeout();
    }
  }

  logout() {
    this.props.hideLogoutCountdownTimer();
    this.props.logoutUser();
    this.destroy();
  }

  destroy() {
    this.clearTimeout();
    this.events.forEach(event => {
      window.removeEventListener(event, this.resetTimeout);
      window.removeEventListener(event, this.setTimeout);
    });
  }

  renderAswIframe() {
    return (
      <iframe
        width="0"
        height="0"
        title="ASW Iframe"
        ref={this.aswIframe}
        src={`${this.state.adAswVar.aswIdentityHost}/LoggedIn`}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          display: 'none'
        }}
      ></iframe>
    );
  }

  render() {
    return this.state.adAswVar.isAdAsw ? this.renderAswIframe() : null;
  }
}

const mapStateToProps = state => ({
  userData: userSelectors.userData(state),
  districtSettings: districtSelectors.getDistrictSettings(state)
});

const mapDispatchToProps = {
  showLogoutCountdownTimer,
  hideLogoutCountdownTimer,
  logoutUser,
  fetchUserSettings,
  fetchDistrictSettings
};

SessionProvider.propTypes = {
  showLogoutCountdownTimer: PropTypes.func.isRequired,
  hideLogoutCountdownTimer: PropTypes.func.isRequired,
  userData: UserDataShape,
  districtSettings: DistrictSettingsShape,
  logoutUser: PropTypes.func.isRequired,
  fetchUserSettings: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SessionProvider));
