import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSearchableModels } from '../../store/actions/modelsActions';

import handleError from '../../utils/handleError';

const useSearchableModels = () => {
  const dispatch = useDispatch();

  const searchableModels = useSelector(state =>
    state.models.get('searchableModels')
  );
  const [isFetching, setIsFetching] = useState(false);
  const [isFetched, setIsFetched] = useState(!!searchableModels.size);

  const getSearchableModels = async () => {
    if (searchableModels.size) return;

    setIsFetching(true);

    try {
      await dispatch(fetchSearchableModels());
      setIsFetched(true);
    } catch (err) {
      handleError(err);
    } finally {
      setIsFetching(false);
    }
  };

  React.useEffect(() => {
    getSearchableModels();
  }, []);

  return { searchableModels, isFetching, isFetched };
};

export default useSearchableModels;
