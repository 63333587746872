import { useState, useEffect, useMemo, memo } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import { Menu, Icon, Dropdown, Avatar } from 'antd';
import { Button } from '../';

import { logoutUser } from '../../store/actions/userActions';
import {
  userSelectors,
  mapPortalSelectors,
  districtSelectors
} from '../../store/selectors';

import useGlobalSettings from '../../hooks/useGlobalSettings';

import { roles } from '../../config';
import mainRoutePaths from '../../router/portal/paths';

import getAdAswVariables from '../../utils/lib/getAdAswVariables';

import './UserMenu.less';

const MenuItem = Menu.Item;

const { userData: getUserData } = userSelectors;
const { getMapPortalId } = mapPortalSelectors;
const { getDistricts, getDistrictSettings } = districtSelectors;

export const UserMenu = () => {
  const { key, pathname } = useLocation();
  const globalSettings = useGlobalSettings({ toJS: false });

  const show_map_portals_list_button = useMemo(
    () => globalSettings.get('show_map_portals_list_button'),
    [globalSettings]
  );
  const show_districts_list_button = useMemo(
    () => globalSettings.get('show_districts_list_button'),
    [globalSettings]
  );

  const districts = useSelector(state => getDistricts(state));
  const districtSettings =
    useSelector(state => getDistrictSettings(state)) || {};
  const district = useMemo(
    () => (districts.size === 1 ? districts.get(0) : null),
    [districts]
  );

  const getDistrictFromPath = () => {
    const path = pathname.split('/');
    const noDistricts = ['admin', 'profil', 'lista_jednostek'];
    const prefix = district || path[1];
    const portal = path[2];

    return noDistricts.includes(prefix) ? null : { prefix, portal };
  };

  const routeParams = getDistrictFromPath();
  const prefix = useMemo(() => routeParams?.prefix, [routeParams]);
  const portal = useMemo(() => routeParams?.portal, [routeParams]);

  const dispatch = useDispatch();
  const user = useSelector(state => getUserData(state));
  const mapPortalId = useSelector(state =>
    getMapPortalId(state, prefix, portal)
  );
  const { isAdAsw, aswEndSessionUrl, aswLogoutRedirecUri } = getAdAswVariables(
    districtSettings,
    window.location.origin,
    prefix
  );

  const [menuOpen, setMenuOpen] = useState(false);

  const isInAdminPanel = useMemo(() => pathname.includes('/admin'), [pathname]);
  const userData = useMemo(() => user?.toJS(), [user]);
  const userHasAdminRole =
    userData &&
    userData.roles.find(
      role =>
        role.name === roles.administrator || role.name === roles.superadmin
    );

  useEffect(() => {
    setMenuOpen(false);
  }, [key]);

  const toggleMenuVisiblity = () => {
    setMenuOpen(prevValue => !prevValue);
  };

  const getInitials = () => {
    const firstNameLetter = userData.first_name[0];
    const lastNameLetter = userData.last_name[0];

    return firstNameLetter.toUpperCase() + lastNameLetter.toUpperCase();
  };

  const logoutAswUser = () => {
    const idToken = localStorage.getItem('id_token');
    if (idToken) {
      window.location.href = `${aswEndSessionUrl}?id_token_hint=${idToken}&post_logout_redirect_uri=${aswLogoutRedirecUri}`;
    }
  };

  const logoutUserFn = () => {
    if (isAdAsw) {
      dispatch(logoutUser(logoutAswUser));
    } else {
      dispatch(logoutUser());
    }
  };

  const checktDistrictPrefix = () => {
    const wrongDistrictPrefix = [
      'maintenance',
      'not_found',
      mainRoutePaths.availability_statement.replace('/', ''),
      mainRoutePaths.terms.replace('/', ''),
      mainRoutePaths.privacy.replace('/', '')
    ];
    if (wrongDistrictPrefix.includes(prefix)) return false;
    return prefix;
  };

  const hasValidDistrictPrefix = checktDistrictPrefix();

  const items = (
    <Menu>
      {userData && (
        <span className="user-menu-account-name">
          <Avatar src={userData.image} icon="user" size={20} />
          {userData.email}
        </span>
      )}
      <MenuItem className="user-menu-item" key="profile">
        <Link to={mainRoutePaths.profile}>
          <Icon type="user" aria-label="user" />
          <FormattedMessage
            id="main_user_menu_profile"
            defaultMessage="Profile"
          />
        </Link>
      </MenuItem>
      {userHasAdminRole && !isInAdminPanel && hasValidDistrictPrefix ? (
        <MenuItem className="user-menu-item" key="admin">
          <Link to={`/${prefix}/admin`}>
            <Icon type="tool" aria-label="tool" />
            <FormattedMessage
              id="main_user_menu_admin_panel"
              defaultMessage="Administration panel"
            />
          </Link>
        </MenuItem>
      ) : null}
      {userHasAdminRole && !isInAdminPanel && prefix && portal ? (
        <MenuItem className="user-menu-item" key="edit-map-portal">
          <Link to={`/${prefix}/admin/portale-mapowe/edytuj/${mapPortalId}`}>
            <Icon type="edit" aria-label="edit" />
            <FormattedMessage
              id="main_user_menu_edit_map_portal"
              defaultMessage="Edit map portal"
            />
          </Link>
        </MenuItem>
      ) : null}
      {show_map_portals_list_button && hasValidDistrictPrefix ? (
        <MenuItem className="user-menu-item" key="map-portals">
          <Link to={`/${prefix}`}>
            <Icon type="global" aria-label="global" />
            <FormattedMessage
              id="general_map_portals"
              defaultMessage="Map portals"
            />
          </Link>
        </MenuItem>
      ) : null}
      {show_districts_list_button ? (
        <MenuItem className="user-menu-item" key="districts">
          <Link to={mainRoutePaths.districtList}>
            <Icon type="appstore" />
            <FormattedMessage
              id="general_choose_district"
              defaultMessage="Choose district"
            />
          </Link>
        </MenuItem>
      ) : null}
      <MenuItem className="user-menu-item" key="logout">
        <Button variant="text" onClick={logoutUserFn}>
          <Icon type="logout" aria-label="logout" />
          <FormattedMessage
            id="main_user_menu_sign_out"
            defaultMessage="Sign out"
          />
        </Button>
      </MenuItem>
    </Menu>
  );

  return userData ? (
    <Dropdown overlay={items} overlayclassname="user-menu" visible={menuOpen}>
      <Button
        variant="text"
        className="user-menu-title"
        onClick={toggleMenuVisiblity}
        aria-haspopup="true"
        aria-expanded={menuOpen}
      >
        {menuOpen ? (
          <FormattedMessage
            id="main_user_menu_aria_label_close"
            defaultMessage="Close menu of user"
          >
            {text => <span className="sr-only">{text}</span>}
          </FormattedMessage>
        ) : (
          <FormattedMessage
            id="main_user_menu_aria_label_open"
            defaultMessage="Open menu of user"
          >
            {text => <span className="sr-only">{text}</span>}
          </FormattedMessage>
        )}
        <div className="user-menu-avatar-wrapper">
          <Avatar src={userData.image} size={40}>
            <span className="user-menu-avatar-initials">{getInitials()}</span>
          </Avatar>
        </div>
        <Icon
          type={menuOpen ? 'caret-up' : 'caret-down'}
          aria-label="caret-icon"
          role="img"
        />
      </Button>
    </Dropdown>
  ) : null;
};

export default memo(UserMenu);
