import Worker from './cropImageHorizontal.worker.js';

const cropImageHorizontalWorker = (from, to, imgData) =>
  new Promise((resolve, reject) => {
    const worker = new Worker();

    worker.postMessage({ from, to, imgData });

    worker.onmessage = ({ data }) => {
      if (data.isValid) {
        resolve(data.newImgData);
      }

      reject(data.error);
    };

    worker.onerror = err => reject(err);
  });

export default cropImageHorizontalWorker;
