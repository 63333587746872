import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import { Row, Input } from 'antd';
import { Button } from '../';
import FilesLibrary from '../FilesLibrary';

import { getFileId } from '../../services';

import { getFilePathFromUrl } from '../../utils/lib';
import handleError from '../../utils/handleError';

import './FileInput.less';

const FileInput = ({
  onChange,
  disabled,
  areButtonsVisible = true,
  ...props
}) => {
  const { prefix } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isFilesLibraryOpen, setIsFilesLibraryOpen] = useState(false);
  const [value, setValue] = useState(props.value);

  const closeFilesLibrary = () => setIsFilesLibraryOpen(false);

  const handleChange = async ({ path } = {}) => {
    let fileId = null;

    if (path) {
      fileId = await getFileId({ district: prefix, path });
      fileId = fileId.uuid;
    }

    onChange({ path, fileId });

    isFilesLibraryOpen && closeFilesLibrary();
  };

  const getValue = async () => {
    if (!props.value) return setValue('');

    setIsLoading(true);

    try {
      const newValue = await getFilePathFromUrl(props.value, prefix);
      setValue(newValue);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getValue();
  }, [props.value]);

  return (
    <>
      {isFilesLibraryOpen && (
        <FilesLibrary
          visible
          handleOk={handleChange}
          handleCancel={closeFilesLibrary}
          parent={props.parent}
        />
      )}
      <Row type="flex" className="file-input">
        <Input
          value={isLoading ? 'Wczytywanie ścieżki...' : value}
          disabled={disabled}
        />
        {areButtonsVisible && (
          <>
            <Button
              variant="settingsDefault"
              onClick={() => setIsFilesLibraryOpen(true)}
              disabled={disabled}
            >
              Plik
            </Button>
            <Button
              variant="danger"
              onClick={() => handleChange()}
              disabled={disabled}
            >
              Usuń
            </Button>
          </>
        )}
      </Row>
    </>
  );
};

FileInput.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default FileInput;
