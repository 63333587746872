import PropTypes from 'prop-types';

import { Button } from '../';
import { Icon } from 'antd';

const ButtonSave = props => (
  <Button variant="success" onClick={props.onClick} {...props.btnProps}>
    {!props.saving ? (
      <span>
        <Icon type="save" /> Zapisz
      </span>
    ) : (
      <span>
        <Icon type="loading" /> Zapisywanie...
      </span>
    )}
  </Button>
);

ButtonSave.propTypes = {
  saving: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  btnProps: PropTypes.object
};

export default ButtonSave;
