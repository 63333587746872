import { decodeSlash, decodeQuestionMark, decodeAmpersand } from '../';

/**
 * Dekoduje znaki specjalne występujące w URL-ach
 *
 * @param {string} url - url do zakodowania
 * @return {string}
 */
const decodeUrl = url => decodeSlash(decodeQuestionMark(decodeAmpersand(url)));

export default decodeUrl;
