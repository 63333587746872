import { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { showError } from '../../store/actions/globalActions';

import useSymbolizationMessages from '../useSymbolizationMessages';

import { getRenderedIcon } from '../../utils/getRenderedIcon';
import handleError from '../../utils/handleError';

import { ICONS_LIST } from '../../constants/icons-list';
import { DEFAULT_LEAFLET_COLOR } from '../../components/MapControl/layer-editor/const';
import { BASIC_MARKER_ICON } from '../../constants/basic-marker-icon';

const DEFAULT_SYMBOL_NAME = ICONS_LIST[0].name;
const DEFAULT_SYMBOL_COLOR = 'rgba(255, 255, 255, 0.9)';
export const DEFAULT_SYMBOL_SIZE = 25;

const usePointSymbolization = ({
  symbolizationType,
  changeSymbolizationMenu,
  defaultSymbolSize = DEFAULT_SYMBOL_SIZE,
  defaultSymbolColor = DEFAULT_LEAFLET_COLOR,
  defaultSymbol = 'StarIcon'
}) => {
  const [symbol, setSymbol] = useState(null);
  const [symbolColor, setSymbolColor] = useState(null);
  const [symbolSize, setSymbolSize] = useState(null);
  const [svgFileList, setSvgFileList] = useState([]);
  const [customSymbol, setCustomSymbol] = useState(null);

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { basicMsg } = useSymbolizationMessages();

  const handleDefaultValues = useCallback(() => {
    if (symbolizationType === basicMsg) {
      setSymbolSize(symbolSize ?? defaultSymbolSize);
      setSymbolColor(symbolColor ?? defaultSymbolColor);
      setSymbol(symbol ?? defaultSymbol);
    }
  }, [defaultSymbolSize, defaultSymbolColor, defaultSymbol, symbolizationType]);

  useEffect(handleDefaultValues, [handleDefaultValues]);

  const getIcon = useCallback(
    giveSymbolSize => {
      if (symbolizationType === 'SVG' && !!svgFileList.length)
        return customSymbol;
      if (symbolizationType === 'SVG' && !svgFileList.length)
        return BASIC_MARKER_ICON;

      if (!symbol && !symbolColor && !symbolSize) return;

      const iconName = symbol ?? DEFAULT_SYMBOL_NAME;
      const iconColor = symbolColor ?? DEFAULT_SYMBOL_COLOR;
      const iconSize = (giveSymbolSize || symbolSize) ?? DEFAULT_SYMBOL_SIZE;

      const renderIconFn = getRenderedIcon(iconName);

      const iconAnchorValue = symbolSize / 2;
      const icon = {
        html: renderIconFn(iconSize, iconColor),
        className: 'custom-leaflet-marker-icon',
        iconSize: [symbolSize, symbolSize],
        iconAnchor: [iconAnchorValue, iconAnchorValue]
      };

      return icon;
    },
    [
      symbolizationType,
      svgFileList,
      customSymbol,
      symbolSize,
      symbol,
      symbolColor
    ]
  );

  const formValues = useMemo(
    () => ({
      symbol,
      symbolColor,
      symbolSize: symbolSize ?? DEFAULT_SYMBOL_SIZE,
      // uid oraz name jest potrzebny do poprawnego wyświetlania uploadowanego pliku
      // podczas edycji szkicu
      svgFileList: svgFileList.length
        ? [{ uid: svgFileList[0].uid, name: svgFileList[0].name }]
        : null,
      customSymbol
    }),
    [symbol, symbolColor, symbolSize, svgFileList, customSymbol]
  );

  const restoreInitialValues = useCallback(() => {
    setSymbol(null);
    setSymbolColor(null);
    setSymbolSize(null);
    setSvgFileList([]);
    setCustomSymbol(null);
  }, []);

  const setSvgAsText = useCallback(file => {
    const reader = new FileReader();
    reader.onload = event => setCustomSymbol(event.target.result);
    reader.onerror = error => handleError(error);
    reader.readAsText(file);
  }, []);

  const customUploadAction = (file, callback) => {
    if (file) callback([file]);
  };

  const uploadSvgProps = useMemo(
    () => ({
      accept: '.svg',
      name: 'svgFile',
      onRemove: () => {
        setSvgFileList([]);
        setCustomSymbol(null);
      },
      customRequest: () => false,
      beforeUpload: file => {
        if (file.type !== 'image/svg+xml') {
          const errorTypeFile = formatMessage({
            id: 'district_portal_svg_type_err',
            defaultMessage:
              'Invalid data type! Please send the file in ".svg" format.'
          });
          dispatch(showError(errorTypeFile));
          return false;
        }
        customUploadAction(file, file => {
          setSvgFileList(file);
          setSvgAsText(file[0]);
        });
        return false;
      },
      fileList: svgFileList
    }),
    [svgFileList]
  );

  const updateValues = useCallback(
    ({
      pointSymbolizationFormValues: {
        symbol,
        symbolColor,
        symbolSize,
        svgFileList,
        customSymbol
      }
    }) => {
      if (svgFileList) {
        setSvgFileList(svgFileList);
        setCustomSymbol(customSymbol);
        changeSymbolizationMenu();
        return;
      }

      setSymbol(symbol);
      setSymbolColor(symbolColor);
      setSymbolSize(symbolSize);
    },
    []
  );

  const values = useMemo(
    () => ({
      symbol,
      symbolColor,
      symbolSize,
      svgFileList,
      uploadSvgProps,
      formValues,
      getIcon,
      restoreInitialValues,
      setSymbol,
      setSymbolColor,
      setSymbolSize,
      setSvgFileList,
      setCustomSymbol,
      updateValues
    }),
    [
      symbol,
      symbolColor,
      symbolSize,
      svgFileList,
      uploadSvgProps,
      formValues,
      getIcon,
      restoreInitialValues,
      updateValues
    ]
  );

  return values;
};

export default usePointSymbolization;
