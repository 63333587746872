import axios from 'axios';

const validateEmailExisting = config =>
  new Promise((resolve, reject) => {
    const { email = '', onValid, onInvalid, onError } = config;

    axios
      .get(`auth/email_verification/`, {
        params: { email },
        validateStatus: status =>
          (status >= 200 && status < 300) || status === 404
      })
      .then(response => {
        if (response.status === 200) {
          onValid && onValid();
          resolve('valid');
        }

        if (response.status === 404) {
          onInvalid && onInvalid();
          resolve('invalid');
        }
      })
      .catch(err => {
        onError && onError(err);
        reject(err);
      });
  });

export default validateEmailExisting;
