import { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import disableMapPropagationByRef from '../../utils/mapUtils/disableMapPropagationByRef';
import LeafletMapContext from '../../contexts/LeafletMapContext';
import './MapToolbar.less';

const MapToolbar = ({ children, className: propsClassName }) => {
  const className = classNames('map-toolbar', propsClassName);
  return <ul className={className}>{children}</ul>;
};

MapToolbar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export const MapToolbarItem = ({
  children,
  onClick,
  name,
  disabled,
  active
}) => {
  const { getLeafletElement } = useContext(LeafletMapContext);

  const className = classNames({
    'map-toolbar-item-active': active
  });

  const disableDoubleClickZoom = () =>
    getLeafletElement().doubleClickZoom.disable();
  const enableDoubleClickZoom = () =>
    getLeafletElement().doubleClickZoom.enable();

  return (
    <li
      className={className}
      title={name}
      onMouseEnter={disableDoubleClickZoom}
      onMouseLeave={enableDoubleClickZoom}
    >
      <button
        ref={disableMapPropagationByRef}
        onClick={onClick}
        aria-label={name}
        disabled={disabled}
      >
        {children}
      </button>
    </li>
  );
};

MapToolbarItem.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  active: PropTypes.bool
};

export default MapToolbar;
