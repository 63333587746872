import PropTypes from 'prop-types';

import './AccessabilityMenu.less';

const AccessabilityMenu = ({ items = [] }) => {
  return (
    <ul className="accessability-menu">
      {items.map(({ href, name }) => (
        <li key={href}>
          <a href={href}>{name}</a>
        </li>
      ))}
    </ul>
  );
};

const MenuItemPropTypes = PropTypes.shape({
  href: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});

AccessabilityMenu.propTypes = {
  items: PropTypes.arrayOf(MenuItemPropTypes).isRequired
};

export default AccessabilityMenu;
