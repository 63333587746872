import { Col, Modal, Row } from 'antd';
import PropTypes from 'prop-types';

import useHandleModalLogic from './hooks/useHandleModalLogic';

import LabeledSwitch from '../LabeledSwitch';

import './AdminToolsConfigurationModal.less';

const AdminToolsConfigurationModal = ({ tool, closeEditor, mapPortalId }) => {
  const {
    saveChanges,
    switchList,
    configuration,
    handleChange,
    title
  } = useHandleModalLogic({
    tool,
    closeEditor,
    mapPortalId
  });

  return (
    <Modal
      className="custom-modal admin-tool-modal "
      title={title}
      visible={tool}
      onCancel={closeEditor}
      onOk={saveChanges}
      okText="Zapisz"
    >
      {switchList.map(({ label, key }) => (
        <Row key={key} type="flex" align="middle">
          <Col span={15}>{label}</Col>
          <Col span={8} offset={1}>
            <LabeledSwitch
              offLabel="nie"
              onLabel="tak"
              checked={configuration?.[key]}
              onChange={value => handleChange(value, key)}
            />
          </Col>
        </Row>
      ))}
    </Modal>
  );
};

AdminToolsConfigurationModal.propTypes = {
  tool: PropTypes.object,
  closeEditor: PropTypes.func.isRequired
};
export default AdminToolsConfigurationModal;
