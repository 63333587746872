export const getArrayDepth = array => {
  if (!Array.isArray(array)) {
    return 0;
  }

  let maxDepth = 1;
  array.forEach(element => {
    const depth = getArrayDepth(element) + 1;
    if (depth > maxDepth) {
      maxDepth = depth;
    }
  });

  return maxDepth;
};
