import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { mapPortalSelectors } from '../../store/selectors';

const { getMapPortalId } = mapPortalSelectors;

const useMapPortalId = () => {
  const { prefix, portal } = useParams();
  return useSelector(state => getMapPortalId(state, prefix, portal));
};

export default useMapPortalId;
